import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { ToastService } from "src/app/services/utils/toast.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { WhitelabelService } from 'src/app/services/pipeline/whitelabel.service';

import { WhitelabelAppPopupDevicePage } from '../whitelabel-app-popup-device/whitelabel-app-popup-device.page';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-whitelabel-app',
  standalone: false,
  templateUrl: './whitelabel-app.page.html',
  styleUrls: ['./whitelabel-app.page.scss'],
})
export class WhitelabelAppPage implements OnInit {

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  app: whitelabelApp = {};

  fallbackImg: string = './assets/img/fallback.webp';
  
  search: searchOptions = {
    keys: ['title', 'location', 'name'],
    itemsKey: 'apps',
    query: '',
  };

  user: user;

  view: any = {
    device: {
      icon: 'phone-portrait-outline',
    },
    editMode: false,
    hideGetGeniusWallet: true,
    itemSize: 67,
    mode: {
      icon: 'shapes-outline',
    },
    placeholders: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
    sidebar: [
      {
        icon: 'information-outline',
        name: 'general',
        description: 'whitelabel_app_sidebar_item_general',
        uid: 'general',
      },
      {
        icon: 'code-outline',
        name: 'api',
        description: 'whitelabel_app_sidebar_item_api',
        uid: 'api',
      },
      {
        icon: 'eye-outline',
        name: 'look_and_feel',
        description: 'whitelabel_app_sidebar_item_look_and_feel',
        uid: 'look_and_feel',
      },
      {
        icon: 'list-outline',
        name: 'sources',
        description: 'whitelabel_app_sidebar_item_sources',
        uid: 'sources',
      },
      {
        icon: 'people-outline',
        name: 'testers',
        description: 'whitelabel_app_sidebar_item_testers',
        uid: 'testers',
      },
      {
        icon: 'folder-outline',
        name: 'projects',
        description: 'whitelabel_app_sidebar_item_projects',
        uid: 'projects',
      },
    ],
    sidebarSize: 4,
    tab: 'general',
  };

  constructor(
    private clipboard: ClipboardService,
    private events: EventsService,
    private modalService: ModalService,
    private popoverCtrl: PopoverController,
    private sources: SourcesService,
    private toast: ToastService,
    private userService: UserService,
    private viewService: ViewService,
    private whitelabelService: WhitelabelService,
  ) {
  }


  addSource() {
    this.sources.pick({
      multiple: true,
    } as any)
    .then((response: any) => {
      let iSuccess: number = 0;
      let iErrors: number = 0;

      if(response && response.data && response.data.items) {
        response.data.items.forEach((item: any, index: number) => {
          if(!!item.uid && !!this.app.uid) {
            setTimeout(() => {
              this.whitelabelService.addSourceToApp(item.uid, this.app.uid)
              .then(() => {
                iSuccess++;
                this.toast.show(`${item.name} (${iSuccess} gesamt) hinzugefügt`);

                if(index === (response.data.items.length-1)) {
                  this.doRefresh();
                }
              })
              .catch((error: any) => {
                iErrors++;

                if(!!error && (error.toLowerCase() !== 'error_pipeline_app_source_relation_already_exists')) {
                  this.events.publish('error', error);
                }

                if(index === (response.data.items.length-1)) {
                  this.doRefresh();
                }
              });
            }, (index * 50));
          }
        });
      }

    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canBack = false;
    this.view.canSave = true;
    this.view.canSlideNext = true;
    this.view.canUpdate = true;

    this.view.sidebarSize = (!!this.view.isDesktop ? 4 : 12);
  }

  copyToClipboard(input: string) {
    this.clipboard.copyText(input);
  }

  deleteSource(source: source) {
    this.whitelabelService
    .deleteSourceFromApp(source.uid, this.app.uid)
    .then(() => {
      this.doRefresh();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any|null = null) {
    this.load(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
      if(event) {
        event.target.complete();
      }
    });
  }
  
  editSourceSettings(source: source) {
    console.log('> edit source settings', source);
  }

  ionViewWillLeave() {
    this.whitelabelService.detailItem({} as any);
  }

  initView() {
    let app: whitelabelApp = (this.whitelabelService.detailItem() || {} as any);

    if(!!app) {
      this.app = app;
      this.app.config = this.app.config || {};
      this.app.sources = this.app.sources || [];
      this.app.testers = this.app.testers || [];
    }

    this.view.editMode = !!app;
  }
  
  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};

    if(!!(this.app && this.app.uid)) {
      this.load()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }

  }

  load(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {

      if(!this.app.uid) {
        resolve(null);
        return false;
      }
      
      this.whitelabelService.getAppByUid(this.app.uid, blForceRefresh)
      .then((app: whitelabelApp) => {
        this.app = app;
        this.app.config = this.app.config || {};
        this.app.sources = this.app.sources || [];
        this.app.testers = this.app.testers || [];
        
        resolve(app);
      })
      .catch(reject);
    });
  }

  ngOnInit() {
    this.initView();
    this.calcViewVars();
  }
  
  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
    this.view.sidebarSize = (!!this.view.isDesktop ? 4 : 12);
  }
  
  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch(e) {
      console.error('firing toolbar search failed', e);
    }
  }
  
  save() {
    this.whitelabelService.submitWhitelabelApp(this.app)
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  setup() {
    this.view.setupMode = !this.view.setupMode;
  }

  sideMenuItemcClicked(item: any) {

    this.view.sidebar.forEach((_item: any) => {
      item.active = (_item.uid === item.uid);
    });

    this.view.tab = item.uid;
  }

  toggleDarkMode() {
    this.view.darkMode = !this.view.darkMode;
  }

  async toggleDevice(event: any|null = null) {
    let popover = await this.popoverCtrl.create({
      component: WhitelabelAppPopupDevicePage,
      event: event,
      translucent: true
    });
    popover.present();
  }

  toggleSourceActive(source: source) {
    source.active = !source.active;
  }

  update() {
    this.whitelabelService.updateWhitelabelApp(this.app)
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
