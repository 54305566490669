import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AiTextToSpeechCardComponent } from './ai-text-to-speech-card.component';

import { AiSettingsPickerComponentModule } from 'src/app/components/ai/ai-settings-picker/ai-settings-picker.component.module';

@NgModule({
  declarations: [
    AiTextToSpeechCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    AiSettingsPickerComponentModule,
  ],
  exports: [
    AiTextToSpeechCardComponent,
  ]
})
export class AiTextToSpeechCardComponentModule { }