import { Component, NgZone, OnInit } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-create-media',
  standalone: false,
  templateUrl: './create-media.page.html',
  styleUrls: ['./create-media.page.scss'],
})
export class CreateMediaPage implements OnInit {

  appConfig: pipelineAppConfig;

  cards: any = {
    ai_style: { open: true },
    information: { open: true },
    media: { open: true },
    music: { open: false },
    output: { open: false },
    voiceover: { open: false },
  };

  search: searchOptions = {
    keys: ['name', 'post_content', 'host', 'title', 'url', 'uid'],
    query: "",
  };

  splineOptions: splineOptions = {
  };

  state: state = {};

  view: any = {
    buttonAction: 'generate',
    hideOrderByBtn: true,
    hideSearch: true,
    images: [],
    loading: false,
    selectedStrings: {},
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    title: 'create_media',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private params: ParamsService,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.view.sizes = this.templates.getScreenSizesList();
  }

  backToInput() {
    this.zone.run(() => {
      this.view.phase = 'input';
    });
  }

  calcColSize() {
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canCreate = (!!this.view.media && !!this.view.media.title && !!this.view.media.subtitle && !!this.view.mediaList && !!this.view.mediaList.length);
    this.view.canGenerate = (!!this.view.media && !!this.view.media.title && !!this.view.media.subtitle && !!this.view.mediaList && !!this.view.mediaList.length);
  }

  create() {
  }

  createVideoMedia() {

  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh(event: any | null = null) {

  }

  downloadSelected() {
    console.log('download selected', this.view);
  }

  async generate() {
    console.log('create-media: fire media:creator:run: view', this.view);
    
    this.events.publish('media:creator:run', {
      view: this.view,
    });
  }

  importSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'importFromUrl',
      identifier: 'url',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        item.downloaded = true;
      },
      service: this.media,
    })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
    this.params.set('viewData_createMedia', null);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadUIParams() {
    const createMediaParams: any = this.params.get('viewData_createMedia');

    if (!createMediaParams) {
      return false;
    }

    if (!!createMediaParams.search) {
      this.search = Object.assign(this.search, createMediaParams.search);
    }

    if (!!createMediaParams.view) {
      this.view = Object.assign(this.view, createMediaParams.view);
    }

    setTimeout(() => {
      if (!!createMediaParams.fire) {
        this.generate();
      } else {
        this.startManually();
      }
    }, 100);
  }

  ngOnInit() {
    this.calcViewVars();
    this.calcColSize();

    this.loadProject();
    this.loadUIParams();

    window.addEventListener('resize', () => {
      this.calcColSize();
      this.calcViewVars();
    });
  }

  onCreated() {
    this.view.phase = 'pick';
    //this.navCtrl.navigateForward('/media/queue');
  }

  onSelectionChanged(event: any | null = null) {

    if (!!this.view.images) {
      this.view.selectedItems = this.view.images.filter((item: any) => {
        return !!item.checked;
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = !!(!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSelectionActionChanged(event: any | null = null) {
    switch (event) {
      case 'download':
        this.downloadSelected();
        break;
      case 'import':
        this.importSelected();
        break;
      case 'upscale':
        this.upscaleSelected();
        break;
      case 'video_media':
        this.createVideoMedia();
        break;
    }
  }

  onViewChanged(event: any | null = null) {
    if (!!event) {
      this.view = event;
    }
  }

  startManually() {
    this.view.startManually = true;
  }

  upscaleSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'upscale',
      identifier: 'guid',
      items: this.view.selectedItems,
      service: this.sd,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}