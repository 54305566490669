<ion-card class="noEntriesCard">

  <ion-card-content>

    <!-- Icon -->
    <ion-icon [name]="icon || 'warning-outline'"></ion-icon>

    <!-- Title -->
    <ion-card-title [innerHTML]="(title || 'no_entries')|translate"></ion-card-title>

    <!-- Subtitle -->
    <ion-card-subtitle *ngIf="!!subtitle" [innerHTML]="subtitle|translate"></ion-card-subtitle>

    <!-- Text -->
    <p *ngIf="!!text" [innerHTML]="text|translate"></p>

    <!-- Call to action -->
    <ion-button fill="clear" size="small" (click)="ctaClick()" *ngIf="!!cta && (!!cta.handler || !!cta.url)">
      <ion-icon [name]="cta.icon" *ngIf="!!cta.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="(cta.label || 'add')|translate"></ion-label>
    </ion-button>

  </ion-card-content>

</ion-card>