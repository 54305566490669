import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { TeamsService } from 'src/app/services/core/teams.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { DynamicViewPageComponent } from 'src/app/components/generic/dynamic/dynamic-view-page/dynamic-view-page.component';

import { TeamPage } from '../team/team.page';

@Component({
  selector: 'app-teams',
  standalone: false,
  templateUrl: './teams.page.html',
  styleUrls: ['./teams.page.scss'],
})
export class TeamsPage implements OnInit {
  @ViewChild(DynamicViewPageComponent) viewPage: any;

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
  ];

  options: dynamicViewPageOptions = {
    labelKey: 'name',
    showPhoto: true,
    showViewModeSelect: true,
  };

  pages: any = {};

  search: searchOptions = {
    keys: ['title', 'description', 'name', 'url', 'uid'],
    itemsKey: 'teams',
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    forceProjectToBeSet: false,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'teams_admin_top_card',
      lottieSrc: './assets/lottie/teams.json',
      text: 'teams_admin_top_card_text',
      title: 'teams_admin_top_card_title',
    },
    itemSize: 64,
    multiple: true,
    options: {

    },
    route: 'teams',
    showViewModeSelect: true,
    title: 'teams',
    viewMode: 'list',
  };

  constructor(
    private events: EventsService,
    public service: TeamsService,
    private teams: TeamsService,
    private tools: ToolsService,
  ) {

  }

  deleteSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'delete',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        item.hidden = true;
      },
      service: this.teams,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh() {
    try {
      if (!!this.viewPage) {
        this.viewPage.doRefresh();
      }
    } catch (e) {
      console.error('refreshing view page failed', e);
    }
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: view.selectedItems', this.view.selectedItems);

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicate',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this.teams,
    })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {

    this.pages = {
      add: TeamPage,
      edit: TeamPage,
    };

    this.view.viewType = 'list';
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

}
