<ion-header class="ion-no-border">
  <pipeline-header-top-toolbar [(state)]="state" [(view)]="view"></pipeline-header-top-toolbar>
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>
</ion-header>

<ion-content>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-grid>

      <ion-row>

        <ion-col [size]="view.isDesktop ? 4 : 12">

          <!-- balance -->
          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_balance'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>

              <p [innerHTML]="'getgenius_wallet_balance_text'|translate"></p>

              <ion-spinner [hidden]="!!wallet && !!wallet.uid"></ion-spinner>

              <h2 *ngIf="!!wallet" [innerHTML]="(wallet.balance || '0') + ' ' + ('token'|translate)"></h2>

            </ion-card-content>

          </ion-card>

        </ion-col>

        <ion-col [size]="view.isDesktop ? 8 : 12">

          <!-- usage -->
          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_usage'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'getgenius_wallet_usage_text'|translate"></p>

              <ion-spinner [hidden]="!!view.usage"></ion-spinner>

              <canvas #usageChart [hidden]="!view.usage"></canvas>

            </ion-card-content>

          </ion-card>

        </ion-col>

      </ion-row>

      <ion-row>

        <!-- packages -->
        <ion-col [size]="view.isDesktop ? 4 : 12" *ngIf="appConfig.useWalletPackagesExtension" [hidden]="!!wallet && (wallet.balance > 0)">

          <ion-card class="auto-height">

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_packages'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'getgenius_wallet_packages_text'|translate"></p>

              <ion-spinner [hidden]="!!view.packages"></ion-spinner>

            </ion-card-content>

            <!-- Iterate packages -->
            <ion-grid *ngIf="!!view.packages && !!view.packages.length">

              <ion-row>

                <ion-col *ngFor="let package of view.packages; trackBy:trackItems" size="4" [hidden]="!package.price">

                  <ion-card>

                    <ion-thumbnail>
                      <ion-img [src]="package.photo || fallbackImg"
                        (ionError)="thumbnailLoadingFailed(package)"></ion-img>
                    </ion-thumbnail>

                    <ion-item lines="none" class="ion-text-wrap">
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="package.name|translate" class="ion-text-wrap"></h3>
                        <p [innerHTML]="(view.currencyPrefixSymbols[package.currency] || '') + package.price + (view.currencySuffixSymbols[package.currency] || '')"></p>
                      </ion-label>
                    </ion-item>

                    <ion-list *ngIf="!!package.benefits">

                      <ion-item *ngFor="let benefit of package.benefits" class="ion-text-wrap">
                        <ion-icon *ngIf="!!benefit.icon" [name]="benefit.icon" slot="start"></ion-icon>
                        <ion-label class="ion-text-wrap">
                          <h3 [innerHTML]="benefit.name"></h3>
                          <p [innerHTML]="benefit.description" *ngIf="!!benefit.description"></p>
                        </ion-label>
                      </ion-item>

                    </ion-list>

                    <ion-button (click)="buyPackage(package)" size="block" color="primary">
                      <ion-icon name="card-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'buy'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                  </ion-card>

                </ion-col>

              </ion-row>

            </ion-grid>

            <pipeline-no-entries-card *ngIf="view.packages && !view.packages.length"></pipeline-no-entries-card>

          </ion-card>

        </ion-col>

        <!-- subscriptions -->
        <ion-col [size]="view.isDesktop ? 4 : 12" *ngIf="appConfig.useWalletSubscriptionsExtension" [hidden]="!!wallet && (wallet.balance > 0)">

          <ion-card class="auto-height">

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_subscriptions'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'getgenius_wallet_subscriptions_text'|translate"></p>

              <ion-spinner [hidden]="!!view.subscriptions"></ion-spinner>

            </ion-card-content>

            <!-- Iterate subscriptions -->
            <ion-grid *ngIf="!!view.subscriptions && !!view.subscriptions.length">

              <ion-row>

                <ion-col *ngFor="let package of view.subscriptions; trackBy:trackItems" size="4" [hidden]="!package.price">

                  <ion-card>

                    <ion-thumbnail>
                      <ion-img [src]="package.photo || fallbackImg"
                        (ionError)="thumbnailLoadingFailed(package)"></ion-img>
                    </ion-thumbnail>

                    <ion-item lines="none" class="ion-text-wrap">
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="package.name|translate" class="ion-text-wrap"></h3>
                        <p [innerHTML]="(view.currencyPrefixSymbols[package.currency] || '') + package.price + (view.currencySuffixSymbols[package.currency] || '')"></p>
                      </ion-label>
                    </ion-item>

                    <ion-list *ngIf="!!package.benefits">

                      <ion-item *ngFor="let benefit of package.benefits" class="ion-text-wrap">
                        <ion-icon *ngIf="!!benefit.icon" [name]="benefit.icon" slot="start"></ion-icon>
                        <ion-label class="ion-text-wrap">
                          <h3 [innerHTML]="benefit.name"></h3>
                          <p [innerHTML]="benefit.description" *ngIf="!!benefit.description"></p>
                        </ion-label>
                      </ion-item>

                    </ion-list>

                    <ion-button (click)="buyPackage(package)" size="block" color="primary">
                      <ion-icon name="card-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'select'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                  </ion-card>

                </ion-col>

              </ion-row>

            </ion-grid>

            <pipeline-no-entries-card
              *ngIf="view.subscriptions && !view.subscriptions.length"></pipeline-no-entries-card>

          </ion-card>

        </ion-col>

        <!-- transaction history -->
        <ion-col [size]="!!view.isDesktop && (!wallet || (1 > wallet.balance)) ? 4 : 12">

          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_history'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'getgenius_wallet_history_text'|translate"></p>

              <ion-spinner [hidden]="!!view.history"></ion-spinner>

              <ion-grid *ngIf="!!view.history && !!view.history.length">
              </ion-grid>

              <pipeline-no-entries-card *ngIf="view.history && !view.history.length"></pipeline-no-entries-card>

            </ion-card-content>


          </ion-card>

        </ion-col>

      </ion-row>

      <ion-row [hidden]="!!wallet && (wallet.balance > 0)" *ngIf="appConfig.useWalletEarningsExtension">

        <!-- Earnings -->
        <ion-col size="12">

          <ion-card class="auto-height">

            <ion-card-header>
              <ion-card-title [innerHTML]="'getgenius_wallet_earnings'|translate"></ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'getgenius_wallet_earnings_text'|translate"></p>
            </ion-card-content>

            <ion-button (click)="node()" fill="clear" color="primary">
              <ion-label [innerHTML]="'getgenius_node_install'|translate"></ion-label>
              <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
            </ion-button>

          </ion-card>

        </ion-col>

      </ion-row>

    </ion-grid>
  </div>

</ion-content>