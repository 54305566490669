<ion-app class="menu-position-{{view.sidebarPosition || (appConfig.sidebarPosition || 'start')}}"
  [class.loggedin]="!!(user && user.uid)" [class.has-project]="!!project && !!project.uid"
  [class.has-windowmanager]="appConfig.useWindowManagerExtension">

  <ion-split-pane contentId="main-content">

    <!-- Create something popover (trigger="createPopoverHoverTrigger" triggerAction="hover") -->
    <ion-popover #createPopover [isOpen]="isCreatePopoverOpen" (didDismiss)="isCreatePopoverOpen = false">
      <ng-template>
        <ion-content>

          <ion-list *ngIf="!!view.globalCreateItems && !!view.globalCreateItems.length">

            <ion-item *ngFor="let item of view.globalCreateItems; trackBy:trackItems"
              (click)="selectGlobalCreateItem(item, $event)" [hidden]="!!item.expertMode && !view.expertMode">
              <ion-icon *ngIf="!!item.icon" [name]="item.icon" slot="start"></ion-icon>
              <ion-label [innerHTML]="item.name|translate" class="ion-text-wrap"></ion-label>
            </ion-item>

          </ion-list>

        </ion-content>
      </ng-template>
    </ion-popover>

    <!-- Add tool to sidebar picker -->
    <ion-popover #toolPickerPopover id="toolPickerPopover" [isOpen]="isToolPickerPopoverOpen"
      (didDismiss)="isToolPickerPopoverOpen = false">
      <ng-template>

        <ion-header class="ion-no-border">

          <ion-toolbar class="ion-no-border container">

            <ion-buttons slot="start">
              <ion-button (click)="dismissToolsPopover()" icon-only fill="clear" color="dark">
                <ion-icon name="close-outline"></ion-icon>
              </ion-button>
            </ion-buttons>

            <ion-title [innerHTML]="'add'|translate"></ion-title>

          </ion-toolbar>

        </ion-header>

        <ion-content>

          <div class="container">

            <ion-grid class="widgets-grid" *ngIf="!!availableAppPages && !!availableAppPages.length">
              <ion-row>

                <ion-col *ngFor="let appPage of availableAppPages; trackBy:trackItems" [size]="view.isDesktop ? 4 : 6">

                  <ion-card button (click)="addAppPage(appPage)"
                    [class.disabled]="appPage.disabled || !!appPage.checked"
                    [disabled]="appPage.disabled || !!appPage.checked">

                    <ion-card-content>
                      <ion-icon [name]="appPage.icon"></ion-icon>
                    </ion-card-content>

                    <ion-card-header>
                      <ion-card-subtitle [innerHTML]="appPage.label|translate"></ion-card-subtitle>
                    </ion-card-header>

                  </ion-card>

                </ion-col>

              </ion-row>
            </ion-grid>

          </div>

        </ion-content>

      </ng-template>
    </ion-popover>

    <!-- Profile settings popover -->
    <ion-popover #profileSettingsPopover [isOpen]="isProfileSettingsPopoverOpen"
      (didDismiss)="isProfileSettingsPopoverOpen = false">
      <ng-template>
        <ion-content>

          <!-- Sidebar actions grid -->
          <ion-grid class="sidebar-actions-grid">
            <ion-row>

              <!-- User -->
              <ion-col [size]="appConfig.useHelpCenterExtension ? 4 : 6">

                <!-- Loading user -->
                <ion-spinner [hidden]="!!user && !!user.uid"></ion-spinner>

                <!-- User button -->
                <ion-button fill="clear" expand="block" color="dark" (click)="account()" *ngIf="user && !!user.uid">
                  <ion-avatar>
                    <ion-img [src]="user.photo || fallbackAvatarImg" (ionError)="userPhotoLoadingFailed()"></ion-img>
                  </ion-avatar>
                  <ion-label [innerHTML]="user.firstname || user.nickname" class="ion-text-wrap"></ion-label>
                </ion-button>

              </ion-col>

              <!-- Team -->
              <ion-col [size]="appConfig.useHelpCenterExtension ? 4 : 6">

                <!-- Loading team-->
                <ion-spinner [hidden]="!!team && !!team.uid"></ion-spinner>

                <!-- Team button -->
                <ion-button fill="clear" expand="block" color="dark" (click)="viewTeam()" *ngIf="team && !!team.uid">
                  <ion-avatar>
                    <ion-img [src]="team.photo || fallbackImg" (ionError)="teamPhotoLoadingFailed()"></ion-img>
                  </ion-avatar>
                  <ion-label [innerHTML]="team.name" class="ion-text-wrap"></ion-label>
                </ion-button>

              </ion-col>

              <!-- Help Center -->
              <ion-col size="4" *ngIf="!!appConfig.useHelpCenterExtension">
                <ion-button fill="clear" expand="block" icon-only color="dark" id="footerSupportItem"
                  (click)="support()">
                  <ion-icon name="help-circle-outline" color="dark"></ion-icon>
                </ion-button>
              </ion-col>

            </ion-row>
          </ion-grid>

          <ion-grid>
            <ion-row>
              <ion-col>

                <!-- Add account -->
                <ion-button expand="block" color="primary" (click)="addAccount()">
                  <ion-label [innerHTML]="'add_account'|translate"></ion-label>
                </ion-button>

              </ion-col>
              <ion-col>

                <!-- Logout all -->
                <ion-button expand="block" color="danger" fill="clear" (click)="logoutAll()">
                  <ion-label [innerHTML]="'logout_all'|translate" class="ion-text-wrap"></ion-label>
                </ion-button>

              </ion-col>
            </ion-row>
          </ion-grid>

        </ion-content>
      </ng-template>
    </ion-popover>

    <!-- Tab children popover -->
    <ion-popover #tabChildrenPopover [isOpen]="isTabChildrenPopoverOpen"
      (didDismiss)="isTabChildrenPopoverOpen = false">
      <ng-template>
        <ion-content>

          <ion-list *ngIf="!!view.openTab && !!view.openTab.children">

            <!-- Main item -->
            <ion-item button (click)="onMenuToggleClick(view.openTab, view.openTabIndex)">

              <ion-icon slot="start" [name]="view.openTab.icon"
                [color]="view.currentMenuItem === view.openTab.key ? 'primary' : 'dark'"></ion-icon>

              <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === view.openTab.key ? 'primary' : 'dark'">
                <h2 [innerHTML]="view.openTab.key|translate"></h2>
                <p [innerHTML]="(view.openTab.description || (view.openTab.key + '_description'))|translate"></p>
              </ion-label>

              <ion-badge *ngIf="view.openTab.badge != null" shape="round" [innerHTML]="view.openTab.badge"
                color="primary"></ion-badge>

            </ion-item>

            <!-- Iterate children -->
            <ion-item button *ngFor="let child of view.openTab.children; let i = index; trackBy:trackItems"
              (click)="onMenuToggleClick(child, i)">

              <ion-icon slot="start" [name]="child.icon"
                [color]="view.currentMenuItem === child.key ? 'primary' : 'dark'"></ion-icon>

              <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === child.key ? 'primary' : 'dark'">
                <h2 [innerHTML]="child.key|translate"></h2>
                <p [innerHTML]="(child.description || (child.key + '_description'))|translate"></p>
              </ion-label>

              <ion-badge *ngIf="child.badge != null" shape="round" [innerHTML]="child.badge"
                color="primary"></ion-badge>

            </ion-item>

          </ion-list>

        </ion-content>
      </ng-template>
    </ion-popover>

    <!-- Sidebar (if sidebar position if left or right) -->
    <ion-menu *ngIf="appConfig.sidebar && (view.sidebarPosition !== 'top' && view.sidebarPosition !== 'bottom')"
      [hidden]="!menuData.visible"
      [side]="view.sidebarPosition === 'right' ? 'end' : (appConfig.sidebarPosition === 'left' ? 'start' : (appConfig.sidebarPosition || 'start'))"
      [class.subMode]="view.subMode" class="position-{{view.sidebarPosition || (appConfig.sidebarPosition || 'start')}}"
      contentId="main-content" [type]="appConfig.sidebarType || 'overlay'">

      <!-- Sidebar header -->
      <ion-header class="ion-no-border">

        <!-- Sidebar header logo + title -->
        <ion-toolbar *ngIf="view.subMode || appConfig.showMenuHeaderLogo || appConfig.showMenuHeaderTitle">

          <!-- Icon (Light-Mode) -->
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/icon.webp" (click)="onMenuHeaderLogoClick()"
            class="pipeline-logo icon light" />

          <!-- Icon (Dark-Mode) -->
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/icon-dark.webp" (click)="onMenuHeaderLogoClick()"
            class="pipeline-logo icon dark" />

          <!-- Logo (Light-Mode) -->
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/logo.webp" (click)="onMenuHeaderLogoClick()"
            class="pipeline-logo light" />

          <!-- Logo (Dark-Mode) -->
          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/logo-dark.webp" (click)="onMenuHeaderLogoClick()"
            class="pipeline-logo dark" />

          <ion-title *ngIf="appConfig.showMenuHeaderTitle" [innerHTML]="appConfig.appName"></ion-title>

          <!-- Edit sidebar actions (in expert mode, if enabled in config) -->
          <ion-buttons slot="end" class="show-on-hover" *ngIf="!!appConfig.allowUserEditSidebar && !!view.expertMode">

            <!-- Edit sidebar -->
            <ion-button fill="clear" (click)="editSidebar(true)" *ngIf="sidebarMode !== 'edit'">
              <ion-icon name="settings-outline"></ion-icon>
            </ion-button>

            <!-- Stop editing sidebar -->
            <ion-button fill="clear" (click)="editSidebar(false)" *ngIf="sidebarMode === 'edit'">
              <ion-icon name="checkmark-outline"></ion-icon>
            </ion-button>

          </ion-buttons>

        </ion-toolbar>

        <!-- Global search -->
        <ion-toolbar *ngIf="!!appConfig.showMenuGlobalSearch">
          <ion-searchbar #globalSearchInput [placeholder]="'search_global_creator_studio'|translate"
            (ionInput)="runSearch($event)"></ion-searchbar>
        </ion-toolbar>

      </ion-header>

      <!-- Sidebar content -->
      <ion-content fullscreen="true">

        <!-- Global "Create" element (in view mode )-->
        <ion-list lines="none">

          <!-- Create new thing (create popover)-->
          <ion-button *ngIf="sidebarMode !== 'edit' && appConfig.showMenuAddButton" lines="none" expand="block"
            fill="clear" detail="false" id="createPopoverHoverTrigger" (click)="showCreatePopover($event)"
            color="primary">
            <ion-icon slot="start" name="create-outline" color="primary"></ion-icon>
            <ion-label class="ion-text-wrap" color="primary">
              <h2 color="primary" [innerHTML]="'global_create_item'|translate"></h2>
              <p color="primary" [innerHTML]="'global_create_item_description'|translate"></p>
            </ion-label>
          </ion-button>

          <!-- Create new object type (in edit mode)-->
          <ion-button *ngIf="sidebarMode === 'edit'" lines="none" expand="block" fill="clear" detail="false"
            (click)="createNewObjectType($event)" color="primary">
            <ion-icon slot="start" name="create-outline" color="primary"></ion-icon>
            <ion-label class="ion-text-wrap" color="primary">
              <h2 color="primary" [innerHTML]="'create_object_type'|translate"></h2>
              <p color="primary" [innerHTML]="'create_object_type_description'|translate"></p>
            </ion-label>
          </ion-button>

        </ion-list>

        <!-- global search results -->
        <ion-list lines="none"
          *ngIf="(!!view.search_results && !!view.search_results.length) && (sidebarMode !== 'edit')">

          <ion-button lines="none" expand="block" fill="clear" detail="false"
            *ngFor="let p of view.search_results; let i = index; trackBy:trackItems" (click)="onMenuToggleClick(p)"
            [color]="!i ? 'primary' : 'dark'"
            [hidden]="(p.hideIfLoggedOut && !user.uid) || (p.hideIfLoggedIn && user.uid) || (p.allowedUserTypes && (!user || !user.classifications || (user.classifications && !user.classifications.type) || (user.classifications && user.classifications.type && p.allowedUserTypes.indexOf(user.classifications.type) === -1)))">
            <ion-icon slot="start" [name]="p.icon"
              [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'"></ion-icon>
            <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'">
              <h2 [innerHTML]="p.key|translate"></h2>
              <p [innerHTML]="(p.description || (p.key + '_description'))|translate"></p>
            </ion-label>
            <ion-badge *ngIf="p.badge != null" shape="round" [innerHTML]="p.badge" color="primary"></ion-badge>
          </ion-button>

        </ion-list>

        <!-- sidebar menu -->
        <ion-reorder-group [disabled]="sidebarMode !== 'edit'" lines="none" (ionItemReorder)="reorderSidebar($event)"
          *ngIf="(!view.search_results || !view.search_results.length) || (sidebarMode === 'edit')">

          <!-- back button -->
          <ion-button *ngIf="!!view.subMode && (sidebarMode !== 'edit')" lines="none" expand="block" fill="clear"
            detail="false" (click)="up()">
            <ion-icon slot="start" name="arrow-back-outline" color="primary"></ion-icon>
            <ion-label class="ion-text-wrap" color="primary">
              <h2 [innerHTML]="'back'|translate"></h2>
              <p [innerHTML]="view.parentMenuItem|translate"></p>
            </ion-label>
          </ion-button>

          <!-- sidebar menu items -->
          <div class="button-wrapper" *ngFor="let p of appPages; let i = index; trackBy:trackItems"
            [class.active]="p.active" [class.disabled]="!!p.hidden"
            [hidden]="(!!p.hidden && (sidebarMode !== 'edit')) || (p.hideIfLoggedOut && !user.uid) || (p.hideIfLoggedIn && user.uid) || (p.allowedUserTypes && (!user || !user.classifications || (user.classifications && !user.classifications.type) || (user.classifications && user.classifications.type && p.allowedUserTypes.indexOf(user.classifications.type) === -1)))">

            <ion-button lines="none" expand="block" fill="clear" detail="false" (click)="onMenuToggleClick(p, i)"
              [class.has-children]="!!p.children">

              <!-- Reorder item -->
              <ion-reorder slot="start" fill="clear" icon-only color="dark" *ngIf="sidebarMode === 'edit'"
                class="show-on-hover">
                <ion-icon name="reorder-two-outline"></ion-icon>
              </ion-reorder>

              <!-- Item icon -->
              <ion-icon slot="start" [name]="p.icon"
                [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'"></ion-icon>

              <!-- Item label -->
              <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'">
                <h2 [innerHTML]="p.key|translate"></h2>
                <p [innerHTML]="(p.description || (p.key + '_description'))|translate"></p>
              </ion-label>

              <!-- Item badge -->
              <ion-badge *ngIf="(p.badge != null) && (sidebarMode !== 'edit')" shape="round" [innerHTML]="p.badge"
                color="primary"></ion-badge>

              <!-- Item children toggle chevron -->
              <ion-icon class="chevron" *ngIf="!!p.children && (sidebarMode !== 'edit')" color="dark"
                [name]="p.active ? 'chevron-up-outline' : 'chevron-down-outline'" slot="end"></ion-icon>

              <!-- Show item (if item has been hidden) -->
              <ion-button slot="end" fill="clear" icon-only color="dark" *ngIf="sidebarMode === 'edit' && !!p.hidden"
                class="show-on-hover" (click)="hideSidebarMenuItem(p,i,false)">
                <ion-icon name="add-outline"></ion-icon>
              </ion-button>

              <!-- Hide item (if item is visible) -->
              <ion-button slot="end" fill="clear" icon-only color="danger" *ngIf="sidebarMode === 'edit' && !p.hidden"
                class="show-on-hover" (click)="hideSidebarMenuItem(p,i,true)">
                <ion-icon name="remove-outline"></ion-icon>
              </ion-button>

            </ion-button>

            <!-- button children -->
            <div class="button-children" *ngIf="!!p.children" [hidden]="!p.active || (sidebarMode === 'edit')">
              <ion-button *ngFor="let child of p.children; trackBy:trackItems" lines="none" expand="block" fill="clear"
                detail="false" (click)="onMenuToggleClick(child, i)">

                <ion-icon slot="start" [name]="child.icon"
                  [color]="view.currentMenuItem === child.key ? 'primary' : 'dark'"></ion-icon>

                <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === child.key ? 'primary' : 'dark'">
                  <h2 [innerHTML]="child.key|translate"></h2>
                  <p [innerHTML]="(child.description || (child.key + '_description'))|translate"></p>
                </ion-label>

                <ion-badge *ngIf="child.badge != null" shape="round" [innerHTML]="child.badge"
                  color="primary"></ion-badge>

              </ion-button>
            </div>

          </div>

        </ion-reorder-group>

        <!-- Add item to sidebar (in edit mode ) -->
        <ion-list lines="none" *ngIf="appConfig.allowUserEditSidebar" class="visible-on-hover">

          <ion-button lines="none" expand="block" fill="clear" (click)="addItemToSidebar($event)" color="primary">
            <ion-icon slot="start" name="add-outline" color="primary"></ion-icon>
            <ion-label class="ion-text-wrap" color="primary">
              <h2 color="primary" [innerHTML]="'add_element_to_sidebar'|translate"></h2>
              <p color="primary" [innerHTML]="'add_element_to_sidebar_description'|translate"></p>
            </ion-label>
          </ion-button>

        </ion-list>

      </ion-content>

      <!-- Sidebar footer -->
      <ion-footer class="ion-no-border" color="light">

        <ion-toolbar class="ion-no-border">

          <!-- Sidebar DANI -->
          <getgenius-dani [hidden]="!!view.dani.state.hidden" (click)="dani()"
            *ngIf="!!view.isDesktop && !!appConfig.showGetGeniusDaniInSidebar" [config]="daniConfig"
            [state]="view.dani.state"></getgenius-dani>

          <!-- Sidebar actions grid -->
          <ion-grid class="sidebar-actions-grid">
            <ion-row>

              <!-- User -->
              <ion-col [size]="appConfig.useHelpCenterExtension ? 4 : 6">

                <!-- Loading user -->
                <ion-spinner [hidden]="!!user && !!user.uid"></ion-spinner>

                <!-- User button -->
                <ion-button fill="clear" expand="block" color="dark" (click)="onUserClicked()"
                  *ngIf="user && !!user.uid">
                  <ion-avatar>
                    <ion-img [src]="user.photo || fallbackAvatarImg" (ionError)="userPhotoLoadingFailed()"></ion-img>
                  </ion-avatar>
                  <ion-label [innerHTML]="user.firstname || user.nickname" class="ion-text-wrap"></ion-label>
                </ion-button>

              </ion-col>

              <!-- Team -->
              <ion-col [size]="appConfig.useHelpCenterExtension ? 4 : 6">

                <!-- Loading team-->
                <ion-spinner [hidden]="!!team && !!team.uid"></ion-spinner>

                <!-- Team button -->
                <ion-button fill="clear" expand="block" color="dark" (click)="onTeamClicked()"
                  *ngIf="team && !!team.uid">
                  <ion-avatar>
                    <ion-img [src]="team.photo || fallbackImg" (ionError)="teamPhotoLoadingFailed()"></ion-img>
                  </ion-avatar>
                  <ion-label [innerHTML]="team.name" class="ion-text-wrap"></ion-label>
                </ion-button>

              </ion-col>

              <!-- Help Center -->
              <ion-col size="4" *ngIf="!!appConfig.useHelpCenterExtension">
                <ion-button fill="clear" expand="block" icon-only color="dark" id="footerSupportItem"
                  (click)="support()">
                  <ion-icon name="help-circle-outline" color="dark"></ion-icon>
                </ion-button>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-toolbar>

        <!-- Basket -->
        <ion-toolbar class="ion-no-border" color="light" *ngIf="!!appConfig.useShop && !!appConfig.showBasketInSidebar"
          [hidden]="!basketInfo || !basketInfo.items">
          <ion-list color="light">
            <ion-menu-toggle *ngIf="user && user.uid" auto-hide="false">
              <ion-item lines="none" (click)="basket()" color="light">
                <ion-label>
                  <h3 [innerHTML]="'basket'|translate"></h3>
                  <p>
                    <small
                      [innerHTML]="(basketInfo.items || ('no_2'|translate)) + ' ' + (basketInfo.items === 1 ? ('product'|translate) : ('products'|translate))"></small>
                  </p>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-toolbar>

      </ion-footer>

    </ion-menu>

    <!-- App content -->
    <ion-router-outlet id="main-content" swipeGesture="false"></ion-router-outlet>

    <!-- Tab bar (if sidebar position = top or bottom ) -->
    <ion-tabs id="mainTabBar"
      *ngIf="appConfig.sidebar && !!appPages && view.sidebarPosition === 'top' || view.sidebarPosition === 'bottom'">

      <ion-tab-bar [slot]="view.sidebarPosition">

        <!-- Icon -->
        <ion-tab-button (click)="onMenuHeaderLogoClick()">

          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/icon.webp" class="pipeline-logo icon light" />

          <img *ngIf="appConfig.showMenuHeaderLogo" src="./assets/img/icon-dark.webp" class="pipeline-logo icon dark" />

        </ion-tab-button>

        <!-- Create new thing (create popover)-->
        <ion-tab-button *ngIf="sidebarMode !== 'edit' && appConfig.showMenuAddButton" lines="none" expand="block"
          fill="clear" detail="false" id="createPopoverHoverTrigger" (click)="showCreatePopover($event)"
          color="primary">
          <ion-icon name="create-outline" color="primary"></ion-icon>
          <ion-label class="ion-text-wrap" color="primary">
            <h2 color="primary" [innerHTML]="'global_create_item'|translate"></h2>
          </ion-label>
        </ion-tab-button>

        <!-- Create new object type (in edit mode)-->
        <ion-tab-button *ngIf="sidebarMode === 'edit'" lines="none" expand="block" fill="clear" detail="false"
          (click)="createNewObjectType($event)" color="primary">
          <ion-icon name="create-outline" color="primary"></ion-icon>
          <ion-label class="ion-text-wrap" color="primary">
            <h2 color="primary" [innerHTML]="'create_object_type'|translate"></h2>
          </ion-label>
        </ion-tab-button>

        <!-- back button -->
        <ion-tab-button *ngIf="!!view.subMode && (sidebarMode !== 'edit')" lines="none" expand="block" fill="clear"
          detail="false" (click)="up()">
          <ion-icon name="arrow-back-outline" color="primary"></ion-icon>
          <ion-label class="ion-text-wrap" color="primary">
            <h2 [innerHTML]="'back'|translate"></h2>
          </ion-label>
        </ion-tab-button>

        <!-- Single menu item -->
        <ion-tab-button (click)="onTabButtonClick(p, i, $event)" [class.has-children]="!!p.children"
          *ngFor="let p of appPages; let i = index; trackBy:trackItems" [class.active]="p.active"
          [class.disabled]="!!p.hidden"
          [hidden]="(!!p.hidden && (sidebarMode !== 'edit')) || (p.hideIfLoggedOut && !user.uid) || (p.hideIfLoggedIn && user.uid) || (p.allowedUserTypes && (!user || !user.classifications || (user.classifications && !user.classifications.type) || (user.classifications && user.classifications.type && p.allowedUserTypes.indexOf(user.classifications.type) === -1)))">

          <!-- Item icon -->
          <ion-icon [name]="p.icon" [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'"></ion-icon>

          <!-- Item label -->
          <ion-label class="ion-text-wrap" [color]="view.currentMenuItem === p.key ? 'primary' : 'dark'">
            <p [innerHTML]="p.key|translate"></p>
          </ion-label>

          <!-- Item badge -->
          <ion-badge *ngIf="(p.badge != null) && (sidebarMode !== 'edit')" shape="round" [innerHTML]="p.badge"
            color="primary"></ion-badge>

          <!-- Item children toggle chevron -->
          <ion-icon class="chevron" *ngIf="!!p.children && (sidebarMode !== 'edit')" color="dark"
            [name]="p.active ? 'chevron-up-outline' : 'chevron-down-outline'" slot="end"></ion-icon>

          <!-- Show item (if item has been hidden) -->
          <ion-button slot="end" fill="clear" icon-only color="dark" *ngIf="sidebarMode === 'edit' && !!p.hidden"
            class="show-on-hover" (click)="hideSidebarMenuItem(p,i,false)">
            <ion-icon name="add-outline"></ion-icon>
          </ion-button>

          <!-- Hide item (if item is visible) -->
          <ion-button slot="end" fill="clear" icon-only color="danger" *ngIf="sidebarMode === 'edit' && !p.hidden"
            class="show-on-hover" (click)="hideSidebarMenuItem(p,i,true)">
            <ion-icon name="remove-outline"></ion-icon>
          </ion-button>

        </ion-tab-button>

        <!-- Add item to sidebar (in edit mode ) -->
        <ion-tab-button *ngIf="appConfig.allowUserEditSidebar" class="visible-on-hover" lines="none" expand="block"
          fill="clear" (click)="addItemToSidebar($event)" color="primary">
          <ion-icon name="add-outline" color="primary"></ion-icon>
          <ion-label class="ion-text-wrap" color="primary">
            <h2 color="primary" [innerHTML]="'add_element_to_sidebar'|translate"></h2>
          </ion-label>
        </ion-tab-button>

        <!-- User button -->
        <ion-tab-button class="user-tab-btn" fill="clear" expand="block" color="dark" *ngIf="user && !!user.uid">

          <ion-avatar (click)="showProfileSettingsPopover($event)">
            <ion-img [src]="user.photo || fallbackAvatarImg" (ionError)="userPhotoLoadingFailed()"></ion-img>
          </ion-avatar>

          <ion-icon name="chevron-down" (click)="onUserClicked()"></ion-icon>

        </ion-tab-button>

      </ion-tab-bar>

    </ion-tabs>

  </ion-split-pane>

</ion-app>