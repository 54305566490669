<ion-header class="ion-no-border">
  <pipeline-header-top-toolbar [(state)]="state" [(view)]="view"></pipeline-header-top-toolbar>
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <ion-grid>
      <ion-row>
        <ion-col>
          <pipeline-intro-card [(view)]="view.introCard"></pipeline-intro-card>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-card class="noEntriesCard" *ngIf="view.orders && !view.orders.length">
      <ion-card-header>
        <ion-card-title [innerHTML]="'no_orders'|translate"></ion-card-title>
      </ion-card-header>
    </ion-card>

    <ion-card *ngFor="let order of view.orders; trackBy:trackItems">
      <ion-list>
        <ion-item>
          <ion-label>
            <p></p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

  </div>

</ion-content>