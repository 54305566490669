import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from 'src/app/services/core/config.service';

//import { OAuth2Client } from "@byteowls/capacitor-oauth2";
import { GenericOAuth2 } from '@capacitor-community/generic-oauth2';

@Injectable({
    providedIn: 'root'
})
export class OauthService {

    config: any = {

    };

    constructor(
        private AppCMS: AppcmsService,
        private configService: ConfigService,
        private loadingCtrl: LoadingController,
    ) {
    }

    authorize(config: any) {
        return new Promise(async (resolve, reject) => {

            const appId: string = this.configService.getAppId(),
                androidConfig: any = (config.android || config.default),
                iosConfig: any = (config.ios || config.default),
                webConfig: any = (config.web || config.default);

            androidConfig.appId = ((androidConfig.appId || androidConfig.clientId) || appId);
            iosConfig.appId = ((iosConfig.appId || iosConfig.clientId) || appId);
            webConfig.appId = ((webConfig.appId || webConfig.clientId) || appId);

            const authParams: any = {
                authorizationBaseUrl: config.authorizationBaseUrl,
                accessTokenEndpoint: config.accessTokenEndpoint,
                scope: config.scope,
                resourceUrl: config.resourceUrl,
                logsEnabled: true,
                default: (config.default || webConfig),
                web: webConfig,
                android: androidConfig,
                ios: iosConfig,
            };

            const loading: any = await this.loadingCtrl.create({
                spinner: 'circular',
            });

            loading.present();

            GenericOAuth2.authenticate(authParams)
                .then((response: any) => {
                    loading.dismiss();

                    if (!!response.authorization_response) {
                        this.setAuthorizationResponse(response.authorization_response, authParams);
                    }

                    this.setAccessToken(response["access_token"], authParams);
                    this.setRefreshToken(response["refresh_token"], authParams);

                    // only if you include a resourceUrl protected user values are included in the response!
                    //let oauthUserId = response["id"];
                    //let name = response["name"];

                    // go to backend
                    resolve(response);

                }).catch((reason: any) => {
                    console.error("oauth service: OAuth rejected", reason);

                    loading.dismiss();

                    if (reason === 'USER_CANCELLED') {
                        resolve({});
                    } else {
                        reject(reason);
                    }
                });
        });
    }

    setAccessToken(token: string, authParams: any) {
        this.AppCMS.setRequestParam('accessToken', token);

        this.config.accessToken = token;

        return this;
    }

    setAuthorizationResponse(response: any, authParams: any) {
        this.config.authorization_response = response;
        return this;
    }

    setRefreshToken(token: string, authParams: any) {
        this.AppCMS.setRequestParam('refreshToken', token);

        this.config.refreshToken = token;

        return this;
    }

}