import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AiCoderComponent } from './ai-coder.component';

/* Intro */
import { IntroOverlayComponentModule } from 'src/app/components/intro/intro-overlay/intro-overlay.component.module';
import { EditorComponentModule } from 'src/app/components/generic/editor/editor.component.module';

@NgModule({
  declarations: [
    AiCoderComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    IntroOverlayComponentModule,
    EditorComponentModule,
  ],
  exports: [
    AiCoderComponent,
  ]
})
export class AiCoderComponentModule { }