<ion-card [class.system]="message.role === 'system'" [class.me]="message.role === 'user'"
    [class.dani]="message.role === 'assistant'">

    <!-- Message type: Image -->
    <ion-img class="cover" [src]="message.input" *ngIf="message.type === 'image' && !!message.input"></ion-img>

    <!-- Message type: Video -->
    <video *ngIf="message.type === 'video' && !!message.input" [src]="message.input" loop autoplay webkit-playsinline
        playsinline muted>
    </video>
    
    <!-- Message -->
    <ion-item class="message" class="ion-text-wrap" lines="none">

        <!-- Icon -->
        <ion-icon [name]="message.icon" *ngIf="!!message.icon" slot="start"></ion-icon>

        <!-- Classic message -->
        <ion-label class="ion-text-wrap" *ngIf="message.mode !== 'input' && (message.type !== 'image')">
            <h3 [innerHTML]="message.input|translate" class="ion-text-wrap"></h3>
        </ion-label>

        <!-- Input (forms) -->
        <ion-textarea *ngIf="(message.mode === 'input') && (message.type === 'textarea')" rows="5"
            [(ngModel)]="message.value" [placeholder]="(message.placeholder || message.name)|translate"
            [label]="message.name|translate" labelPlacement="stacked"></ion-textarea>

        <!-- Textarea (forms) -->
        <ion-input *ngIf="(message.mode === 'input') && (message.type !== 'textarea')" [type]="message.type || 'text'"
            [(ngModel)]="message.value" [placeholder]="(message.placeholder || message.name)|translate"
            [label]="message.name|translate" labelPlacement="stacked"></ion-input>

        <ion-button slot="end" fill="clear" color="white" icon-only (click)="resend(message)"
            *ngIf="message.role === 'user'">
            <ion-icon name="sync-outline"></ion-icon>
        </ion-button>

    </ion-item>

    <!-- Rate actions -->
    <ion-item *ngIf="!!index && (message.mode === 'view') && (message.role === 'assistant')" lines="none">

        <!-- Rate item buttons -->
        <pipeline-rate-item-buttons [(item)]="message" type="message" slot="start"></pipeline-rate-item-buttons>

        <!-- Copy output to clipboard -->
        <ion-button fill="clear" color="dark" *ngIf="!!message.input" (click)="copyText(message.input)">
            <ion-icon name="clipboard-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
        </ion-button>

    </ion-item>

</ion-card>