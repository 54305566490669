import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { GetgeniusDaniComponent } from './getgenius-dani.component';

import { TranslateModule } from '@ngx-translate/core';

/* Content */
import { IntroCardComponentModule } from 'src/app/components/intro/intro-card/intro-card.component.module';
import { SplineViewerComponentModule } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component.module';
import { ChatMessageComponentModule } from 'src/app/components/community/chat/chat-message/chat-message.component.module';
import { EditorComponentModule } from 'src/app/components/generic/editor/editor.component.module';
import { TableViewComponentModule } from 'src/app/components/generic/table-view/table-view.component.module';

/* AI */
import { AiBrowserComponentModule } from 'src/app/components/ai/ai-browser/ai-browser.component.module';
import { AiCoderComponentModule } from 'src/app/components/ai/ai-coder/ai-coder.component.module';
import { AiImageToVideoCardComponentModule } from 'src/app/components/ai/ai-image-to-video-card/ai-image-to-video-card.component.module';
import { AiSettingsCardComponentModule } from 'src/app/components/ai/ai-settings-card/ai-settings-card.component.module';
import { AiTextToAudioCardComponentModule } from 'src/app/components/ai/ai-text-to-audio-card/ai-text-to-audio-card.component.module';
import { AiTextToImageCardComponentModule } from 'src/app/components/ai/ai-text-to-image-card/ai-text-to-image-card.component.module';
import { AiTextToSpeechCardComponentModule } from 'src/app/components/ai/ai-text-to-speech-card/ai-text-to-speech-card.component.module';
import { AiUpscalerCardComponentModule } from 'src/app/components/ai/ai-upscaler-card/ai-upscaler-card.component.module';

@NgModule({
  declarations: [
    GetgeniusDaniComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    IntroCardComponentModule,
    SplineViewerComponentModule,
    EditorComponentModule,
    ChatMessageComponentModule,
    TableViewComponentModule,
    AiBrowserComponentModule,
    AiCoderComponentModule,
    AiImageToVideoCardComponentModule,
    AiSettingsCardComponentModule,
    AiTextToAudioCardComponentModule,
    AiTextToImageCardComponentModule,
    AiTextToSpeechCardComponentModule,
    AiUpscalerCardComponentModule,
  ],
  exports: [
    GetgeniusDaniComponent,
  ]
})
export class GetgeniusDaniComponentModule { }