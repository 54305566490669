<ion-grid>
  <ion-row>

    <ion-col class="leftCol" [size]="!!view.hasUI ? view.colSize : 12">
      <swiper-container #whitelabelConfiguratorSlider [options]="sliderOptions" [pagination]="true">

        <swiper-slide>
        
          <pipeline-intro-card [hidden]="view.introCard.hidden" [view]="!!view.editMode ? view.introCard.edit : view.introCard.create"></pipeline-intro-card>

          <ion-grid class="btn-grid">
            <ion-row>
              <ion-col [size]="view.colSize" *ngFor="let platform of view.platforms">
                <ion-card [class.checked]="!!platform.checked">
                  <ion-button expand="block" fill="clear" [color]="platform.checked ? 'success' : 'dark'" (click)="usePlatform(platform)">
                    <ion-icon *ngIf="!!platform.icon" [name]="platform.icon" color="primary"></ion-icon>
                    <ion-label [innerHTML]="('whitelabel_app_platform_' + platform.name)|translate"></ion-label>
                  </ion-button>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>

        </swiper-slide>

        <swiper-slide>

          <pipeline-intro-card [hidden]="view.introCard.hidden" [view]="!!view.editMode ? view.introCard.edit : view.introCard.create"></pipeline-intro-card>
          
          <ion-card>
            <ion-list>

              <ion-item>

                <ion-thumbnail slot="start" (ionError)="thumbnailLoadingFailed('app_icon')" (click)="uploadLogo('app_icon')">
                  <ion-img [src]="(app.config.appIconUrl || app.icon) || fallbackImg"></ion-img>
                </ion-thumbnail>

                <ion-label [innerHTML]="'whitelabel_app_icon'|translate"></ion-label>

              </ion-item>

              <ion-item>
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="fixed" [label]="'whitelabel_app_name'|translate" labelPlacement="stacked" [placeholder]="'whitelabel_app_name_placeholder'|translate" [(ngModel)]="app.config.appName" (ionInput)="onInputChanged($event)"></ion-input>
              </ion-item>

              <ion-item>
                <ion-icon name="text-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="fixed" [label]="'whitelabel_app_description'|translate" labelPlacement="stacked" [placeholder]="'whitelabel_app_description_placeholder'|translate" [(ngModel)]="app.config.appDescription" (ionInput)="onInputChanged($event)"></ion-input>
              </ion-item>

              <ion-item [hidden]="!app.config.appId">
                <ion-icon name="finger-print-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="fixed" [label]="'whitelabel_app_id'|translate" labelPlacement="stacked" [placeholder]="'whitelabel_app_id_placeholder'|translate" [(ngModel)]="app.config.appId" disabled></ion-input>
              </ion-item>

              <ion-item lines="none" [hidden]="!app.config.appPackageName">
                <ion-icon name="ribbon-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="fixed" [label]="'whitelabel_app_package_name'|translate" labelPlacement="stacked" [placeholder]="'whitelabel_app_package_name_placeholder'|translate" [(ngModel)]="app.config.appPackageName" disabled></ion-input>
              </ion-item>

            </ion-list>
          </ion-card>

        </swiper-slide>
        
        <swiper-slide>

          <div class="ion-padding">
            <pipeline-intro-card [view]="!!view.editMode ? view.introCard.edit : view.introCard.create"></pipeline-intro-card>
          </div>

          <div class="ion-padding">
            <h3 [innerHTML]="'whitelabel_app_sources_h3'|translate"></h3>
            <p [innerHTML]="'whitelabel_app_sources_text'|translate"></p>
          </div>

          <div *ngIf="!!app.sources">
            <ion-card *ngFor="let source of app.sources; let i = index">
              <ion-grid>
                <ion-row>
                  <ion-col size="5">
                    <ion-item>
                      <ion-select labelPlacement="stacked" interface="popover" [okText]="'okay'|translate" [label]="'source_type'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="source.type">
                        <ion-select-option *ngFor="let type of view.sourceTypes" [value]="type" [innerHTML]="('whitelabel_app_source_type_' + type)|translate"></ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size="5">
                    <ion-item>
                      <ion-input labelPlacement="stacked" [(ngModel)]="source.url" [label]="'url'|translate" [placeholder]="'source_url'|translate"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="2">
                    <ion-button expand="block" (click)="deleteSource(source, i)" icon-only fill="clear" color="danger">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card>
          </div>

          <div class="ion-padding">
            <ion-button (click)="addSource()">
              <ion-icon name="add-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'add_source'|translate"></ion-label>
            </ion-button>
          </div>

        </swiper-slide>

        <swiper-slide>

          <div class="ion-padding">
            <pipeline-intro-card [view]="!!view.editMode ? view.introCard.edit : view.introCard.create"></pipeline-intro-card>
          </div>

          <div class="ion-padding">
            <h3 [innerHTML]="'whitelabel_app_testers_h3'|translate"></h3>
            <p [innerHTML]="'whitelabel_app_testers_text'|translate"></p>
          </div>

          <div *ngIf="!!app.testers">
            <ion-card *ngFor="let tester of app.testers; let i = index">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-item size="3">
                      <ion-input labelPlacement="stacked" [(ngModel)]="tester.firstname" [placeholder]="'tester_firstname'|translate" [label]="'firstname'|translate"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="3">
                    <ion-item>
                      <ion-input labelPlacement="stacked" [(ngModel)]="tester.lastname" [placeholder]="'tester_lastname'|translate" [label]="'lastname'|translate"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="4">
                    <ion-item>
                      <ion-input labelPlacement="stacked" [(ngModel)]="tester.email" [placeholder]="'tester_email'|translate" [label]="'email'|translate"></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="2">
                    <ion-button expand="block" (click)="deleteTester(tester, i)" icon-only fill="clear" color="danger">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card>
          </div>

          <div class="ion-padding">
            <ion-button (click)="addTester()">
              <ion-icon name="add-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'add_tester'|translate"></ion-label>
            </ion-button>
          </div>

        </swiper-slide>

      </swiper-container>
    </ion-col>

    <ion-col class="rightCol" [hidden]="!view.hasUI" [size]="view.colSize">

      <div class="iphone">
        <div class="view"></div>
      </div>

    </ion-col>

  </ion-row>
</ion-grid>