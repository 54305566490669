import { Component, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { AiTrainingService } from 'src/app/services/ai/ai-training.service';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { StablediffusionService } from "src/app/services/media/stablediffusion.service";
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'app-ai-model',
  standalone: false,
  templateUrl: './ai-model.page.html',
  styleUrls: ['./ai-model.page.scss'],
})
export class AiModelPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('infoPopover') infoPopover: any;

  appConfig: pipelineAppConfig;

  calcConfig: any = {
    timePerFile: 15,
    tokenPerFile: 50,
  };

  cards: any = {};

  fallbackImg: string = './assets/img/fallback.webp';

  isInfoPopoverOpen: boolean = false;

  media: any;

  mediaList: any;

  search: searchOptions = {
    itemsKey: 'model',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    base_models: [],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    instances: [],
    input: '',
    introCard: {
      uid: 'ai_model_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_model_top_card_text',
      title: 'ai_model_top_card_title',
    },
    media: {},
    mediaList: [],
    model: {
      title: '',
    },
    multiple: true,
    output: '',
    parameters: [

      // TEXT PARAMETERS
      {
        icon: '',
        label: 'block_size',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'block_size',
        value: 1,
      },
      {
        icon: '',
        label: 'model_max_length',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'model_max_length',
        value: 8192,
      },
      {
        icon: '',
        label: 'max_prompt_length',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'max_prompt_length',
        value: 1024,
      },
      {
        icon: '',
        label: 'epochs',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'epochs',
        value: 3,
      },
      {
        icon: '',
        label: 'peft',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'boolean',
        uid: 'peft',
        value: true,
      },
      {
        icon: '',
        label: 'quantization',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'quantization',
        value: null,
      },
      {
        icon: '',
        label: 'target_modules',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'string',
        uid: 'target_modules',
        value: 'all-linear',
      },
      {
        icon: '',
        label: 'padding',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'string',
        uid: 'padding',
        value: 'right',
      },
      {
        icon: '',
        label: 'optimizer',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'string',
        uid: 'optimizer',
        value: 'paged_adamw_8bit',
      },
      {
        icon: '',
        label: 'scheduler',
        supportedModelTypes: ['text_classification', 'text_to_text', 'translation'],
        type: 'string',
        uid: 'scheduler',
        value: 'cosine',
      },

      // IMAGE PARAMETERS
      {
        icon: '',
        label: 'resolution',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'number',
        uid: 'resolution',
        value: 1024,
      },
      {
        icon: '',
        label: 'batch_size',
        supportedModelTypes: ['image_to_image', 'text_to_image', 'text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'batch_size',
        value: 1,
      },
      {
        icon: '',
        label: 'num_steps',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'number',
        uid: 'num_steps',
        value: 500,
      },
      {
        icon: '',
        label: 'lr',
        supportedModelTypes: ['image_to_image', 'text_to_image', 'text_classification', 'text_to_text', 'translation'],
        type: 'text',
        uid: 'lr',
        value: '1e-4',
      },
      {
        icon: '',
        label: 'gradient_accumulation',
        supportedModelTypes: ['image_to_image', 'text_to_image', 'text_classification', 'text_to_text', 'translation'],
        type: 'number',
        uid: 'gradient_accumulation',
        value: 4,
      },
      {
        icon: '',
        label: 'mixed_precision',
        supportedModelTypes: ['image_to_image', 'text_to_image', 'text_classification', 'text_to_text', 'translation'],
        type: 'text',
        uid: 'mixed_precision',
        value: 'fp16',
      },
      {
        icon: '',
        label: 'train_text_encoder',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'boolean',
        uid: 'train_text_encoder',
        value: false,
      },
      {
        icon: '',
        label: 'xformers',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'boolean',
        uid: 'xformers',
        value: false,
      },
      {
        icon: '',
        label: 'use_8bit_adam',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'boolean',
        uid: 'use_8bit_adam',
        value: false,
      },
      {
        icon: '',
        label: 'xl',
        supportedModelTypes: ['image_to_image', 'text_to_image'],
        type: 'boolean',
        uid: 'xl',
        value: true,
      },
    ],
    providers: [
      {
        name: 'getgenius',
        photo: './assets/img/icon.webp',
        uid: 'getgenius',
      },
      {
        name: 'integration_google_colab',
        photo: './assets/img/integrations/icons/google.webp',
        uid: 'google_colab',
      },
      {
        name: 'integration_huggingface',
        photo: './assets/img/integrations/icons/huggingface.webp',
        uid: 'huggingface',
      },
      {
        name: 'integration_lambdalabs',
        photo: './assets/img/integrations/icons/lambdalabs.webp',
        uid: 'lambdalabs',
      },
      {
        name: 'integration_microsoft_azure',
        photo: './assets/img/integrations/icons/microsoft_azure.webp',
        uid: 'microsoft_azure',
      },
    ],
    route: 'ai/model',
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    statistics: {
      percentage: 0,
    },
    tab: 'model',
    tabs: [
      {
        icon: 'bulb-outline',
        name: 'recommendations',
        uid: 'model',
      },
      {
        icon: 'image-outline',
        name: 'assets',
        uid: 'assets',
      },
      {
        icon: 'film-outline',
        name: 'creatives',
        uid: 'creatives',
      },
      {
        icon: 'newspaper-outline',
        name: 'posts',
        uid: 'posts',
      },
    ],
    title: 'ai_model',
    types: [
      {
        label: 'text_classification',
        uid: 'text_classification',
      },
      {
        label: 'text_to_audio',
        uid: 'text_to_audio',
      },
      {
        label: 'text_to_image',
        uid: 'text_to_image',
      },
      {
        label: 'text_to_text',
        uid: 'text_to_text',
      },
      {
        label: 'translate',
        uid: 'translation',
      },
      {
        label: 'image_to_text',
        uid: 'image_to_text',
      },
      {
        label: 'image_to_image',
        uid: 'image_to_image',
      },
      {
        label: 'object_detection',
        uid: 'object_detection',
      },
    ],
    viewType: 'grid',
  };

  constructor(
    private aiTools: AiToolsService,
    private aiTraining: AiTrainingService,

    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  build() {
    this.view.loading = true;

    this.aiTraining.schedule({
      integrations: (this.view.integrations || []),
      mediaList: (this.view.mediaList || []),
      model: (this.view.model || {}),
      parameters: (this.view.parameters || []),
      provider: (this.view.provider || {}),
    })
      .then((response: any) => {
        this.view.loading = false;

        console.log('schedule response', response);

        this.calculateStatistics(response);

        console.log('response.exec', response.exec);

        if (!!response && !!response.exec) {

          // handle exec error if set
          if (!!response.exec.error_message) {
            this.events.publish('error', response.exec.error_message);
            return false;
          }

          // else, handle successful response
          this.view.config_preview = `${response.exec.output || ''}`;
        } else {
          this.view.config_preview = '';
        }

        console.log('this.view.config_preview', this.view.config_preview);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  calculateStatistics(response: any) {

    this.view.statistics.estimated_files = (!!response && !!response.data ? response.data.length || 0 : 0);
    this.view.statistics.estimated_time = (5 * 60) + (!!this.view.statistics.estimated_files ? (this.view.statistics.estimated_files * this.calcConfig.timePerFile) : 0);
    this.view.statistics.estimated_tokens = (!!this.view.statistics.estimated_files ? (this.view.statistics.estimated_files * this.calcConfig.tokenPerFile) : 0);

    console.log('this.view.statistics', this.view.statistics);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
      right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
    };

    if (!!this.media) {
      this.view.media = this.media;
    }

    if (!!this.mediaList) {
      this.view.mediaList = this.mediaList;
    }

  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh(event: any | null = null) {
    this.load(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  filterBaseModelsByModelType() {

    if (!this.view.models || !this.view.models.length) {
      return false;
    }

    const matchType: string = (this.view.model.type === 'text_to_text' ? 'text_generation' : this.view.model.type);

    this.view.base_models = this.view.models.filter((model: any) => {
      return (`${model.category || ''}`.replace(/-/g, "_") === matchType);
    });

    this.view.model.base_model = (!!this.view.base_models && !!this.view.base_models[0] && !!this.view.base_models[0].uid ? this.view.base_models[0].uid : null);
  }

  filterParametersByModelType() {

    if (!this.view.parameters || !this.view.parameters.length) {
      return false;
    }

    this.view.parameters.forEach((parameter: any) => {
      parameter.hidden = (!!parameter.supportedModelTypes ? (parameter.supportedModelTypes.indexOf(this.view.model.type) === -1) : false);
    });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
        right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!this.view.project || !this.view.project.uid) {
      return false;
    }

    try {
      await this.loadProviders(blForceRefresh);
      await this.loadModels(blForceRefresh);
      await this.loadPlatforms(blForceRefresh);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadInstances(blForceRefresh: boolean = false) {
    this.sd.getAvailableInstances({}, blForceRefresh)
      .then((instances: any[]) => {
        this.view.instances = (instances || []);
      })
      .catch((error: any) => {
        console.warn('loading models failed', error);
      });
  }

  loadModels(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      this.aiTools.getAvailableModels({}, blForceRefresh)
        .then((response: any) => {
          const models: aiModel[] = (!!response && !!response.data ? response.data : []);

          this.view.loading = false;
          this.view.models = (models || []);

          resolve(this.view.models);
        })
        .catch(reject);
    });
  }

  async loadPlatforms(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.availableIntegrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProviders(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.aiTools.getAvailableProviders({}, blForceRefresh)
        .then((providers: any[]) => {
          this.view.providers = (providers || []);
          console.log('ai-model: view.providers', this.view.providers);

          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();

    if (!!this.view.project && !!this.view.project.uid) {
      this.loadInstances();
    }
  }

  modelTypeChanged() {
    this.filterBaseModelsByModelType();
    this.filterParametersByModelType();
  }

  ngOnInit() {
    this.loadCards();
    this.load();

    this.calcViewVars();

    this.view.provider = this.view.providers[0];
  }

  onItemChecked(model: aiModel) {

    this.view.selectedItems = this.view.model.filter((_model: aiModel) => {
      return _model.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onInputChanged(event: any | null = null) {
    console.log('onInputChanged', event);
  }

  onInstancesChanged(event: any | null = null) {
    console.log('onInstancesChanged', event);
  }

  onIntegrationsChanged(event: any | null = null) {
    console.log('onIntegrationsChanged', event);
  }

  onProviderChanged(event: any | null = null) {
    console.log('onProviderChanged', event);
  }

  onSearchChanged(searchOptions: any | null = null) {
    console.log('onSearchChanged', searchOptions);
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {

  }

  validate() {
    this.view.loading = true;
    this.view.validating = true;

    this.aiTraining.validate({
      integrations: (this.view.integrations || []),
      mediaList: (this.view.mediaList || []),
      model: (this.view.model || {}),
      parameters: (this.view.parameters || []),
      provider: (this.view.provider || {}),
    })
      .then((response: any) => {
        console.log('validation response', response);

        this.view.config_preview = `${!!response && !!response.config ? response.config || '' : ''}`;
        this.calculateStatistics(response);

        this.view.loading = false;
        this.view.validated = true;
        this.view.validating = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.loading = false;
        this.view.validating = false;
      });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}