import { Injectable } from '@angular/core';

import { LogfileService } from 'src/app/services/core/logfile.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsService {

  constructor(
    private log: LogfileService,
    private user: UserService,
  ) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.getProfilesByCounts()
      .then((profiles: user[]) => {
        if(profiles && profiles.length) {
          profiles.forEach((profile: any) => {
            profile.name = this.user.getDisplayName(profile);
          });
        }
        resolve(profiles);
      })
      .catch(reject);
    });
  }

  getProfilesByCounts() {
    return new Promise((resolve, reject) => {
      this.log.getProfileViewCounts()
      .then((counts: any) => {
        let iFetched: number = 0,
            users: user[] = [],
            uids: any[] = Object.keys(counts).sort(function(a,b){return counts[b]-counts[a]});

        if(uids && uids.length) {
          uids.forEach((uid: any, index: number) => {
            uids[index] = parseInt(uid);

            this.user.getByUid(uid)
            .then((user: user) => {
              iFetched++;
              users.push(user);
              
              if(iFetched === uids.length) {
                resolve(users);
              }
            })
            .catch((error: any) => {
              iFetched++;
              console.warn('> user error', error);

              if(iFetched === uids.length) {
                resolve(users);
              }
            });
          });
        } else {
          resolve(users);
        }

      })
      .catch(reject);
    });
  }

}
