<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!pages && !!pages.add">

      <!-- Add action -->
      <ion-button (click)="add()" shape="round" color="primary" fill="solid">
        <ion-icon name="add" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar *ngIf="!!view[view.viewItemsKey]" [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!!view[view.viewItemsKey]" [(items)]="view[view.viewItemsKey]"
    [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="_onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content [fullscreen]="true">

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!!view.forceProjectToBeSet && (!view.project || !view.project.uid)">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!view.forceProjectToBeSet || (!!view.project && !!view.project.uid)">

    <!-- Intro card -->
    <pipeline-intro-card class="introCard" [(view)]="view.introCard"
      [hidden]="view.introCard.hidden"></pipeline-intro-card>

    <!-- Loading spinner -->
    <ion-spinner name="circular" *ngIf="!view[view.viewItemsKey]"></ion-spinner>

    <!-- Dynamic view -->
    <pipeline-dynamic-view *ngIf="!!view[view.viewItemsKey]" [(items)]="view[view.viewItemsKey]" [buttons]="buttons"
      [cta]="cta" [(view)]="view" [method]="method || 'getAll'" [options]="options"
      [selectionOptions]="selectionOptions" (selectionChanged)="_onSelectionActionChanged($event)" [service]="service"
      (itemsChanged)="onItemsChanged($event)"></pipeline-dynamic-view>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!options && !!options.showViewModeSelect">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>