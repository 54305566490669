import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

import { apiUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class ExchangeService {

    exchangeAppId: number = 1;

    tenantId: number = 1;

    constructor(
        private oauth: OauthService,
    ) {

    }

    authorize() {
        //const accessTokenRoute: string = this.AppCMS.getRequestUrl('youtube', ['authorized']);
        //const resourceUrl: string = this.AppCMS.getRequestUrl('youtube', ['profile']);

        return this.oauth.authorize({
            appId: this.exchangeAppId,
            authorizationBaseUrl: `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/authorize`,
            scope: 'https://graph.microsoft.com/User.Read', // See Azure Portal -> API permission
            accessTokenEndpoint: `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/token`,
            resourceUrl: 'https://graph.microsoft.com/v1.0/me/',
            responseType: 'code',
            pkceEnabled: true,
            web: {
                redirectUrl: `${window.location.origin}/integrations/integration/azure/connections`,
                windowOptions: 'height=600,left=0,top=0',
            },
            android: {
                redirectUrl: 'msauth://{package-name}/{url-encoded-signature-hash}', // See Azure Portal -> Authentication -> Android Configuration "Redirect URI"
            },
            ios: {
                pkceEnabled: true, // workaround for bug #111
                redirectUrl: 'msauth.{package-name}://auth',
            },
        });
    }

    connect(options: any = {}) {
        return new Promise((resolve, reject) => {

            // first, authorize application
            this.authorize().then((authResponse: any) => {
                console.log('authResponse', authResponse);
                resolve(authResponse);
            }).catch(reject);
        });
    }

}