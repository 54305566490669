<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="addBlog()" shape="round" color="primary" fill="solid">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search + Filter -->
  <ion-toolbar class="container">

    <ion-grid>
      <ion-row>

        <ion-col>
          <ion-searchbar [(ngModel)]="search.query" (ionInput)="runSearch($event)" [placeholder]="'search'|translate">
          </ion-searchbar>
        </ion-col>

        <ion-col>
          <ion-item lines="none">
            <ion-checkbox [(ngModel)]="view.filters.local" slot="start"></ion-checkbox>
            <ion-label [innerHTML]="'local'|translate"></ion-label>
          </ion-item>
        </ion-col>

        <ion-col [hidden]="!view.filters.local">
          <ion-item lines="none">
            <ion-label [innerHTML]="'region'|translate"></ion-label>
            <ion-select [(ngModel)]="view.filters.region_uid" [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" [placeholder]="'choose_region'|translate">
              <ion-select-option [innerHTML]="region.title" *ngFor="let region of view.regions"
                [value]="region.uid"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.blogs" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- No entries -->
    <pipeline-no-entries-card [cta]="cta" *ngIf="view.blogs && !view.blogs.length"></pipeline-no-entries-card>

    <!-- Loading bar -->
    <ion-spinner name="circular" *ngIf="!view.blogs"></ion-spinner>

    <!-- Blogs grid -->
    <ion-grid *ngIf="view.blogs && view.blogs.length">

      <ion-row>

        <ion-col *ngFor="let blog of view.blogs" [hidden]="blog.hidden"
          [size]="view.viewType === 'list' || !view.isDesktop ? 12 : 3">

          <ion-card>

            <ion-list lines="none">

              <ion-item-sliding>

                <!-- Item -->
                <ion-item>

                  <ion-thumbnail slot="start">
                    <ion-img [src]="blog.photo" (ionError)="thumbnailLoadingFailed(blog)"></ion-img>
                  </ion-thumbnail>

                  <ion-icon (click)="toggleActive(blog)" [name]="blog.active ? 'radio-button-on-outline' :
                    'radio-button-off-outline'" [color]="blog.active ? 'success' : 'danger'" slot="start"></ion-icon>

                  <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="blog.checked" [hidden]="!blog.uid"
                    (ionChange)="onItemChecked(blog)">
                    <ion-label (click)="edit(blog)" class="ion-text-wrap">
                      <h3 [innerHTML]="blog.name" *ngIf="blog.name"></h3>
                      <h3 [innerHTML]="blog.url" *ngIf="!blog.name"></h3>
                      <p [innerHTML]="blog.url" *ngIf="blog.name"></p>
                    </ion-label>
                  </ion-checkbox>

                  <ion-button [hidden]="view.viewType === 'grid'" slot="end" fill="clear" color="primary"
                    *ngIf="view.isDesktop" (click)="edit(blog, $event)">
                    <ion-icon name="create-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'edit'|translate"></ion-label>
                  </ion-button>

                  <ion-button [hidden]="view.viewType === 'grid'" slot="end" fill="clear" color="warning"
                    *ngIf="view.isDesktop" (click)="index(blog, $event)">
                    <ion-icon name="server-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'index'|translate"></ion-label>
                  </ion-button>

                  <ion-button [hidden]="view.viewType === 'grid'" slot="end" fill="clear" color="danger"
                    *ngIf="view.isDesktop" (click)="delete(blog, $event)">
                    <ion-icon name="trash-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'delete'|translate"></ion-label>
                  </ion-button>

                  <ion-button [hidden]="view.viewType === 'grid'" slot="end" fill="clear" color="dark"
                    (click)="open(blog.url)" icon-only>
                    <ion-icon name="open-outline"></ion-icon>
                  </ion-button>

                </ion-item>

                <ion-item [hidden]="view.viewType !== 'grid'" lines="none">

                  <ion-button slot="end" fill="clear" color="primary" *ngIf="view.isDesktop"
                    (click)="edit(blog, $event)">
                    <ion-icon name="create-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'edit'|translate"></ion-label>
                  </ion-button>

                  <ion-button slot="end" fill="clear" color="warning" *ngIf="view.isDesktop"
                    (click)="index(blog, $event)">
                    <ion-icon name="server-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'index'|translate"></ion-label>
                  </ion-button>

                  <ion-button slot="end" fill="clear" color="danger" *ngIf="view.isDesktop"
                    (click)="delete(blog, $event)">
                    <ion-icon name="trash-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'delete'|translate"></ion-label>
                  </ion-button>

                  <ion-button slot="end" fill="clear" color="dark" (click)="open(blog.url)" icon-only>
                    <ion-icon name="open-outline"></ion-icon>
                  </ion-button>

                </ion-item>

                <!-- Item options -->
                <ion-item-options slot="end" *ngIf="!view.isDesktop">
                  <ion-item-option color="primary" (click)="edit(blog,
                    $event)">
                    <ion-icon name="create-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'edit'|translate"></ion-label>
                  </ion-item-option>
                  <ion-item-option color="warning" (click)="index(blog,
                    $event)">
                    <ion-icon name="server-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'index'|translate"></ion-label>
                  </ion-item-option>
                  <ion-item-option color="danger" (click)="delete(blog,
                    $event)">
                    <ion-icon name="trash-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'delete'|translate"></ion-label>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>

            </ion-list>
          </ion-card>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>