<pipeline-spline-viewer
  [options]="splineOptions"
  [hidden]="!view.loading"
></pipeline-spline-viewer>

<ion-popover #itemSettingsPopover [isOpen]="isItemSettingsPopoverOpen" (didDismiss)="isItemSettingsPopoverOpen = false">
  <ng-template>
    <ion-content>
      <ion-list>

        <ion-item *ngIf="!!view.idea && !!view.idea.config && !!view.idea.config.composition">
          <ion-icon name="eye-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'media_template'|translate"></h3>
            <p [innerHTML]="view.idea.config.composition"></p>
          </ion-label>

        </ion-item>

        <ion-item button (click)="editCustomTemplate(view.idea)" [hidden]="!view.expertMode">
          <ion-icon name="create-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'edit_media_template'|translate" class="ion-text-wrap"></ion-label>
        </ion-item>

        <ion-item button (click)="disableTemplate(view.idea)" *ngIf="!!view.isAdmin" [hidden]="!view.expertMode">
          <ion-icon name="eye-off-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'disable_media_template'|translate" class="ion-text-wrap"></ion-label>
        </ion-item>

        <ion-item *ngIf="!!view.idea && !!view.idea.config && !!view.idea.config.instance" [hidden]="!view.expertMode">
          <ion-icon name="server-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'instance'|translate"></h3>
            <p [innerHTML]="view.idea.config.instance"></p>
          </ion-label>

        </ion-item>

        <!-- Build time -->
        <ion-item *ngIf="!!view.idea && !!view.idea.config && (!!view.idea.config.build_time || (!!view.idea.config.validation_time_start && !!view.idea.config.time_done))">
          <ion-icon name="hourglass-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'build_time'|translate"></h3>
            <p [innerHTML]="view.idea.config.build_time || ((view.idea.config.time_done - view.idea.config.validation_time_start) + ' ' + ('seconds'|translate))"></p>
          </ion-label>

        </ion-item>

        <!-- Validation time start -->
        <ion-item *ngIf="!!view.idea && !!view.idea.config && !!view.idea.config.validation_time_start_formatted" [hidden]="!view.expertMode">
          <ion-icon name="hourglass-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'time_start'|translate"></h3>
            <p [innerHTML]="view.idea.config.validation_time_start_formatted"></p>
          </ion-label>

        </ion-item>

        <!-- Time done -->
        <ion-item *ngIf="!!view.idea && !!view.idea.config && !!view.idea.config.time_done_formatted" [hidden]="!view.expertMode">
          <ion-icon name="hourglass-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'time_done'|translate"></h3>
            <p [innerHTML]="view.idea.config.time_done_formatted"></p>
          </ion-label>

        </ion-item>

      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<pipeline-selection-toolbar [(items)]="view.ideas" [(view)]="view" [options]="selectionOptions" (selectedItemsChanged)="onSelectedItemsChanged($event)" (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

<ion-grid *ngIf="view.ideas && view.ideas.length">
  <ion-row>
    <ion-col
      *ngFor="let idea of view.ideas; let i = index; trackBy:trackItems"
      [size]="view.mediaItemColSize"
      [hidden]="idea.hidden"
    >

      <ion-card
        *ngIf="!!idea"
        [class.disabled]="idea.disabled"
        [class.square]="!view.filters || (!!view.filters && !view.filters.aspect_ratio) || (!!view.filters && view.filters.aspect_ratio === '1x1')"
        [class.portrait]="!!view.filters && view.filters.aspect_ratio === '9x16'"
        [class.landscape]="!!view.filters && view.filters.aspect_ratio === '16x9'">

        <!-- item header -->
        <ion-item lines="none">

          <ion-checkbox slot="start" [(ngModel)]="idea.checked" (ionChange)="onItemCheckboxClicked(idea)" [disabled]="idea.disabled"></ion-checkbox>

          <ion-button
            slot="start"
            *ngIf="idea.type === 'video' && (!idea.state || !!idea.url)"
            [hidden]="idea.view.running"
            (click)="playIdea(idea)"
            fill="clear"
            icon-only
            color="dark"
          >
            <ion-icon name="play"></ion-icon>
          </ion-button>

          <ion-button
            slot="start"
            *ngIf="idea.type === 'video' && (!idea.state || !!idea.url)"
            [hidden]="!idea.view.running"
            (click)="stopIdea(idea)"
            fill="clear"
            icon-only
            color="dark"
          >
            <ion-icon name="stop"></ion-icon>
          </ion-button>

          <pipeline-rate-item-buttons *ngIf="!idea.state || !!idea.url" [(item)]="view.ideas[i]" type="media" slot="end"></pipeline-rate-item-buttons>
          
        </ion-item>

        <!-- show skeleton if media preview is in queue right now -->
        <ion-skeleton-text [animated]="idea.state !== 'failed'" *ngIf="!!idea.state && !idea.url" class="image"></ion-skeleton-text>

        <pipeline-media-template-preview
          *ngIf="!!idea.custom && idea.config"
          [template]="idea"
          [(view)]="idea.view"
          (click)="onItemClicked(idea)"
        ></pipeline-media-template-preview>

        <ion-img [src]="idea.url" *ngIf="(idea.type === 'image') && !!idea.url" (ionError)="thumbnailLoadingFailed(idea)" (click)="onItemClicked(idea)"></ion-img>

        <pipeline-video-template-preview (click)="onItemClicked(idea)"
          *ngIf="!idea.custom && (idea.type === 'video') && (!idea.state || !!idea.url)"
          [template]="idea"></pipeline-video-template-preview>

        <ion-item lines="none" [hidden]="!view.expertMode" *ngIf="!!idea.config && !!idea.config.error_message" [class.error]="!!idea.config && !!idea.config.error_message">
          <p class="error-message" [innerHTML]="idea.config.error_message"></p>
        </ion-item>

        <!-- item footer -->
        <ion-item lines="none" class="ion-text-wrap">

          <ion-label class="ion-text-wrap" (click)="onItemClicked(idea)">
            <h3 [innerHTML]="idea.name"></h3>
            <p *ngIf="!!idea && !!idea.template && !!idea.template.name" [innerHTML]="idea.template.name"></p>
          </ion-label>

          <ion-button *ngIf="!idea.state || !!idea.url"
            slot="end"
            (click)="presentPopover($event, idea)"
            fill="clear"
            icon-only
            color="dark">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>