import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-media-library',
  standalone: false,
  templateUrl: './media-library.page.html',
  styleUrls: ['./media-library.page.scss'],
})
export class MediaLibraryPage implements AfterViewInit, OnInit {
  @ViewChild('headerPopover') headerPopover;
  isHeaderPopoverOpen: boolean = false;

  @ViewChild('searchInput') searchInput: IonSearchbar;

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() inSetupEmbedded: boolean = false;

  paginationConfig: paginationConfig = {
    itemsKey: 'mediaList',
    limit: 500,
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyze',
    },
    {
      icon: 'film-outline',
      label: 'ai_image_image_to_video',
      requires_type: ['image'],
      uid: 'image_to_video',
    },
    {
      icon: 'cut-outline',
      label: 'combine',
      requires_type: ['video'],
      uid: 'combine',
    },
    {
      icon: 'images-outline',
      label: 'send_to_media_creator',
      requires_type: ['image', 'video'],
      uid: 'send_to_media_creator',
    },
    {
      icon: 'film-outline',
      label: 'send_to_video_creator',
      requires_type: ['image', 'video'],
      uid: 'send_to_video_creator',
    },
    {
      icon: 'download-outline',
      label: 'download',
      uid: 'download',
    },
    {
      color: 'duplicate',
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'share-outline',
      label: 'share',
      requires_type: ['image', 'video'],
      uid: 'share',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  state: state = {};

  view: any = {
    colSize: {},
    filters: {
      attributes: {},
    },
    hideGetGeniusWallet: true,
    hideMediaImportCard: true,
    itemSize: 86,
    itemsKey: 'mediaList',
    libraryView: {
      mode: 'full', // full, select
      showLanguagesSelect: true,
      showViewModeSelect: true,
      source: 'media_library',
    },
    mediaCreator: 'all',
    mediaType: 'all',
    mediaTypes: [
      {
        icon: 'images-outline',
        uid: 'all',
        name: 'all_files',
      },
      {
        icon: 'image-outline',
        uid: 'image',
        name: 'images',
      },
      {
        icon: 'film-outline',
        uid: 'video',
        name: 'videos',
      },
      {
        icon: 'document-outline',
        uid: 'document',
        name: 'documents',
      },
    ],
    multiple: true,
    options: {
      include_items: false,
      limit: 50,
    },
    queueViewMode: 'card',
    route: 'media/library',
    showLanguagesSelect: true,
    showPagination: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'media_library',
    viewType: 'grid',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private importService: ImporterService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    this.view.folderIdFromRoute = parseInt(this.route.snapshot.paramMap.get('folderId'));
  }

  async add(event: any | null = null, params: any = {}) {
    params.mediaType = 'creative';
    params.multiple = true;
    params.services = ['ai', 'database', 'upload'];

    if (!!this.view.folder_uid) {
      params.folder_uid = this.view.folder_uid;
    }

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcViewVars() {
    this.paginationConfig.limit = this.paginationConfig.limit || 500;
    this.view = this.viewService.calcVars(this.view);
  }

  detectChanges() {

  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh() {
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (!!this.view.libraryView && !!this.view.libraryView.mediaList ? this.view.libraryView.mediaList : []),
      service: this.media,
      source: 'media',
      type: 'mediaItem',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  importMedia(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.media,
      source: 'mediaextend',
      type: 'media',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaFolderCurrent = this.events.subscribe('media:folder:current', (folder: folder) => {
      this.view.folder_uid = (!!folder && !!folder.uid ? folder.uid : null);
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewDidEnter() {
    this.calcViewVars();
  }

  ionViewWillEnter() {
    this.loadProject();

    this.initEvents();

    this.calcViewVars();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
    this.calcViewVars();
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterViewInit() {
    this.loadProject();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  viewModeChanged(event: any | null = null) {

    if (!!this.view.libraryView) {
      this.view.expertMode = !!this.view.libraryView.expertMode;
    }

    this.calcViewVars();
  }

}