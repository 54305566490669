import { Component, OnInit } from '@angular/core';

import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-translation',
  standalone: false,
  templateUrl: './translation.page.html',
  styleUrls: ['./translation.page.scss'],
})
export class TranslationPage implements OnInit {

  state: state = {};

  translation: translationItem;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'translation_admin_top_card',
      text: 'translation_admin_top_card_text',
      title: 'translation_admin_top_card_title',
    },
    route: 'translation',
    title: 'translation',
  };

  constructor(
    private modalService: ModalService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.translation && this.translation.uid ? 'edit_translation' : 'create_translation');
  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  save() {
    
  }

  update() {

  }

}
