import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { MediaItemsComponent } from './media-items.component';

import { VideoTemplatePreviewComponentModule } from 'src/app/components/media/videos/video-template-preview/video-template-preview.component.module';
import { MediaTemplatePreviewComponentModule } from 'src/app/components/media/media-template-preview/media-template-preview.component.module';
import { SplineViewerComponentModule } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component.module';
import { SelectionToolbarComponentModule } from 'src/app/components/generic/selection/selection-toolbar/selection-toolbar.component.module';
import { RateItemButtonsComponentModule } from 'src/app/components/ratings/rate-item-buttons/rate-item-buttons.component.module';

@NgModule({
  declarations: [
    MediaItemsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    RateItemButtonsComponentModule,
    MediaTemplatePreviewComponentModule,
    VideoTemplatePreviewComponentModule,
    SelectionToolbarComponentModule,
    SplineViewerComponentModule,
  ],
  exports: [
    MediaItemsComponent,
  ]
})
export class MediaItemsComponentModule { }