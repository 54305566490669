import { AfterContentInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { AiCoderService } from 'src/app/services/ai/ai-coder.service';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ViewService } from 'src/app/services/core/view.service';

import { proxyUrl } from 'src/config/variables';

import { EditorComponentModule } from 'src/app/components/generic/editor/editor.component.module';

@Component({
  selector: 'app-ai-coder',
  standalone: false,
  templateUrl: './ai-coder.page.html',
  styleUrls: ['./ai-coder.page.scss'],
})
export class AiCoderPage implements AfterContentInit, OnInit {

  @ViewChild('aiCoderSwiper') swiper: ElementRef | undefined;
  @ViewChild('aiCoderThumbsSwiper') thumbsSwiper: ElementRef | undefined;

  aiCoderProject: aiCoderProject = {
    active: true,
    files: [],
    icon: 'rocket-outline',
    name: '',
  };

  aiSettings: aiSettings = {
    context: 'text_generation',
    use_proxy: true,
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_generation'
    ],
  };

  appConfig: pipelineAppConfig;

  cards: any = {
    code: { open: true },
    files: { open: true },
    general: { open: true },
    preview: { open: true },
  };

  codeProjectId: number | null;

  @ViewChild(EditorComponentModule) editor: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    input_placeholder: 'ai_coder_ai_helper_input_placeholder',
    headline: 'ai_coder_ai_helper_text',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
  };

  proxyUrl: string;

  search: searchOptions = {
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  view: any = {
    buttonAction: 'generate',
    canGenerate: false,
    editorMode: 'code',
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_coder_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_coder_top_card_text',
      title: 'ai_coder_top_card_title',
    },
    progress: 0,
    route: 'ai/items',
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    title: 'ai_coder',
  };

  constructor(
    private aiCoder: AiCoderService,

    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private params: ParamsService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.codeProjectId = parseInt(this.route.snapshot.paramMap.get('codeProjectId'));
    this.proxyUrl = proxyUrl;
  }

  aiCreate(options: any = {}) {
    options.init = options.init !== false;
    options.input = options.input || this.view.aiCreateInput;

    if (!options.input || !options.input.length) {
      return false;
    }

    this.aiCoderProject = this.aiCoderProject || {};
    this.aiCoderProject.name = this.aiCoderProject.name || `${options.input || ''}`;

    this.view.finalResultUrl = null;
    this.view.input_backup = options.input;
    this.view.generating = true;
    this.view.loading = true;
    this.view.progress = 0;
    this.view.showSplineView = true;

    setTimeout(() => {
      this.startManually();
    }, 3500);

    const params: any = {
      history: (options.history || []),

      onFilesChanged: (files: any[] | null) => {
        this.onFilesChanged(files);
      },
    };

    this.aiCoder.executeCreateCode(options, params)
      .then(async (response: any) => {

        if (!!options.init) {
          this.aiCoderProject.files = (!!response && !!response.files ? response.files : []);
        }

        if (!!response && !!response.output) {
          this.startManually();

          const contentLines: string[] = `${response.output || ''}`.split("\n");
          const blShouldBeautify: boolean = `${contentLines[0] || ''}`.indexOf('`') === 0;

          let outputFormat: string = `${contentLines[0] || ''}`.replace('```', '');
          let initCode: string | null = response.output;

          if (!outputFormat && !!initCode && (
            (initCode.toLocaleLowerCase().indexOf('<!doctype') !== -1) ||
            (initCode.toLocaleLowerCase().indexOf('<html') !== -1))
          ) {
            outputFormat = 'html';
          }

          // detect code in output and beautify it
          if (blShouldBeautify) {
            initCode = `${response.output || ''}`.replace(`${contentLines[0]}\n`, '').replace('```', '');
          }

          initCode = `${initCode || ''}`.replace('```html', '').replace('```', '');

          if (!!initCode) {

            if (!!this.aiSettings.use_proxy) {
              this.view.code = this.aiCoder.addProxyUrlToResources(initCode || '');
            } else {
              this.view.code = this.aiCoder.removeProxyUrlToResources(initCode || '');
            }

            this.view.codeLanguage = (outputFormat || 'html');
            this.updateEditor();

            switch (outputFormat) {
              case 'html':
                this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);
                break;
              default:

                const simulate: any = await this.aiCoder.simulate(`${this.view.code}`);
                console.log('ai-coder: simulate response', simulate);

                if (!!simulate && !!simulate.output) {
                  this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${simulate.output}`);
                } else {
                  this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);
                }

                break;
            }
          }
        }

        this.view.buttonAction = 'rebuild';
        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;
        this.view.introCard.hidden = true;

        this.calcViewVars();
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcViewStates() {
    this.view.canGenerate = true;
    this.view.canSave = (!!this.aiCoderProject && !!this.aiCoderProject.name && !!this.view.code_preview);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.calcColSize();
    this.calcViewStates();
  }

  dismiss() {

  }

  doRefresh(event: any | null = null) {
    this.loadCoder(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  generate() {
    this.startManually();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadCoder(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      //this.view.loading = true;
      //this.view.loading = false;
      resolve(true);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  async loadUIData() {
    const createCodeParams: any = this.params.get('viewData_createCode') || {};

    if (!!this.codeProjectId) {
      try {

        // load server-side code project
        const serverSideCodeProject: any = await this.aiCoder.getCodeProjectByUid(this.codeProjectId);
        console.log('serverSideCodeProject', serverSideCodeProject);

        if (!!serverSideCodeProject && !!serverSideCodeProject.uid) {

          // apply server-side code project
          this.aiCoderProject = (serverSideCodeProject as aiCoderProject);
          console.log('updated aiCoderProject', this.aiCoderProject);

          if (!!this.aiCoderProject.files && !!this.aiCoderProject.files.length) {
            let blCodeFound: boolean = false;

            // load first existing file with output response
            this.aiCoderProject.files.forEach((file: any) => {
              if (!!file.code && (!blCodeFound || file.label === 'index.html')) {
                this.previewFile(file);
                blCodeFound = true;
              }
            });

          }

          this.startManually();
        }
      } catch (e) {
        this.events.publish('error', e);
      }
    } else
      if (!!createCodeParams && !!createCodeParams.code) {
        // apply code params if set

        this.aiCoderProject.name = `${createCodeParams.code.name || ''}`;
        this.view.code = `${createCodeParams.code.post_content || ''}`;

        this.refreshView();
        this.startManually();
      }
  }

  ngAfterContentInit() {

  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();
    this.loadProject();
    this.loadUIData();
  }

  onEditorInputChanged(value: string, key: string = 'input') {
    this.view.code = `${value || ''}`;
  }

  onFilesChanged(files: any[] | null = null) {
    this.zone.run(() => {
      this.aiCoderProject = this.aiCoderProject || {};
      this.aiCoderProject.files = (files || []);
    });
  }

  onProjectFileClicked(file: aiCoderProjectFile, event: any | null = null) {
    console.log('onProjectFileClicked: file', file);

    this.aiCoderProject.files.forEach((_file: any) => {
      _file.checked = false;
    });

    file.checked = true;

    this.previewFile(file);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  previewFile(file: any) {
    console.log('previewFile', file);

    if (!file.code) {
      return false;
    }

    this.view.code = `${file.code || ''}`;
    this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);

    this.updateEditor();
    this.calcViewVars();
  }

  rebuildAll() {

  }

  rebuildFile() {
    this.view.aiCreateInput = `${this.view.input_backup || ''}`;

    if (!!this.view.aiCreateInput) {
      this.aiCreate();
    }
  }

  refreshView() {
    this.view.code = `${this.view.code || ''}`;
    this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);

    this.updateEditor();
    this.calcViewVars();
  }

  async runAiPrompt(event: any = null) {

    if (!!event && (typeof event === 'string') && !this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      this.view.aiCreateInput = event;
    }

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.input_backup = this.view.aiCreateInput;

    const prompt: string = (!!this.view.code ? `${this.view.aiCreateInput}: ${this.view.code}` : this.view.aiCreateInput);

    await this.aiCreate({
      init: false,
      input: prompt,
    });

    this.view.aiCreateInput = '';
  }

  save() {

    if (!!this.aiCoderProject && !!this.aiCoderProject.uid) {
      return this.update();
    }

    // apply code per file (if missing)
    if (!!this.aiCoderProject.files && !!this.aiCoderProject.files.length) {
      this.aiCoderProject.files.forEach((file: any) => {
        if ((!file.code || file.label === 'index.html') && !!this.view.code) {
          file.code = this.view.code;
        }
      });
    }

    this.aiCoder.create(this.aiCoderProject)
      .then((response: any) => {
        console.log('ai-coder: create response', response);

        if (!!response && !!response.project) {
          this.aiCoderProject = response.project;
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.startManually = true;

    this.calcViewVars();
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleFullscreen(card: string | null = null) {
    this.view.fullscreen = !this.view.fullscreen;
    this.view.fullscreenCard = (!!this.view.fullscreen ? card : null);

    this.calcViewVars();
  }

  useProxyChanged() {

    if (!!this.aiSettings.use_proxy) {
      this.view.code = this.aiCoder.addProxyUrlToResources(this.view.code);
    } else {
      this.view.code = this.aiCoder.removeProxyUrlToResources(this.view.code);
    }

    this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${(this.view.code || '')}`);

    this.updateEditor();
    this.calcViewVars();
  }

  update() {

    // apply code per file (if missing)
    if (!!this.aiCoderProject.files && !!this.aiCoderProject.files.length) {
      this.aiCoderProject.files.forEach((file: any) => {
        if ((!file.code || file.label === 'index.html') && !!this.view.code) {
          file.code = this.view.code;
        }
      });
    }

    this.aiCoder.update(this.aiCoderProject)
      .then((response: any) => {
        console.log('ai-coder: update response', response);

        if (!!response && !!response.project) {
          this.aiCoderProject = response.project;
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  updateEditor() {
    try {
      if (!!this.editor) {
        this.editor.setInput(this.view.code);
      }
    } catch (e) {
      console.warn('updating editor failed', e);
    }
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}