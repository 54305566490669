<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
      
    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="update()" *ngIf="translation && !!translation.uid" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="save()" *ngIf="!translation || !translation.uid" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>
  
</ion-header>

<ion-content>

  <div class="container">

    <ion-grid>

      <ion-row>

        <!-- Input -->
        <ion-col>
          <ion-card>

            <pipeline-languages-select [label]="'input_language'" [(input)]="view.input_language" [options]="view.languageSelectOptions"></pipeline-languages-select>
            
            <ion-item lines="none">
              <ion-textarea [(ngModel)]="view.input_key" [placeholder]="'translation_input_key_placeholder'|translate"></ion-textarea>
            </ion-item>

          </ion-card>
        </ion-col>

        <!-- Output-->
        <ion-col>
          <ion-card>

            <pipeline-languages-select [label]="'output_language'" [(input)]="view.output_language" [options]="view.languageSelectOptions"></pipeline-languages-select>
            
            <ion-item lines="none">
              <ion-textarea [(ngModel)]="view.output_key" [placeholder]="'translation_output_value_placeholder'|translate"></ion-textarea>
            </ion-item>

          </ion-card>
        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>
