import { Component, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { CountriesService } from 'src/app/services/geo/countries.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { UserService } from 'src/app/services/core/user.service';
import { CitiesService } from 'src/app/services/geo/cities.service';
import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';

@Component({
  selector: 'app-edit-source',
  standalone: false,
  templateUrl: './edit-source.page.html',
  styleUrls: ['./edit-source.page.scss'],
})
export class EditSourcePage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  search: any = {
    cityQuery: '',
    countryQuery: '',
  };

  source: source = {};

  state: state = {};

  view: any = {
    automation: {},
    hideOrderByBtn: true,
    hideSearch: true,
    indexing: {
      interval: 'auto',
      intervals: [
        'auto',
        'weekly',
        'daily',
        'hourly',
      ],
    },
    notifications: {},
    permissions: {
      allow_extension: false,
      allow_image_caching: false,
      allow_image_optimization: false,
      allow_summarization: false,
      allow_translation: false,
      auto_activate_after_index: false,
    },
    segment: 'general',
    title: 'edit_source',
  };

  constructor(
    private cities: CitiesService,
    private countries: CountriesService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navParams: NavParams,
    private sources: SourcesService,
    private userService: UserService,
    private zone: NgZone,
  ) {
    this.source = this.navParams.get('source') || this.source;

    this.applySourceViewData();
  }

  applySourceViewData() {
    if (!!this.source && !!this.source.region) {
      this.view.cityLookupBlocked = true;
      this.search.cityQuery = this.source.region;

      setTimeout(() => {
        this.view.cityLookupBlocked = false;
      });
    }
  }

  convertToRegionalSource() {
    this.update();
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss({
      source: this.source,
    });
  }

  doRefresh(event: any | null = null) {
    this.sources.getSourceByUid(this.source.uid, true)
      .then((source: source) => {
        this.loadSettings();
        this.source = source;

        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  async loadSettings() {
    this.sources.getSourceSettings(this.source.uid)
      .then((settings: any) => {
        this.view.blockUpdate = true;

        if (settings && settings.length) {
          settings.forEach((setting: any) => {
            if (!!setting.key) {
              switch (setting.key) {
                case 'indexing_interval':
                  this.view.indexing.interval = setting.value;
                  break;
                case 'indexing_max_age':
                  this.view.indexing.max_age = parseInt(setting.value);
                  break;
                default:
                  this.view.permissions[setting.key] = !!setting.value;
              }
            }
          });
        }

        setTimeout(() => {
          this.view.blockUpdate = false;
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.loadSettings();
  }

  onCityQueryChanged() {

    if (!!this.view.cityLookupBlocked) {
      return false;
    }

    if (this.search.cityQuery && (this.search.cityQuery.length > 2)) {
      this.cities.get(false, {
        query: this.search.cityQuery,
      })
        .then((cities: city[]) => {
          this.view.cities = cities;
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onCountryQueryChanged() {
    if (this.search.countryQuery && (this.search.countryQuery.length > 1)) {
      this.countries.getCountries(false, {
        query: this.search.countryQuery,
      })
        .then((countries: country[]) => {
          this.view.countries = countries;
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onSourcePhotoClick() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.source.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  pickSourceUser() {
    this.userService.pick()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  removeCity() {
    this.zone.run(() => {
      this.search.cityQuery = '';

      delete this.source.region;

      delete this.view.city;
    });
  }

  removeCountry() {
    this.zone.run(() => {
      this.search.countryQuery = '';
      delete this.source.country;
      delete this.view.country;

      this.removeCity();
    });
  }

  thumbnailLoadingFailed() {
    this.source.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {
    this.sources.updateSource(this.source)
      .then((response: any) => {
        this.updatedPermissions();
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  updatedPermissions() {
    if (!this.view.blockUpdate) {
      this.view.permissions.indexing_interval = this.view.indexing.interval || 'auto';
      this.view.permissions.indexing_max_age = this.view.indexing.max_age || 0;

      this.sources.updateSourcePermissions(this.view.permissions, this.source.uid)
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  useCity(city: city) {
    this.zone.run(() => {
      this.search.cityQuery = city.title;

      this.source.region = city.title;
      this.source.region_uid = city.uid;

      this.view.city = city;
      delete this.view.cities;
    });
  }

  useCountry(country: country) {
    this.zone.run(() => {
      this.search.countryQuery = country.name;
      this.source.country = country.indent;
      this.view.country = country;
      delete this.view.countries;
    });
  }

}
