<ion-header class="ion-no-border">

  <ion-toolbar class="container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
      
    </ion-buttons>

  </ion-toolbar>
  
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-card>
      <ion-item lines="none">
        <ion-checkbox slot="start" [(ngModel)]="rule.active" (ionChange)="onInputChanged($event)"></ion-checkbox>
        <ion-label [innerHTML]="'mail_rule_active_label'|translate"></ion-label>
      </ion-item>
    </ion-card>

    <ion-card>
      <ion-item lines="none">
        <ion-label [innerHTML]="'mail_rule_filter'|translate" position="floating"></ion-label>
        <ion-input type="text" [(ngModel)]="rule.filter" (ionInput)="onInputChanged($event)" [placeholder]="'mail_rule_filter_placeholder'|translate"></ion-input>
      </ion-item>
    </ion-card>

    <ion-card>
      <ion-item lines="none">
        <ion-label [innerHTML]="'mail_rule'|translate" position="floating"></ion-label>
        <ion-select [(ngModel)]="rule.rule" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" (ionChange)="onInputChanged($event)" [placeholder]="'mail_rule_placeholder'|translate">
          <ion-select-option *ngFor="let _rule of view.rules" [innerHTML]="_rule|translate" [value]="_rule"></ion-select-option>
        </ion-select>
      </ion-item>
    </ion-card>

    <ion-card [hidden]="rule.rule !== 'forward' && rule.rule !== 'move'">
      <ion-item lines="none">
        <ion-label [innerHTML]="'mail_target_filter'|translate" position="floating"></ion-label>
        <ion-input type="text" [(ngModel)]="rule.target" (ionInput)="onInputChanged($event)" [placeholder]="'mail_rule_target_placeholder'|translate"></ion-input>
      </ion-item>
    </ion-card>

  </div>

</ion-content>