import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';

import { MediaTemplatePreviewLayerComponent } from './media-template-preview-layer.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MediaTemplatePreviewLayerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    DragDropModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [
    MediaTemplatePreviewLayerComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class MediaTemplatePreviewLayerComponentModule { }