<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
      
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">

    <ion-card color="white"
      *ngIf="!deliveryOptions.repeat || (deliveryOptions.repeat && tmpOrder.interval && (tmpOrder.time || tmpOrder.datetime))">

      <ion-card-header [hidden]="!!view.hasStripeUI">
        <ion-card-title [innerHTML]="'choose_your_payment_method'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list color="white" class="payments" lines="none">
        
        <ion-item *ngIf="applePayAvailable" [color]="paymentOptions.paymentMethod == ('apple_pay') ? 'primary' : ''"
          (click)="use('apple_pay')">
          <ion-icon name="logo-apple" slot="start"></ion-icon>
          <ion-label [innerHTML]="'apple_pay'|translate"></ion-label>
        </ion-item>

        <ion-item *ngIf="googlePayAvailable" [color]="paymentOptions.paymentMethod == ('google_play') ? 'primary' : ''"
          (click)="use('google_play')">
          <ion-icon name="logo-apple" slot="start"></ion-icon>
          <ion-label [innerHTML]="'google_play'|translate"></ion-label>
        </ion-item>

        <p class="or" *ngIf="applePayAvailable || googlePayAvailable">
          <span [innerHTML]="'or'|translate"></span>
        </p>

        <div class="ion-padding">
          <div id="paypal-button-container"></div>
        </div>
      </ion-list>

      <div id="stripeEmbed" #stripeEmbed [hidden]="!view.hasStripeUI"></div>
    
    </ion-card>

  </div>

</ion-content>

<ion-footer class="ion-no-border"
  *ngIf="(!deliveryOptions.repeat || (deliveryOptions.repeat && tmpOrder.interval && (tmpOrder.time || tmpOrder.datetime))) && paymentOptions.paymentMethod">
  <ion-toolbar class="ion-no-border container">
    <ion-button (click)="next()" expand="block" color="primary">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label [innerHTML]="'pay'|translate"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>