import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from "src/app/services/core/view.service";

@Component({
  selector: 'pipeline-dynamic-view',
  standalone: false,
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.scss']
})
export class DynamicViewComponent implements OnInit {

  @Input() buttons: button[];

  @Input() cta: any = {};

  @Input() items: any[];

  @Output() itemsChanged = new EventEmitter();

  @Input() method: string;

  @Input() mode: string;

  @Input() options: dynamicViewPageOptions;

  @Input() paginationConfig: paginationConfig = {};

  @Output() selectedItemsChanged = new EventEmitter();
  
  @Output() selectionChanged = new EventEmitter();

  @Input() selectionOptions: selectionOption[];

  @Input() service: any;

  @Input() view: any;

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private alertService: AlertService,
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  calcSelectedItems(event: any | null = null) {

    if (this.items && this.items.length) {
      this.view.selectedItems = this.items.filter((_event: any) => {
        return !!_event.checked || (!!event && (_event.uid === event.uid && !!event.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.selectedItemsChanged.emit(this.view.selectedItems || []);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.viewType = this.view.viewType || 'list';
  }

  doRefresh(event: any | null = null) {
    try {
      this.loadData(true);
    } catch (e) {
      this.events.publish('error', e);
      console.warn('refreshing failed (1)', e);
    }
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  loadData(blForceRefresh: boolean = false) {

    if (!this.method) {
      console.warn('dynamic view: method not defined');
      return false;
    } else
      if (!this.service) {
        console.warn('dynamic view: service not defined');
        return false;
      }

    try {
      this.view.loading = true;

      let params: any = JSON.parse(JSON.stringify((!!this.options && !!this.options.params ? this.options.params || {} : {})));

      this.service[this.method](params, blForceRefresh)
        .then((response: any) => {
          this.items = (response || []);
          this.view.loading = false;

          this.itemsChanged.emit(this.items);
        })
        .catch((error: any) => {
          this.view.loading = false;
          this.events.publish('error', error);
        });
    } catch (e) {
      this.events.publish('error', e);
    }

  }

  loadNext(event: any) {
    console.log('loadNext: event', event);
    console.log('loadNext: paginationConfig', this.paginationConfig);
    console.log('loadNext: view', this.view);
  }

  ngOnInit() {
    this.options = this.options || {};
    this.options.labelKey = this.options.labelKey || 'label';

    this.initEvents();
    this.calcViewVars();
    this.validateUIState();
  }

  onButtonClick(button: button, item: any) {
    console.log('dynamic-view: onButtonClick: button', button);
    console.log('dynamic-view: onButtonClick: item', item);

    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              item.disabled = true;
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            item.disabled = false;
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  onItemChanged(item: any) {
    this.calcSelectedItems();
  }

  onSelectionActionChanged(event: any) {
    this.selectionChanged.emit(event);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  validateUIState() {
    if (!this.items || !this.items.length) {
      this.loadData();
    }
  }

}