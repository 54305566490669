import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  containStrings: string[] = ['youtube.com/embed', '<video'];
  
  constructor(
    private AppCMS: AppcmsService,
    private media: MediaextendService,
    private postsAdmin: PostsAdminService,
  ) {

  }

  create(video: mediaItem) {
    return this.AppCMS.loadPluginData('pipeline', {
      video: video
    }, ['videos', 'create']);
  }

  delete(videoId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      video: {
        uid: videoId,
      }
    }, ['videos', 'delete']);
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, ) {
    return this.getVideos(options, blForceRefresh);
  }

  getLibrary(blForceRefresh: boolean = false, options: any = {}, params: any = {}) {
    return this.media.getVideos(options, blForceRefresh, params);
  }

  getVideos(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['videos'], {}, blForceRefresh);
  }

  parseVideoPost(post: post) {
    let videoFromContentLookup: string|null = null;

    post.type = 'post_video';

    if(this.containStrings && this.containStrings.length) {
      this.containStrings.forEach((string: string) => {
        let stringExplode = post.post_content.split(string);

        if(stringExplode.length === 2 && (stringExplode[1].length > 10)) {
          let stringExplodeB = (stringExplode[1] || '').split('>');

          if(string === '<video' && (stringExplodeB[0].indexOf('<video') === -1)) {
            videoFromContentLookup = `<video width="100%" height="100%" ${stringExplodeB[0]}></video>`;
          } else
          if(string === 'youtube.com/embed') {
            videoFromContentLookup = `<iframe width="100%" height="100%" src="https://youtube.com/embed${stringExplodeB[0]}></iframe>`;
          } else {
            videoFromContentLookup = `<iframe width="100%" height="100%" ${stringExplodeB[0]}></iframe>`;
          }
        }
      });
    }

    if(!!videoFromContentLookup) {
      post.video = videoFromContentLookup;
    }

    return post;
  }

  parseVideoPosts(posts: post[]) {
    if(posts && posts.length) {
      posts.forEach((post: post) => {
        post = this.parseVideoPost(post);
      });
    }
    return posts;
  }

  async submitForReview(post: post) {
    return this.postsAdmin.submitForReview(post);
  }

  submitVideo(post: post) {
    return this.postsAdmin.submitPost(post);
  }

  updateVideo(post: post) {
    return this.postsAdmin.updatePost(post);
  }

}
