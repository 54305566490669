import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class ElevenlabsService {

  _detailItem: any;
  
  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  createProject(project: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('elevenlabs', Object.assign(options, {
      project: project,
    }), ['projects', 'add']);
  }

  createVoice(voice: any = {}, options: any = {}) {
    return this.AppCMS.loadPluginData('elevenlabs', Object.assign(options, {
      voice: voice,
    }), ['voices', 'add']);
  }

  deleteChapter(chapterId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {}, ['chapters', chapterId, 'delete'], {}, blForceRefresh);
  }

  deleteProject(projectId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {}, ['projects', projectId, 'delete'], {}, blForceRefresh);
  }

  deleteSample(sampleId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {}, ['samples', sampleId, 'delete'], {}, blForceRefresh);
  }

  deleteVoice(voiceId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {}, ['voices', voiceId, 'delete'], {}, blForceRefresh);
  }

  detailItem(item: any|null = null) {

    if(item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getChapters(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['chapters'], {}, blForceRefresh);
  }
  
  getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['connections'], {}, blForceRefresh);
  }

  getHistory(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['history'], {}, blForceRefresh);
  }

  getModels(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['models'], {}, blForceRefresh);
  }

  getProjects(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['projects'], {}, blForceRefresh);
  }

  getSettings(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['settings'], {}, blForceRefresh);
  }

  getVoices(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', options, ['voices'], {}, blForceRefresh);
  }

  speechToSpeech(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {
      options: options,
    }, ['textToSpeech'], {}, blForceRefresh);
  }

  textToSpeech(input: string, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('elevenlabs', {
      input: input,
      options: options,
    }, ['textToSpeech'], {}, blForceRefresh);
  }

}