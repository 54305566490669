import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { MessagesService } from 'src/app/services/social/messages.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ReactionsService {

  emojiList: emoji[] = [
    {
      name: 'thumb_up',
      emoji: '👍',
      matches: ['like', 'thumb_up'],
    },
    {
      name: 'love',
      emoji: '&#x2764;&#xFE0F;',
      matches: ['love'],
    },
    {
      name: 'wonderful',
      emoji: '🙌',
      matches: ['care', 'wonderful'],
    },
    {
      name: 'haha',
      emoji: '😂',
      matches: ['haha', 'laught'],
    },
    {
      name: 'sad',
      emoji: '😢',
      matches: ['sad'],
    },
    {
      name: 'angry',
      emoji: '😡',
      matches: ['angry'],
    },
    {
      name: 'wow',
      emoji: '😮',
      matches: ['wow'],
    },
    /*
    {
      name: 'heart',
      emoji: '💜',
    },
    {
      name: 'rocks',
      emoji: '🤘',
    },
    {
      name: 'cool',
      emoji: '😎',
    },
    {
      name: 'lie',
      emoji: '🤥',
    },
    {
      name: 'shock',
      emoji: '😨',
    },
    */
  ];

  constructor(
    private AppCMS: AppcmsService,
    private messages: MessagesService,
    private tools: ToolsService,
    private translations: TranslationService,
    private userService: UserService,
  ) {

  }

  get(options: reactionOptions, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['reactions', 'item'], {}, blForceRefresh);
  }

  getByItem(options: any = {}, blForceRefresh: boolean = false) {
    return this.get(options, blForceRefresh);
  }

  getEmojis() {
    return this.emojiList;
  }

  getStageHtml(reaction: reaction) {
    let stage = '', stageElements = [], i = 0;
    
    while(i <= 10) {
      i++;
      stageElements.push('<span id="item' + i + '">' + reaction.emoji + '</span>');
    }

    stageElements = this.tools.shuffle(stageElements);
    stageElements.forEach((el: string) => {
      stage += el;
    });
    
    return stage;
  }

  reactOnPost(reaction: reaction) {
    reaction = JSON.parse(JSON.stringify(reaction));

    return this.AppCMS.loadPluginData('pipeline', {
      reaction: reaction,
      user: this.userService.getUid(),
    }, ['reactions', 'reactOnPost']);
  }

  send(reaction: reaction) {
    reaction = JSON.parse(JSON.stringify(reaction));
    
    return new Promise((resolve, reject) => {
      this.translations.get([
        'user_likes_your_message',
      ])
      .subscribe((translations: any) => {
        const user = this.userService.getUser() || {};

        if(reaction.partner) {
          this.messages.sendPushToFriend(reaction.partner, {
            description: translations.user_likes_your_message.replace(/\$ /g, '$').replace('$user', (user.firstname || user.lastname ? (user.firstname + ' ' + user.lastname) : user.nickname)),
          })
          .catch((error: any) => {
            console.warn('push send error', error);
          });
        }

        delete reaction.partner;

        this.AppCMS.loadPluginData('pipeline', {
          reaction: reaction,
          user: this.userService.getUid(),
        }, ['reactions', 'send']).then(resolve).catch(reject);
        
      });
    });
  }

}
