import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whitelabel-app-popup-device',
  standalone: false,
  templateUrl: './whitelabel-app-popup-device.page.html',
  styleUrls: ['./whitelabel-app-popup-device.page.scss'],
})
export class WhitelabelAppPopupDevicePage implements OnInit {

  view: any = {
    devices: [
      {
        icon: 'phone-portrait-outline',
        indent: 'device_smartphone',
      },
      {
        icon: 'tablet-portrait-outline',
        indent: 'device_tablet',
      },
      {
        icon: 'desktop-outline',
        indent: 'device_desktop',
      },
    ],
  };

  constructor(

  ) {

  }

  ngOnInit() {

  }

  select(device: any) {
    this.view.devices.forEach((_device: any) => {
      _device.checked = false;
    });
    device.checked = true;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}
