import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http'; // HttpResponse

import { Observable, throwError, BehaviorSubject, of } from 'rxjs';

import {
    catchError,
    /*
    finalize,
    switchMap,
    filter,
    take,
    tap,
    */
} from 'rxjs/operators';

import { ToastController } from '@ionic/angular';

import { AuthService } from 'src/app/services/core/auth.service';

import { apiUrl } from 'src/config/variables';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    api_url: string;

    isRefreshingToken: boolean = false;

    sessionId: string;

    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthService,
        private toastCtrl: ToastController
    ) {
        this.api_url = apiUrl;
    }

    private async handle400Error(err) {
        console.log('jwt: handle400Error', err);

        const toast: any = await this.toastCtrl.create({
            message: 'Logged out due to authentication mismatch',
            duration: 2000
        });

        toast.present();

        this.authService.logout();

        return of(null);
    }

    private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log('jwt: handle401Error: request', request);

        // @todo: implement refresh flow
        return this.handle400Error('not_implemented: 401') as any;

        /*
        if (!this.isRefreshingToken) {
            this.tokenSubject.next(null);
            this.isRefreshingToken = true;

            this.authService.clearAccessToken();

            return this.authService.getNewAccessToken().pipe(
                switchMap((token: any) => {
                    console.log('jwt: handle401Error: new token: ', token);

                    if (token) {
                        const accessToken = token.accessToken;
                        return this.authService.setAccessToken(accessToken).pipe(
                            switchMap(_ => {
                                this.tokenSubject.next(accessToken);
                                return next.handle(this.addToken(request));
                            })
                        );
                    } else {
                        return of(null);
                    }
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request));
                })
            );
        }
        */
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isInBlockedList(request.url)) {
            return next.handle(request);
        } else {
            return next.handle(
                this.addToken(request)
            )
                .pipe(
                    /*
                    tap((event: any) => {
                        if (event instanceof HttpResponse) {
                            const cookies = event.headers.getAll('Set-Cookie');
                            let sessionId: any;

                            if (cookies) {

                                const phpSessionCookie = cookies.find(cookie => {
                                    cookie.toLowerCase().startsWith('phpsessid=')
                                });

                                if (phpSessionCookie) {
                                    const match = phpSessionCookie.match(/PHPSESSID=([^;]+)/);

                                    if (match && match[1]) {
                                        this.sessionId = match[1];
                                    }
                                }
                            }

                            //const sessionId = event.headers.get('X-Session-ID');

                            if (sessionId) {
                                this.sessionId = sessionId;
                            }
                        }
                    }),
                    */
                    catchError((err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            switch (err.status) {
                                case 400:
                                    return this.handle400Error(err);
                                case 401:
                                    return this.handle401Error(request, next);
                                default:
                                    return throwError(err);
                            }
                        } else {
                            return throwError(err);
                        }
                    }),
                );
        }
    }

    private isInBlockedList(url: string): Boolean {
        if (url == `${this.api_url}/auth` ||
            url == `${this.api_url}/auth/logout`) {
            return true;
        } else {
            return false;
        }
    }

    private addToken(req: HttpRequest<any>) {
        if (!!this.authService.currentAccessToken) {

            let headers = new HttpHeaders({
                Authorization: `Bearer ${this.authService.currentAccessToken}`,
            });

            /*
            if (this.sessionId) {
                headers = headers.set('X-Session-ID', this.sessionId);
            }
            */

            return req.clone({
                headers: headers,
                //withCredentials: true
            });
        } else {
            return req;
        }
    }

}