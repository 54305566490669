<ion-header class="ion-no-border" [translucent]="config.translucentHeaders">

  <!-- Top header -->
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false && (view.mode !== 'edit')"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal || (view.mode === 'edit')"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Edit -->
      <ion-button (click)="edit()" *ngIf="view.mode !== 'edit'" fill="clear" color="primary" shape="round">
        <ion-icon name="create-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'edit'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="updateUser()" *ngIf="view.mode === 'edit'" fill="solid" color="primary" shape="round"
        [disabled]="!!view.blockUpdates">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">

    <!-- Edit user details -->
    <ion-card *ngIf="!!config.allowUserEditProfileDetails">
      <ion-list lines="none">

        <ion-grid>

          <ion-row>

            <!-- Firstname -->
            <ion-col [size]="view.mode === 'edit' ? 6 : 12">
              <ion-item lines="none">

                <ion-avatar slot="start" class="cover" (click)="uploadPhoto()">
                  <ion-img [src]="user.photo || fallbackAvatarImg" (ionError)="thumbnailLoadingFailed(user)"></ion-img>
                </ion-avatar>

                <ion-label *ngIf="view.mode !== 'edit'">
                  <h3 [innerHTML]="(user.firstname || '') + ' ' + (user.lastname || '')"></h3>
                  <p [innerHTML]="user.email"></p>
                </ion-label>

                <ion-input [(ngModel)]="user.firstname" [placeholder]="'firstname'|translate"
                  [label]="'firstname'|translate" labelPlacement="floating" *ngIf="view.mode === 'edit'"></ion-input>

              </ion-item>
            </ion-col>

            <!-- Lastname -->
            <ion-col size="6" *ngIf="view.mode === 'edit'">
              <ion-item lines="none">

                <ion-input [(ngModel)]="user.lastname" [placeholder]="'lastname'|translate"
                  [label]="'firstname'|translate" labelPlacement="floating"></ion-input>

              </ion-item>
            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-list>
    </ion-card>

    <!-- Public visible? -->
    <ion-card *ngIf="user && user.classifications">
      <ion-list lines="none">
        <ion-item>
          <ion-icon name="eye-outline" slot="start" color="primary"></ion-icon>

          <ion-checkbox class="ion-text-wrap" [(ngModel)]="user.classifications.public" (ionChange)="updateUser()">
            <ion-label>
              <h2 [innerHTML]="'public_visible'|translate"></h2>
              <p [innerHTML]="'public_visible_description'|translate"></p>
            </ion-label>
          </ion-checkbox>

        </ion-item>
      </ion-list>
    </ion-card>

    <!-- preferred text length -->
    <ion-card *ngIf="user && user.classifications && !!config.useComplexityExtension">
      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'preferredTextLength'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item>
          <ion-icon name="chatbox-outline" slot="start" color="primary"></ion-icon>
          <ion-range [(ngModel)]="user.classifications.preferredTextLength" min="250" snaps="true" pin="true" step="250"
            max="2000" (ionChange)="updateUser()"></ion-range>
          <ion-icon name="book-outline" slot="end" color="primary"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-card>

    <!-- Complexity -->
    <ion-card *ngIf="user && user.classifications && !!config.useComplexityExtension">
      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'preferredComplexity'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-list lines="none">
        <ion-item>
          <ion-icon [name]="user.classifications.preferredComplexity === 1 ? 'cafe' : 'cafe-outline'" slot="start"
            color="primary"></ion-icon>
          <ion-range [(ngModel)]="user.classifications.preferredComplexity" snaps="true" pin="true" min="1" step="1"
            max="3" (ionChange)="updateUser()"></ion-range>
          <ion-icon [name]="user.classifications.preferredComplexity === 3 ? 'school' : 'school-outline'" slot="end"
            color="primary"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-card>

    <!-- Language -->
    <ion-card *ngIf="!!config.multiLanguageSupport">
      <ion-list lines="none">
        <pipeline-languages-select [(input)]="view.language" [options]="languageOptions"></pipeline-languages-select>
      </ion-list>
    </ion-card>

    <!-- Abonnement -->
    <ion-card [hidden]="view.mode === 'edit'" *ngIf="!!config.useAbonnements">
      <ion-list lines="none">
        <ion-item button (click)="abonnement()">
          <ion-icon name="sync-outline" slot="start" color="primary"></ion-icon>
          <ion-label>
            <h2 [innerHTML]="'abonnement'|translate"></h2>
            <p [innerHTML]="'abonnement_description'|translate"></p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <!-- More -->
    <ion-card [hidden]="view.mode === 'edit'">

      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'more'|translate"></ion-card-subtitle>
      </ion-card-header>

      <ion-list lines="none">

        <ion-item button (click)="transparency()" *ngIf="!!config.useTransparencyExtension">
          <ion-icon name="finger-print-outline" slot="start" color="primary"></ion-icon>
          <ion-label [innerHTML]="'transparency'|translate"></ion-label>
        </ion-item>

        <ion-item button (click)="security()" *ngIf="!!config.useSecurityExtension">
          <ion-icon name="lock-closed-outline" slot="start" color="primary"></ion-icon>
          <ion-label [innerHTML]="'security'|translate"></ion-label>
        </ion-item>

        <ion-item button (click)="clearCaches()">
          <ion-icon name="server-outline" slot="start" color="primary"></ion-icon>
          <ion-label [innerHTML]="'clear_caches'|translate"></ion-label>
        </ion-item>

      </ion-list>
    </ion-card>

  </div>

</ion-content>