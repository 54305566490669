import { Component, Input, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'pipeline-ad-preview',
  standalone: false,
  templateUrl: './ad-preview.component.html',
  styleUrls: ['./ad-preview.component.scss'],
})
export class AdPreviewComponent implements OnInit {
  @Input() ad: ad;
  @Input() options: adCardOptions = {};

  view: any = {
    fixedColSize: 12,
    story: {

    }
  };

  constructor(
    private events: EventsService,
  ) {
    this.view.fixedColSize = 12;
  }

  calcViewVars() {
    this.view.fixedColSize = this.ad.size || 12;
    this.ad.location = this.ad.location || 'ad.location.stories.between';

    let posts: post[] | ad[] = [this.ad];

    let placeholder = {
      location: this.ad.location,
      size: this.view.fixedColSize,
    };

    if (this.ad.location !== 'ad.location.stories.between') {
      posts.push(placeholder);
    } else
      if (this.ad.location.indexOf('ad.location.web') === 0) {
        posts[0].size = 4;
        placeholder.size = 4;

        posts = posts.concat([placeholder, placeholder]);
      }

    this.view.posts = posts;

    this.events.publish('create:ad:detectChanges');
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('ad:preview:updated', (ad: ad) => {
      this.ad = ad;

      this.calcViewVars();
    });
  }

  read() {

  }

}