<ion-toolbar class="container header-suggestions-toolbar ion-no-border"
  *ngIf="view && view.suggestions && view.suggestions.length">

  <ion-grid class="suggestionsGrid">
    <ion-row nowrap class="scroll-row">

      <ion-col [size]="view.suggestionsColSize" *ngFor="let suggestion of view.suggestions; trackBy:trackItems">

        <ion-card (click)="onSuggestionClicked(suggestion)">

          <ion-card-content [hidden]="suggestion.name">
            <ion-skeleton-text style="width:90%;" animated></ion-skeleton-text>
            <ion-skeleton-text style="width:30%;" animated></ion-skeleton-text>
          </ion-card-content>

          <ion-icon *ngIf="suggestion.icon" [name]="suggestion.icon"></ion-icon>

          <ion-avatar *ngIf="(suggestion && suggestion.photo) || suggestion.src">
            <ion-img
              [src]="(suggestion && suggestion.photo && suggestion.photo.length ? suggestion.photo : suggestion.src)"></ion-img>
          </ion-avatar>

          <small *ngIf="suggestion.name" [innerHTML]="suggestion.name|translate"></small>

        </ion-card>

      </ion-col>

    </ion-row>
  </ion-grid>

</ion-toolbar>