import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from "src/app/services/core/events.service";
import { FilesExplorerService } from "src/app/services/files/files-explorer.service";
import { OauthService } from 'src/app/services/core/oauth.service';
import { UserService } from 'src/app/services/core/user.service';

import { apiUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class FtpService {

    _detailItem: any;

    customButtons: button[] = [];

    customSelectionOptions: selectionOption[] = [
        {
            handler: async (item: any = null, event: any = null) => {
                this.filesExplorer.detailItem(item);
                this.navCtrl.navigateForward('/files/explorer/ftp');
            },
            icon: 'search-outline',
            label: 'browse',
            uid: 'browse',
        },
        {
            handler: async (event: any = null) => {
                try {
                    const exec: any = await this.analyse(event.item);
                } catch (e) {
                    this.events.publish('error', e);
                }
            },
            icon: 'search-outline',
            label: 'analyze',
            uid: 'analyze',
        },
        {
            handler: async (event: any = null) => {
                try {
                    const exec: any = await this.synchronize(event.item);
                } catch (e) {
                    this.events.publish('error', e);
                }
            },
            icon: 'sync-outline',
            label: 'synchronize',
            uid: 'synchronize',
        },
        {
            handler: async (event: any = null) => {
                try {
                    const exec: any = await this.validate(event.item);
                } catch (e) {
                    this.events.publish('error', e);
                }
            },
            icon: 'checkmark-outline',
            label: 'validate',
            uid: 'validate',
        },
    ];

    fileTypesByExtension: any = {
        avif: 'video',
        jpeg: 'image',
        jpg: 'image',
        png: 'image',
        txt: 'attachment',
        webm: 'video',
        webp: 'image',
    };

    iconsByExtension: any = {
        bat: 'code-slash-outline',
        css: 'logo-css3',
        doc: 'document-text-outline',
        docx: 'document-text-outline',
        htm: 'logo-html5',
        html: 'logo-html5',
        js: 'logo-javascript',
        php: 'code-slash-outline',
        py: 'code-slash-outline',
        rss: 'logo-rss',
        sass: 'logo-sass',
        sh: 'code-slash-outline',
        txt: 'document-text-outline',
    };

    iconsByFileType: any = {
        'attachment': 'document-outline',
        'document': 'document-outline',
        'file': 'document-outline',
        'folder': 'folder-outline',
        //'image': 'image-outline',
        //'video': 'film-outline',
    };

    constructor(
        private AppCMS: AppcmsService,
        private events: EventsService,
        private filesExplorer: FilesExplorerService,
        private navCtrl: NavController,
        private oauth: OauthService,
        private userService: UserService,
    ) {

    }

    analyse(connection: integrationConnection) {
        return new Promise((resolve, reject) => {
            if (!connection || !connection.uid) {
                reject('error_missing_connection_uid');
            } else {
                this.AppCMS.loadPluginData('ftpmanager', {
                    connection_uid: connection.uid,
                }, ['connections', 'analyse']).then(resolve).catch(reject);
            }
        });
    }

    authorize() {
        return new Promise((resolve, reject) => {
            const accessTokenRoute: string = this.AppCMS.getRequestUrl('ftpmanager', ['authorized']),
                resourceUrl: string = this.AppCMS.getRequestUrl('ftpmanager', ['profile']);

            this.oauth.authorize({
                authorizationBaseUrl: 'https://www.ftpmanager.com/api/v1/authorize',
                accessTokenEndpoint: accessTokenRoute, // accessTokenRoute,
                default: {
                    clientId: 'e0yAYCdF8sTKXFAUSg_Glw',
                    redirectUrl: `${apiUrl}/ftpmanager/authorized.json`,
                    responseType: 'code',
                },
                duration: 'permanent',
                resourceUrl: resourceUrl,
                scope: 'identity edit flair history read vote wikiread wikiedit',
                state: this.calcState(),
            })
                .then(resolve)
                .catch(reject);
        });
    }

    browse(connection: integrationConnection, options: any = {}, blForceRefresh: boolean = false) {
        return new Promise((resolve, reject) => {
            if (!connection || !connection.uid) {
                reject('error_missing_connection_uid');
            } else {
                this.detailItem(connection);

                this.AppCMS.loadPluginData('ftpmanager', Object.assign(options, {
                    connection_uid: connection.uid,
                }), ['connections', 'browse'], {}, blForceRefresh).then(resolve).catch(reject);
            }
        });
    }

    calcState() {
        return Math.random().toString(36).substring(2, 12);
    }

    createConnection(connection: integrationConnection) {
        return this.AppCMS.loadPluginData('ftpmanager', {
            connection: connection,
        }, ['connections', 'create']);
    }

    deleteConnection(connectionId: number) {
        return this.AppCMS.loadPluginData('ftpmanager', {
            uid: connectionId,
        }, ['connections', 'delete']);
    }

    detailItem(item: any | null = null) {

        if (item !== null) {
            this._detailItem = item;
            return this;
        }

        return this._detailItem;
    }

    getApiRequestLog(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('ftpmanager', options, ['log'], {}, blForceRefresh);
    }

    getConnections(options: any = {}, blForceRefresh: boolean = false): Promise<any> {
        return this.AppCMS.loadPluginData('ftpmanager', options, ['connections'], {}, blForceRefresh);
    }

    getCustomSelectionOptions() {
        return this.customSelectionOptions;
    }

    getSettings(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('ftpmanager', options, ['settings'], {}, blForceRefresh);
    }

    proxy(connection: integrationConnection, options: any = {}, blForceRefresh: boolean = false) {
        return new Promise((resolve, reject) => {
            if (!connection || !connection.uid) {
                reject('error_missing_connection_uid');
            } else {
                this.detailItem(connection);

                this.AppCMS.loadPluginData('ftpmanager', Object.assign(options, {
                    connection_uid: connection.uid,
                }), ['connections', 'proxy'], {}, blForceRefresh).then(resolve).catch(reject);
            }
        });
    }

    sortBrowseResponse(exec: any, connection: integrationConnection, options: any = {}) {
        options = options || {};

        let folders: any[] = [], mediaList: mediaItem[] = [];

        const root: string = (!!options && !!options.directory ? `${options.directory}/` : '/');
        const rootParentId: string | null = (!!options && !!options.directory ? `${options.directory}` : null);
        const user: user = this.userService.getUser() || {};
        const apiUrl: string = this.AppCMS.getApiUrl();
        const thumbnailPrefix: string = `${apiUrl}/images/thumbnail.webp?width=500&height=500&input=`;

        let urlPrefix: string = `${apiUrl}/ftpmanager/connections/proxy.json?raw=1&userId=${user.uid}&user[email]=${user.email}&connection_uid=${connection.uid}&file=`;
        urlPrefix = `${urlPrefix}${root.replace(urlPrefix, '')}`;

        if (!!exec && !!exec.connect && !!exec.connect.tree) {
            const remoteFilesKeys: string[] = Object.keys(exec.connect.tree).sort();

            remoteFilesKeys.forEach((fileKey: string) => {
                let children: any[] = [];

                const childFilesKeys: string[] = Object.keys(exec.connect.tree[fileKey]);
                const extensionSplit: string[] = fileKey.split('.');
                const extension: string | null = (extensionSplit.length > 1 ? extensionSplit[extensionSplit.length - 1] : null);

                const fileType: string = (
                    (!!extension && !!this.fileTypesByExtension[extension]) ?
                        this.fileTypesByExtension[extension] :
                        (exec.connect.tree[fileKey].type || (!!exec.connect.tree[fileKey].size ? 'attachment' : 'folder'))
                );

                const icon: string | null = (!!extension && !!this.iconsByExtension[extension] ? this.iconsByExtension[extension] : this.iconsByFileType[fileType]);

                fileKey = `${fileKey || ''}`.replace(urlPrefix, '');

                const guid: string = `${urlPrefix}${fileKey}`;
                const thumbnailUrl: string = `${thumbnailPrefix}${encodeURIComponent(guid)}`;

                let file: any = {
                    extension: extension,
                    guid: guid,
                    icon: icon,
                    info: {
                        mtime: exec.connect.tree[fileKey].mtime,
                        size: exec.connect.tree[fileKey].size,
                    },
                    parent: rootParentId,
                    platform: 'ftpmanager',
                    post_mime_type: exec.connect.tree[fileKey].post_mime_type || fileType,
                    thumbnail: thumbnailUrl,
                    title: fileKey,
                    type: fileType,
                    uid: `${root}${fileKey}`,
                };

                childFilesKeys.forEach((childKey: string) => {

                    if (childKey === 'mtime' || childKey === 'size') {
                        return;
                    }

                    const child: any = exec.connect.tree[fileKey][childKey];
                    const childExtensionSplit: string[] = fileKey.split('.');
                    const childExtension: string | null = (childExtensionSplit.length > 1 ? childExtensionSplit[childExtensionSplit.length - 1] : null);

                    const childType: string = (
                        (!!childExtension && !!this.fileTypesByExtension[childExtension]) ?
                            this.fileTypesByExtension[childExtension] :
                            (child.type || (!!child.size ? 'attachment' : 'folder'))
                    );

                    const childIcon: string | null = (!!childExtension && !!this.iconsByExtension[childExtension] ? this.iconsByExtension[childExtension] : this.iconsByFileType[childType]);

                    const childGuid: string = `${urlPrefix}${fileKey}/${childKey}`;
                    const childThumbnailUrl: string = `${thumbnailPrefix}${encodeURIComponent(childGuid)}`;

                    const childFile: any = {
                        extension: childExtension,
                        guid: childGuid,
                        icon: childIcon,
                        info: {
                            mtime: child.mtime,
                            size: child.size,
                        },
                        parent: file.guid,
                        post_mime_type: child.post_mime_type || childType,
                        platform: 'ftpmanager',
                        thumbnail: childThumbnailUrl,
                        title: childKey,
                        type: childType,
                        uid: `${root}${fileKey}/${childKey}`,
                    };

                    children.push(childFile);

                    if (childFile.type === 'folder') {
                        folders.push(childFile);
                    }
                });

                file.children = children;

                if (file.type === 'folder') {
                    folders.push(file);
                } else {
                    mediaList.push(file);
                }
            });
        }

        return {
            folders: folders,
            items: mediaList,
        };
    }

    synchronize(connection: integrationConnection) {
        return new Promise((resolve, reject) => {
            if (!connection || !connection.uid) {
                reject('error_missing_connection_uid');
            } else {
                this.AppCMS.loadPluginData('ftpmanager', {
                    connection_uid: connection.uid,
                }, ['connections', 'synchronize']).then(resolve).catch(reject);
            }
        });
    }

    updateConnection(connection: integrationConnection) {
        connection = JSON.parse(JSON.stringify(connection));

        delete connection.checked;

        return this.AppCMS.loadPluginData('ftpmanager', {
            connection: connection,
        }, ['connections', 'update']);
    }

    validate(connection: integrationConnection) {
        return new Promise((resolve, reject) => {
            if (!connection || !connection.uid) {
                reject('error_missing_connection_uid');
            } else {
                this.AppCMS.loadPluginData('ftpmanager', {
                    connection_uid: connection.uid,
                }, ['connections', 'validate']).then(resolve).catch(reject);
            }
        });
    }

}