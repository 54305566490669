import { Injectable } from '@angular/core';

import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { SettingsService } from "src/app/services/core/settings.service";

declare var EXIF: any;
//import EXIF from 'exif-js';

import { apiUrl } from 'src/config/variables';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  cdnHost: string;

  quality: number = 85;

  shouldOptimize: boolean = false;

  constructor(
    private media: MediaextendService,
    private postsAdmin: PostsAdminService,
    private settings: SettingsService,
  ) {
    this.cdnHost = `${apiUrl}/images/optimize`;
  }

  delete(mediaId: number) {
    return this.postsAdmin.delete(mediaId);
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.media.getImages(options, blForceRefresh, params);
  }

  async getExif(element: HTMLImageElement) {
    let allMetaData: any;

    EXIF.getData(<HTMLImageElement>element, function (event) {
      allMetaData = EXIF.getAllTags(this);
      console.log('getExif: allMetaData', allMetaData);
    });

    return allMetaData;
  }

  async getpreferredQuality() {
    let preferredImageSize = (await this.settings.getSetting('preferredImageSize')) || 5;
    switch (preferredImageSize) {
      case 1:
        return 10;
      case 2:
        return 20;
      case 3:
        return 60;
      case 4:
        return 75;
      case 5:
        return 100;
      default:
        return this.quality;
    }
  }

  async submitForReview(mediaItem: mediaItem) {
    return this.postsAdmin.submitForReview(mediaItem as post);
  }

}