import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { PushnotificationsService } from 'src/app/services/core/pushnotifications.service';

import { Badge } from '@awesome-cordova-plugins/badge/ngx';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { MessagesService } from 'src/app/services/social/messages.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';


@Injectable({
  providedIn: 'root'
})
export class InboxService {

  chats: any = {};

  lastNotificationTimestamp: any;

  constructor(
    private AppCMS: AppcmsService,
    private badge: Badge,
    private events: EventsService,
    private messages: MessagesService,
    private navCtrl: NavController,
    private push: PushnotificationsService,
    private tools: ToolsService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
  ) {
  }

  archiveChat(chat: inboxChat) {
    const partnerId: number = (chat.user && chat.user.uid ? chat.user.uid : chat.user);
    return this.messages.archiveMessages({
      user: this.userService.getUid(),
      partner: partnerId,
    });
  }

  cacheChat(partnerId: number, chat: inboxChat) {
    return this.messages.setCachedChat(partnerId, chat);
  }

  createChat(options: any = {}) {
    return new Promise((resolve, reject) => {
      if (!options) {
        reject('error_missing_chat_options');
      } else
        if (!options.partner || !options.partner.uid) {
          reject('error_missing_chat_partner_uid');
        } else {
          this.setPartnerId(options.partner.uid);
          resolve({});
        }
    });
  }

  deleteChat(chat: inboxChat) {
    const partnerId: number = (chat.user && chat.user.uid ? chat.user.uid : chat.user);
    return this.messages.deleteConversation(partnerId);
  }

  get(blForceRefresh: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      options.filter = options.filter || {};
      options.filter.archived = !!options.filter.archived;

      const user = this.userService.getUser() || {};

      this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
        user: user.uid,
      }), ['inbox'], {}, blForceRefresh)
        .then((inbox: inbox) => {
          inbox = this.parseInbox(inbox);
          resolve(inbox);
        })
        .catch(reject);
    });
  }

  getArchived(blForceRefresh: boolean = false, options: any = {}) {
    return this.get(blForceRefresh, Object.assign(options, {
      filter: {
        archived: true,
      }
    }));
  }

  getBadge() {
    return 0;
  }

  getChat(partnerId: number) {
    let chat = this.messages.getCachedChat(partnerId);

    if (chat.messages && chat.messages.length) {

      chat.messages.forEach((message: inboxChatMessage) => {
        if (message.item && typeof message.item === 'object') {
          message.item.style = {
            'backgroundUrl': message.item.thumbnail ? this._sanitizer.bypassSecurityTrustStyle(`url('${message.item.thumbnail}')`) : '',
          };
        }
      });

      chat.messages.sort((a: any, b: any) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
      });
    }

    return chat;
  }

  onChatClick(chat: inboxChat) {
    let userId = typeof chat.user === 'number' ? chat.user : chat.user.uid;
    this.messages.setCachedChat(userId, chat);
    this.messages.setPartnerId(userId);
    this.navCtrl.navigateForward('/messenger');
  }

  onNotificationClick(notification: notification) {
    return this.push.onNotificationClick(notification);
  }

  parseInbox(inbox: inbox) {
    const user = this.userService.getUser() || {};

    if (inbox && inbox.chats && inbox.chats.length) {
      inbox.chats.forEach((chat: inboxChat) => {
        if (chat && chat.user && chat.user.uid) {
          chat.messages.forEach((message: inboxChatMessage) => {

            if (message.timestamp) {
              //const timestamp = typeof message.timestamp === 'number' ? moment.unix(message.timestamp) : moment(message.timestamp, 'YYYY-MM-DD HH:mm'),
              //    timestampFormatted = timestamp.format('DD.MM.YYYY') != moment().format('DD.MM.YYYY') ? timestamp.format('DD.MM.YYYY') : timestamp.format('HH:mm');
              //    message.timestamp_formatted = timestampFormatted;
            }

            message.description = this.tools.wrapURL(message.description || '');
            message.user = typeof message.user === 'object' ? message.user : (message.user == chat.user.uid ? chat.user : user);
          });
          this.cacheChat(chat.user.uid, chat);
        }
      });
    }
    return inbox;
  }

  refresh() {
    return new Promise((resolve, reject) => {
      this.get(true)
        .then((inbox: inbox) => {

          // new notification received since last refresh
          if (this.lastNotificationTimestamp && (inbox.chats && inbox.chats[0] && inbox.chats[0].messages && inbox.chats[0].messages[inbox.chats[0].messages.length - 1].timestamp > this.lastNotificationTimestamp)) {
            this.badge.increase(1);
          }

          if (inbox.chats && inbox.chats[0] && inbox.chats[0].messages) {
            this.lastNotificationTimestamp = inbox.chats[0].messages[inbox.chats[0].messages.length - 1].timestamp;
          }

          this.events.publish('inbox:refreshed', inbox);

          resolve(inbox);
        }).catch(reject);
    });
  }

  restoreFromArchive(chat: inboxChat) {
    return this.messages.unarchiveMessages({
      user: this.userService.getUid(),
      partner: (chat.user && !!chat.user.uid ? chat.user.uid : null),
    });
  }

  setPartnerId(partnerId: number) {
    return this.messages.setPartnerId(partnerId);
  }

}
