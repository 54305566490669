import { NgModule } from '@angular/core';

// directives:

// pipes
import { FillVarsPipe } from './pipes/fillVars';

@NgModule({
    declarations: [
        FillVarsPipe,
    ],
    exports: [
        FillVarsPipe,
    ],
})
export class SharedModule { }