<ion-header  class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
      
    </ion-buttons>
  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">
    <ion-grid>
      <ion-row>
        
        <ion-col>
          <ion-card>
            <ion-card-header>
              <ion-card-title [innerHTML]="'creators_regional_settings'|translate"></ion-card-title>
              <p [innerHTML]="'creators_regional_settings_text'|translate"></p>
            </ion-card-header>
            <ion-list>

              <pipeline-languages-select [(input)]="view.language"></pipeline-languages-select>

              <ion-item>
                <ion-icon name="locate-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap" [innerHTML]="'creators_default_region'|translate"></ion-label>
              </ion-item>
              
            </ion-list>
          </ion-card>
        </ion-col>
  
        <ion-col>
          <ion-card>
            <ion-card-header>
              <ion-card-title [innerHTML]="'creators_monetarization_rate'|translate"></ion-card-title>
              <p [innerHTML]="'creators_monetarization_rate_text'|translate"></p>
            </ion-card-header>
            <ion-list lines="none">
              <ion-item>
                <ion-icon name="funnel-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap" [innerHTML]="'creators_monetarization_rate_mode'|translate"></ion-label>
                <ion-select interface="popover" [(ngModel)]="view.monetarization.mode" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" (ionChange)="updateMonetarizationSettings()">
                  <ion-select-option *ngFor="let mode of view.monetarization.modes" [value]="mode" [innerHTML]="('creators_monetarization_rate_mode_' + mode)|translate"></ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-range [(ngModel)]="view.monetarization.rate" snaps="true" pin="true" min="0" step="5" max="100" (ionChange)="updateMonetarizationSettings()"></ion-range>
              </ion-item>
              <ion-item *ngIf="view.monetarization.description">
                <ion-label class="ion-text-wrap" [innerHTML]="view.monetarization.description"></ion-label>
              </ion-item>
            </ion-list>
          </ion-card>
        
        </ion-col>
  
      </ion-row>
    </ion-grid>
  </div>

</ion-content>
