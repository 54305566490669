<ion-card class="url-preview">

  <ion-chip color="primary" class="sponsored" *ngIf="data.sponsored">
    <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
  </ion-chip>

  <ion-thumbnail [class.responsive]="!!data.responsive">
    <ion-img [src]="data.thumbnail" (ionError)="thumbnailLoadingFailed()"></ion-img>
  </ion-thumbnail>

  <ion-item lines="none" [hidden]="data.mini">
    
    <ion-avatar slot="start">
      <ion-img [src]="data.avatar" (ionError)="avatarLoadingFailed()"></ion-img>
    </ion-avatar>

    <ion-label class="ion-text-wrap">
      <h2 [innerHTML]="data.name"></h2>
      <p [innerHTML]="data.description"></p>
    </ion-label>

  </ion-item>

  <ion-item lines="none" *ngIf="!data.mini && (data && data.url)">
    <ion-label [innerHTML]="data.url"></ion-label>
    <ion-icon name="open-outline" slot="end"></ion-icon>
  </ion-item>

</ion-card>