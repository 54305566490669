<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="create()" color="primary" shape="round" fill="solid"
        [disabled]="!product.uid || !product.name || !view.canPublish" *ngIf="!view.canUpdate">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="update()" color="primary" shape="round" fill="solid"
        [disabled]="!product.uid || !product.name || !view.canUpdate" *ngIf="!view.canPublish">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container main-container" [class.has-nav]="!!products && !!products.length" *ngIf="!!view.keys" [hidden]="view.loading">

    <ion-button *ngIf="!!products && !!products.length" fill="clear" color="primary" shape="round" class="nav-btn back" icon-only
      [disabled]="!view.canNavigateBack" (click)="navBack()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>

    <ion-button *ngIf="!!products && !!products.length" fill="clear" color="primary" shape="round" class="nav-btn next" icon-only
      [disabled]="!view.canNavigateNext" (click)="navNext()">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- General -->
          <ion-card>

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'general'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <ion-list>

              <ion-item>
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-textarea rows="3" labelPlacement="stacked" type="text" [(ngModel)]="product.name" [label]="'name'|translate"
                  [placeholder]="'product_name'|translate"></ion-textarea>
              </ion-item>

              <ion-item disabled *ngIf="!!product.uid">
                <ion-icon name="fingerprint-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="stacked" disabled type="text" [(ngModel)]="product.uid"
                  [label]="'uid'|translate" [placeholder]="'product_uid'|translate"></ion-input>
              </ion-item>

              <ion-item disabled *ngIf="!!product.original_uid">
                <ion-icon name="git-branch-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="stacked" disabled type="text" [(ngModel)]="product.original_uid"
                  [label]="'original_uid'|translate" [placeholder]="'product_original_uid'|translate"></ion-input>
              </ion-item>

              <ion-item>

                <!-- Photo -->
                <ion-thumbnail slot="start">
                  <ion-img [src]="product.image || fallbackImg"
                    (ionError)="thumbnailLoadingFailed('thumbnail')"></ion-img>
                </ion-thumbnail>

                <ion-input labelPlacement="stacked" type="text" [(ngModel)]="product.image" [label]="'photo'|translate"
                  [placeholder]="'product_photo'|translate"></ion-input>

              </ion-item>

              <ion-item lines="none">
                <ion-icon name="link-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="stacked" type="text" [(ngModel)]="product.url" [label]="'url'|translate"
                  [placeholder]="'product_url'|translate"></ion-input>
              </ion-item>

            </ion-list>
          </ion-card>

          <!-- Attributes -->
          <pipeline-item-attributes-card *ngIf="!view.rebuild && !view.loading"
            [(cards)]="cards" [(item)]="product" location="pipeline_product" type="pipeline_product" [(view)]="view">
          </pipeline-item-attributes-card>

        </ion-col>

        <!-- Content area -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 9 : 12">

          <!-- Description -->
          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'description'|translate"></ion-card-title>
            </ion-card-header>

            <ion-segment scrollable [(ngModel)]="view.descriptionTab" *ngIf="!!product.field_optimizations && !!product.field_optimizations.length" (ionChange)="descriptionTabChanged($event)">

                <ion-segment-button value="original">
                    <ion-label [innerHTML]="'original'|translate"></ion-label>
                </ion-segment-button>

                <ion-segment-button *ngFor="let optimization of product.field_optimizations; let i = index;" [value]="i">
                  <ion-label [innerHTML]="('optimization'|translate) + ' ' + (i+1)"></ion-label>
                </ion-segment-button>

            </ion-segment>
            
            <!-- Original description -->
            <ion-grid [hidden]="view.descriptionTab !== 'original'">
              <ion-row>

                <!-- Input prompt -->
                <ion-col [hidden]="view.mode !== 'optimize'">
                  <ion-item lines="none">

                    <ion-label position="stacked" [innerHTML]="'input'|translate"></ion-label>

                    <pipeline-editor *ngIf="!view.rebuild && !view.loading" [(editor)]="view.prompt_editor"
                      [input]="view.input_prompt || ''" [disabled]="!!view.loading"></pipeline-editor>

                  </ion-item>
                </ion-col>

                <!-- Description -->
                <ion-col>

                  <ion-spinner [hidden]="!view.loading"></ion-spinner>

                  <ion-item lines="none">

                    <ion-label position="stacked" [innerHTML]="'output'|translate"></ion-label>

                    <pipeline-editor *ngIf="!view.rebuild && !view.loading" [(editor)]="view.editor"
                      [input]="product.description || ''" [disabled]="!!view.loading"></pipeline-editor>

                    <!-- Switch to optimize view -->
                    <ion-button [hidden]="(view.mode === 'optimize') || !!view.loading" slot="end" fill="clear"
                      color="dark" (click)="optimize('description')">
                      <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'optimize'|translate"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-col>

              </ion-row>

              <!-- Run optimize -->
              <ion-row [hidden]="!!view.loading || (view.mode !== 'optimize')">
                <ion-col>
                  <ion-button size="block" shape="round" fill="solid" color="primary" (click)="optimize('description')">
                    <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'optimize'|translate"></ion-label>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>

            <!-- Optimized description -->
            <pipeline-editor [hidden]="view.descriptionTab === 'original'"
              *ngIf="!!product.field_optimizations && !!product.field_optimizations[view.descriptionTab] && !!product.field_optimizations[view.descriptionTab].value && !view.rebuild && !view.loading"
              [(editor)]="view.prompt_editor" [input]="product.field_optimizations[view.descriptionTab].value || ''" [disabled]="!!view.loading"></pipeline-editor>

          </ion-card>

          <!-- Dynamic keys -->
          <ion-card *ngFor="let key of view.keys">

            <ion-list>

              <ion-item lines="none">

                <!-- Photo -->
                <ion-thumbnail slot="start" *ngIf="key === 'image' || key === 'photo' || key === 'thumbnail'">
                  <ion-img [src]="product[key] || fallbackImg" (ionError)="thumbnailLoadingFailed(key)"></ion-img>
                </ion-thumbnail>

                <!-- Icon -->
                <ion-icon slot="start" *ngIf="!!view.icons[key]" [name]="view.icons[key]"></ion-icon>

                <!-- Active or public -->
                <ion-checkbox [(ngModel)]="product[key]" [hidden]="key !== 'active' && key !== 'public'" justify="start"
                  labelPlacement="end">
                  <ion-label [innerHTML]="key|translate"></ion-label>
                </ion-checkbox>

                <!-- Generic -->
                <ion-input [label]="key|translate" labelPlacement="stacked" [(ngModel)]="product[key]"
                  [disabled]="key === 'uid'"
                  [hidden]="key === 'active' || key === 'description' || key === 'public'"></ion-input>

                <!-- Description -->
                <ion-label [hidden]="key !== 'description'" [innerHTML]="key|translate" position="stacked"></ion-label>

                <pipeline-editor *ngIf="key === 'description' && !view.rebuild && !view.loading"
                  [(editor)]="view.editor" [input]="product[key] || ''" [disabled]="!!view.loading"></pipeline-editor>

                <ion-button [hidden]="(key !== 'description') || !!view.loading" slot="end" fill="clear" color="dark"
                  fill="clear" (click)="optimize(key)">
                  <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'optimize'|translate"></ion-label>
                </ion-button>
                
                <ion-spinner slot="end" [hidden]="!view.loading || (view.loadingKey !== key)"></ion-spinner>

              </ion-item>

              <pipeline-rate-item-buttons *ngIf="(key === 'description' && !!view.optimized)" [item]="product"
                type="product"></pipeline-rate-item-buttons>

            </ion-list>

          </ion-card>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!product && (!!product.uid || !!view.startManually)">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-button (click)="toggleLanguage()" icon-only [disabled]="!product.uid">
        <ion-icon name="language-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'translations'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>

</ion-footer>