import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ChatMessageComponent } from './chat-message.component';

import { RateItemButtonsComponentModule } from 'src/app/components/ratings/rate-item-buttons/rate-item-buttons.component.module';

@NgModule({
  declarations: [
    ChatMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RateItemButtonsComponentModule,
  ],
  exports: [
    ChatMessageComponent,
  ]
})
export class ChatMessageComponentModule { }