import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { PaginationService } from 'src/app/services/utils/pagination.service';

@Component({
  selector: 'pipeline-pagination',
  standalone: false,
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() config: any;
  @Input() view: any;

  @Output() onPaginationButtonClick = new EventEmitter();
  @Output() onPaginationConfigUpdated = new EventEmitter();

  buttons: button[] = [];

  constructor(
    private events: EventsService,
    private paginationService: PaginationService,
    private zone: NgZone,
  ) {
  }

  public async calculate() {
    this.zone.runOutsideAngular(async () => {
      try {
        const calc: any = await this.paginationService.calculateConfig(this.view || {}, this.config || {});

        if (!calc) {
          return false;
        }

        if (calc.hasOwnProperty('buttons')) {
          this.buttons = (calc.buttons || []);
        }

        if (!!calc.config) {
          this.config = calc.config;
        }

        if (!!calc.view) {
          this.view = calc.view;
        }

        return this.view;
      } catch (e) {
        console.warn('calc failed', e);
        return this.view;
      }
    });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.paginationButtonsChanged = this.events.subscribe('pagination:buttons:changed', (buttons: button[]) => {
      this.buttons = (buttons || []);
    });
  }

  ngOnInit() {
    this.initEvents();
    this.calculate();
  }

  async onButtonClick(button: button) {
    this.view.loading = true;
    this.config = this.config || {};
    this.config.offset = button.index;

    await this.calculate();

    setTimeout(() => {
      this.view.loading = false;
    });
  }

  onLimitChanged(event: any | null = null) {
    this.config.offset = 0;

    if (!!this.config && !!this.config.hasOwnProperty('limit')) {
      this.paginationService.setLimit(this.config.limit);
    }

    this.calculate();
  }

  async paginateBack() {
    try {
      this.view.loading = true;

      const calc: any = await this.paginationService.calculateBack(this.view || {}, this.config || {});

      if (!calc) {
        return false;
      }

      if (calc.hasOwnProperty('buttons')) {
        this.buttons = (calc.buttons || []);
      }

      if (!!calc.config) {
        this.config = calc.config;
      }

      if (!!calc.view) {
        this.view = calc.view;
      }

      this.view.loading = false;
    } catch (e) {
      console.warn('pagination: calc back failed', e);
      this.view.loading = false;
    }
  }

  async paginateNext() {
    try {
      this.view.loading = true;

      const calc: any = await this.paginationService.calculateNext(this.view || {}, this.config || {});

      if (!calc) {
        return false;
      }

      if (calc.hasOwnProperty('buttons')) {
        this.buttons = (calc.buttons || []);
      }

      if (!!calc.config) {
        this.config = calc.config;
      }

      if (!!calc.view) {
        this.view = calc.view;
      }

      this.view.loading = false;
    } catch (e) {
      console.warn('pagination: calc next failed', e);
      this.view.loading = false;
    }
  }

}