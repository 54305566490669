<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>
  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <ion-card>
    <ion-card-header>
      <ion-card-title [innerHTML]="'configure_region_title'|translate"></ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p [innerHTML]="'configure_region_text'|translate"></p>
    </ion-card-content>
    <ion-searchbar [(ngModel)]="view.regionQuery" [placeholder]="'search_region'|translate"
      (ionInput)="runSearch()"></ion-searchbar>
    <ion-list *ngIf="view.results">
      <ion-item *ngFor="let result of view.results" [class.active]="view.city && (view.city.uid == result.uid)"
        (click)="setCity(result)">
        <ion-label>
          <h2 [innerHTML]="result.city"></h2>
          <p [innerHTML]="result.state"></p>
        </ion-label>
        <ion-button slot="end" fill="outline" color="white" (click)="confirmCity()"
          [hidden]="!(view.city && (view.city.uid == result.uid))">
          <ion-label [innerHTML]="'confirm'|translate"></ion-label>
        </ion-button>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>