<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add button -->
      <ion-button (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Mobile selection toolbar -->
  <pipeline-selection-toolbar
    *ngIf="!view.isDesktop && (!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid))"
    [(items)]="view.tasks" [options]="selectionOptions" [(paginationConfig)]="paginationConfig" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importTask($event)" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- AI shortcodes -->
        <ion-item button (click)="shortcodes($event)" [disabled]="!!view.loading">
          <ion-icon name="code-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'ai_shortcodes'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export($event)" [disabled]="!!view.loading"
          *ngIf="!!view.tasks && !!view.tasks.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Import popover -->
  <ion-popover #importPopover [isOpen]="isImportPopoverOpen" (didDismiss)="isImportPopoverOpen = false">
    <ng-template>
      <ion-content>
        <ion-list>

          <ion-list-header>
            <ion-label [innerHTML]="'import_from'|translate"></ion-label>:
          </ion-list-header>

          <ion-item button class="btn-item" (click)="store()">
            <ion-icon name="storefront-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'ai_store'|translate"></ion-label>
          </ion-item>

          <ion-item button class="btn-item" (click)="comfyUI()">
            <ion-icon name="code-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'ComfyUI'|translate"></ion-label>
          </ion-item>

        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)"
    [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" *ngIf="!view.dragging">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <pipeline-intro-card [hidden]="!!view.isDesktop || view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <ion-grid class="ai-tasks-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="aiTools" location="tasks" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadTasksByFolder($event)"></pipeline-folders-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Desktop selection toolbar -->
          <pipeline-selection-toolbar
            *ngIf="!!view.isDesktop && (!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid))"
            [(items)]="view.tasks" [options]="selectionOptions" [(view)]="view"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <pipeline-intro-card [hidden]="!view.isDesktop || view.introCard.hidden" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- No entries card -->
          <pipeline-no-entries-card [cta]="cta" [text]="'ai_tasks_cta_text'|translate"
            *ngIf="view.tasks && !view.tasks.length && !view.loading"></pipeline-no-entries-card>

          <!-- Results list -->
          <ion-grid *ngIf="!!view.tasks && !!view.tasks.length">
            <ion-row>

              <ion-col *ngFor="let task of view.tasks; let i = index; trackBy:trackItems"
                [size]="view.viewType === 'list' ? 12 : view.colSize.item">

                <ion-card>

                  <!-- Top item -->
                  <ion-item [lines]="!!view.isDesktop ? true : 'none'">

                    <ion-checkbox [(ngModel)]="task.checked" justify="start" labelPlacement="end"
                      (ionChange)="onItemChecked(task)" [disabled]="!task.uid">
                      <p [innerHTML]="task.name" class="ion-text-wrap"></p>

                      <span class="active-span" (click)="toggleActive(task)">
                        <ion-icon [name]="task.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                          [color]="task.active ? 'success' : 'danger'" slot="start"></ion-icon>
                        <span [hidden]="task.active" [innerHTML]="'not_active'|translate"></span>
                        <span [hidden]="!task.active" [innerHTML]="'active'|translate"></span>
                      </span>

                    </ion-checkbox>

                    <ion-button slot="end" (click)="presentPopover($event, task)" fill="clear" icon-only color="dark"
                      *ngIf="(view.mode !== 'pick') && !!task.uid">
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <!-- Loading spinner -->
                  <ion-spinner [hidden]="!!task.uid"></ion-spinner>

                  <!-- Task childs list -->
                  <ion-list *ngIf="!!task.children && (view.viewType !== 'list')" [hidden]="!view.isDesktop">
                    <ion-item *ngFor="let child of task.children; let iSub = index; trackBy:trackItems">
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="('step'|translate) + ' ' + (iSub+1)"></h3>
                        <h3 [innerHTML]="(child.name || 'no_name')|translate"></h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>

                  <ion-item lines="none" *ngIf="!!task.uid">

                    <ion-button slot="end" icon-only fill="clear" [hidden]="task.running" color="primary"
                      (click)="runSet(task)">
                      <ion-icon name="play"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" icon-only fill="clear" [hidden]="!task.running" color="primary"
                      (click)="stopSet(task)">
                      <ion-icon name="stop"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" fill="clear" icon-only (click)="edit(task)" color="dark">
                      <ion-icon name="create-outline"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" fill="clear" icon-only (click)="editTaskCronSettings(task)" color="dark">
                      <ion-icon name="settings-outline"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" fill="clear" icon-only (click)="deleteSavedTask(task)" color="danger">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </ion-col>

            </ion-row>
          </ion-grid>


        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>