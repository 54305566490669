import { Injectable } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from 'src/app/services/core/modal.service';

import { DataEditorPage } from 'src/app/pages/core/data/data-editor/data-editor.page';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private config: ConfigService,
        private modalService: ModalService,
    ) {
    }

    editUsingUI(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            options = options || {};
            options.service = this;

            console.log('editUsingUI', options);

            const modal: any = await this.modalService.create({
                component: DataEditorPage,
                componentProps: options,
                animated: true,
                presentingElement: await this.modalService.getTop(),
                cssClass: 'defaultModal',
            });

            modal.onWillDismiss().then(resolve);

            this.modalService.present(modal);
        });
    }

}