<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="add()" shape="round" color="primary" fill="solid">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!!view.shop && !!view.shop.categories" [(items)]="view.shop.categories"
    [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Loading spinner -->
  <ion-spinner [hidden]="!view.loading"></ion-spinner>

  <div class="container" [hidden]="view.loading">

    <!-- No categories -->
    <pipeline-no-entries-card [cta]="cta" [text]="'shop_admin_cta_text'|translate"
      *ngIf="view.shop && view.shop.categories && !view.shop.categories.length"></pipeline-no-entries-card>

    <ion-grid *ngIf="view.shop.categories && !!view.shop.categories.length" [hidden]="view.loading">
      <ion-row>
        <ion-col *ngFor="let category of view.shop.categories; let i = index; trackBy:trackItems" [size]="view.colSize">
          <pipeline-product-category-card [category]="view.shop.categories[i]"></pipeline-product-category-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

  <!-- Infinite scroll -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNext($event)">
    <ion-infinite-scroll-content loadingSpinner="circular">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>