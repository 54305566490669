import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { NewslettersService } from 'src/app/services/newsletters/newsletters.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { NewsletterPage } from '../newsletter/newsletter.page';

@Component({
  selector: 'app-newsletters',
  standalone: false,
  templateUrl: './newsletters.page.html',
  styleUrls: ['./newsletters.page.scss'],
})
export class NewslettersPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
  ];
  
  options: dynamicViewPageOptions = {
    labelKey: 'title',
    showPhoto: true,
    showViewModeSelect: true,
  };

  pages: any = {};

  search: searchOptions = {
    itemsKey: 'newsletters',
    keys: ['name', 'url', 'title', 'description', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  user: user;

  view: any = {
    hideGetGeniusWallet: true,
    introCard: {
      uid: 'creator_newsletters_top_card',
      text: 'creator_newsletters_top_card_text',
      title: 'creator_newsletters_top_card_title',
    },
    itemSize: 72,
    multiple: true,
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'newsletters',
    newsletters: [],
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    public newsletters: NewslettersService,
    private tools: ToolsService,
    private userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser() || {};
    this.view.newsletters = JSON.parse(JSON.stringify(this.view.placeholders));
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'delete',
      items: this.view.selectedItems,
      service: this.newsletters,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  doRefresh() {

  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicate',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.newsletters,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }


  ngOnInit() {

    this.pages = {
      add: NewsletterPage,
      edit: NewsletterPage,
    };

    this.view.viewType = 'list';
  }

  @HostListener('window:resize')
  onResize() {
    this.view.isDesktop = this.tools.isDesktop();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }

  }

}