import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { LanguagesSelectComponent } from './languages-select.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LanguagesSelectComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [
    LanguagesSelectComponent,
  ]
})
export class LanguagesSelectComponentModule { }