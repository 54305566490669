import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';

import { EditorService } from 'src/app/services/utils/editor.service';

// text editor 
import editorJsHTML from "editorjs-html/.build/edjsHTML.node";

@Component({
  selector: 'pipeline-editor',
  standalone: false,
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements AfterViewInit {

  blockDetectOnChange: boolean = true;

  codeTheme: string = 'vs-dark';

  codeModel: any = {
    language: 'typescript',
    uri: 'index.js',
    value: "\n",
  };

  codeOptions: any = {
    contextmenu: true,
    minimap: {
      enabled: true
    }
  };

  @Input() disabled: boolean;

  @Input() editor: any;
  //editor: any;

  @ViewChild('editorJs', { read: ElementRef }) private editorElement: ElementRef;

  @Input() id: string | null;

  @Input() input: string;

  @Output() inputChange = new EventEmitter();

  @Input() language: string;

  @Input() mode: string = 'text'; // text or code

  rebuild: boolean = false;

  saveProcess: any;

  constructor(
    private editorService: EditorService,
    private zone: NgZone,
  ) {

  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
    */
  }

  async initEditor() {

    if (this.mode === 'code') {
      return false;
    }

    if (!this.editorElement || !this.editorElement.nativeElement) {
      return false;
    }

    const edjsParser = editorJsHTML();

    this.blockDetectOnChange = true;

    this.editor = await this.editorService.create(this.editorElement.nativeElement, {
      onChange: async (api: any) => {

        if (!!this.blockDetectOnChange) {
          return false;
        }

        const content = await api.saver.save();
        const blockStrings: any = edjsParser.parse(content);
        let html: string;

        if (typeof blockStrings === 'string') {
          html = blockStrings;
        } else {
          html = (blockStrings || [] as string[]).join("\n");
        }

        this.zone.run(() => {
          this.input = `${html || ''}`;
          this.codeModel.value = this.input;
          this.inputChange.emit(this.input);
        });
      },
    });

    setTimeout(() => {

      try {
        if (!!this.editor) {
          console.log('this.editor', this.editor);

          this.editor.addEventListener('focusin', (event: any | null = null) => {
            console.log('editor: focusin', event);
          });

          this.editor.addEventListener('focusout', (event: any | null = null) => {
            console.log('editor: focusout', event);
          });
        }
      } catch (e) {
        console.warn('adding editor focus events failed', e);
      }

      this.blockDetectOnChange = false;
      this.renderInput();
    }, 50);
  }

  ngAfterViewInit() {
    this.initEditor();
  }

  ngOnInit() {
    if (this.mode === 'code') {

      if (!!this.language) {
        this.codeModel.language = this.language;
      }

      this.setInput(this.input || "\n");
    }
  }

  onCodeChanged(value) {
    console.log('editor: onCodeChanged', value);
  }

  onCodeEditorLoaded(event: any) {
    console.log('editor: onCodeEditorLoaded', event);
  }

  onCodeEditorModelChanged(event: any) {
    console.log('editor: onCodeEditorModelChanged', event);
  }

  onCodeEditorModelContentChanged(event: any) {
    console.log('editor: onCodeEditorModelContentChanged', event);
  }

  public async renderInput() {

    try {
      if (!this.input || !this.input.length) {
        return false;
      }

      this.blockDetectOnChange = true;

      await this.editor.isReady;

      this.zone.run(() => {
        this.editor.blocks.renderFromHTML(this.input);
        this.detectChanges();

        setTimeout(() => {
          this.blockDetectOnChange = false;
        }, 50);

      });
    } catch (e) {
      console.warn('render input failed', e);
    }

  }

  public setInput(input: string) {
    this.rebuild = true;

    this.zone.run(() => {
      this.input = input;

      switch (this.mode) {
        case 'code':
          this.codeModel.value = this.input;
          setTimeout(() => {
            this.rebuild = false;
            this.detectChanges();
          });
          break;
        default:
          this.rebuild = false;
          this.renderInput();
          break;
      }
    });
  }

}