import { Component, OnInit, ViewChild, NgZone, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NavController, AlertController, IonContent, PopoverController } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { ProfileService } from 'src/app/services/social/profile.service';
import { UserService } from 'src/app/services/core/user.service';
import { ExternalService } from 'src/app/services/extensions/external.service';
import { StateService } from 'src/app/services/utils/state.service';
import { PurchaseService } from 'src/app/services/ecommerce/purchase.service';
import { FontsService } from 'src/app/services/utils/fonts.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { BrowserService } from 'src/app/services/utils/browser.service';
import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { CookiesService } from 'src/app/services/core/cookies.service';
import { TranslationService } from "src/app/services/core/translation.service";
import { ModalService } from "src/app/services/core/modal.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';

import { CardOptionsPage } from 'src/app/pages/core/card-options/card-options.page';
import { PostInformationPage } from '../post-information/post-information.page';

import { DomSanitizer } from '@angular/platform-browser';
import { RatingsService } from 'src/app/services/pipeline/ratings.service';
import { WebService } from 'src/app/services/utils/web.service';
import { LanguageService } from 'src/app/services/core/language.service';
import { LogfileService } from 'src/app/services/core/logfile.service';

import { CrudService } from 'src/app/services/core/crud.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-post',
  standalone: false,
  templateUrl: './post.page.html',
  styleUrls: ['./post.page.scss'],
})
export class PostPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('headlineToolbar', {read: ElementRef}) headlineToolbar: ElementRef;
  @ViewChild('hostItem', {read: ElementRef}) hostItem: ElementRef;
  @ViewChild('post', {read: ElementRef}) postPage: ElementRef;

  allowWithoutLogin: boolean;

  appConfig: pipelineAppConfig;

  modal: any;

  post: post;

  posts: post[];
  postsByCategory: post[];

  user: user;

  view: any = {
    activeIndex: 0,
    canBack: false,
    canNext: false,
    footer: {
      bottom: 0,
    },
    header: {
    },
    headerOpacity: 0,
    showReactionsCard: false,
    size: 2,
  };

  constructor(
    private abo: AbonnementService,
    private alertCtrl: AlertController,
    private browser: BrowserService,
    private configService: ConfigService,
    private cookies: CookiesService,
    private crud: CrudService,
    private events: EventsService,
    private external: ExternalService,
    private fonts: FontsService,
    private language: LanguageService,
    private log: LogfileService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private profile: ProfileService,
    private purchaseService: PurchaseService,
    private popoverCtrl: PopoverController,
    private postsService: PostsService,
    private postsAdmin: PostsAdminService,
    private ratings: RatingsService,
    private stateService: StateService,
    private translations: TranslationService,
    private tools: ToolsService,
    private zone: NgZone,
    private userService: UserService,
    private viewService: ViewService,
    private _sanitizer: DomSanitizer,
    private webService: WebService,
  ) {
    this.appConfig = this.configService.getConfig();
    
    this.user = this.userService.getUser() || {};

    this.view.admin = this.userService.isType(['Admin', 'Moderator']);
    
    if(!!(this.user && this.user.uid)) {
      this.view.readerFont = this.fonts.getReaderFont();  
    }
    
    let post: post = (this.postsService.detailItem() || {} as any);
    
    if(!!post && (typeof post === 'object')) {
      this.post = this.postsService.getFullPost(post);
    }

    this.post = (this.post || {} as any);
    this.post.allowed = this.view.admin || this.stateService.isPostAllowed(this.post);
    this.posts = [this.post];
  }

  accept() {
    this.postsAdmin.accept(this.post)
    .then((response: any) => {
      this.events.publish('toast', {
        message: 'Beitrag akzeptiert',
        color: 'primary',
      });
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  addToCollection() {
    this.post.added = true;
  }

  back() {
    this.view.activeIndex = this.view.activeIndex || 0;
    this.view.activeIndex--;

    if(this.posts[this.view.activeIndex]) {
      this.post = this.posts[this.view.activeIndex];
    }
    
    this.detectChanges();
  }
  
  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    
    this.zone.run(() => {
      setTimeout(() => {
        let headlineToolbarHeight = this.headlineToolbar && this.headlineToolbar.nativeElement ? this.headlineToolbar.nativeElement.clientHeight : 0;
        let hostItemHeight = this.hostItem && this.hostItem.nativeElement ? this.hostItem.nativeElement.clientHeight : 0;
        this.view.contentPaddingTop = (headlineToolbarHeight - (15 - hostItemHeight)) + 'px';
      });
    });
  }

  changeAbonnement() {
    this.navCtrl.navigateForward('/abonnements');
    this.dismiss(null, 'done');
  }
  
  checkInCollection() {
    if(this.user && this.user.uid && this.post && this.post.uid) {
      this.profile.inCollection(this.post)
      .then((blInCollection: boolean) => {
        this.post.added = blInCollection;
        this.detectChanges();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  createAccount() {
    this.dismiss(null, 'done');
    this.navCtrl.navigateRoot('/login');
  }

  detectChanges() {
    this.zone.run(() => {
      this.events.publish('post:view:updated', this.view);
      //this.changeDetectorRef.detectChanges();
    });
  }

  downloadAndroid() {
    this.browser.create(`https://play.google.com/store/apps/details?id=${this.appConfig.appId}`);
  }

  downloadApp() {
    this.translations.get([
      'cancel',
      'download_app_header',
      'download_android',
      'download_ios',
    ])
    .subscribe(async (translations: any) => {
      const alert: any = await this.alertCtrl.create({
        header: translations.download_app_header || 'download_app_header',
        buttons: [
          {
            text: translations.download_android || 'download_android',
            handler: () => {
              this.downloadAndroid();
            },
          },
          {
            text: translations.download_ios || 'download_ios',
            handler: () => {
              this.downloadIos();
            },
          },
          {
            text: translations.cancel || 'cancel',
            role: 'cancel',
          },
        ]
      });
      alert.present();
    });
  }

  downloadIos() {
    this.browser.create('https://apps.apple.com/us/app/pipeline/id1525263609');
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss', event: any|null = null) {

    if(!!event && !!event.target) {
      event.target.complete();
    }
    
    this.modalService.dismiss(data, role);
    this.stopTTS();
  }
  
  async editPost() {

    if(!this.userService.isType(['Admin', 'Moderator'])) {
      this.navCtrl.navigateForward('/create-contents-banner');
      return false;
    }

    this.crud.edit('post', this.post);
  }

  goToCollection(collectionItem: any) {
    console.log('goToCollection', collectionItem);
  }
  
  hideUI() {
    this.view.footer.bottom = -100;
    this.view.header.top = -100;
    this.detectChanges();
  }

  async information(event: any|null = null) {
    let informationPopup = await this.popoverCtrl.create({
      animated: true,
      component: PostInformationPage,
      componentProps: {
        post: this.post,
      },
      cssClass: 'postInformationPopover',
      event: event,
    });
    informationPopup.present();
  }

  initPurchases() {
    if(!this.view.purchaseInitialized) {
      try {
        this.purchaseService.init();
        this.view.purchaseInitialized = true;
      } catch(e) {
        console.log('post: purchase init failed', e);
      }
    }
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
    
    this.view.admin = this.userService.isType('Admin');
    this.view.isWeb = this.tools.isWeb();

    if(this.view.admin || this.userService.isType('Creator') || this.userService.isType('Moderator')) {
      this.view.short = false;
      this.detectChanges();
    } else
    if(this.view.isWeb && !this.view.isPreviewMode) {
      this.view.short = !!this.userService.getUid();
      this.cookies.showCookieBanner();
      this.detectChanges();
    }

    if(!this.userService.getUid() && !this.allowWithoutLogin) {
      this.navCtrl.navigateRoot('/login', { animated: false, });
    } else
    if(this.user && this.user.uid) {
      this.checkInCollection();
    }

    if(this.user && this.user.classifications && this.user.classifications.language) {
      this.view.language = this.user.classifications.language;
    }

    setTimeout(() => {
      this.zone.run(() => {
        if(this.post) {
          this.loaded();

          try {
            if(!!this.post.host_uid) {
              this.log.countProfileView(this.post.host_uid);
            }
          } catch(e) {
            console.warn('log profile view error', e);
          }

        }
      });
    }, 50);

    this.calcViewVars();
    this.detectChanges();

    try {
      //this.statusBar.styleLightContent();
    } catch(e) {
      console.warn('status bar styling failed', e);
    }
  }

  initEvents() {

    this.events.subscribe('post:preview:update', (post: post) => {
      this.post = post;
      this.loaded();
    });

    this.events.subscribe('post:reader:font:size:updated', (iSize: number) => {
      this.view.size = iSize;
      this.detectChanges();
    });

    this.events.subscribe('post:reader:toggle:darkMode', (bl: boolean) => {
      this.toggleMode(bl, true);
    });

    this.events.subscribe('post:reader:update', (post: post) => {
      if(!!post) {
        this.post = post;
        this.loaded();
      }
    });

    this.events.subscribe('post:next', () => {
      this.next();
    });

    this.events.subscribe('post:back', () => {
      this.back();
    });

  }

  ionViewWillLeave() {
    //this.statusBar.styleDefault();
    this.logClose();

    this.postsService.detailItem({} as any);
  }

  ionViewDidEnter() {
    this.zone.run(async () => {
      let state = await this.stateService.get() as state;
      state.canBack = false;
      state.canNext = true;

      this.stateService.set(state);
    });

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addListener((mediaQuery) => { this.toggleMode(mediaQuery.matches); });
    this.toggleMode(prefersDark.matches);
  }

  loaded() {
    this.post.post_content_formatted = this._sanitizer.bypassSecurityTrustHtml(this.post.post_content);
    this.detectChanges();
  }

  loadPost(postId: number = null) {
    this.postsService.getPostByUid((postId || this.post.uid), {
      language: this.view.language,
    })
    .then((post: post) => {
      this.post = post;
      this.posts = [this.post];
      this.loaded();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  logClose() {
    /*
    this.log.add({
      class: 'post_close_' + this.post.uid + '_' + this.user.uid,
      group: 'post_close_byUser_' + this.user.uid,
      description: (this.user.nickname || this.user.firstname) + ' hat den Beitrag "' + this.post.name + '" geschlossen',
      inAppLink: this.post.uid,
      url: this.post.url,
    })
    .catch((error: any) => {
      console.warn('log error:', error);
    });
    */
  }

  next() {
    this.view.activeIndex = this.view.activeIndex || 0;
    this.view.activeIndex++;

    if(this.posts[this.view.activeIndex]) {
      this.post = this.posts[this.view.activeIndex];
    }
    
    this.detectChanges();
  }

  openProfile() {
    if(!!this.post.host_uid) {
      this.events.publish('view:profile', this.post.host_uid);
    }
  }

  async options(post: post, event: any|null = null) {
    event.preventDefault();

    const optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: post,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });

    await optionsPopover.present();
    
    return false;
  }

  promptBetaTest() {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.abo.promptBetaTest()
    .then(() => {
      this.user = this.userService.getUser();

      this.post.vip = false;
      this.loaded();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async rate() {
    
    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.ratings.rate(this.post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  toggleSponsoredInfoView() {
    this.view.showSponsoredInfo = !this.view.showSponsoredInfo;
  }

  ngOnInit() {
    this.initEvents();
  }

  onScroll(event: any) {
    this.zone.run(() => {
      this.view.scrollDirection = (event.detail.scrollTop > this.view.scrollTop) ? 1 : 0;
      this.view.scrollTop = event.detail.scrollTop;

      if(this.view.scrollDirection) {
        this.hideUI();
      } else {
        this.showUI();
      }

      let maxScroll = window.outerHeight * 0.25,
          scrollTop = event.detail.scrollTop,
          offset = scrollTop > maxScroll ? maxScroll : scrollTop;

      this.view.headerOpacity = (1 - ((100 - ((100 / maxScroll) * offset)) / 100)).toFixed(3);
      this.detectChanges();
    });
  }

  // log the scroll position to AI
  async onScrollEnd() {
    /*
    let postPage = document.getElementById('post');

    if(!!postPage) {

      try {

        let log: scrollspyData = await this.scrollspy.log('post', {
          uid: this.post.uid,
          container: postPage,
          scrollTop: this.view.scrollTop + (window.outerHeight * 0.25),
          textLength: this.post.post_content.length,
        });

        let blIsEnd = (log && log.percentage && (log.percentage > 90)),
          blShowReactionsCard = !!(this.userService.getUid() && blIsEnd);

        if(blIsEnd) {
          this.score.scoreReadArticle(this.post)
            .catch((error) => {
              console.log('score error', error);
            });
        }

        if(blShowReactionsCard !== this.view.showReactionsCard) {
          this.view.showReactionsCard = blShowReactionsCard;
          this.detectChanges();
        }

      } catch(e) {
        console.log('> onScrollEnd e', e);
      }

    }
    */
  }

  // opens the content in browser
  openExternal() {
    this.stopTTS();

    if(!!this.post.host_uid) {
      this.openProfile();
    } else {
      this.browser.create(this.post.url);
    }
  }

  // purchase a content
  purchase(post: post) {
    this.initPurchases();

    setTimeout(() => {
      this.purchaseService.purchase(`${this.appConfig.appId}.vip`)
      .then(() => {
        this.zone.run(() => {
          this.post.vip = false;
          this.loaded();
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }, 150);
  }

  readRelated(post: post, event: any|null = null) {
    this.loadPost(post.uid);
    this.content.scrollToTop(1000);
    this.detectChanges();
  }

  reject() {
    this.external.reject(this.post)
    .then((response: any) => {
      
      this.events.publish('toast', {
        message: 'Beitrag abgelehnt',
        color: 'primary',
      });
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  removeFromCollection() {
    this.profile.removeFromCollection(this.post, 'posts')
    .then((response: any) => {
      if(response.success) {
        this.post.added = false;
        this.detectChanges();
      }
    })
    .catch((error: any) => {
      this.post.added = true;
      this.detectChanges();
      this.events.publish('error', error);
    });
  }

  showUI() {

    if(this.view.hideUITimeout) {
      clearTimeout(this.view.hideUITimeout);
    }

    this.view.footer.bottom = 0;
    this.view.header.top = 0;
    this.detectChanges();
  }

  stopTTS() {
    this.events.publish('tts:stop');
  }

  swipeEvent(event: any) {
    console.log('swipeEvent', event);
  }

  toggleCollection() {

    if(!this.userService.getUid()) {
      this.webService.appFeaturesRequested();
    } else {
      if(this.post.added) {
        this.removeFromCollection();
      } else {
        this.addToCollection();
      }
    }

    this.detectChanges();
  }

  async toggleLanguage() {
    let languages: any = await this.language.getLanguages();
    let inputs = [];

    languages.forEach((language: language) => {
      inputs.push({
        type: 'radio',
        name: 'language',
        value: language.indent,
        label: language.name,
      });
    });

    const languageAlert = await this.alertCtrl.create({
      header: 'Sprache wählen',
      inputs: inputs,
      buttons: [
        {
          text: 'Okay',
          handler: (language: string) => {
            this.view.language = language;
            this.loadPost();
          },
        },
        {
          role: 'cancel',
          text: 'Abbrechen',
        }
      ]
    });

    await languageAlert.present();
  }

  toggleMode(bl: boolean|null = null, blAddClass: boolean = false) {
    this.zone.run(() => {
      this.view.darkMode = bl !== null ? bl : !this.view.darkMode;

      if(blAddClass) {
        document.getElementById('postPage').classList.toggle('invert', !this.view.darkMode);
      }
      
      this.detectChanges();
    });
  }

  async verifiedInfo() {
  }

}
