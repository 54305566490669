import { Injectable } from '@angular/core';

import { AiConfigService } from './ai-config.service';
import { AiLoaderService } from './ai-loader.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { GetgeniusService } from '../getgenius/getgenius.service';
import { UserService } from 'src/app/services/core/user.service';

import { AutoModelForDepthEstimation, RawImage } from '@huggingface/transformers';

import { proxyUrl } from 'src/config/variables';

@Injectable({
  providedIn: 'root'
})
export class AiBridgeService {

  proxyUrl: string;

  constructor(
    private aiConfig: AiConfigService,
    private aiLoader: AiLoaderService,

    private AppCMS: AppcmsService,
    private getgenius: GetgeniusService,
    private userService: UserService,
  ) {
    this.proxyUrl = proxyUrl;
  }

  calculateSentenceSimilarity(texts: string[], options: any = {}) {
    return new Promise((resolve, reject) => {
      console.log('calculateSentenceSimilarity: texts', texts);
      console.log('calculateSentenceSimilarity: options', options);

      const pipe: any = this.aiLoader.getPipe('sentence-similarity');
      console.log('calculateSentenceSimilarity: pipe', pipe);
    });
  }

  execute(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      if (!!config) {
        item.config = config;
      }

      /*
      console.log('ai-bridge: execute: config', config);
      console.log('ai-bridge: execute: item', item);
      console.log('ai-bridge: execute: params', params);
      */

      try {

        if (!!config && !!config.provider && (config.provider === 'local')) {
          // if local execution requested, execute using AI loader
          this.executeLocal(item, blForceRefresh, config, params).then(resolve).catch(reject);
        } else {
          // else, execute using server-side request

          // first register ai execution job on the server-side (token + queue)
          await this.getgenius.registerAction({
            config: config,
            item: item,
            params: params,
          });

          // then execute the server-side request
          this.AppCMS.loadPluginData('pipeline', Object.assign((params || {}), {
            item: item,
          }), ['ai', 'execute'], {}, blForceRefresh).then(resolve).catch(reject);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  executeLocal(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      /*
      console.log('executeLocal: item', item);
      console.log('executeLocal: config', config);
      console.log('executeLocal: params', params);
      */

      if (!item || !item.post_content || !item.post_content.length) {
        reject('error_missing_input_content');
      } else {
        try {

          if (!!config && !!config.model) {
            this.aiLoader.setSelectedModel(config.model);
          }

          const history: aiExecutionHistoryItem[] = (item.history || []).map((historyItem: aiExecutionHistoryItem) => {
            return {
              content: `${(historyItem.content || historyItem.input) || ''}`,
              role: `${historyItem.role || 'user'}`,
            }
          }) as aiExecutionHistoryItem[];

          this.aiLoader.completions(
            item.post_content,
            history,
            true,
            {

              // stream partial result (currently only supported in local mode)
              onPartialResult: params.onPartialResult || ((partialResult: string, partIndex: number) => {
                console.log('executeLocal: default: partialResult', partialResult);
              }),

            }
          ).then(resolve).catch(reject);
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  getBasePrompt() {
    return this.aiLoader.getBasePrompt();
  }

  getConfig() {
    return this.aiLoader.getConfig();
  }

  getPipe(task: string, modelName: string | null = null, options: any = {}, blBackground: boolean = false) {
    return this.aiLoader.getPipe(task, modelName, options, blBackground);
  }

  async getPreferredModels(context: string = 'general') {
    return this.aiLoader.getPreferredModels(context);
  }

  getProvider() {
    return this.aiLoader.getProvider();
  }

  getQueue(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "stablediffusion",
      options,
      ["queue"],
      {},
      blForceRefresh
    );
  }

  imageToDepth(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return new Promise(async (resolve, reject) => {
      try {
        options.modelId = options.modelId || 'onnx-community/DepthPro-ONNX';

        loadingOptions = loadingOptions || this.aiLoader.getDefaultLoadingOptions();

        if (!blBackground) {
          loadingModal = (loadingModal || await this.aiLoader.getLoadingModal());

          loadingModal.updateConfig({
            icon: 'hourglass-outline',
            text: `ai_loader_init_step_0`,
          });
        }

        const model: any = await this.aiLoader.getModel(options.modelId, {
          config: options.config || { dtype: "q4" },
          pipeline: AutoModelForDepthEstimation
        }, false, loadingModal, loadingOptions);

        const processor: any = await this.aiLoader.getProcessor(options.modelId, {}, false, loadingModal, loadingOptions);

        setTimeout(async () => {
          const response = await fetch(this.proxyUrl + url);
          const blob = await response.blob();
          const reader = new FileReader();

          async function predict(url: string) {
            console.log('imageToDepth: predict: url', url);

            try {
              const image = await RawImage.fromURL(url);
              console.log('imageToDepth: predict: image', image);

              const inputs = await processor(image);
              console.log('imageToDepth: predict: inputs', inputs);

              // Run depth estimation model
              const { predicted_depth, focallength_px } = await model(inputs);

              // Normalize the depth map to [0, 1]
              const depth_map_data = predicted_depth.data;
              let minDepth = Infinity;
              let maxDepth = -Infinity;
              for (let i = 0; i < depth_map_data.length; ++i) {
                minDepth = Math.min(minDepth, depth_map_data[i]);
                maxDepth = Math.max(maxDepth, depth_map_data[i]);
              }
              const depth_tensor = predicted_depth
                .sub_(minDepth)
                .div_(-(maxDepth - minDepth)) // Flip for visualization purposes
                .add_(1)
                .clamp_(0, 1)
                .mul_(255)
                .round_()
                .to("uint8");

              // Save the depth map
              const depth_image = await RawImage.fromTensor(depth_tensor); // .resize(image.width, image.height);
              console.log('depth_image', depth_image);

              //depth_image.save("./assets/depth.png");

              // Set container width and height depending on the image aspect ratio
              const ar = image.width / image.height;
              const [cw, ch] = (ar > 720 / 480) ? [720, 720 / ar] : [480 * ar, 480];

              // Create new canvas
              const canvas = document.createElement('canvas');
              canvas.width = image.width;
              canvas.height = image.height;

              const ctx: any = canvas.getContext('2d');

              // Draw original image output to canvas
              ctx.drawImage(image.toCanvas(), 0, 0);

              // Update alpha channel
              const pixelData = ctx.getImageData(0, 0, image.width, image.height);

              for (let i = 0; i < depth_image.data.length; ++i) {
                pixelData.data[4 * i + 3] = depth_image.data[i];
              }

              ctx.putImageData(pixelData, 0, 0);

              resolve({
                canvas: canvas,
                mask: depth_image,
                size: [ch, cw],
                url: canvas.toDataURL('image/png'),
              });
            } catch (e) {
              reject(e);
            }
          }

          reader.onload = (e: any) => {
            predict(e.target.result);
          };

          reader.onerror = (e: any) => {
            reject(e);
          };

          reader.readAsDataURL(blob);
        }, 500);
      } catch (e) {
        reject(e);
      }
    });
  }

  /*
  imageToMask(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.sd.imageToMask(url, options);
  }

  imageToSegmentation(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.sd.imageToSegmentation(url, options);
  }
  */

  async initConfig() {
    return this.aiLoader.initConfig();
  }

  async initTextToSpeechPipeline(pipeName: string | null = null) {
    return this.aiLoader.initTextToSpeechPipeline(pipeName);
  }

  removeBackground(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return new Promise(async (resolve, reject) => {
      try {
        options.modelId = options.modelId || 'briaai/RMBG-1.4';

        loadingOptions = loadingOptions || this.aiLoader.getDefaultLoadingOptions();

        if (!blBackground) {
          loadingModal = (loadingModal || await this.aiLoader.getLoadingModal());

          loadingModal.updateConfig({
            icon: 'hourglass-outline',
            text: `ai_loader_init_step_0`,
          });
        }

        const model: any = await this.aiLoader.getModel(options.modelId, {
          config: options.config || { model_type: 'custom' },
        }, false, loadingModal, loadingOptions);

        console.log('model', model);

        const processor: any = await this.aiLoader.getProcessor(options.modelId, {
          config: options.processorConfig || {
            do_normalize: true,
            do_pad: false,
            do_rescale: true,
            do_resize: true,
            image_mean: [0.5, 0.5, 0.5],
            feature_extractor_type: "ImageFeatureExtractor",
            image_std: [1, 1, 1],
            resample: 2,
            rescale_factor: 0.00392156862745098,
            size: { width: 1024, height: 1024 },
          }
        }, false, loadingModal, loadingOptions);

        setTimeout(async () => {
          const response = await fetch(this.proxyUrl + url);
          const blob = await response.blob();
          const reader = new FileReader();

          async function predict(url: string) {

            try {
              // Read image
              const image = await RawImage.fromURL(url);

              // Set container width and height depending on the image aspect ratio
              const ar = image.width / image.height;
              const [cw, ch] = (ar > 720 / 480) ? [720, 720 / ar] : [480 * ar, 480];

              // Preprocess image
              const { pixel_values } = await processor(image);
              console.log('pixel_values', pixel_values);

              // Predict alpha matte
              const { output } = await model({ input: pixel_values });

              // Resize mask back to original size
              const mask = await RawImage.fromTensor(output[0].mul(255).to('uint8')).resize(image.width, image.height);

              // Create new canvas
              const canvas = document.createElement('canvas');
              canvas.width = image.width;
              canvas.height = image.height;

              const ctx: any = canvas.getContext('2d');

              // Draw original image output to canvas
              ctx.drawImage(image.toCanvas(), 0, 0);

              // Update alpha channel
              const pixelData = ctx.getImageData(0, 0, image.width, image.height);

              for (let i = 0; i < mask.data.length; ++i) {
                pixelData.data[4 * i + 3] = mask.data[i];
              }

              ctx.putImageData(pixelData, 0, 0);

              resolve({
                canvas: canvas,
                mask: mask,
                size: [ch, cw],
                output: output,
                pixel_values: pixel_values,
                url: canvas.toDataURL('image/png'),
              });
            } catch (e) {
              reject(e);
            }
          }

          reader.onload = (e: any) => {
            predict(e.target.result);
          };

          reader.onerror = (e: any) => {
            reject(e);
          };

          reader.readAsDataURL(blob);
        }, 500);
      } catch (e) {
        reject(e);
      }
    });
  }

  search(options: any = {}, params: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      options = options || {};
      options.prompt = options.query;

      params = params || {};

      if (this.getProvider() === 'local') {
        // if local execution requested, execute using ai loader

        options.guidance = parseInt(`${options.cfg_scale || 7}`);
        options.steps = parseInt(`${options.steps || 12}`);

        const localExec: any = await this.textToImage(options.prompt, {
          index: (params.hasOwnProperty('index') ? (params.index || 0) : null),
          options: options,

          showStep: params.showStep || ((data: any) => {
            console.log('ai-bridge: showStep: data', data);
          }),
        });

        console.log('ai-bridge: localExec', localExec);

        resolve(localExec);
      } else {
        // else, execute using cloud infrastructure

        this.AppCMS.loadPluginData('pipeline', Object.assign(params, {
          options: options,
        }), ['ai', 'search'], {}, blForceRefresh)
          .then((response: any) => {
            console.log('ai-bridge: response', response);

            if (!!response && !!response.queue && !!response.queue.length) {
              this.watchQueue(response, params).then(resolve).catch(reject);
            } else {
              resolve(response);
            }
          })
          .catch(reject);
      }
    });
  }

  segmentAnything(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
  }

  setBasePrompt(prompt: string) {
    return this.aiLoader.setBasePrompt(prompt);
  }

  setConfig(config: aiSettings) {
    return this.aiLoader.setConfig(config);
  }

  async setPreferredModels(models: aiModel[], context: string = 'general') {
    return this.aiConfig.setPreferredModels(models, context);
  }

  async syncPreferredModels(models: aiModel[], context: string = 'general') {
    return this.aiConfig.syncPreferredModels(models, context);
  }

  textToImage(input: string, searchParams: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this.getProvider() === 'local') {
        this.aiLoader.textToImage(input, searchParams, blForceRefresh).then(resolve).catch(reject);
      } else {
        searchParams = searchParams || {};

        searchParams.options = Object.assign(searchParams.options || {}, {
          limit: 1,
          query: input,
          request: 'images',
        });

        searchParams.queue = !!searchParams.queue;

        const fallbackPartialCaller: any = (data: any) => {
          console.log('stablediffusion: fallbackPartialCaller: showStep: data', data);
        };

        searchParams.showStep = searchParams.showStep || fallbackPartialCaller;

        this.search(searchParams.options, searchParams, blForceRefresh)
          .then((response: any) => {
            console.log('[ AI BRIDGE] text to image response: ', response);
            resolve(response);
          })
          .catch(reject);
      }
    });
  }

  watchQueue(response: any, params: any = {}) {
    return new Promise(async (resolve, reject) => {
      let iRequestedItems: number = 0;
      let itemsByUids: any = {}, queueItemIds: number[] = [];

      (response.queue || []).forEach((queueItem: any) => {

        if (!queueItem.uid) {
          return false;
        }

        const payload: any = (!!queueItem && !!queueItem.value && !!queueItem.value.payload ? queueItem.value.payload : null);

        if (!!payload) {
          iRequestedItems += (payload.batch_count || 1) * (payload.batch_size || 1);
        }

        queueItemIds.push(queueItem.uid);
      });

      let results: any[] = [];

      if (!queueItemIds || !queueItemIds.length) {
        reject('error_missing_queue_item_uids');
      } else {
        let iDone: number = 0, iImageIndex: number = 0;

        const lookupCall = async () => {

          const exec: any = await this.getQueue(true, {
            filter: {
              user_uid: this.userService.getUid(),
            },
            lookup_uids: queueItemIds,
          });

          const queue: mediaQueueItem[] = (!!exec && !!exec.length ? exec : []);

          if (!queue || !queue.length) {
            return {
              reason: 'empty_queue',
              success: false,
            };
          }

          queue.forEach((queueItem: mediaQueueItem) => {

            if (!queueItem.uid) {
              return false;
            }

            itemsByUids[queueItem.uid] = queueItem;

            if (!!queueItem && queueItem.state === 'failed') {
              iDone++;
            } else
              if (!!queueItem && !!queueItem.config && !!queueItem.config.images) {
                const iProgress: number = (100 / iRequestedItems) * iDone;

                queueItem.config.images.forEach((image: string) => {
                  if (results.indexOf(image) === -1) {
                    results.push(image);

                    if (params.showStep != null) {
                      params.showStep({
                        base64: image,
                        frame: image,
                        index: iImageIndex,
                        info: queueItem.config.info,
                        progress: iProgress,
                      });
                    }

                    iDone++;
                    iImageIndex++;
                  }
                });
              }
          });

          return {
            counts: {
              all: iRequestedItems,
              done: iDone,
              index: iImageIndex,
            },
            exec: exec,
            items: itemsByUids,
            queue: queue,
            results: results,
          };
        };

        const interval: any = setInterval(async (event: any) => {
          const partLookup: any = await lookupCall();

          console.log('watchQueue: itemsByUids', itemsByUids);
          console.log(`watchQueue: ${iDone} / ${iRequestedItems} results done`);

          if (!!iRequestedItems && (iDone >= iRequestedItems)) {
            // handle done
            clearInterval(interval);
            resolve(partLookup);
          } else
            if (!partLookup || !partLookup.success) {
              // handle empty queue / error
              console.warn('watchQueue: error partLookup', partLookup);
            } else {
              // other state, inspect
              console.warn('watchQueue: other partLookup', partLookup);
            }
        }, (1 * 1000));
      }
    });
  }

}