import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ChooserComponent } from './chooser.component';

import { NoEntriesCardComponentModule } from 'src/app/components/generic/no-entries-card/no-entries-card.component.module';
import { MediaTemplatePreviewComponentModule } from 'src/app/components/media/media-template-preview/media-template-preview.component.module';
import { VideoTemplatePreviewComponentModule } from 'src/app/components/media/videos/video-template-preview/video-template-preview.component.module';

@NgModule({
  declarations: [
    ChooserComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    NoEntriesCardComponentModule,
    MediaTemplatePreviewComponentModule,
    VideoTemplatePreviewComponentModule,
  ],
  exports: [
    ChooserComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' }
  ]
})
export class ChooserComponentModule { }