import { Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { AdsService } from "src/app/services/ads/ads.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SharingService } from "src/app/services/sharing/sharing.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { CreateAdPage } from '../create-ad/create-ad.page';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'app-ads-admin',
  standalone: false,
  templateUrl: './ads-admin.page.html',
  styleUrls: ['./ads-admin.page.scss'],
})
export class AdsAdminPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  cards: any = {};

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'ads',
    keys: ['date_end_formatted', 'date_start_formatted', 'label', 'location', 'name', 'query', 'uid'],
    query: '',
  };

  state: state = {};

  user: user;

  view: any = {
    adType: 'text',
    colSize: {},
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ads_top_card',
      text: 'ads_top_card_text',
      title: 'ads_top_card_title',
    },
    itemSize: 116,
    locationTranslations: [],
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    showProjectsSelect: true,
    title: 'ads_2',
  };

  constructor(
    public ads: AdsService,
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sharing: SharingService,
    private sidebar: SidebarService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser() || {};

    this.view.adTypes = this.ads.getAdTypes();
    this.view.locationTranslations = this.ads.getLocationTranslations();
  }

  async add() {

    const adModal: any = await this.modalService.create({
      component: CreateAdPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    adModal.onWillDismiss().then(() => {
      this.loadAds(true)
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    });
    await this.modalService.present(adModal);
  }

  adTypeSegmentChanged() {
    this.view.rebuild = true;
    this.runSearch();

    setTimeout(() => {
      this.view.rebuild = false;
      this.detectChanges();
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
      right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
    };

    this.detectChanges();
  }

  delete(ad: ad) {
    this.ads.delete(ad.uid)
      .then(() => {
        this.loadAds(true)
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
    */
  }

  doRefresh(event: any | null = null) {
    this.load(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }

        delete this.view.ads_backup;
        this.runSearch();
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  async export(event: any | null = null, options: any = {}) {
    this.exportService.exportUsingUI({
      data: (this.view.ads || []),
      service: this.ads,
      source: 'ads',
      type: 'ad',
    })
      .then((response: any) => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.loadProject();
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
        right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};

    this.initEvents();
    this.detectChanges();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
    this.calcViewVars();
  }

  async load(blForceRefresh: boolean = false) {
    try {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.connections = [];
        this.view.connections_backup = [];
        this.view.sources = [];
        this.view.sources_backup = [];
        this.view.ads = [];
        this.view.ads_backup = [];
      } else {
        await this.loadAds(blForceRefresh);
      }
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  loadAds(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.ads = JSON.parse(JSON.stringify(this.view.placeholders));
      this.detectChanges();

      this.view.options = this.view.options || {};

      this.ads.getAll(this.view.options, blForceRefresh)
        .then(async (ads: ad[]) => {
          delete this.view.ads_backup;

          this.view.ads = ads || [];
          this.detectChanges();

          if (!this.view.connections || this.view.connections.length) {
            await this.loadConnections(blForceRefresh);
          }

          setTimeout(() => {
            this.runSearch();
          });

          resolve(ads);
        })
        .catch(reject);
    });
  }

  loadAdsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadConnections(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loadingConnections = true;

      this.sharing.getConnections(blForceRefresh)
        .then((connections: any[]) => {

          // parse connections
          if (!!connections && !!connections.length) {
            connections = connections.map((connection: integrationConnection) => {
              connection.checked = true;
              connection.title = `${connection.name}`;
              return connection;
            });
          }

          this.view.connections = connections;
          this.view.sources = connections;

          this.view.loadingConnections = false;

          resolve(connections);
        })
        .catch((error: any) => {
          this.view.loadingConnections = false;
          reject(error);
        });
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();

    this.load()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);
    this.doRefresh();
  }

  onFolderLoaded(items: any[] | null = null) {

  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  pay(ad: ad) {
    this.ads.pay(ad, true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  runSearch() {
    try {
      if (!!this.searchToolbar) {
        this.searchToolbar.runSearch();
      }
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  thumbnailLoadingFailed(post: post) {
    post.photo = this.fallbackImg;
    post.thumbnail = this.fallbackImg;
    this.detectChanges();
  }

  toggleActive(ad: ad) {

    if (!ad.uid || !this.userService.isType('Admin')) {
      return false;
    }

    ad.active = !ad.active;

    this.ads.update(ad)
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    this.detectChanges();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  async viewAd(ad: ad) {

    const editModal: any = await this.modalService.create({
      component: CreateAdPage,
      componentProps: {
        ad: ad,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'viewAdModal'
    });

    this.modalService.present(editModal);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}