import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-chooser',
  standalone: false,
  templateUrl: './chooser.component.html',
  styleUrls: ['./chooser.component.scss'],
})
export class ChooserComponent implements OnDestroy, OnInit {
  @Input() cards: any = {};
  @Input() data: any[];
  @Input() events: any[];
  @Input() item: any;
  @Input() options: chooseConfig;
  @Input() service: any;

  @Output() selectedItemsChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  view: any = {};

  constructor(
    private eventsService: EventsService,
    private sidebar: SidebarService,
  ) {

  }

  calcViewVars() {
  }

  doRefresh() {

  }

  initEvents() {
    this.eventsService.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.eventsService.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.loadCards();
    this.calcViewVars();
    this.initEvents();
  }

  onMultiItemSelected(item: any) {

    const selectedItems: any[] = (this.data || []).filter((item: any) => {
      return !!item.checked;
    });

    this.selectedItemsChanged.emit({
      data: this.data,
      item: item,
      items: selectedItems,
      mode: 'partial',
      multi: true,
    });
  }

  onSelectionActionChanged(event: any | null = null, item: any | null = null) {
  }

  select(item: any) {

    if (!!item.disabled) {
      return false;
    }

    this.selectedItemsChanged.emit({
      data: this.data,
      item: item,
      items: [item],
      mode: 'stop',
      multi: false,
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}