import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-mappings-card',
  standalone: false,
  templateUrl: './mappings-card.component.html',
  styleUrls: ['./mappings-card.component.scss'],
})
export class MappingsCardComponent implements OnInit {
  @Input() attributes: attribute[];
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() mappings: any = {};
  @Input() sources: string[];
  @Input() targets: string[];

  selectedAttributes: any = {};

  view: any = {
    loading: true,
  };

  @Output() changed = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private alertService: AlertService,
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    this.mappings = this.mappings || {};
    this.view = this.viewService.calcVars(this.view);
  }

  _changed() {
    this.changed.emit(this.mappings);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ngOnInit() {
    this.initEvents();
    this.calcViewVars();
    this.validateUIState();
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  validateUIState() {
  }

}