import { Component, NgZone, OnInit } from '@angular/core';

import { ModalService } from "src/app/services/core/modal.service";
import { CitiesService } from 'src/app/services/geo/cities.service';
import { EventsService } from "src/app/services/core/events.service";
import { RegionalService } from 'src/app/services/geo/regional.service';
import { SettingsService } from 'src/app/services/core/settings.service';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-region-setup',
  standalone: false,
  templateUrl: './region-setup.page.html',
  styleUrls: ['./region-setup.page.scss'],
})
export class RegionSetupPage implements OnInit {

  user: user;

  state: state = {};

  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    regionQuery: '',
    title: 'configure_region',
  };

  constructor(
    private cities: CitiesService,
    private events: EventsService,
    private modalService: ModalService,
    private regional: RegionalService,
    private settings: SettingsService,
    private userService: UserService,
    private zone: NgZone,
  ) {

  }

  confirmCity() {
    this.regional.setCity(this.view.city)
    .then(() => {
      this.dismiss(this.view);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  loadRegions() {
    if(!this.view.regions || !this.view.regions.length) {
      this.regional.getCities()
      .then((cities: any) => {
        this.view.regions = cities;
        this.zone.run(() => {
          this.view.region = this.user.city;
          setTimeout(() => {
            this.view.blockUpdates = false;
          }, 150);
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    } else {
      setTimeout(() => {
        this.view.blockUpdates = false;
      }, 150);
    }
  }

  ngOnInit() {
  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }
  
  runSearch(event: any|null = null) {
    this.cities.get(false, {
      query: this.view.regionQuery,
    })
    .then((cities: city[]) => {
      this.view.results = cities.slice(0, 25);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  setCity(city: city) {
    this.view.city = city;
  }

  updateRegion() {
    this.user.city = this.view.region;
    
    if(!this.view.blockUpdates) {
      this.userService.setUser(this.user, true)
      .then(() => {
        this.settings.showUpdatedToast();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }


}
