import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from "src/app/services/core/view.service";

import { SearchPage } from 'src/app/pages/core/search/search.page';

@Component({
  selector: 'pipeline-header-top-toolbar',
  standalone: false,
  templateUrl: './header-top-toolbar.component.html',
  styleUrls: ['./header-top-toolbar.component.scss'],
})
export class HeaderTopToolbarComponent implements OnInit {
  @Input() state: state;
  @Input() view: any;

  avatarUrl: string;

  config: pipelineAppConfig;

  user: user;

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    public modalService: ModalService,
    private navCtrl: NavController,
    public userService: UserService,
    private viewService: ViewService,
  ) {
    this.config = this.configService.getConfig();
    this.user = this.userService.getUser() || {};
  }

  back() {
    this.navCtrl.back();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.events.subscribe("appcms:user:updated", () => {
      this.avatarUrl = this.userService.getAvatarUrl();
      this.user = this.userService.getUser() || {};
    });

    this.events.subscribe('config:updated', (appConfig: pipelineAppConfig) => {
      this.config = appConfig;
      this.events.publish('detect:changes');
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  ngOnInit() {
    this.avatarUrl = this.userService.getAvatarUrl();
    this.user = this.userService.getUser() || {};

    if (this.config.showHeaderMenuButton) {
      this.view.showMenuButton = !this.view.showBackButton && !this.view.showDismiss;
    }

    this.calcViewVars();
    this.initEvents();
  }

  openOwnProfile() {
    this.navCtrl.navigateForward('/tabs/profile/me');
  }

  toggleIntroCard() {

    if (!this.view.introCard) {
      return false;
    }

    this.view.introCard.hidden = !this.view.introCard.hidden;
  }

  async toggleSearch() {

    const searchModal: any = await this.modalService.create({
      component: SearchPage,
      animated: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: "defaultModal",
    });

    searchModal.onWillDismiss().then((response: any) => {
      console.log('response', response);
    });

    this.modalService.present(searchModal);
  }

}