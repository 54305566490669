import { Injectable } from '@angular/core';

import { AppcmsService } from "src/app/services/core/appcms.service";
import { ModalService } from 'src/app/services/core/modal.service';

import { DataDuplicatorPage } from 'src/app/pages/core/data/data-duplicator/data-duplicator.page';

@Injectable({
    providedIn: 'root'
})
export class DuplicatorService {

    private csv: any = {
        delimiter: ';',
    };

    private data: any;

    private headers: any = {
        csv: "data:text/csv;charset=utf-8,"
    };

    private columnParser: any;

    private itemParser: any;

    private output: string;

    constructor(
        private AppCMS: AppcmsService,
        private modalService: ModalService,
    ) {

    }

    executeServerSideDuplicator(options: any) {
        return this.AppCMS.loadPluginData('pipeline', {
            config: options,
        }, ['duplicator', 'execute']);
    }

    duplicateUsingUI(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            options = options || {};
            options.duplicatorService = this;

            const modal: any = await this.modalService.create({
                component: DataDuplicatorPage,
                componentProps: options,
                animated: true,
                presentingElement: await this.modalService.getTop(),
                cssClass: 'defaultModal',
            });

            modal.onWillDismiss().then(resolve);

            this.modalService.present(modal);
        });
    }

    getData() {
        return this.data;
    }

    getFallbackItemParser() {
        return (item: any) => {
            return item;
        }
    }

    getItemParser() {
        if (!this.itemParser) {
            return this.getFallbackItemParser();
        }
        return this.itemParser;
    }

    getOutput() {
        return this.output;
    }

    setData(data: any[]) {
        this.data = data;
        return this;
    }

    setItemParser(handler: any) {
        this.itemParser = handler;
        return this;
    }

    setOutput(output: string) {
        this.output = output;
        return this;
    }

}
