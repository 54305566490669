import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-create-object-type',
  standalone: false,
  templateUrl: './create-object-type.page.html',
  styleUrls: ['./create-object-type.page.scss'],
})
export class CreateObjectTypePage implements OnInit {

  avatarUrl: string = './assets/img/avatars/1.jpg';

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'projects',
    keys: ['title', 'email', 'firstname', 'lastname', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  type: any = {};

  view: any = {
    fieldTypes: [
      {
        name: 'boolean',
        uid: 'boolean',
      },
      {
        name: 'datetime',
        uid: 'datetime',
      },
      {
        name: 'json',
        uid: 'json',
      },
      {
        name: 'number',
        uid: 'number',
      },
      {
        name: 'field_type_select',
        uid: 'enum',
      },
      {
        name: 'field_type_text',
        uid: 'text',
      },
      {
        name: 'timestamp',
        uid: 'timestamp',
      },
      {
        name: 'url',
        uid: 'url',
      },
    ],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'create_object_type_top_card',
      text: 'create_object_type_top_card_text',
      title: 'create_object_type_top_card_title',
    },
    route: 'create-object-type',
    template: 'custom',
    templates: [
      {
        name: 'custom',
        uid: 'custom',
      },
    ],
    title: 'create_object_type',
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private objectsService: ObjectsService,
    private viewService: ViewService,
  ) {

  }

  addField(event: any|null = null) {
    this.type.fields = this.type.fields || [];

    this.type.fields.push({
      label: '',
      type: 'text',
      uid: '',
    });
  }

  async calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

  }

  dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  save(blDismiss: boolean = true) {
    this.type.active = true;
    this.view.loading = true;

    this.objectsService.createType(this.type)
      .then((response: any) => {
        this.events.publish('create-object:created', response);

        if (!!response && !!response.object) {
          this.type = response.object;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  thumbnailLoadingFailed(object: any) {
    object.photo = this.fallbackImg;
  }

  update(blDismiss: boolean = true) {
    this.view.loading = true;

    this.objectsService.updateType(this.type)
      .then((response: any) => {

        if (!!response && !!response.object) {
          this.type = response.object;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  updateIcon(icon: string | null = null) {
    if (!!icon) {
      this.type.icon = icon;
    }
  }

  uploadPhoto() {

    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.type.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}