import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { BrowserService } from "src/app/services/utils/browser.service";
import { CalendarService } from 'src/app/services/extensions/calendar.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ClipboardService } from 'src/app/services/utils/clipboard.service';
import { CompaniesService } from 'src/app/services/extensions/companies.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from "src/app/services/core/modal.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { TranslateService } from '@ngx-translate/core';

import { EventColor } from 'calendar-utils';

import { SplineViewerComponent } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component';
import { PostsAdminPage } from 'src/app/pages/core/post/posts-admin/posts-admin.page';

import { AiPlannerService } from 'src/app/services/ai/ai-planner.service';
import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { AiToolsPage } from '../ai-tools/ai-tools.page';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-ai-planner',
  standalone: false,
  templateUrl: './ai-planner.page.html',
  styleUrls: ['./ai-planner.page.scss'],
})
export class AiPlannerPage implements OnDestroy, OnInit {
  @ViewChild('headerPopover') headerPopover;
  isHeaderPopoverOpen: boolean = false;

  @ViewChild('infoPopover') infoPopover: any;
  @ViewChild('sharingPopover') sharingPopover: any;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  aiSettings: aiSettings = {
    auto_create_creatives: true,
    context: 'text_generation',
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  calendarOptions: calendarOptions = {
    showPagination: true,
    showSelectionToolbar: true,
    showTitle: true,
    showViewModes: true,
    view: 'month',
    viewModes: ['list', 'day', 'week', 'month'],
  };

  cards: any = {
    ai: { open: false },
    caption: { open: false },
    comments: { open: false },
    creation: { open: true },
    duration: { open: true },
    information: { open: true },
    media: { open: true },
    prompts: { open: true },
    share: { open: false },
    sharing: { open: false },
    types: { open: true },
  };

  eventsList: calendarEvent[] = [];

  isInfoPopoverOpen: boolean = false;
  isSharingPopoverOpen: boolean = false;

  languageOptions: languagesSelectOptions = {
    cached: false,
    default: null,
    mode: 'view',
    multiple: true,
  };

  mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    showGenerateOptions: true,
  };

  selectionOptions: selectionOption[] = [
    {
      color: 'success',
      icon: 'checkmark-done-outline',
      label: 'accept',
      uid: 'accept',
    },
    {
      color: 'warning',
      icon: 'sync-outline',
      label: 'recreate',
      uid: 'recreate',
    },
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      color: 'warning',
      icon: 'hand-left-outline',
      label: 'reject',
      uid: 'reject',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_idle.splinecode',
  };

  state: state = {};

  viewData: any = {
    create_assets: true,
    create_creatives: true,
    edit: true,
    eventsByUid: {},
    generate: {
      createImages: true,
      createVideos: false,
    },
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_planner_top_card',
      text: 'ai_planner_top_card_text',
      title: 'ai_planner_top_card_title',
    },
    multiple: true,
    originalByUid: {},
    output: '',
    plan: {
      shortcodes: {
        calendar: {
          name: 'select',
          prompt: '',
        },
        post_content: {
          name: 'select',
          prompt: '',
        },
      },
      weekdays: [],
    },
    plannerLoadingIndex: 1,
    progress: 0,
    recommendations: {
      topics: [],
    },
    route: 'ai/planner',
    shortcodes: [
      {
        uid: 'calendar',
      },
      {
        uid: 'photo',
      },
      {
        uid: 'post_content',
      }
    ],
    showMenuButton: true,
    showProjectsSelect: true,
    slide: 1,
    title: 'ai_planner',
    topics: '',
    types: [
      {
        checked: true,
        icon: 'desktop-outline',
        uid: 'blog',
        name: 'ai_planner_type_blog',
      },
      {
        icon: 'share-social-outline',
        uid: 'social',
        name: 'ai_planner_type_social',
      },
      {
        icon: 'mail-outline',
        uid: 'newsletter',
        name: 'ai_planner_type_newsletter',
      },
      {
        icon: 'chatbubbles-outline',
        uid: 'messenger',
        name: 'ai_planner_type_messenger',
      }
    ],
    uiMode: 'create',
    weekdays: [
      {
        index: 0,
        name: 'Montag',
        uid: 'monday',
      },
      {
        index: 1,
        name: 'Dienstag',
        uid: 'tuesday',
      },
      {
        index: 2,
        name: 'Mittwoch',
        uid: 'wednesday',
      },
      {
        index: 3,
        name: 'Donnerstag',
        uid: 'thursday',
      },
      {
        index: 4,
        name: 'Freitag',
        uid: 'friday',
      },
      {
        index: 5,
        name: 'Samstag',
        uid: 'saturday',
      },
      {
        index: 6,
        name: 'Sonntag',
        uid: 'sunday',
      },
    ],
  };

  constructor(
    private aiPlanner: AiPlannerService,
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,

    private browser: BrowserService,
    private calendar: CalendarService,
    private dani: DaniService,
    private chooser: ChooserService,
    private clipboard: ClipboardService,
    private companies: CompaniesService,
    private events: EventsService,
    private exportService: ExportService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private postsAdmin: PostsAdminService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private translate: TranslateService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.viewData.planUidFromRoute = this.route.snapshot.paramMap.get('planId');
  }

  addEvent(item: any = {}): void {
    const date: any = (!!item.date_gmt ? new Date(item.date_gmt) : new Date());

    this.eventsList = [
      ...this.eventsList,
      ({
        title: item.title || 'New event',
        start: date,
        end: date,
        color: (item.color || colors.red),
        draggable: true,
        language: (item.language || null),
        photo: ((item.photo || item.thumbnail) || ''),
        platform: (item.platform || ''),
        post_content: (item.post_content || ''),
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        uid: (item.uid || null),
      } as calendarEvent),
    ];
  }

  addEvents(items: any[]) {

    if (!items || !items.length) {
      return false;
    }

    items.forEach((item: any) => {
      this.addEvent(item);
    });
  }

  addRecommendation(tag: any, key: string) {
    /*
    this.project.config.assets[key] = this.project.config.assets[key] || [];
    this.project.config.assets[key].push(tag);

    this.view.recommendations[key] = this.view.recommendations[key].filter((item: any) => {
      return item.uid !== tag.uid;
    });

    if(!this.view.recommendations[key] || !this.view.recommendations[key].length) {
      this.loadRecommendationsByKey(key);
    }
    */
  }

  aiCreate() {

    if (!this.viewData.createCampaignInput || !this.viewData.createCampaignInput.length) {
      return false;
    }

    this.viewData.generating = true;
    this.viewData.loading = true;
    this.viewData.progress = 0;

    this.dani.executeCreateCampaign({
      input: this.viewData.createCampaignInput
    })
      .then(async (response: any) => {
        this.viewData.generating = false;
        this.viewData.loading = false;

        if (!!response && !!response.data) {

          this.viewData.plan.title = `${response.data.name || ''}`;

          this.viewData.topics = `${response.data.topics || ''}`.split(',').map((string: string) => {
            return {
              title: this.tools.trim(string),
              uid: this.tools.trim(string),
            };
          });

          this.startManually();
          this.create();
        }
      })
      .catch((error: any) => {
        this.viewData.generating = false;
        this.viewData.loading = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcCommand() {

    const topicsString: string = (this.viewData.topics || []).map((topic: any) => {
      return `${topic.title || topic.uid}`;
    }).join(',');

    this.viewData.topicsExtend = (!!this.viewData.topics ? `. Das Thema lautet: "${topicsString || ''}".
    ` : ' ');

    const customerExtend: string = (!!this.viewData.plan.customerLabel ? ` für ein Unternehmen namens "${this.viewData.plan.customerLabel}", welches` : '');
    const customerExtendB: string = (!!this.viewData.plan.customerLabel ? ` Beiträge` : '');
    const customerExtendC: string = (!!this.viewData.plan.customerLabel ? ` veröffentlichen möchte` : '');
    const weekdaysExtend: string = (!!this.viewData.weekdaysLabel ? ` und ausschließlich an den Wochentagen ${this.viewData.weekdaysLabel}` : '');

    let command: string = `Erstelle einen Postingplan${customerExtend}${weekdaysExtend}${customerExtendB}${this.viewData.topicsExtend}${customerExtendC}.`;

    if (!!this.viewData.plan.date_start) {
      command += `\nStart-Datum: ${this.viewData.plan.date_start}.`;
    }

    if (!!this.viewData.plan.date_end) {
      command += `\nBis einschließlich: ${this.viewData.plan.date_end}.`;
    }

    if (!!this.viewData.plan && !!this.viewData.plan.shortcodes && !!this.viewData.plan.shortcodes.calendar) {
      this.viewData.plan.shortcodes.calendar.prompt = `${command}`;
    }

    this.calcViewVars();

    return `${command}\n\nImportant: Exactly one post per day.\n\n.Format each line as follows:\n "Date (YYYY-mm-dd HH:mm:ss)"; "Post name"; "Platform";\n`;
  }

  calcDates() {
    let dates = [];

    if (!!this.viewData.plan.weekdays_ints && !!this.viewData.plan.weekdays_ints.length) {
      this.viewData.plan.weekdays_ints.forEach((int: number) => {
        const datesByInt = this.getDatesBetween(this.viewData.plan.date_start, this.viewData.plan.date_end, int);

        if (!!datesByInt && !!datesByInt.length) {
          dates = dates.concat(datesByInt);
        }
      });
    }

    this.viewData.dates = dates;
  }

  calcScreenSizeVars() {
    this.viewData = this.viewService.calcScreenSizeVars(this.viewData);

    this.viewData.colSize = {
      left: (!!this.viewData.isUltraWide ? 2 : (this.viewData.isDesktop ? 3 : 12)),
      right: (!!this.viewData.isUltraWide ? 10 : (this.viewData.isDesktop ? 9 : 12)),
    };
  }

  calcTypesStates() {
    this.viewData.selectedTypesList = {};

    this.viewData.selectedTypes = (this.viewData.types || []).filter((type: any) => {
      this.viewData.selectedTypesList[type.uid] = !!type.checked;

      return !!type.checked;
    });

    this.viewData.hasSelectedTypes = !!this.viewData.selectedTypes.length;
  }

  calcUIMode() {

    const split: string[] = `${window.location.pathname || ''}`.replace('/campaigns/', '').split('/'),
      uiMode: string = (split.length === 2 ? split[1] : 'edit');

    this.calendarOptions = this.calendarOptions || {
      showPagination: true,
      showSelectionToolbar: true,
      showTitle: true,
      showViewModes: true,
      view: 'month',
      viewModes: ['list', 'day', 'week', 'month'],
    };

    switch (uiMode) {
      case 'preview':
        this.calendarOptions.view = 'list';
        this.viewData.hideGetGeniusWallet = true;
        this.viewData.showProjectsSelect = false;
        break;
      case 'review':
        this.calendarOptions.view = 'list';
        this.viewData.hideGetGeniusWallet = true;
        this.viewData.showProjectsSelect = false;
        break;
      default:
        //delete this.calendarOptions.view;
        this.calendarOptions.view = (!!this.viewData.isDesktop ? 'week' : 'list');
        this.viewData.hideGetGeniusWallet = false;
        this.viewData.showProjectsSelect = true;
        break;
    }

    this.viewData.uiMode = uiMode;
  }

  calcViewLabels() {
    return new Promise((resolve, reject) => {
      let weekdays: string[] = [];

      this.calcViewVars();

      if (!this.viewData.plan.weekdays || !this.viewData.plan.weekdays.length) {
        resolve(true);
      } else {
        (this.viewData.plan.weekdays || []).forEach(async (weekday: any, index: number) => {
          const translation: string = await this.translate.get(weekday).toPromise();
          weekdays.push(this.tools.capitalize(translation));

          if (index === (this.viewData.plan.weekdays.length - 1)) {
            this.viewData.weekdaysLabel = weekdays.join(', ');
            resolve(this.viewData.weekdaysLabel);
          }
        });
      }
    });
  }

  calcViewVars() {
    this.viewData = this.viewService.calcVars(this.viewData);

    this.calendarOptions = this.calendarOptions || {
      showPagination: true,
      showSelectionToolbar: true,
      showTitle: true,
      showViewModes: true,
      view: 'month',
      viewModes: ['list', 'day', 'week', 'month'],
    };

    this.calendarOptions.view = (!!this.viewData.isDesktop ? 'week' : 'list');

    this.calcUIMode();

    this.viewData.hasSidebar = this.viewData.uiMode !== 'preview';

    switch (this.viewData.slide) {
      case 1:
        this.viewData.canNext = !!this.viewData.plan.title && !!this.viewData.project;
        break;
      case 2:
        this.viewData.canNext = !!this.viewData.plan.title && !!this.viewData.project;
        break;
    }

    this.calcScreenSizeVars();
  }

  async chooseAiShortcode(shortcode: any) {

    this.viewData.toolsMenuModal = await this.modalService.create({
      backdropDismiss: false,
      component: AiToolsPage,
      componentProps: {
        executeTool: false,
        mode: 'pick',
        multiple: false,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.viewData.toolsMenuModal.onWillDismiss().then((response: any) => {
      if (!!response && !!response.data && !!response.data.input) {
        this.viewData.plan.shortcodes[shortcode.uid].prompt = `${response.data.input}`;
        this.toggleShortcode(shortcode, true);
      }
    });

    this.modalService.present(this.viewData.toolsMenuModal);
  }

  chooseAssets(params: any = {}) {
    params.multiple = true;
    params.services = ['ai', 'database', 'upload'];

    if (!!this.viewData.folder_uid) {
      params.folder_uid = this.viewData.folder_uid;
    }

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  chooseCompany() {
    this.companies.pick({
      multiple: false,
    })
      .then((company: company) => {
        if (!!company && !!company.uid) {
          this.viewData.plan.customer = company.uid;
          this.viewData.plan.customerLabel = company.name;

          this.calcViewLabels().then(() => {
            this.calcCommand();
          });
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      })
  }

  chooseCreatives(params: any = {}) {
    params.mediaType = 'creative';
    params.multiple = true;
    params.services = ['ai', 'database', 'upload'];

    if (!!this.viewData.folder_uid) {
      params.folder_uid = this.viewData.folder_uid;
    }

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  chooseWeekdays(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign((options || {}), {
      data: this.viewData.weekdays,
      labelKey: 'name',
      multiple: true,
      service: this,
      valueKey: 'uid',
    });

    this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {

        if (chooseResponse && chooseResponse.data && chooseResponse.data.items) {

          this.viewData.plan.weekdays = chooseResponse.data.items.map((weekday: any) => {
            return weekday.uid;
          });

          this.viewData.plan.weekdays_ints = chooseResponse.data.items.map((weekday: any) => {
            return weekday.index;
          });

          this.calcDates();

          this.calcViewLabels().then(() => {
            this.calcCommand();
          });
        }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  copySharingLink() {

    if (!this.viewData.sharingLink || !this.viewData.sharingLink.length) {
      return false;
    }

    this.clipboard.copyText(this.viewData.sharingLink);
  }

  async create() {
    return new Promise((resolve, reject) => {

      const topicsString: string = (this.viewData.topics || []).map((topic: any) => {
        return `${topic.title || topic.uid}`;
      }).join(',');

      let calendar: any = JSON.parse(JSON.stringify(this.viewData.plan));

      if (!!calendar && !!calendar.uid) {
        return this.update();
      }

      delete calendar.shortcodes;

      calendar.active = true;
      calendar.description = `${topicsString || ''}`;
      calendar.items = this.parseEventsToPlanItems();
      calendar.user = this.userService.getUid();

      this.aiTools.createPlan(calendar)
        .then((response: any) => {

          if (!!response && !!response.uid) {
            this.viewData.plan.uid = response.uid;
          }

          resolve(response);
        })
        .catch((error: any) => {
          this.events.publish('error', error);
          reject(error);
        });
    });
  }

  dateChanged(event: any | null = null) {
    this.calcDates();

    this.calcViewLabels().then(() => {
      this.calcCommand();
    });
  }

  doRefresh() {

  }

  export(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.eventsList || []),
      service: this.aiPlanner,
      source: 'ai',
      type: 'plan',
    })
      .then((response: any) => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  async filterProjectPosts() {

    this.viewData.toolsMenuModal = await this.modalService.create({
      backdropDismiss: false,
      component: PostsAdminPage,
      componentProps: {
        mode: 'pick',
        multiple: true,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.viewData.toolsMenuModal.onWillDismiss().then(async (response: any) => {
      if (!!response && !!response.data && !!response.data.items && !!response.data.items.length) {
        await this.addEvents(response.data.items);
        await this.update(true);
      }
    });

    this.modalService.present(this.viewData.toolsMenuModal);
  }

  async generate() {

    if (!!this.viewData.plan && !this.viewData.plan.uid) {
      await this.create();
    }

    this.setLoading(true);
    this.startManually();

    this.viewData.generated = false;
    this.viewData.progress = 0;

    setInterval(() => {
      if (!!this.viewData.loading) {
        if (this.viewData.plannerLoadingIndex < 3) {
          this.viewData.plannerLoadingIndex++;
        } else {
          this.viewData.plannerLoadingIndex = 1;
        }
      }
    }, (5 * 1000));

    this.calcViewLabels()
      .then(() => {

        const topicsString: string = (this.viewData.topics || []).map((topic: any) => {
          return `${topic.title || topic.uid}`;
        }).join(',');

        if (!topicsString || !topicsString.length) {
          this.events.publish('error', 'error_missing_topics');
          this.setLoading(false);

          return false;
        }

        this.aiPlanner.schedule(this.viewData.plan || {}, {
          channels: (this.viewData.channels || []),
          create_assets: !!this.viewData.create_assets,
          create_creatives: !!this.viewData.create_creatives,
          languages: (this.viewData.languages || []),
          mediaList: (this.viewData.mediaList || []),
          topics: topicsString,
        })
          .then((response: any) => {
            this.setLoading(false);
            this.viewData.generated = true;

            this.handleResponse(response);
          })
          .catch((error: any) => {
            this.setLoading(false);
            this.viewData.generated = true;

            this.events.publish('error', error);
          });
      });
  }

  /*
  getDatesBetween(startDate: number, endDate: number, dayNum) {
    const current = moment(startDate);
    const end = moment(endDate);
    const results = [];

    // If current is on the wrong weekday, move it forward to the first matching weekday:
    if (current.weekday() !== dayNum) {
      current.add(dayNum >= current.weekday() ? (dayNum - current.weekday()) : (7 - (current.weekday() - dayNum)), 'day');
    }

    while (current.isSameOrBefore(end)) {
      results.push(current.clone());
      current.add(7, 'day');
    }

    return results;
  }
  */

  getDatesBetween(startDate: number, endDate: number, dayNum) {

    // Convert timestamps to Date objects
    let current = new Date(startDate);
    const end = new Date(endDate);
    const results: any[] = [];

    // Get current weekday (0-6, where 0 is Sunday)
    let currentWeekday = current.getDay();

    // Adjust current to the first matching weekday
    if (currentWeekday !== dayNum) {
      const daysToAdd = dayNum >= currentWeekday
        ? (dayNum - currentWeekday)
        : (7 - (currentWeekday - dayNum));
      current.setDate(current.getDate() + daysToAdd);
    }

    // Keep adding dates until we pass the end date
    while (current <= end) {
      results.push(new Date(current));
      current.setDate(current.getDate() + 7);
    }

    return results;
  }

  handleResponse(response: any) {
    let entries: any[] = [];

    if (!response || !response.entries || !response.entries.length) {
      console.warn('response has no entries', response);
      return false;
    }

    response.entries.forEach((entry: any, index: number) => {

      if (!entry.photo && !!entry.media_uids && !!this.viewData.mediaList) {

        const matchingMedia: mediaItem[] = this.viewData.mediaList.filter((mediaItem: mediaItem) => {
          return entry.media_uids.indexOf(mediaItem.ID) !== -1;
        });

        if (!!matchingMedia[0] && !!matchingMedia[0].thumbnail) {
          entry.photo = matchingMedia[0].thumbnail;
        }

      }

      entry.platforms.forEach((platform: string) => {
        const iStart: any = new Date(Date.parse(entry.date));
        const iEnd: any = iStart;
        const title: string = this.tools.trim(`${entry.title}`.replace('• ', '').replace('- ', '').trim(), '"');

        entries.push({
          title: title,
          start: iStart,
          end: iEnd,
          color: (platform === 'Instagram' ? colors.red : colors.blue),
          draggable: true,
          language: entry.language,
          photo: `${entry.photo || ''}`,
          platform: platform,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
        });
      });

    });

    this.lazyLoadEntries(entries);

    const eventSource = this.mapEntriesToFixedDates(entries);

    this.eventsList = eventSource;
    console.log('ai-planner: eventsList (a)', this.eventsList);
  }

  hasChanges() {
    this.viewData.hasChanges = true;
    this.calcViewVars();
  }

  importPosts(event: any | null = null) {
    console.log('importPosts', this.viewData);

    return this.filterProjectPosts();
  }

  initEvents() {
    this.viewData.events = {};

    this.viewData.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.aiWorker.setCurrentProject(this.viewData.project);
      this.viewData.project = project;

      if (!!this.viewData.project && !!this.viewData.project.config && !!this.viewData.project.config.languages) {
        this.viewData.languages = (this.viewData.project.config.languages || []);
      }

      this.calcViewVars();
    });

    this.viewData.events.windowResized = this.events.subscribe('window:resized', () => {
      this.calcScreenSizeVars();
    });
  }

  async initLoadPlan() {
    let plan: any | null = this.aiTools.detailItem();

    if (!plan && !!this.viewData.planUidFromRoute) {
      try {
        this.viewData.loading = true;

        plan = await this.aiPlanner.getPlanByUid(parseInt(this.viewData.planUidFromRoute));

        this.viewData.loading = false;
      } catch (e) {
        this.viewData.loading = false;
        this.events.publish('error', e);
      }
    }

    if (!!plan && !!plan.uid) {
      plan.shortcodes = (plan.hasOwnProperty('shortcodes') ? plan.shortcodes : this.viewData.plan.shortcodes);
      plan.weekdays = ((plan.hasOwnProperty('weekdays') ? plan.weekdays : this.viewData.plan.weekdays) || []);
      plan.date_end = plan.date_end || (!!plan.entries && !!plan.entries.length ? plan.entries[plan.entries.length - 1].end : null);
      plan.date_start = plan.date_start || (!!plan.entries && !!plan.entries.length ? plan.entries[0].start : null);

      this.viewData.plan = plan;

      this.viewData.topics = `${plan.description || ''}`.split(',').map((line: string) => {
        return {
          uid: line,
          title: line,
        }
      });

      if (!!plan.entries && !!plan.entries.length) {

        // apply posts if exist
        this.eventsList = this.calendar.parseDatabaseEvents(plan.entries as any[]);

        this.validateEventsList();

      } else {
        // else, generate posts if plan is empty
        await this.generate();
      }

      this.viewData.generated = true;
    }

    this.loadUIData();
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    this.aiTools.detailItem({} as any);
    this.events.stop(this.viewData.events);
  }

  async lazyLoad(entry: any, index: number) {

    let response: any = {
      errors: {},
    };

    if (!entry.photo) {
      try {
        response.image = await this.lazyLoadImage(entry, index);

        // save first entry with image
        this.saveCalendarEntry(index);
      } catch (e) {
        response.errors.image = e;
      }
    }

    setTimeout(() => {
      this.lazyLoadContent(entry, index)
        .then((content: any) => {
          response.content = content;

          // store post-processed result
          this.saveCalendarEntry(index);
        })
        .catch((e: any) => {
          response.errors.content = e;
        });
    }, 1000);

    /*
    try {
      response.content = await this.lazyLoadContent(entry, index);
    } catch(e) {
      response.errors.content = e;
    }
    */

    return response;
  }

  lazyLoadContent(entry: any, index: number, blForceRefresh: boolean = true) {
    return new Promise((resolve, reject) => {
      let options: any = {};

      if (!!this.viewData.plan.shortcodes.post_content.prompt) {
        options.prompt = this.viewData.plan.shortcodes.post_content.prompt;
      }

      entry.loading = true;

      this.aiPlanner.preProcess(entry, options, blForceRefresh)
        .then(async (response: any) => {

          if (!!response && !!response.output) {
            entry.post_content = response.output;

            if (!!this.eventsList[index]) {
              (this.eventsList[index] as any).post_content = response.output;
            }
          }

          // store pre-processed result
          await this.saveCalendarEntry(index, entry);

          this.aiPlanner.postProcess(entry, options, blForceRefresh)
            .then((response: any) => {
              console.log('post-process response of #', index, response);

              if (!!response && !!response.output) {
                entry.post_content = response.output;

                if (!!this.eventsList[index]) {
                  (this.eventsList[index] as any).post_content = response.output;
                }
              }

              entry.loading = false;

              resolve(entry);
            })
            .catch((e: any) => {
              entry.loading = false;
              reject(e);
            });
        })
        .catch((e: any) => {
          entry.loading = false;
          reject(e);
        });
    });
  }

  lazyLoadEntries(entries: any[]) {

    if (!entries || !entries.length) {
      return false;
    }

    switch (this.aiSettings.provider) {

      // in local mode, lazy-load one by another
      case 'local':
        return this.lazyLoadEntry(entries[0], 0, entries);

      default:
        entries.forEach((entry: any, i: number) => {
          setTimeout(() => {
            this.lazyLoadEntry(entry, i);
          }, (i * (10 * 1000)));
        });
        break;
    }
  }

  lazyLoadEntry(entry: any, index: number, entries: any[] | null = null) {
    return new Promise((resolve, reject) => {
      this.lazyLoad(!!entries ? entries[index] : entry, index)
        .then(() => {

          if (!!entries) {
            this.viewData.progress = ((100 / entries.length) * (index + 1) / 100);
          }

          if (!!entries && !!entries[index + 1]) {
            setTimeout(() => {
              this.lazyLoadEntry(entries[index + 1], index + 1, entries).then(resolve).catch(reject);
            }, 1000);
          } else {
            resolve(entries);
          }
        })
        .catch((error: any) => {
          console.warn('lazy loading failed for index', index, 'with error: ', error);

          if (!!entries && !!entries[index + 1]) {
            setTimeout(() => {
              this.lazyLoadEntry(entries[index + 1], index + 1, entries).then(resolve).catch(reject);
            }, 1000);
          } else {
            resolve(entries);
          }
        });
    });
  }

  lazyLoadImage(entry: any, index: number, blForceRefresh: boolean = true) {
    return new Promise((resolve, reject) => {

      const options: aiSearchOptions = {
        blFineTuneInput: true,
        creative: true,
        limit: 1,
        mediaList: (this.viewData.mediaList || []),
        query: `${entry.title}`,
        request: 'images',
        width: 1024,
        height: 576,
      };

      this.aiTools.search(options, {
        showStep: (data: any) => {
          console.log('ai-planner: showStep: data', data);

          if (!!data && !!data.base64) {
            (this.eventsList[index] as any).photo = `${data.base64}`;
          }
        },
      }, blForceRefresh)
        .then((response: any) => {

          if (!!response && !!response.suggestions && !!response.suggestions[0] && !!response.suggestions[0].url && !!this.eventsList[index]) {
            (this.eventsList[index] as any).photo = `${response.suggestions[0].url}`;
          }

          resolve(this.eventsList[index]);
        })
        .catch(reject);
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadUIData(blForceRefresh: boolean = false) {
    this.viewData.project = await this.projects.getCurrent();

    if (!!this.viewData.project && !!this.viewData.project.config && !!this.viewData.project.config.languages) {
      this.viewData.languages = (this.viewData.project.config.languages || []);
    }

    // calculate date UI variables
    var date: any = (date || new Date()), endDate = new Date();
    endDate.setDate(date.getDate() + 30)

    // apply date variables if not set
    this.viewData.plan.date_start = this.viewData.plan.date_start || new Date(date).toISOString();
    this.viewData.plan.date_end = this.viewData.plan.date_end || new Date(endDate).toISOString();

    this.calcViewVars();
  }

  mapEntriesToFixedDates(entries: any[]) {
    let _entries: any[] = [];

    if (entries && entries.length) {
      entries.forEach((entry: any, index: number) => {
        const matchingDate = (!!this.viewData.dates && this.viewData.dates[index] ? this.viewData.dates[index] : null);

        if (!!matchingDate || (!this.viewData.dates || !this.viewData.dates.length)) {

          if (!!matchingDate) {
            entry.endTime = matchingDate.toDate();
            entry.startTime = matchingDate.toDate();
          }

          _entries.push(entry);
        }
      });
    }

    return _entries;
  }

  ngAfterContentInit() {
    this.calcTypesStates();
  }

  ngOnDestroy() {
    if (!!this.viewData && !!this.viewData.events) {
      this.events.stop(this.viewData.events);
    }
  }

  ngOnInit() {
    this.initLoadPlan();
    this.loadCards();
    this.calcDates();

    this.calcViewLabels().then(() => {
      this.calcCommand();
    });

    this.calcViewVars();
  }

  onChannelsChanged(event: any | null = null) {

    this.viewData.channels = (event || []).map((item: any) => {
      return { platform: item.platform, uid: item.uid, };
    });

    this.calcViewLabels().then(() => {
      this.calcCommand();
    });
  }

  onLanguageChange(event: any | null = null) {
    this.viewData.languages = (event || []);
    this.languageOptions.default = this.viewData.languages;
  }

  onMediaChanged(event: any | null = null) {
    console.log('onMediaChanged', event);
  }

  onSelectedEventsChanged(event: any | null = null) {
    console.log('planner: onSelectedEventsChanged', event);
  }

  onSharingModeChanged(event) {

    if (!this.viewData.plan || !this.viewData.plan.uid) {
      return false;
    }

    const host: string = ((window.location.host.indexOf('localhost') !== -1) ? 'getgenius.app' : window.location.host);

    this.viewData.sharingLink = `https://${host}/campaigns/${this.viewData.plan.uid}/${this.viewData.sharingMode}`;
  }

  onTagAdd(event: any | null = null) {
    this.calcCommand();
  }

  onTagRemove(event: any | null = null) {
    this.calcCommand();
  }

  openSharingLink() {

    if (!this.viewData.sharingLink || !this.viewData.sharingLink.length) {
      return false;
    }

    this.browser.create(this.viewData.sharingLink);
  }

  parseEventsToPlanItems() {
    return JSON.parse(JSON.stringify(this.eventsList || []))
      .filter((item: any) => {
        return (!!item.end_date && !!item.start_date) || (!!item.end && !!item.start);
      })
      .map((item: any) => {
        item.end = item.end || `${item.end_date}`;
        item.start = item.start || `${item.start_date}`;

        delete item.draggable;
        delete item.resizable;

        return item;
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.viewData.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  prev() {
    this.viewData.slide--;
  }

  runAiPrompt(event: any = null) {

  }

  saveCalendarEntry(index: number, entry: any | null = null) {

    if (!entry && !this.eventsList[index]) {
      return false;
    }

    this.savePost(index, entry);
  }

  savePost(index: number, entry: any) {

    let post: any = Object.assign(JSON.parse(JSON.stringify(entry || this.eventsList[index])), {
      active: true,
      local: false,
      region: 'global',
      region_uid: 0,
      type: 'draft',
    });

    //post.date_gmt = moment(`${post.start}`).format('YYYY-MM-DD HH:mm:ss');
    post.name = `${post.title}`;
    post.post_date = post.date_gmt;
    post.thumbnail = `${post.photo || ''}`;

    delete post.loading;

    if (!!post.uid) {
      // update post if uid is set

      this.postsAdmin.updatePost(post)
        .catch((error: any) => {
          console.warn('updating post failed', error);
        });

    } else {
      // else, create post

      this.postsAdmin.submitPost(post)
        .then((response: any) => {
          if (!!response && !!response.post && !!response.post.uid) {
            (this.eventsList[index] as any).uid = response.post.uid;

            // add post to plan after creating it
            this.aiPlanner.addPostToPlan(response.post.uid, this.viewData.plan.uid)
              .catch((error: any) => {
                console.warn('linking post to plan failed', error);
                //this.events.publish('error', error);
              });
          }
        })
        .catch((error: any) => {
          console.warn('submitting post failed', error);
          //this.events.publish('error', error);
        });

    }

    // set campaign photo (if not set and provided)
    if (!index && !!post.thumbnail) {
      this.viewData.plan.photo = post.thumbnail;
      this.update(true);
    }

  }

  sharePlan(e: any | null = null) {
    this.sharingPopover.event = e;
    this.isSharingPopoverOpen = true;
  }

  setLoading(bl: boolean) {
    if (!!bl) {
      this.splineOptions.url = './assets/spline/dani/dani_working.splinecode';
      this.viewData.loading = true;
    } else {
      //this.splineOptions.url = './assets/spline/dani/dani_presenting.splinecode';

      //setTimeout(() => {
      //this.splineOptions.url = './assets/spline/dani/dani_idle.splinecode';
      //this.splineViewer.updateAnimation(this.splineOptions);

      this.viewData.loading = false;
      //}, 2000);
    }

    try {

      if (!this.splineViewer) {
        return false;
      }

      this.splineViewer.updateAnimation(this.splineOptions);
    } catch (e) {
      console.warn('updating spline viewer failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  startManually() {
    this.viewData.startManually = true;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleShortcode(shortcode: any, blValue: boolean | null = null) {

    if (!!shortcode.checked && !blValue) {
      shortcode.checked = false;
      return false;
    }

    if (!!this.viewData.shortcodes && !!this.viewData.shortcodes.length) {
      this.viewData.shortcodes.forEach((_shortcode: any) => {
        _shortcode.checked = false;
      });
    }

    shortcode.checked = (blValue !== null ? !!blValue : true);
  }

  toggleType(type: any, iType: number) {
    this.viewData.types[iType].checked = !this.viewData.types[iType].checked;

    this.calcTypesStates();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update(blBackground: boolean = false) {

    if (!this.viewData.plan || !this.viewData.plan.uid) {
      return this.create();
    }

    const calendar: any = {
      active: true,
      items: this.parseEventsToPlanItems(),
      title: `${this.viewData.plan.title}`,
      uid: this.viewData.plan.uid,
      user: this.userService.getUid(),
    };

    this.aiTools.updatePlan(calendar)
      .then((response: any) => {

        if (!!response && !!response.uid) {
          this.viewData.plan.uid = response.uid;
        }

        if (!blBackground) {
          this.navCtrl.navigateBack('/campaigns');
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  validateCTAInput() {
    const validate: any = this.tools.validateUrl(this.viewData.ctaInput);
    this.viewData.isValidWebsiteUrl = validate.success;
  }

  validateEventsList() {

    if (!this.eventsList || !this.eventsList.length) {
      return false;
    }

    try {

      const incompletePosts: any[] = this.eventsList.filter((post: any) => {
        return (!post.post_content || !post.post_content.length) || (!post.photo || !post.photo.length);
      });

      if (!!incompletePosts && !!incompletePosts.length) {
        this.lazyLoadEntries(incompletePosts);
      }

    } catch (e) {
      console.warn('validation / completion failed', e);
    }
  }

}