<ion-popover #selectionOptionsPicker [isOpen]="isSelectionOptionsPickerOpen"
  (didDismiss)="isSelectionOptionsPickerOpen = false">

  <ng-template>

    <ion-content>

      <ion-list *ngIf="!!isSelectionOptionsPickerOpen && !!options">

        <ion-item button class="btn-item" *ngFor="let option of options" (click)="runSelectionOption(option, $event)"
          [class.danger]="option.color == 'danger' || option.uid === 'delete'"
          [class.success]="option.color == 'success'" [class.warning]="option.color == 'warning'">
          <ion-icon [name]="option.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="option.label|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ion-content>

  </ng-template>

</ion-popover>