<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Main export button -->
      <ion-button *ngIf="!!view.startManually && !!view.project && !!view.project.uid"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="update()" [disabled]="!!view.loading || !data || !data.length" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <pipeline-selection-toolbar *ngIf="!!data" [(items)]="data" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig" [hidden]="!!view.startManually"
    (onIntegrationChanged)="onIntegrationChanged($event)" (onSkip)="startManually()"
    [(view)]="view"></pipeline-intro-overlay>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container slider-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.expertMode">

            <!-- Source -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('source')">
                    <span [innerHTML]="'source'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('source')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.source && !!cards.source.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.source && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_editor_source_text'|translate"></p>
                  </ion-card-content>

                  <ion-item lines="none" *ngIf="!!view.integrations && !!view.integrations.length">

                    <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                    <ion-select interface="popover" [label]="'integration'|translate" [(ngModel)]="view.integration"
                      (ionChange)="onIntegrationChanged($event)">
                      <ion-select-option *ngFor="let integration of view.integrations; trackBy:trackItems" [value]="integration"
                        [innerHTML]="integration.name|translate"></ion-select-option>
                    </ion-select>
                  </ion-item>

                </div>
              </ion-card>
            </div>

            <!-- Filters -->
            <div class="col-inner">
              <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
                (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop && !!view.expertMode ? 9 : 12">

            <!-- Intro card -->
            <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <!-- Preview -->
            <ion-card class="preview-card" [hidden]="!!view.loading">

              <ion-card-header>
                <ion-card-title [innerHTML]="'changes'|translate"></ion-card-title>
              </ion-card-header>

              <!-- List View -->
              <ion-list [hidden]="view.viewType !== 'list'">

                <ion-item *ngFor="let key of view.dataKeys; let i = index;">

                  <!-- If options for key provided, show select -->
                  <ion-select *ngIf="!!optionsByKey[key]" [hidden]="!!manuallyItems[key]"
                    [(ngModel)]="view.newValues[key]" interface="popover" [okText]="'okay'|translate"
                    [cancelText]="'cancel'|translate" [label]="key|translate">
                    <ion-select-option [innerHTML]="'no_4'|translate" value=""></ion-select-option>
                    <ion-select-option *ngFor="let option of optionsByKey[key]" [value]="option"
                      [innerHTML]="option|translate"></ion-select-option>
                  </ion-select>

                  <!-- Else, show text field -->
                  <ion-input *ngIf="!optionsByKey[key] || !!manuallyItems[key]" [label]="key|translate"
                    labelPlacement="floating" [(ngModel)]="view.newValues[key]"></ion-input>

                  <!-- Write manually (select found, but switch to text mode ) -->
                  <ion-button *ngIf="!!optionsByKey[key]" slot="end" (click)="writeManuallyDataItem(key, i)"
                    [hidden]="!!manuallyItems[key]" fill="clear" color="dark">
                    <ion-icon name="create-outline"></ion-icon>
                  </ion-button>

                </ion-item>

              </ion-list>

              <!-- Table View -->
              <div [hidden]="view.viewType !== 'table'">

                <pipeline-table-view (itemsChanged)="onItemsChanged($event)" [(items)]="data" [buttons]="buttons"
                  [fields]="view.dataKeys" mode="edit" [method]="view.method" [(view)]="view"
                  [options]="tableViewOptions" [service]="dataEditorService"></pipeline-table-view>

              </div>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>