<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="update()" *ngIf="view.editMode" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="save()" *ngIf="!view.editMode" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment bar -->
  <ion-toolbar class="container">
    <ion-segment name="title" [(ngModel)]="view.segment">
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="permissions">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'permissions'|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <ion-card>

      <!-- General -->
      <ion-list [hidden]="view.segment != 'general'">

        <!-- Name -->
        <ion-item>
          <ion-label [innerHTML]="'name'|translate" position="stacked"></ion-label>
          <ion-input type="text" [(ngModel)]="item.title" [placeholder]="'item_name'|translate"></ion-input>
        </ion-item>

        <!-- Active -->
        <ion-item>
          <ion-checkbox [innerHTML]="'state_active'|translate" alignment="end" justify="space-between"
            labelPlacement="start" [(ngModel)]="item.active"></ion-checkbox>
        </ion-item>

        <!-- Public -->
        <ion-item lines="none">
          <ion-checkbox [innerHTML]="'state_public'|translate" alignment="end" justify="space-between"
            labelPlacement="start" [(ngModel)]="item.public"></ion-checkbox>
        </ion-item>

      </ion-list>

      <!-- Permissions -->
      <ion-list [hidden]="view.segment != 'permissions'" *ngIf="!!item.config">

        <!-- Is superuser? -->
        <ion-item>
          <ion-checkbox [(ngModel)]="item.config.superuser" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_superuser'|translate"></ion-checkbox>
        </ion-item>

        <!-- Is a user in this group allowed to login? -->
        <ion-item>
          <ion-checkbox [(ngModel)]="item.config.can_login" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_can_login'|translate"></ion-checkbox>
        </ion-item>

        <!-- Can user deactivate other user's accounts? -->
        <ion-item>
          <ion-checkbox [(ngModel)]="item.config.can_deactivate_users" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_can_deactivate_users'|translate"></ion-checkbox>
        </ion-item>

        <!-- Can user delete other users? -->
        <ion-item>
          <ion-checkbox [(ngModel)]="item.config.can_delete_users" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_can_delete_users'|translate"></ion-checkbox>
        </ion-item>

        <!-- Can user change own user group? -->
        <ion-item>
          <ion-checkbox [(ngModel)]="item.config.can_change_own_user_group" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_can_change_own_user_group'|translate"></ion-checkbox>
        </ion-item>

        <!-- Can user toggle plugins? -->
        <ion-item lines="none">
          <ion-checkbox [(ngModel)]="item.config.can_toggle_plugins" alignment="end" justify="space-between"
            labelPlacement="start" [innerHTML]="'state_can_toggle_plugins'|translate"></ion-checkbox>
        </ion-item>

      </ion-list>

    </ion-card>
  </div>

</ion-content>