import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ViewService } from 'src/app/services/core/view.service';

import { AiBrowserComponent } from 'src/app/components/ai/ai-browser/ai-browser.component';

@Component({
  selector: 'app-ai-browser',
  standalone: false,
  templateUrl: './ai-browser.page.html',
  styleUrls: ['./ai-browser.page.scss'],
})
export class AiBrowserPage implements AfterContentInit, OnInit {
  @ViewChild(AiBrowserComponent) aiBrowser: any;
  @ViewChild('aiBrowserSwiper') swiper: ElementRef | undefined;
  @ViewChild('aiBrowserThumbsSwiper') thumbsSwiper: ElementRef | undefined;

  aiSettings: aiSettings = {
    context: 'text_generation',
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  browserConfig: aiBrowserConfig = {
    use_proxy: true,
  };

  cards: any = {};

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    input_placeholder: 'ai_browser_ai_helper_input_placeholder',
    headline: 'ai_browser_ai_helper_text',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
  };

  search: searchOptions = {
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  view: any = {
    buttonAction: 'generate',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_browser_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_browser_top_card_text',
      title: 'ai_browser_top_card_title',
    },
    progress: 0,
    route: 'ai/items',
    sections: [],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    title: 'ai_browser',
  };

  constructor(
    private aiBridge: AiBridgeService,

    private configService: ConfigService,
    private events: EventsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  aiCreate() {

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.finalResultUrl = null;
    this.view.generating = true;
    this.view.loading = true;
    this.view.progress = 0;
    this.view.showSplineView = true;

    setTimeout(() => {
      this.startManually();
    }, 3500);

    // "selector": "a css selector to find the elements",

    let history: aiExecutionHistoryItem[] = [
      {
        role: 'system',
        content: `You are tasked with returning a perfect JSON array that explains step-by-step how an AI with a browser can follow the user's request so that an AI can automatically search the internet using a Selenium web browser to fulfill the user's request.
        Only enter existing / known URLs, no imaginary / fake addresses.
        
        The response should look like this:
        [
          {
            "description": ”What do we do at this step? (e.g: Navigate to google, search for XYZ)
            "query": "the query to insert into the searchbar after loading the url",
            "url": "the url to navigate to",
          },
          ...
        ]`,
      },
      {
        role: 'user',
        content: `${this.view.aiCreateInput}`
      }
    ];

    this.aiBridge.execute({
      context: 'ai_browser',
      history: history,
      post_content: `IMPORTANT:
      - Do not add any other output or human text to your output.
      - Your output is processed directly as JSON.
      - You only speak JSON.
      - Respond with the json list only.`,
    })
      .then(async (response: any) => {

        if (!!response && !!response.output) {

          /*
          old (can be removed?):
          const outputSplit: string[] = `${response.output || ''}`.replace('```json', '').replace('```', '').split('[');

          if (outputSplit.length > 1) {
            response.output = this.tools.trim(`[${outputSplit[outputSplit.length - 1].replace("\n", '')}`);
            console.log('cleaned response.output', response.output);
          }
          */

          // new generic json extractor:
          this.view.steps = this.tools.extractJson(response.output); //JSON.parse(response.output);
        } else {
          this.view.steps = [];
        }

        this.startManually();

        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;
        this.view.introCard.hidden = true;

        setTimeout(() => {
          this.runSteps();
        }, 250);
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcInputModeStates() {
    this.view.selectedInputModesList = {};

    this.view.selectedInputModes = (this.view.inputModes || []).filter((mode: any) => {
      this.view.selectedInputModesList[mode.uid] = !!mode.checked;

      return !!mode.checked;
    });

    this.view.hasSelectedInputModes = !!this.view.selectedInputModes.length;
    console.log('this.view.selectedInputModesList', this.view.selectedInputModesList);
  }

  calcViewStates() {
    this.view.canGenerate = true;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSize();
  }

  deleteSelected() {

  }

  dismiss() {

  }

  doRefresh(event: any | null = null) {
    this.loadBrowser(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected() {

  }

  edit(section: any, index: number) {
    section.mode = 'edit';
    this.view.mode = 'edit';
  }

  editVideoParts() {
    this.view.finalResultUrl = null;
    this.view.phase = 'edit';
  }

  generate() {
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadBrowser(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;
    });
  }

  loadNextFrame() {
    this.view.stepIndex = this.view.stepIndex || 0;
    
    const nextStep: any = this.view.steps[this.view.stepIndex + 1];
    console.log('ai-browser-page: loadNextFrame: nextStep', nextStep);

    if (!nextStep) {
      this.onListCompleted();
      return false;
    }

    this.view.stepIndex++;
    this.view.step = this.view.steps[this.view.stepIndex];

    try {

      this.aiBrowser
        .setStep(this.view.stepIndex)
        .load();

    } catch (e: any) {
      console.warn('browser loading next page failed', e);
      this.events.publish('error', e);
    }
  }

  loadPreview() {
    console.log('loadPreview: step', this.view.step);

    if (!this.view.step || !this.view.step.url || !this.view.step.url.length) {
      return false;
    }

    try {

      this.aiBrowser
        .setSteps(this.view.steps || [])
        .setUrl(this.view.step.url)
        .load();

    } catch (e: any) {
      console.warn('browser loading page failed', e);
      this.events.publish('error', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterContentInit() {

  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();
    this.loadProject();
  }

  onItemChecked(item: mediaTemplate) {

    this.view.selectedItems = this.view.items.filter((_item: mediaTemplate) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onListCompleted() {
    console.log('onListCompleted', this.view);
  }

  onPageDone(event: any | null = null) {
    console.log('ai-browser-page: onPageDone: event', event);

    this.loadNextFrame();
  }

  onPageLoaded(event: any | null = null) {
    console.log('ai-browser-page: onPageLoaded', event);
  }

  onPageRendered(event: any | null = null) {
    console.log('ai-browser-page: onPageRendered', event);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onUrlChanged(event: any | null = null) {
    console.log('onUrlChanged', event);
  }

  runAiPrompt(event: any = null) {

  }

  runSteps() {
    this.view.step = (!!this.view.steps && !!this.view.steps.length ? this.view.steps[0] : null);
    this.view.stepIndex = (!!this.view.steps && !!this.view.steps.length ? 0 : null);

    console.log('this.view.step', this.view.step);
    console.log('this.view.steps', this.view.steps);

    setTimeout(() => {
      this.loadPreview();
    }, 250);
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleInputMode(mode: any, section: any) {
    section.mode = 'edit';
    section.type = 'image';

    this.calcInputModeStates();
  }

  useProxyChanged() {
    this.runSteps();
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}