<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.startManually">

      <!-- Connect -->
      <ion-button color="primary" [disabled]="(!view.success || !view.verifyCustomBlog) && !view.verifyOwnership"
        shape="round" fill="solid" (click)="submit()">
        <ion-icon name="link-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'connect'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!!view.startManually"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Create code intro mode -->
  <pipeline-intro-overlay [config]="introOverlayConfig" [hidden]="!!view.startManually" (onContinue)="aiCreate()"
    (onSkip)="startManually()" [(view)]="view"></pipeline-intro-overlay>

  <div class="container">

    <ion-card>
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_blog_headline'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <p [innerHTML]="'connect_blog_text'|translate"></p>
        <ion-searchbar type="url" [(ngModel)]="view.input" [placeholder]="'url'|translate"
          (ionInput)="onUrlChanged()"></ion-searchbar>
      </ion-card-content>
    </ion-card>

    <ion-card color="success" *ngIf="view.success">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_blog_headline_success'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_blog_text_success'|translate"></p>
      </ion-card-content>
    </ion-card>

    <ion-card color="danger" [hidden]="view.success !== false">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_blog_headline_failed'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_blog_text_failed'|translate"></p>
      </ion-card-content>
      <ion-item lines="none" [hidden]="!view.input || view.loading || view.success">
        <ion-checkbox [(ngModel)]="view.verifyCustomBlog" slot="start"></ion-checkbox>
        <ion-label class="ion-text-wrap">
          <h2 [innerHTML]="'verify_custom_blog'|translate"></h2>
          <p [innerHTML]="'verify_custom_blog_text'|translate"></p>
        </ion-label>
      </ion-item>
    </ion-card>

    <ion-card>

      <!-- Loading -->
      <ion-spinner [hidden]="!view.loading"></ion-spinner>
      <h3 [innerHTML]="'connect_blog_loading_text'|translate" [hidden]="!view.loading"></h3>

      <ion-list *ngIf="view.success || view.verifyCustomBlog">

        <ion-item>

          <ion-thumbnail slot="start" (click)="onSourcePhotoClick()">
            <ion-img [src]="view.source.photo || fallbackImg" (ionError)="thumbnailLoadingFailed()"></ion-img>
          </ion-thumbnail>

          <ion-input [(ngModel)]="view.source.name" [label]="'name'|translate" labelPlacement="floating"></ion-input>

        </ion-item>

        <ion-item>
          <ion-icon name="link-outline" slot="start"></ion-icon>
          <ion-label position="stacked">
            <h3 [innerHTML]="'url'|translate"></h3>
          </ion-label>
          <ion-input type="text" [(ngModel)]="view.source.url" [placeholder]="'blog_url'|translate"
            disabled></ion-input>
        </ion-item>

        <pipeline-languages-select [(input)]="view.language"></pipeline-languages-select>

        <ion-item>

          <ion-icon name="code-outline" slot="start"></ion-icon>

          <ion-label position="stacked">
            <h3 [innerHTML]="'system'|translate"></h3>
          </ion-label>

          <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
            [(ngModel)]="view.source.system" [placeholder]="'system'|translate">
            <ion-select-option *ngFor="let system of view.systems" [value]="system"
              [innerHTML]="('system_' + system)|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <ion-item button (click)="pickRegion()" lines="none">

          <ion-icon name="location-outline" slot="start"></ion-icon>

          <ion-label position="stacked">
            <h3 [innerHTML]="'region'|translate"></h3>
          </ion-label>

          <ion-note slot="end" [innerHTML]="view.source.region"></ion-note>
        </ion-item>


        <ion-item lines="none" [hidden]="!!view.loading">
          <ion-checkbox [disabled]="!view.success && !view.verifyCustomBlog" [(ngModel)]="view.verifyOwnership"
            justify="start" labelPlacement="end" [innerHTML]="'verify_ownership_blog_text'|translate|fillVars"
            class="ion-text-wrap"></ion-checkbox>
        </ion-item>

      </ion-list>

    </ion-card>

  </div>

</ion-content>