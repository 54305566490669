import { Component, OnDestroy, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'pipeline-whitelabel-configuration-slider-navigation',
  standalone: false,
  templateUrl: './whitelabel-configuration-slider-navigation.component.html',
  styleUrls: ['./whitelabel-configuration-slider-navigation.component.scss'],
})
export class WhitelabelConfigurationSliderNavigationComponent implements OnDestroy, OnInit {

  view: any = {
    canSlideBack: false,
    canSlideNext: false,
  };

  constructor(
    private events: EventsService,
  ) {

  }

  back() {
    this.events.publish('whitelabel:configurator:slide:back');
  }

  initEvents() {
    this.events.subscribe('whitelabel:configurator:view:updated', (view: any) => {
      this.view = view;
    });
  }

  next() {
    this.events.publish('whitelabel:configurator:slide:next');

    this.view.canSlideBack = true;
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }
  
  ngOnInit() {
    this.initEvents();
  }

}
