import { AfterContentInit, Component, OnInit } from '@angular/core';

import { AlertService } from "src/app/services/core/alert.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-data-importer',
  standalone: false,
  templateUrl: './data-importer.page.html',
  styleUrls: ['./data-importer.page.scss'],
})
export class DataImporterPage implements AfterContentInit, OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  buttons: button[] = [
  ];

  cards: any = {
    filters: { open: true },
    item_attributes: { open: true },
    output: { open: true },
    source: { open: true },
  };

  data: any; // provided by modalCtrl
  data_backup: any;

  exportService: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'data_importer_ai_helper_text',
    showAiCreate: false,
    showAiSettings: false,
    showInput: false,
    showIntegrations: true,
    showSources: true,
    sourceRequired: true,
    sources: [
      {
        icon: 'document-outline',
        name: 'file',
        uid: 'file',
      },
      {
        icon: 'extension-puzzle-outline',
        name: 'integration',
        uid: 'integration',
      },
    ],
  };

  items: any[];

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'chevron-up-outline',
      label: 'use_as_fields',
      uid: 'use_as_fields',
    },
  ];

  service: any; // provided by modalCtrl

  settings: any = {
    format: 'csv',
    proxy: true,
    quote_character: '"',
    quote_characters: false,
    seperator: ',',
    use_first_row_as_fields: true,
  };

  source: any; // provided by modalCtrl

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  tableViewOptions: tableViewOptions = {
    mode: 'edit',
  };

  type: any; // provided by modalCtrl

  view: any = {
    buttonAction: 'export',
    canGenerate: false,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    introCard: {
      uid: 'data_importer_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'data_importer_top_card_text',
      title: 'data_importer_top_card_title',
    },
    multiple: true,
    newItem: {
      name: 'create_new',
      uid: 'new',
    },
    output: '',
    phase: 'edit',
    progress: 0,
    quote_characters: [
      {
        indent: '"',
        label: '"',
      },
      {
        indent: "'",
        label: "'",
      },
    ],
    route: 'ai/items',
    rowMappings: [

    ],
    sections: [],
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    sourceType: 'integration',
    tab: 'item',
    title: 'data_importer',
    viewType: 'table',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ],
  };

  constructor(
    private alert: AlertService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private loading: LoadingService,
    private media: MediaextendService,
    private modalService: ModalService,
    private objects: ObjectsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcFilters() {
    const blockKeys: string[] = ['checked', 'field_optimizations', 'image', 'photo', 'timestamp', 'uid', 'variations'];
    let filters: any[] = [], valuesByKey: any = {};

    if (!this.data || !this.data[0]) {
      return false;
    }

    const keys: string[] = Object.keys(this.data[0]).filter((key: string) => {
      return blockKeys.indexOf(key) === -1;
    });

    this.data.forEach((item: any) => {
      keys.forEach((key: string) => {
        const value: string = `${item[key] || ''}`;
        valuesByKey[key] = valuesByKey[key] || [];

        if (!!value && (valuesByKey[key].indexOf(value) === -1)) {
          valuesByKey[key].push(value);
        }
      });
    });

    keys.forEach((key: string) => {

      const values: any[] = (valuesByKey[key] || []).map((value: string) => {
        return { label: value, value: `${value || ''}` };
      });

      if (!!values && !!values.length) {
        filters.push({
          label: key,
          name: key,
          type: 'select',
          values: values,
          uid: key,
        });
      }
    });

    this.view.filters = (filters || []);
  }

  calcMappings() {
    console.log('calcMappings: data', this.data);
    console.log('calcMappings: view', this.view);
    console.log('calcMappings: view.dataKeys', this.view.dataKeys);
    console.log('calcMappings: view.mappingKeys', this.view.mappingKeys);
    console.log('calcMappings: view.rowMappings', this.view.rowMappings);

    this.calcViewVars();
  }

  calcParams() {
    return {
      data: this.data,
      settings: this.settings,
      //service: this.service,
      source: this.source,
      type: this.type,
    };
  }

  calcSelectedItems(item: any | null = null) {

    if (this.data && this.data.length) {
      this.view.selectedItems = this.data.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    console.log('calcSelectedItems: view.selectedItems', this.view.selectedItems);

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
    console.log('calcSelectedItems: view.hasSelectedItems', this.view.hasSelectedItems);

    this.calcViewVars();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canImport = (!!this.data && !!this.data.length) && !!this.view.hasSelectedItems && !!this.view.target && !!this.view.object_type;
    console.log('calcViewVars: view.canImport?', this.view.canImport);

    if (!this.view.canImport) {
      console.log('calcViewVars: data', this.data);
      console.log('calcViewVars: view.hasSelectedItems', this.view.hasSelectedItems);
      console.log('calcViewVars: view.target', this.view.target);
      console.log('calcViewVars: view.object_type', this.view.object_type);
    }

    // skip intro view if data is provided
    if (!this.view.startManually && (!!this.service && !!this.items && !!this.items.length)) {
      this.startManually();
    }
  }

  createObjectType(event: any | null = null) {
    return new Promise((resolve, reject) => {

      const type: any = {
        active: true,
        name: this.view.object_name,
        fields: (this.view.dataKeys || []),
      };

      console.log('createObjectType: type', type);

      this.view.loading = true;

      this.objects.createType(type)
        .then((response: any) => {
          this.events.publish('create-object-type: created', response);

          if (!!response && !!response.type && !!response.type.uid) {
            this.view.object_type = response.type.uid;
            this.view.object_type_item = response.type;
            console.log('this.view.object_type_item', this.view.object_type_item);
          }

          this.view.loading = false;
          resolve(response);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    })
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
  }

  import() {
    console.log('data-importer: import: settings', this.settings);
    console.log('data-importer: import: view', this.view);

    if (!this.view.target) {
      this.events.publish('error', 'error_missing_import_target');
    }

    console.log('data-importer: import: target', this.view.target);

    switch (this.view.target) {
      case 'custom':
        return this.importToObject();
      case 'integration':
        return this.importToIntegration();
      default:
        this.events.publish('error', 'error_missing_unknown_target');
        break;
    }

    return false;
  }

  importToIntegration() {

  }

  importToObject() {
    const items: any[] = (this.view.selectedItems || []);

    if (!items || !items.length || !this.view.object_type) {
      return false;
    }

    this.view.loading = true;

    this.requestUpdateObjectFields()
      .then(async (bl: boolean) => {
        console.log('requestUpdateObjectFields: should?', bl);

        if (this.view.object_type === 'new') {
          // if new object type is requested, create it first
          await this.createObjectType();
        } else
          if (!!bl) {
            // update object type fields before import if should
            await this.updateObjectTypeFields();
          }

        new Promise(async (resolve, reject) => {
          let iAll: number = this.view.selectedItems.length;
          let iDone: number = 0;
          let iPercentage: number = 0;

          const loadingOptions: loadingOptions = {
            headline: 'data_importer_loading_headline',
            icon: null,
            progress: 0,
            text: `data_importer_loading_text`,
          };

          const loadingModal: any = await this.loading.present(loadingOptions);

          setTimeout(() => {
            items.forEach(async (_item: any, index: number) => {
              setTimeout(async () => {
                try {
                  let item: any | null = null;

                  if (!!this.view.mappingKeys && !!this.view.mappingKeys.length) {
                    this.view.mappingKeys.forEach((key: string, index: number) => {
                      if (_item.hasOwnProperty(index)) {
                        item = item || {};
                        item[key] = _item[index];
                      }
                    });
                  } else {
                    item = _item;
                  }

                  if (!!item) {
                    const exec: any = await this.objects.createObject(item, this.view.object_type)
                    console.log('importItem: exec', exec);
                  }

                  iDone++;
                  iPercentage = parseFloat(`${((100 / iAll) * iDone).toFixed(2)}`);

                  if (iDone === iAll) {
                    loadingModal.dismiss();
                    resolve(true);
                  } else {
                    loadingModal.updateConfig(Object.assign(loadingOptions, {
                      progress: iPercentage,
                      text: `${iPercentage}% (${iDone} / ${iAll})`,
                    }));
                  }
                } catch (e) {
                  iDone++;
                  iPercentage = parseFloat(`${((100 / iAll) * iDone).toFixed(2)}`);

                  console.log('importItem failed', e);

                  if (iDone === iAll) {
                    loadingModal.dismiss();
                    resolve(true);
                  } else {
                    loadingModal.updateConfig(Object.assign(loadingOptions, {
                      headline: 'error',
                      progress: iPercentage,
                      text: `${e}`,
                    }));
                  }
                }
              }, (index * 100));
            });
          }, 1000);

        })
          .then(() => {
            this.view.loading = false;
            this.dismiss();
          })
          .catch((error: any) => {
            this.view.loading = false;
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load() {
    await this.loadProject();

    if (!!this.view.project) {
      await this.loadIntegrations();
    }

    // if all params are provided, skip intro config view
    if (!!this.data && !!this.service && !!this.source && !!this.type) {

      if (!this.data_backup) {
        this.data_backup = this.data;
      }

      this.calcFilters();
      this.calcMappings();
      this.startManually();
    }

    this.loadObjectTypes();
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.integrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadIntegrationSettings(blForceRefresh: boolean = false) {
    console.log('loadIntegrationSettings', this.view);
  }

  async loadObjectTypes(blForceRefresh: boolean = true) {
    try {
      const objectTypes: any = await this.objects.getTypes({}, blForceRefresh);
      this.view.object_types = (objectTypes || []);
    } catch (e) {
      console.warn('loading object types failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  async loadUrl(url: string | null = null) {
    url = url || this.view.url;

    if (!url) {
      return false;
    }

    this.view.loading = true;
    this.view.url = url;

    const validate: any = this.tools.validateUrl(url);

    if (!validate.success) {
      return false;
    }

    try {

      const load: any = await this.service.loadUrl(url, this.settings);

      this.data = (!!load && !!load.data ? load.data : []);

      // mark all entries as selected by default
      this.data = this.data.map((item: any) => {
        item.checked = true;
        return item;
      });

      this.view.dataKeys = (!!load && !!load.dataKeys ? load.dataKeys : []);
      this.view.mappingKeys = (!!load && !!load.mappingKeys ? load.mappingKeys : []);

      this.view.previewGridWidth = load.previewGridWidth || this.view.previewGridWidth;
      this.view.preview_data = (this.data || []).slice(0, 100);

      this.startManually();

      this.view.loading = false;
    } catch (e) {
      this.view.loading = false;
      console.warn('handling load url failed', e);
    }
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.view.viewType = 'table';

    this.loadCards();
    this.calcViewVars();
    this.load();
  }

  objectTypeChanged(event: any | null = null) {
    console.log('objectTypeChanged: view.object_type_item', this.view.object_type_item);

    if (!!this.view.object_type_item && !!this.view.object_type_item.uid) {
      this.view.object_type = this.view.object_type_item.uid;
    }

    this.calcMappings();
  }

  onFieldsChanged(event: any | null = null) {
    this.view.dataKeys = (event || []);

    console.log('onFieldsChanged: view.dataKeys', this.view.dataKeys);

    this.view.mappingKeys = (this.view.dataKeys).map((field: any) => {
      return field.name || field.uid;
    });

    console.log('onFieldsChanged: mappingKeys', this.view.mappingKeys);

    this.calcMappings();
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
  }

  onFormatChanged() {
    console.log('onFormatChanged', this.settings.format);

    switch (this.settings.format) {
      case 'csv':
        this.view.seperator = 'comma';
        break;
      case 'tsv':
        this.view.seperator = 'tab';
    }
  }

  async onIntegrationChanged(event: any | null = null) {
    this.view.integrationName = (!!this.view.integration ? `${this.view.integration.name}`.replace('integration_', '') : null);
    console.log('this.view.integrationName', this.view.integrationName);

    switch (this.view.integrationName) {
      case 'ftp':
        this.pickConnection();
        break;
    }

    this.startManually();

    await this.loadIntegrationSettings();
  }

  onItemsChanged(items: any[] = []) {
    console.log('onItemsChanged: items', items);

    this.view.selectedItems = (items || []);
    console.log('onItemsChanged: view.selectedItems', this.view.selectedItems);

    this.calcSelectedItems();
  }

  onLanguageChange(event: string | null = null) {
    this.view.language = event;
    this.saveFilters();
  }

  onOverlayInputChanged(event: any = null) {
    console.log('onOverlayInputChanged', event);
  }

  pickConnection() {
    console.log('pickConnection', this.view.integrationName);
  }

  renderCsvContent() {
    this.loadUrl();
  }

  requestUpdateObjectFields() {
    return new Promise(async (resolve, reject) => {
      console.log('requestUpdateObjectFields: view', this.view);

      if (!!this.view && !!this.view.object_type && (this.view.object_type === 'new')) {
        resolve(false);
      } else {
        try {
          await this.alert.requestConfirm({
            headline: 'request_update_object_fields_headline',
            message: 'request_update_object_fields_text',
            buttons: [
              {
                handler: () => {
                  resolve(true);
                },
                role: 'submit',
                text: 'okay',
              },
              {
                handler: () => {
                  resolve(false);
                },
                role: 'cancel',
                text: 'cancel',
              },
            ],
          });
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      //this.media.saveFilters(this.view.filters);
    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  seperatorChanged() {
    this.renderCsvContent();
  }

  sourceTypeChanged(event: any | null = null) {
    let type: any = (!!event && !!event.detail && !!event.detail.hasOwnProperty('value') ? event.detail.value : event);

    if (!!type && !!type.uid) {
      type = type.uid;
    }

    if (!type || !type.length) {
      return false;
    }

    switch (type) {
      case 'file':
        this.upload();
        break;
    }
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  targetChanged(event: any | null = null) {
    this.calcViewVars();
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updateObjectTypeFields() {
    return new Promise(async (resolve, reject) => {
      if (!this.view.object_type_item || !this.view.object_type_item.uid) {
        reject('error_missing_object_type_uid');
      } else {
        try {
          this.view.object_type_item.fields = (this.view.dataKeys || []);

          const exec: any = await this.objects.updateType(this.view.object_type_item);
          console.log('updateObjectTypeFields: exec', exec);

          resolve(exec);
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  upload() {

    const params: any = {
      mediaType: 'document',
      multiple: false,
      services: ['media_library', 'upload'],
    };

    this.media.applyFromWeb(null, params)
      .then((response: any) => {

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].guid) {
          this.loadUrl(response.items[0].guid)
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useFirstRowAsFieldsChanged(event: any | null = null) {
    this.renderCsvContent();
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}