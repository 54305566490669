<div class="tool-intro-wrapper" *ngIf="!view.startManually">
  <div class="container">

    <ion-card>

      <!-- Header -->
      <ion-card-header>
        <ion-card-title [innerHTML]="config.headline|translate"></ion-card-title>
      </ion-card-header>

      <!-- Input textarea -->
      <ion-textarea *ngIf="!!config.showInput" [rows]="config.input_type === 'url' ? 1 : 6"
        [(ngModel)]="view.aiCreateInput" [disabled]="view.loading"
        [placeholder]="config.input_placeholder|translate"></ion-textarea>

      <!-- Full loading (if ai generate is disabled) -->
      <ion-spinner *ngIf="!config.showAiCreate" [hidden]="!view.loading"></ion-spinner>

      <!-- Sources -->
      <ion-list [hidden]="!!view.loading" *ngIf="!!config.showSources && (!!config.sources && !!config.sources.length)">
        <ion-item lines="none">

          <!-- Selected source icon -->
          <ion-icon [name]="!!config.source && !!config.source.icon ? config.source.icon : 'server-outline'"
            slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [label]="'source'|translate" [(ngModel)]="config.source" (ionChange)="_onSourceChanged($event)">
            <ion-select-option *ngFor="let source of config.sources; trackBy:trackItems" [value]="source"
              [innerHTML]="source.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

      </ion-list>

      <!-- Integrations -->
      <ion-list *ngIf="!!config.showIntegrations && (!!view.integrations && !!view.integrations.length)"
        [hidden]="!!view.loading || ((!!config.sourceRequired || (!!config.sources && !!config.sources.length)) && (!config.source || (config.source.uid !== 'integration')))">
        <ion-item lines="none">

          <!-- Selected integration icon -->
          <ion-icon
            [name]="!!view.integration && !!view.integration.icon ? view.integration.icon : 'extension-puzzle-outline'"
            slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [label]="'integration'|translate" [(ngModel)]="view.integration"
            (ionChange)="_onIntegrationChanged($event)">
            <ion-select-option *ngFor="let integration of view.integrations; trackBy:trackItems" [value]="integration"
              [innerHTML]="integration.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

      </ion-list>

      <ion-grid>
        <ion-row>

          <!-- Generate -->
          <ion-col [size]="view.isDesktop ? 6 : 12" *ngIf="config.showAiCreate">

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
              [disabled]="!view.aiCreateInput || !view.aiCreateInput.length">
              <ion-icon name="sparkles-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="(config.submitLabel || 'generate')|translate"></ion-label>
            </ion-button>
          </ion-col>

          <!-- AI settings -->
          <ion-col [size]="view.isDesktop ? 6 : 12" *ngIf="config.showAiSettings">

            <pipeline-ai-settings-picker [config]="aiSettings" size="block" fill="clear" [mini]="false" [view]="view"
              (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

          </ion-col>

          <!-- Generate manually -->
          <ion-col size="12" *ngIf="config.allowManually">

            <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'manually'|translate"></ion-label>
            </ion-button>

          </ion-col>

        </ion-row>
      </ion-grid>

    </ion-card>

  </div>
</div>