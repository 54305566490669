import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class TrendsService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  get(options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
    }), ['statistics', 'trends']);
  }

  getAll(options: any = {}) {
    return this.get();
  }

  getByHost(host: string) {
    return this.get({
      host: host,
    });
  }

  getByTimeRange(from: number, to: number) {
    return this.get({
      range: {
        from: from,
        to: to,
      }
    });
  }

}
