<div *ngIf="!!item" class="ratings-buttons">

  <!-- Thumbs up -->
  <ion-button [hidden]="item.loading" [disabled]="!!disabled" icon-only slot="end"
    [color]="item.rating === 1 ? 'success' : 'dark'" size="small" fill="clear" (click)="thumb(1)">
    <ion-icon [name]="item.rating === 1 ? 'thumbs-up' : 'thumbs-up-outline'"></ion-icon>
  </ion-button>

  <!-- Thumbs down -->
  <ion-button [hidden]="item.loading" [disabled]="!!disabled" icon-only slot="end"
    [color]="item.rating === -1 ? 'danger' : 'dark'" size="small" fill="clear" (click)="thumb(-1)">
    <ion-icon [name]="item.rating === -1 ? 'thumbs-down' : 'thumbs-down-outline'"></ion-icon>
  </ion-button>

  <!-- Favourite -->
  <ion-button [hidden]="item.loading" [disabled]="!!disabled" icon-only slot="end" color="warning" size="small"
    fill="clear" (click)="favorite()">
    <ion-icon [name]="!!item.favorite ? 'star' : 'star-outline'"></ion-icon>
  </ion-button>

</div>