<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">
    </ion-buttons>
    
  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container" [hidden]="view.loading">

  </div>

</ion-content>