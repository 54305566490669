import { Directive, Input, OnInit, OnDestroy, ElementRef, HostListener } from '@angular/core'; // Renderer2
import { Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[drawing]',
    standalone: false
})
export class DrawingDirective implements OnInit, OnDestroy {
    @Input() canvas: any;
    @Input() config: drawingConfig;
    @Input() overlay: any;

    private ctx: any;
    private ctxo: any;

    private drawing: boolean;

    private lastX: number;
    private lastY: number;

    offsetX: number;
    offsetY: number;

    prevStartX: number = 0;
    prevStartY: number = 0;
    prevWidth: number = 0;
    prevHeight: number = 0;

    private startX: number;
    private startY: number;

    /* Configuration */

    private lineWidth: number = 50;

    private strokeColor: string = '#000000'; // "#A56B3D";

    /* End configuration */

    @Output() onChanged: any = new EventEmitter();
    @Output() onStop: any = new EventEmitter();

    constructor(
        private el: ElementRef,
    ) {

    }

    ngOnInit() {

        // main canvas settings
        this.ctx = (!!this.canvas && !!this.canvas.nativeElement ? this.canvas.nativeElement.getContext('2d') : this.el.nativeElement.getContext('2d'));
        console.log('this.ctx', this.ctx);

        if (!!this.ctx) {
            //this.ctx.lineJoin = 'round';
            this.ctx.lineWidth = this.lineWidth;
            this.ctx.miterLimit = 2;
        }

        // overlay settings
        if (!!this.overlay) {
            console.log('this.overlay', this.overlay);
            this.ctxo = this.overlay.getContext('2d');

            if (!!this.ctxo) {
                //this.ctxo.lineJoin = 'round';
                this.ctxo.lineWidth = this.lineWidth;
                this.ctxo.miterLimit = 2;
            }
        }
    }

    ngOnDestroy() {

    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {

        // free mode
        if (!this.config || !this.config.mode || (this.config.mode !== 'squares')) {
            this.lastX = event.offsetX;
            this.lastY = event.offsetY;
            this.ctx.beginPath();
        } else {
            // suqares mode
            this.startX = event.clientX - this.offsetX;
            this.startY = event.clientY - this.offsetY;
        }

        this.drawing = true;
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.drawing) return;

        // free mode:
        if (!this.config || !this.config.mode || (this.config.mode !== 'squares')) {
            this.draw(this.lastX, this.lastY, event.offsetX, event.offsetY);

            this.lastX = event.offsetX;
            this.lastY = event.offsetY;
        } else {
            // squares mode

            const canvasOffset = this.el.nativeElement.getBoundingClientRect();

            this.offsetX = canvasOffset.left;
            this.offsetY = canvasOffset.top;

            const   mouseX: number = (event.clientX - this.offsetX),
                    mouseY: number = (event.clientY - this.offsetY),
                    width = mouseX - this.startX,
                    height = mouseY - this.startY;

            this.ctxo.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
            this.ctxo.fillRect(this.startX, this.startY, width, height); // strokeRect

            this.prevStartX = this.startX;
            this.prevStartY = this.startY;

            this.prevWidth = width;
            this.prevHeight = height;
        }

    }

    @HostListener('mouseout', ['$event'])
    onMouseOut(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.drawing = false;
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.drawing = false;

        if (!this.config || !this.config.mode || (this.config.mode !== 'squares')) {
            // free mode:
        } else {
            // squares mode:
            if (!!this.ctxo) {
                this.ctx.fillRect(this.prevStartX, this.prevStartY, this.prevWidth, this.prevHeight); // strokeRect
                this.ctxo.fillRect(this.prevStartX, this.prevStartY, this.prevWidth, this.prevHeight); // strokeRect
            }
        }

        this.onStop.emit({
            event: event,
            ctx: this.ctx,
        });
    }

    private draw(lX: number, lY: number, cX: number, cY: number) {
        this.ctx.moveTo(lX, lY);
        this.ctx.lineTo(cX, cY);

        this.ctx.fillStyle = this.strokeColor;
        this.ctx.strokeStyle = this.strokeColor;

        this.ctx.stroke();

        this.onChanged.emit({
            coords: [cX, cY],
            ctx: this.ctx,
        });
    }

}