import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { UserService } from './user.service';

//import { createAction, props } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class ClassificationsService {

    constructor(
        private AppCMS: AppcmsService,
        private userService: UserService,
    ) {

    }

    cleanClassifications(classifications: userClassifications) {
        classifications = JSON.parse(JSON.stringify(classifications));

        if (classifications.settings) {
            if (classifications.settings.woo_tabs) {
                classifications.settings.woo_tabs.forEach((tab: tab, index: number) => {
                    delete tab.streamlineIcon;
                });
            }
        }

        return classifications;
    }

    getByClassification(Key: string, Value: any, forceReload: boolean = false) {
        return this.AppCMS.loadPluginData('classification', {}, ['customers', Key, Value]);
    };

    getClassification(classification: string) {
        const user: user = this.userService.getUser(), classifications = user.classifications || {};
        return classifications && classifications.hasOwnProperty(classification) ? classifications[classification] : null;
    }

    setClassification(key: string, value: any, user: user | null = null) {
        user = user || this.userService.getUser();
        user.classifications = (user.classifications || {}) as userClassifications;
        user.classifications[key] = value;

        return this.userService.setUser(user, true);
    }

    setClassifications(classifications: object, user: user | null = null) {
        user = user || this.userService.getUser();
        user.classifications = (user.classifications || {}) as userClassifications;

        const keys: string[] = Object.keys(classifications);

        keys.forEach((key: string) => {
            user.classifications[key] = classifications[key];
        });

        return this.userService.setUser(user, true);
    }

}