<ion-grid>
  <ion-row>
    <ion-col>
      <ion-button expand="block" (click)="back()" fill="clear" [disabled]="!view.canSlideBack">
        <ion-icon name="chevron-back-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'prev'|translate"></ion-label>
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button expand="block" (click)="next()" [disabled]="!view.canSlideNext">
        <ion-label [innerHTML]="'next'|translate"></ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>