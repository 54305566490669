import { Injectable } from '@angular/core';

import { fetchEventSource } from '@microsoft/fetch-event-source';

@Injectable({
    providedIn: 'root'
})
export class EventSourceService {
    private eventSource: EventSource | null;

    constructor(
    ) {

    }

    connectToServerSentEvents(url: string, options: any, eventNames: string[] = []) {
        return new Promise(async (resolve, reject) => {
            let finalResponse: any, iPartIndex: number = 0, messages: any[] = [], output: any;

            await fetchEventSource(url, Object.assign(options, {
                method: 'POST',

                headers: options.headers || {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(options.data || {}),

                async onopen(response: any) {
                    console.log('onopen: response', response);

                    if (response.ok) {
                        finalResponse = response;
                        //resolve(response);
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        // client-side errors are usually non-retriable:
                        reject(response.status);
                    } else {
                        reject('unknown_error');
                    }
                },

                onmessage(msg: any) {
                    if (msg.event === 'FatalError') {
                        reject(msg.data);
                    } else {
                        messages.push(msg);
                        output = msg.data;

                        if (!!options.hasOwnProperty('onPartialResult')) {
                            options.onPartialResult(msg.data, iPartIndex);
                            iPartIndex++;
                        }
                    }
                },

                onclose() {
                    // if the server closes the connection unexpectedly, retry:
                    //reject('error_closed');
                    
                    resolve({
                        messages: messages,
                        output: output,
                        response: finalResponse,
                        success: !!output && !!finalResponse,
                    });
                },

                onerror(err: any) {
                    console.log('onerror: err', err);
                    reject(err);
                }
            }));
        });
    }

    close(): void {
        if (!this.eventSource) {
            return;
        }

        this.eventSource.close();
        this.eventSource = null;
    }

}