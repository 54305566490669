<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="templateStore()" fill="clear" color="dark">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="add($event)" shape="round" [color]="view.isDesktop ? 'primary' : 'dark'"
        [fill]="view.isDesktop ? 'solid' : 'clear'">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <ion-toolbar *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    class="ion-no-border container second-toolbar">
    <ion-searchbar autocorrect="on" [(ngModel)]="search.query" #searchInput id="searchInput"
    [placeholder]="'search_something'|translate" (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>
  
  <!-- Mobile selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!view.isDesktop && !!view.templates && !!view.templates.length" [(items)]="view.templates"
    [(view)]="view" [options]="selectionOptions"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)" [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project picker -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile intro card -->
    <pipeline-intro-card [hidden]="!!view.isDesktop || view.introCard.hidden" class="introCard" [(view)]="view.introCard"></pipeline-intro-card>

    <ion-grid class="media-templates-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="templates" [(view)]="view" location="templates" (onChanged)="loadTemplatesByFolder()"></pipeline-folders-filter-card>

          <!-- Filters -->
          <pipeline-items-filters-card [(cards)]="cards" [(view)]="view" (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

          <!--
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>
              <ion-card-title [innerHTML]="'filters'|translate"></ion-card-title>
            </ion-card-header>

            <ion-list>

            <ion-item lines="none">
              <ion-icon
                [name]="view.options.filter.type === 'image' ? 'image-outline' : (view.options.filter.type === 'video' ? 'film-outline' : 'images-outline')"
                [hidden]="!view.isDesktop" slot="start"></ion-icon>
              <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" labelPlacement="stacked"
                [(ngModel)]="view.options.filter.type" (ionChange)="typeChanged()" [label]="'type'|translate"
                [placeholder]="'select'|translate">
                <ion-select-option [innerHTML]="'all'|translate" [value]="null"></ion-select-option>
                <ion-select-option [innerHTML]="'image'|translate" value="image"></ion-select-option>
                <ion-select-option [innerHTML]="'video'|translate" value="video"></ion-select-option>
              </ion-select>
            </ion-item>
            
            <ion-item lines="none">
              <ion-icon name="layers-outline" slot="start" [hidden]="!view.isDesktop"></ion-icon>
              <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" labelPlacement="stacked"
                [(ngModel)]="view.options.filter.custom" (ionChange)="filterChanged()" [label]="'format'|translate"
                [placeholder]="'select'|translate">
                <ion-select-option [innerHTML]="'all'|translate" [value]="null"></ion-select-option>
                <ion-select-option [innerHTML]="'media_template_format_aep'|translate" [value]="0"></ion-select-option>
                <ion-select-option [innerHTML]="'media_template_format_html5'|translate"
                  [value]="1"></ion-select-option>
              </ion-select>
            </ion-item>
            
            <ion-item lines="none">
              <ion-icon name="eye-outline" slot="start" [hidden]="!view.isDesktop"></ion-icon>
              <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" labelPlacement="stacked"
                [(ngModel)]="view.options.filter.public" (ionChange)="filterChanged()"
                [label]="'public_visible'|translate" [placeholder]="'select'|translate">
                <ion-select-option [innerHTML]="'all'|translate" [value]="null"></ion-select-option>
                <ion-select-option [innerHTML]="'yes'|translate" [value]="1"></ion-select-option>
                <ion-select-option [innerHTML]="'no'|translate" [value]="0"></ion-select-option>
              </ion-select>
            </ion-item>
            </ion-list>

          </ion-card>
          -->

          <!-- Tags -->
          <pipeline-tags-filter-card
            [(cards)]="cards" [service]="templates" [(view)]="view"
            (selectionChanged)="onSelectedTagChanged($event)"></pipeline-tags-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Desktop selection toolbar -->
          <pipeline-selection-toolbar *ngIf="!!view.isDesktop && !!view.templates && !!view.templates.length" [(items)]="view.templates"
            [(view)]="view" [options]="selectionOptions"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <!-- Intro card -->
          <pipeline-intro-card [hidden]="!view.isDesktop || view.introCard.hidden" class="introCard" [(view)]="view.introCard"></pipeline-intro-card>

          <!-- No templates card -->
          <ion-card class="noTemplatesCard" *ngIf="view.templates && !view.templates.length">

            <ion-grid>

              <ion-row>
                <ion-col>

                  <ion-card-header>
                    <ion-card-title [innerHTML]="'media_project_templates_empty_card_title'|translate"></ion-card-title>
                  </ion-card-header>

                  <ion-card-content>
                    <p [innerHTML]="'media_project_templates_empty_card_text'|translate"></p>
                  </ion-card-content>

                </ion-col>
              </ion-row>

            </ion-grid>

            <ion-card-content>

              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-card>
                      <ion-button size="block" fill="clear" (click)="templateStore()" color="dark">
                        <ion-icon name="cloud-download-outline" color="primary"></ion-icon>
                        <ion-label [innerHTML]="'import'|translate"></ion-label>
                      </ion-button>
                    </ion-card>
                  </ion-col>
                  <ion-col>
                    <ion-card>
                      <ion-button size="block" fill="clear" (click)="add()" color="dark">
                        <ion-icon name="add-outline" color="primary"></ion-icon>
                        <ion-label [innerHTML]="'create'|translate"></ion-label>
                      </ion-button>
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>

            </ion-card-content>

          </ion-card>

          <!-- Loading spinner -->
          <ion-spinner name="circular" *ngIf="!view.templates"></ion-spinner>

          <!-- Templates grid -->
          <ion-grid>
            <ion-row>
              <ion-col *ngFor="let template of view.templates; let i = index; trackBy:trackItems"
                [class.isGrid]="view.viewType === 'grid'"
                [size]="view.viewType === 'grid' && !!view.isDesktop ? view.colSize.item : 12">

                <ion-card>
                  <ion-list>

                    <ion-item lines="none">

                      <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="template.checked"
                        [hidden]="!template.uid" (ionChange)="onItemChecked(template)">
                        <p [innerHTML]="template.name" class="ion-text-wrap"></p>
                      </ion-checkbox>

                      <ion-skeleton-text [hidden]="!!template.uid"
                        style="float:left;width:60%;margin-right: 10px;"></ion-skeleton-text>
                      <ion-skeleton-text [hidden]="!!template.uid" style="float:left;width:30%;"></ion-skeleton-text>

                      <ion-button slot="end" (click)="presentPopover($event, template)" fill="clear" icon-only
                        color="dark" *ngIf="(view.mode !== 'pick') && !!template.uid">
                        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                      </ion-button>

                    </ion-item>

                    <ion-item-sliding>
                      <ion-item lines="none">

                        <pipeline-media-template-preview slot="start"
                          *ngIf="!view.modalVisible && (view.scrollIndex >= (i-10)) && !!template.custom && !!template.config && !!template.config.aspect_ratios"
                          [template]="view.templates[i]" [view]="template.view"
                          (click)="edit(template)"></pipeline-media-template-preview>

                        <pipeline-video-template-preview slot="start"
                          *ngIf="(view.scrollIndex >= (i-10)) && !template.custom && template.config && template.config._meta"
                          [template]="view.templates[i]" (click)="edit(template)"></pipeline-video-template-preview>

                        <ion-thumbnail *ngIf="!template.custom && (!template.config || !template.config._meta)"
                          (click)="edit(template)" slot="start">
                          <ion-img *ngIf="template.photo || template.thumbnail"
                            [src]="template.photo || template.thumbnail"
                            (ionError)="thumbnailLoadingfailed(template)"></ion-img>
                          <ion-skeleton-text [animated]="(!template.local_uid && !template.uid)" class="image"
                            [hidden]="template.photo || template.thumbnail"></ion-skeleton-text>
                        </ion-thumbnail>

                        <ion-skeleton-text [hidden]="!!template.uid"
                          style="float:left;width:25%;margin-right: 10px;"></ion-skeleton-text>
                        <ion-skeleton-text [hidden]="!!template.uid" style="float:left;width:55%;"></ion-skeleton-text>

                      </ion-item>

                      <ion-item-options slot="end" *ngIf="!view.isDesktop && (template.uid || template.local_uid)">

                        <ion-item-option *ngIf="!!template.uid || !!template.local_uid" (click)="edit(template)">
                          <ion-icon name="create-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'edit'|translate"></ion-label>
                        </ion-item-option>

                        <ion-item-option *ngIf="!!view.canPublish && !!template.uid" (click)="publishTemplate(template)"
                          [hidden]="template.type !== 'review'">
                          <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                          <ion-label class="ion-text-wrap" [innerHTML]="'publish'|translate"></ion-label>
                        </ion-item-option>

                        <ion-item-option *ngIf="!!view.canDelete && (!!template.uid || !!template.local_uid)"
                          color="danger" (click)="delete(template)">
                          <ion-icon name="trash-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="'delete'|translate"></ion-label>
                        </ion-item-option>

                      </ion-item-options>
                    </ion-item-sliding>

                    <ion-item lines="none" [hidden]="view.viewType !== 'grid'">

                      <ion-button [hidden]="!view.isDesktop" *ngIf="!!template.uid || !!template.local_uid" slot="end"
                        fill="clear" color="dark" (click)="duplicate(template)">
                        <ion-icon name="copy-outline" slot="start"></ion-icon>
                      </ion-button>

                      <ion-button [hidden]="!view.isDesktop" *ngIf="!!template.uid || !!template.local_uid" slot="end"
                        fill="clear" color="dark" (click)="edit(template)">
                        <ion-icon name="create-outline" slot="start"></ion-icon>
                      </ion-button>

                      <ion-button *ngIf="!!view.canPublish && !!template.uid" slot="end" fill="clear" color="dark"
                        (click)="publishTemplate(template)" [hidden]="!view.isDesktop || template.type !== 'review'">
                        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                      </ion-button>

                      <ion-button [hidden]="!view.isDesktop"
                        *ngIf="!!view.canDelete && (!!template.uid || !!template.local_uid)" slot="end" fill="clear"
                        color="danger" (click)="delete(template)">
                        <ion-icon name="trash-outline" slot="start"></ion-icon>
                      </ion-button>

                    </ion-item>

                  </ion-list>
                </ion-card>

              </ion-col>
            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>