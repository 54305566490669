import { Injectable } from '@angular/core';

import { AiConfigService } from '../ai/ai-config.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class StablediffusionService {

  aiSettings: aiSettings = {
    auto_upscale: true,
    sampler_name: 'auto',
    use_safety_checker: true,
  };

  samplers: any[] = [
    {
      name: 'DDIM',
      uid: 'DDIM',
    },
    {
      name: 'DPM2 a',
      uid: 'DPM2 a',
    },
    {
      name: 'DPM++ 2S a',
      uid: 'DPM++ 2S a',
    },
    {
      name: 'DPM++ 2S a Karras',
      uid: 'DPM++ 2S a Karras',
    },
    {
      name: 'Euler',
      uid: 'Euler',
    },
    {
      name: 'Euler A',
      uid: 'EulerA',
    },
    {
      name: 'Heun',
      uid: 'Heun',
    },
    {
      name: 'k-diffusion',
      uid: 'k-diffusion',
    },
    {
      name: 'LMS',
      uid: 'LMS',
    },
    {
      name: 'PLMS',
      uid: 'PLMS',
    },
    {
      name: 'UniPC',
      uid: 'UniPC',
    },
  ];

  constructor(
    private aiConfig: AiConfigService,
    private AppCMS: AppcmsService,
  ) {
  }

  audioToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['audioToText'], {}, blForceRefresh);
  }

  getAvailableInstances(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('stablediffusion', options, ['instances'], {}, blForceRefresh);
  }

  getDefaultAiOptions() {
    return this.aiSettings;
  }

  getSamplers() {
    return this.samplers;
  }

  imageToImage(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToImage'], {}, blForceRefresh);
  }

  imageToText(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToText'], {}, blForceRefresh);
  }

  imageToVideo(url: string, searchParams: any = {}, blForceRefresh: boolean = true) {
    searchParams = searchParams || {};
    searchParams.options = searchParams.options || this.aiConfig.get();
    searchParams.queue = true;
    searchParams.url = `${url}`;

    return this.AppCMS.loadPluginData('stablediffusion', searchParams, ['imageToVideo'], {}, blForceRefresh);
  }

  imageVariations(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageVariations'], {}, blForceRefresh);
  }

  inpaint(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['inpaint'], {}, blForceRefresh);
  }

  outpaint(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['outpaint'], {}, blForceRefresh);
  }

  imageToDepth(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToDepth'], {}, blForceRefresh);
  }

  imageToMask(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToMask'], {}, blForceRefresh);
  }

  imageToSegmentation(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToSegmentation'], {}, blForceRefresh);
  }

  speechToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

  sv3d(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['sv3d'], {}, blForceRefresh);
  }

  sv4d(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['sv4d'], {}, blForceRefresh);
  }

  textToVideo(input: string, options: any = {}, blForceRefresh: boolean = false) {
    options.input = `${input}`;
    options.queue = true;

    return this.AppCMS.loadPluginData('stablediffusion', options, ['textToVideo'], {}, blForceRefresh);
  }

  transcribe(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

  upscale(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.inputFile = `${url}`;
    options.outputFile = `${url}`;

    return this.AppCMS.loadPluginData('stablediffusion', options, ['upscale'], {}, blForceRefresh);
  }

  videoToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

}