import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { NoEntriesCardComponent } from './no-entries-card.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    NoEntriesCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [
    NoEntriesCardComponent,
  ]
})
export class NoEntriesCardComponentModule { }