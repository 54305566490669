import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { WindowManagerLoaderService } from './window-manager-loader.service';

@Injectable({
    providedIn: 'root'
})
export class WindowManagerService {

    appConfig: pipelineAppConfig;

    constructor(
        private configService: ConfigService,
        private loader: WindowManagerLoaderService,
        private modalService: ModalService,
        private navCtrl: NavController,
        private tools: ToolsService,
    ) {
        this.appConfig = this.configService.getConfig();
    }

    init() {
        return this.loader.init();
    }

    open(params: windowManagerParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const blIsDesktop: boolean = this.tools.isDesktop();

                // if window manager is enabled and component is not provided, try loading it using loader first
                if (!!this.appConfig.useWindowManagerExtension && !params.component) {
                    let tool: appPage | null = await this.loader.byRoute(params.route);

                    if (!tool && !!params.uid) {
                        console.warn('[ WINDOW MANAGER LOADER ] Tool by route not found, looking up by uid', params);
                        tool = await this.loader.byUid(params.uid);
                    }

                    if (!tool) {
                        console.warn('[ WINDOW MANAGER LOADER ] Tool not found: params', params);

                        if (!!params.uid) {
                            // lookup component by uid
                            const component: any | null = this.loader.getCcomponentByUid(params.uid);

                            if (!!component) {
                                params.component = component;
                            } else {
                                console.warn('[ WINDOW MANAGER LOADER ] Component not found: params', params);
                            }
                        }
                    }

                    if (!!tool && !!tool.component) {
                        params.component = tool.component;
                    }
                }

                // if window manager is disabled or no component provided or current device is mobile, just redirect
                if (!this.appConfig.useWindowManagerExtension || !params.component || !blIsDesktop) {

                    // close all modals so the new route is visible
                    this.modalService.closeAll();

                    // redirect to new route
                    this.navCtrl.navigateForward(params.route);

                    return false;
                }

                // else, handle as modal
                const modal: any = await this.modalService.create({
                    component: params.component,
                    componentProps: params.componentProps,
                });

                this.modalService.present(modal);

                return modal;
            } catch (e) {
                reject(e);
            }
        });
    }

}