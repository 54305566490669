import { Component, Input, OnInit } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'pipeline-header',
  standalone: false,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() state: state;
  @Input() view: any;

  appConfig: pipelineAppConfig;

  avatarUrl: string;

  @Input() selectionOptions: selectionOption[];

  user: user;

  constructor(
    private configService: ConfigService,
    private userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser() || {};
  }

  deleteSelected() {

  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
    }

  }

}