<ion-grid *ngIf="!!state && !!state.pairs">
  <ion-row *ngFor="let pair of state.pairs">
    <ion-col *ngIf="pair[0]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)">
      <pipeline-post-card [post]="pair[0]" [selectionOptions]="selectionOptions"
        [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)" *ngIf="pair[0] && !pair[0].stats"></pipeline-post-card>
      <pipeline-ad [ad]="pair[0]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)"
        *ngIf="pair[0] && !!pair[0].stats"></pipeline-ad>
    </ion-col>
    <ion-col *ngIf="pair[1]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)">
      <pipeline-post-card [post]="pair[1]" [selectionOptions]="selectionOptions"
        [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)" *ngIf="pair[1] && !pair[1].stats"></pipeline-post-card>
      <pipeline-ad [ad]="pair[1]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)"
        *ngIf="pair[1] && !!pair[1].stats"></pipeline-ad>
    </ion-col>
    <ion-col *ngIf="pair[2]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)">
      <pipeline-post-card [post]="pair[2]" [selectionOptions]="selectionOptions"
        [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)" *ngIf="pair[2] && !pair[2].stats"></pipeline-post-card>
      <pipeline-ad [ad]="pair[2]" [size]="pair[2] ? 4 : (pair[1] ? 6 : 12)"
        *ngIf="pair[2] && !!pair[2].stats"></pipeline-ad>
    </ion-col>
  </ion-row>
</ion-grid>