import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';

import { MediaTemplatePreviewComponent } from './media-template-preview.component';
import { MediaTemplatePreviewLayerComponentModule } from '../media-template-preview-layer/media-template-preview-layer.component.module';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MediaTemplatePreviewComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    DragDropModule,
    FormsModule,
    MediaTemplatePreviewLayerComponentModule,
    TranslateModule,
  ],
  exports: [
    MediaTemplatePreviewComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class MediaTemplatePreviewComponentModule { }