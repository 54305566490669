import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LoadingController } from "@ionic/angular";
import { NavController } from '@ionic/angular';

import { BrowserService } from "src/app/services/utils/browser.service";
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { ShopAdminService } from 'src/app/services/ecommerce/shop-admin.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { ViewService } from 'src/app/services/core/view.service';

import { ConnectShopPage } from 'src/app/pages/extensions/shop/connect-shop/connect-shop.page';
import { EditShopSourcePage } from 'src/app/pages/extensions/shop/edit-shop-source/edit-shop-source.page';

@Component({
  selector: 'app-creator-shops',
  standalone: false,
  templateUrl: './creator-shops.page.html',
  styleUrls: ['./creator-shops.page.scss'],
})
export class CreatorShopsPage implements OnDestroy, OnInit {

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
  ];

  cta: any = {
    handler: () => {
      this.addShop();
    },
    icon: 'link-outline',
    label: 'connect',
  };

  options: dynamicViewPageOptions = {
    labelKey: 'title',
    showPhoto: true,
    showViewModeSelect: true,
  };

  pages: any = {};

  search: searchOptions = {
    itemsKey: 'shops',
    keys: ['indent', 'language', 'name', 'post_content', 'host', 'title', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'cloud-download-outline',
      label: 'import',
      uid: 'import',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'export',
      uid: 'export',
    },
  ];

  state: state = {};

  view: any = {
    forceProjectToBeSet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      text: 'creators_shop_admin_text',
      title: 'creators_shop_admin_headline',
      uid: 'creators_shop_admin_card',
    },
    isModal: false,
    itemSize: 64,
    multiple: true,
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    key: ['name', 'url'],
    shops: [],
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'creators_shop_admin',
    viewType: 'list',
  };

  user: user;

  constructor(
    private browser: BrowserService,
    private creators: CreatorsService,
    private events: EventsService,
    private loading: LoadingController,
    private navCtrl: NavController,
    private modalService: ModalService,
    private projects: ProjectsService,
    public shopAdmin: ShopAdminService,
    private sources: SourcesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.user = this.userService.getUser() || {};

    this.view.isModal = this.modalService.isModal();
  }

  async addShop() {

    const shopModal: any = await this.modalService.create({
      component: ConnectShopPage,
      animated: true,
      cssClass: 'defaultModal',
    });

    shopModal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(shopModal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.detectChanges();
  }

  delete(shop: shopSource, event: any) {
    this.shopAdmin.deleteSource(shop)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteSource',
      items: this.view.selectedItems,
      service: this.shopAdmin,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
    */
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadShops(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  edit(shop: shopSource, event: any) {
    this.navCtrl.navigateForward(`/edit-shop/${shop.uid}`);
  }

  async exportSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const loading: any = await this.loading.create({
      backdropDismiss: false,
      spinner: 'circular',
    });

    loading.present();

    this.tools.bulk({
      action: 'exportSourceData',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this.shopAdmin,
    })
      .then(() => {
        loading.dismiss();
        this.doRefresh();
      })
      .catch((error: any) => {
        loading.dismiss();

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async importSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const loading: any = await this.loading.create({
      backdropDismiss: false,
      spinner: 'circular',
    });

    loading.present();

    this.tools.bulk({
      action: 'importSourceData',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this.shopAdmin,
    })
      .then(() => {
        loading.dismiss();
        this.doRefresh();
      })
      .catch((error: any) => {
        loading.dismiss();

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShops(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {

      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.shops = [];
        resolve(this.view);
      } else {
        this.view.shops = JSON.parse(JSON.stringify(this.view.placeholders));
        this.detectChanges();

        this.creators.getShops(blForceRefresh)
          .then((shops: shopSource[]) => {
            this.view.shops = shops;
            this.detectChanges();
            resolve(shops);
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      }
    });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};

    this.calcViewVars();
    this.initEvents();
    this.loadProject();

    this.pages = {
      add: EditShopSourcePage,
      edit: EditShopSourcePage,
    };

    this.view.viewType = 'list';
  }

  onItemChecked(shop) {

    this.view.selectedItems = this.view.shop.filter((_item: any) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'export':
        this.exportSelected();
        break;
      case 'import':
        this.importSelected();
        break;
    }
  }

  open(url: string) {
    this.browser.create(url);
  }

  toggleActive(shop: source) {
    if (this.userService.isType('Admin')) {
      shop.active = !shop.active;
      this.sources.updateSource(shop)
        .catch((error: any) => {
          this.events.publish('error', error);
          shop.active = !shop.active;
        });
    }
  }

}