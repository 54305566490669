<div class="container">

  <!-- No entries card -->
  <pipeline-no-entries-card [cta]="cta"
    *ngIf="(!items || (items && !items.length)) && !view.loading"></pipeline-no-entries-card>

  <div *ngIf="!!items && !!items.length">

    <!-- Pagination -->
    <pipeline-pagination *ngIf="!!view.showPagination && !!paginationConfig && !view.loading"
      [config]="paginationConfig" [(view)]="view"></pipeline-pagination>

    <!-- Iterate items -->
    <ion-grid>
      <ion-row>
        <ion-col *ngFor="let item of items; let i = index; trackBy:trackItems"
          [size]="view.viewType !== 'grid' ? 12 : view.isDesktop ? 3 : 12">
          <ion-card>
            <pipeline-dynamic-item [buttons]="buttons" [(item)]="items[i]" [mode]="mode" [options]="options"
              [selectionOptions]="selectionOptions" (selectionChanged)="onSelectionActionChanged($event)" [view]="view"
              (itemChanged)="onItemChanged(items[i])"></pipeline-dynamic-item>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
    
    <!-- Infinite scroll -->
    <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNext($event)">
      <ion-infinite-scroll-content loadingSpinner="circular">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </div>

</div>