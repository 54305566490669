<ion-card class="ai-upscaler-card">

  <ion-card-header>

    <ion-card-title [innerHTML]="'ai_upscaler'|translate"></ion-card-title>

  </ion-card-header>

  <ion-spinner [hidden]="(!!items && !!items.length) || !view.upscaling"></ion-spinner>

  <ion-grid *ngIf="!!items">
    <ion-row>

      <ion-col *ngFor="let media of items; let iMedia = index; trackBy:trackItems" size="4">
        <ion-card (click)="chooseMediaFromList(media)">

          <ion-button [disabled]="!!disabled" size="small" icon-only color="white" fill="clear"
            (click)="removeMediaFromList(media, iMedia)">
            <ion-icon name="trash-outline"></ion-icon>
          </ion-button>

          <ion-thumbnail>
            <ion-img [src]="media.thumbnail || fallbackImg" (ionError)="thumbnailLoadingFailed(media)"></ion-img>
          </ion-thumbnail>

          <ion-icon name="film-outline" class="video" [hidden]="media.post_mime_type != 'video'"></ion-icon>

        </ion-card>
      </ion-col>

      <ion-col>
        <ion-button [disabled]="!!disabled" size="block" icon-only fill="outline" color="dark" (click)="add()">
          <ion-icon name="add-outline"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>
      </ion-col>

    </ion-row>

  </ion-grid>

  <ion-item lines="none">

    <!-- Model -->
    <pipeline-ai-settings-picker slot="end" [config]="aiSettings" [hidden]="view.expertMode === false"
      [mini]="!view.isDesktop" [view]="view" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

  </ion-item>

</ion-card>