<ion-card>

  <!-- Header -->
  <ion-card-header>
    <ion-card-title [innerHTML]="'ai_text_to_image'|translate"></ion-card-title>
  </ion-card-header>

  <ion-spinner [hidden]="!view.loadingTextToImage || (!!view.ideas && !!view.ideas.length)"></ion-spinner>

  <!-- Step 1: Input data -->
  <div [hidden]="!!view.loadingTextToImage" class="ion-padding">

    <ion-list>

      <ion-item lines="none">

        <ion-icon name="information-outline" slot="start"></ion-icon>

        <ion-textarea rows="8" [label]="'input'|translate" labelPlacement="stacked" [(ngModel)]="input"
          [placeholder]="'ai_text_to_image_helper_input_placeholder'|translate"></ion-textarea>

        <ion-button slot="end" color="primary" shape="round" icon-only (click)="generate()">
          <ion-icon name="send-outline"></ion-icon>
        </ion-button>

      </ion-item>

    </ion-list>

  </div>

  <!-- Step 2: Media items -->
  <pipeline-media-items *ngIf="!!view.ideas && !!view.ideas.length" [selectionOptions]="selectionOptions" [view]="view"
    (selectedItemsChanged)="selectedMediaItemsChanged($event)"></pipeline-media-items>

  <!-- Footer -->
  <ion-item lines="none">

    <!-- Model -->
    <pipeline-ai-settings-picker slot="end" [config]="aiSettings" [hidden]="view.expertMode === false"
      [mini]="!view.isDesktop" [view]="view" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

  </ion-item>

</ion-card>