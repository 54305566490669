import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/services/core/user.service';

import { UserGroupPage } from '../user-group/user-group.page';

@Component({
  selector: 'app-user-groups',
  standalone: false,
  templateUrl: './user-groups.page.html',
  styleUrls: ['./user-groups.page.scss'],
})
export class UserGroupsPage implements OnInit {

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
  ];

  pages: any = {};

  options: dynamicViewPageOptions = {
    labelKey: 'title',
    showPhoto: false,
    showViewModeSelect: true,
  };

  search: searchOptions = {
    itemsKey: 'user_groups',
    keys: ['name', 'post_content', 'title', 'description', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'user_groups_admin_top_card',
      lottieSrc: './assets/lottie/user_groups.json',
      text: 'user_groups_admin_top_card_text',
      title: 'user_groups_admin_top_card_title',
    },
    itemSize: 64,
    multiple: true,
    route: 'user-groups',
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'user_groups_admin',
    user_groups: [],
    viewMode: 'list',
  };

  constructor(
    public service: UserService,
  ) {

  }

  deleteSelected(event: any | null = null) {
    console.log('delete selected: event', event);
    console.log('delete selected: view', this.view);
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  ngOnInit() {

    this.pages = {
      add: UserGroupPage,
      edit: UserGroupPage,
    };

    this.view.viewType = 'list';
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

}
