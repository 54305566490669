import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { SelectionToolbarComponent } from './selection-toolbar.component';

import { LanguagesSelectComponentModule } from 'src/app/components/generic/languages-select/languages-select.component.module';
import { PaginationComponentModule } from 'src/app/components/generic/pagination/pagination.component.module';

@NgModule({
  declarations: [
    SelectionToolbarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    LanguagesSelectComponentModule,
    PaginationComponentModule,
  ],
  exports: [
    SelectionToolbarComponent,
  ]
})
export class SelectionToolbarComponentModule { }