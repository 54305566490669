import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';

@Injectable({
    providedIn: 'root'
})
export class AiConfigService {

    blUseStreaming: boolean = false;
    
    CACHE_KEY_CONFIG: string = 'pipeline_ai_worker_config';

    CACHE_KEY_MODELS_CACHE: string = 'pipeline_ai_models_list';

    config: aiSettings = {
        temperature: 0.5,
    };

    constructor(
        private cache: CacheService,
    ) {

    }

    get() {
        return this.config || {};
    }

    getBasePrompt() {
        return (!!this.config && !!this.config.basePrompt ? this.config.basePrompt : null);
    }

    async getPreferredModels(context: string = 'general') {
        const cacheKey: string = `${this.CACHE_KEY_MODELS_CACHE}_${context}`;
        const fromCache: cacheItem = await this.cache.get(cacheKey, -1);

        if (!!fromCache && !!fromCache.data) {
            return fromCache.data;
        }

        return [];
    }

    async init() {
        const fromCache: cacheItem = await this.cache.get(this.CACHE_KEY_CONFIG, -1);

        if (!!fromCache && !!fromCache.data) {
            this.config = Object.assign(this.config, fromCache.data || {});
        }
    }

    set(config: aiSettings) {
        if (!!config) {
            console.log('[ AI LOADER ] updated config', config);
            this.config = config;

            if (!!config && !!config.models && !!config.models.length) {
                this.syncPreferredModels(config.models, config.context || 'general');
            }

            const cacheConfig: any = JSON.parse(JSON.stringify(config)) || {};
            delete cacheConfig.batch_count;
            delete cacheConfig.batch_size;
            delete cacheConfig.query;
            delete cacheConfig.request;

            console.log('[ AI LOADER ] updated cacheConfig', cacheConfig);
            return this.cache.set(this.CACHE_KEY_CONFIG, cacheConfig);
        }

        return this;
    }

    setBasePrompt(prompt: string) {
        this.config.basePrompt = prompt;
        return this;
    }

    async setPreferredModels(models: aiModel[], context: string = 'general') {
        const cacheKey: string = `${this.CACHE_KEY_MODELS_CACHE}_${context}`;
        return this.cache.set(cacheKey, (models || [] as aiModel[]));
    }

    async syncPreferredModels(models: aiModel[], context: string = 'general') {
        const existing: aiModel[] = (await this.getPreferredModels(context) || []);

        const existingUids: any[] = existing.map((item: aiModel) => {
            return item.uid;
        });

        let all: aiModel[] = existing;

        if (!!models && !!models.length) {
            models.forEach((model: aiModel) => {
                if (existingUids.indexOf(model.uid) === -1) {
                    all.push(model);
                }
            });
        }

        const sorted: aiModel[] = all.sort((a: any, b: any) => {
            const _a: string = `${a.name}`.toLowerCase();
            const _b: string = `${b.name}`.toLowerCase();

            if (_a < _b) return -1;
            if (_b > _a) return 1;
            return 0;
        });

        return this.setPreferredModels(sorted, context);
    }

    async useStreaming() {
        const config: aiSettings = this.get();
        console.log('ai-config: useStreaming: config', config);

        return this.blUseStreaming;
    }

}