<ion-header class="ion-no-border" *ngIf="!!view">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <!-- Options button -->
      <ion-button *ngIf="view.mode !== 'pick'" (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark"
        [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Create -->
      <ion-button *ngIf="view.mode !== 'pick'" (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

      <!-- Use -->
      <ion-button *ngIf="view.mode === 'pick'" (click)="submitSelected($event)" shape="round" color="primary"
        fill="solid" [disabled]="!view.hasSelectedItems">
        <ion-icon slot="start" name="checkmark-outline"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.models" [options]="selectionOptions"
    [(paginationConfig)]="paginationConfig" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Popover for Header actions -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Upload -->
        <ion-button *ngIf="view.mode !== 'pick'" (click)="upload()" fill="clear" color="dark">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [hidden]="!view.isDesktop" [innerHTML]="'upload'|translate"></ion-label>
        </ion-button>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- Connections -->
          <pipeline-connections-filter-card *ngIf="!!view.connections && !!view.connections.length" [(cards)]="cards"
            [(view)]="view" (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Platforms -->
          <pipeline-platforms-filter-card *ngIf="!!view.platforms && !!view.platforms.length" [(cards)]="cards"
            [(view)]="view"></pipeline-platforms-filter-card>

          <!-- Providers (if no single provider is requested) -->
          <ion-card class="sidebar-accordion-card" *ngIf="!filter || !filter.provider">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_models_provider_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.providers" class="card-toggle" (click)="toggleCard('providers')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.providers && !!cards.providers.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.providers && !cards.providers.open"
              *ngIf="(view.providers && !view.providers.length) && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.providers && !!view.providers.length"
              [hidden]="!!cards && !!cards.providers && !cards.providers.open">

              <ion-chip button (click)="filterProvider(provider)"
                *ngFor="let provider of view.providers; trackBy:trackItems"
                [color]="filter.provider === (provider.uid) || filter.provider === (provider.name)? 'primary' : 'dark'">

                <ion-avatar>
                  <ion-img [src]="provider.photo || fallbackImg"
                    (ionError)="thumbnailLoadingFailed(provider)"></ion-img>
                </ion-avatar>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="provider.name|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

          <!-- Authors (if no single author is requested) -->
          <ion-card class="sidebar-accordion-card"
            *ngIf="(!!view.authors && !!view.authors.length) && !filter || !filter.provider">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'authors'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.authors" class="card-toggle" (click)="toggleCard('authors')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.authors && !!cards.authors.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.authors && !cards.authors.open"
              *ngIf="(view.authors && !view.authors.length) && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.authors && !!view.authors.length"
              [hidden]="!!cards && !!cards.authors && !cards.authors.open">

              <ion-chip button (click)="filterAuthor(author)" *ngFor="let author of view.authors; trackBy:trackItems"
                [color]="filter.author === (author.uid) || filter.author === (author.name)? 'primary' : 'dark'">

                <ion-avatar>
                  <ion-img [src]="author.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(author)"></ion-img>
                </ion-avatar>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="author.name|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

          <!-- Tools -->
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_models_category_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.categories" class="card-toggle" (click)="toggleCard('categories')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.categories && !!cards.categories.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.categories && !cards.categories.open"
              *ngIf="view.categories && !view.categories.length && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.categories && !!view.categories.length"
              [hidden]="!!cards && !!cards.categories && !cards.categories.open">

              <ion-chip button (click)="filterCategory(category)"
                *ngFor="let category of view.categories; trackBy:trackItems"
                [color]="(filter.category === category.uid) || (filter.category === category.name) ? 'primary' : 'dark'">

                <ion-icon *ngIf="!!category.icon" [name]="category.icon"></ion-icon>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="(category.name_formatted || category.name)|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

          <!-- Libraries -->
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_models_library_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.library_names" class="card-toggle"
                (click)="toggleCard('library_names')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.library_names && !!cards.library_names.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.library_names && !cards.library_names.open"
              *ngIf="view.library_names && !view.library_names.length && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.library_names && !!view.library_names.length"
              [hidden]="!!cards && !!cards.library_names && !cards.library_names.open">

              <ion-chip button (click)="filterLibrary(library)"
                *ngFor="let library of view.library_names; trackBy:trackItems"
                [color]="(filter.library_name === library.uid) || (filter.library_name === library.name) ? 'primary' : 'dark'">

                <ion-icon *ngIf="!!library.icon" [name]="library.icon"></ion-icon>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="(library.name_formatted || library.name)|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

        </ion-col>

        <!-- Content area -->
        <ion-col class="right" [size]="view.isDesktop ? 9 : 12">

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <!-- No entries -->
          <pipeline-no-entries-card
            *ngIf="((view.models && !view.models.length) || (!!view.hasFilter && !view.filterHasResults)) && !view.loading"></pipeline-no-entries-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- Models list -->
          <ion-radio-group [(ngModel)]="view.singleSelectedModel" (ionChange)="onSingleSelectedModelChanged()">

            <ion-grid *ngIf="!!view.models && !!view.models.length" [hidden]="view.loading">
              <ion-row>

                <ion-col [size]="view.viewType === 'list' ? 12 : view.colSize" *ngFor="let model of view.models"
                  [hidden]="model.hidden">
                  <ion-card>

                    <!-- Top item -->
                    <ion-item class="ion-text-wrap" lines="none">

                      <!-- Thumbnail -->
                      <ion-thumbnail slot="start" *ngIf="!!model.thumbnail || !!model.photo">
                        <ion-img *ngIf="!!model.photo || !!model.thumbnail"
                          [src]="(model.thumbnail || model.photo) || fallbackImg"
                          (ionError)="thumbnailLoadingFailed(model)"></ion-img>
                        <ion-skeleton-text class="image" *ngIf="!model.photo && !model.thumbnail"
                          [animated]="!model.uid"></ion-skeleton-text>
                      </ion-thumbnail>

                      <!-- Single selection -->
                      <ion-radio name="model" *ngIf="!view.multiple" class="ion-text-wrap" [value]="model">
                        <p [innerHTML]="model.name" class="ion-text-wrap"></p>
                        <small [innerHTML]="model.provider" class="ion-text-wrap"></small>
                      </ion-radio>

                      <!-- Multi selection -->
                      <ion-checkbox *ngIf="!!view.multiple" class="ion-text-wrap" [(ngModel)]="model.checked"
                        justify="start" labelPlacement="end" (ionChange)="onItemChecked(model)">
                        <p [innerHTML]="model.name" class="ion-text-wrap"></p>
                        <small [innerHTML]="model.provider" class="ion-text-wrap"></small>
                      </ion-checkbox>

                      <!-- Configure / setup model -->
                      <ion-button slot="end" fill="clear" icon-only (click)="use(model)" color="dark"
                        [hidden]="!view.expertMode">
                        <ion-icon name="settings-outline"></ion-icon>
                      </ion-button>

                      <!--
                      Delete model:
                      <ion-button (click)="delete(model)" fill="clear" color="danger" icon-only slot="end">
                        <ion-icon name="trash-outline"></ion-icon>
                      </ion-button>
                      -->

                    </ion-item>

                    <!-- Details -->
                    <ion-list lines="none" [hidden]="view.viewType === 'list'">

                      <ion-item lines="none">

                        <!-- Favourite -->
                        <ion-button fill="clear" *ngIf="model.downloads >= 100000" slot="start">
                          <ion-icon name="star" slot="start" color="warning"></ion-icon>
                          <ion-label [innerHTML]="'suggestion'|translate"></ion-label>
                        </ion-button>

                        <!-- Likes -->
                        <ion-button fill="clear" slot="end">
                          <ion-icon [name]="!!model.likes ? 'thumbs-up' : 'thumbs-up-outline'" slot="start"></ion-icon>
                          <ion-label [innerHTML]="model.likes || 0"></ion-label>
                        </ion-button>

                        <!-- Downloads -->
                        <ion-button fill="clear" slot="end" *ngIf="!!model.downloads"
                          [hidden]="model.downloads >= 100000">
                          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
                          <ion-label [innerHTML]="model.downloads || 0"></ion-label>
                        </ion-button>

                      </ion-item>

                    </ion-list>

                  </ion-card>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-radio-group>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>