<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start" *ngIf="!inSetupEmbedded">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="update()" *ngIf="team && !!team.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!team.name || !team.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="save()" *ngIf="!team || !team.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!team.name || !team.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-toolbar>

  <!-- Segments -->
  <ion-toolbar class="container second-toolbar" *ngIf="!inSetupEmbedded">

    <ion-segment name="title" [(ngModel)]="view.segment" (ionChange)="onSegmentChange($event)"
      [disabled]="!team.name || !team.name.length">

      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="members">
        <ion-icon name="people-outline"></ion-icon>
        <ion-label [innerHTML]="'members'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="projects">
        <ion-icon name="folder-open-outline"></ion-icon>
        <ion-label [innerHTML]="'projects'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="billing">
        <ion-icon name="card-outline"></ion-icon>
        <ion-label [innerHTML]="'billing'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>

  </ion-toolbar>

  <!-- Searchbar -->
  <ion-toolbar class="ion-no-border container second-toolbar"
    *ngIf="!!team && !!team.uid && (view.segment === 'members' || view.segment === 'projects')">
    <ion-searchbar [(ngModel)]="search.query" [placeholder]="'search_something'|translate"
      (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>

  <!-- Members actions -->
  <ion-toolbar *ngIf="!view.loading && (view.segment === 'members')">
    <ion-buttons slot="start">

      <ion-button color="primary" (click)="addMember()">
        <ion-icon name="person-add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_member'|translate"></ion-label>
      </ion-button>

      <ion-button color="primary" fill="clear" (click)="createUser()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create_user'|translate"></ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <!-- Projects actions -->
  <ion-toolbar *ngIf="!view.loading && (view.segment === 'projects')">
    <ion-buttons slot="start">

      <ion-button color="primary" (click)="addProject()">
        <ion-icon name="folder-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

      <ion-button color="primary" fill="clear" (click)="createProject()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create_project'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Abonnement overlay -->
  <getgenius-abo-overlay [requiresOne]="['premium', 'professional', 'enterprise']" [view]="view"
    [hidden]="view.aboVerified"></getgenius-abo-overlay>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!!view.introCard" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <div [hidden]="!!view.loading || (view.segment != 'general')">
      <ion-card>
        <ion-list [hidden]="view.segment != 'general'">

          <ion-item>

            <ion-thumbnail slot="start" (click)="uploadPhoto()">
              <ion-img [src]="team.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(team)"></ion-img>
            </ion-thumbnail>

            <ion-input type="text" [label]="'name'|translate" [autofocus]="true" labelPlacement="stacked"
              [(ngModel)]="team.name" [placeholder]="'team_name'|translate"></ion-input>

          </ion-item>

          <ion-item lines="none" [hidden]="!team || !team.uid">
            <ion-checkbox [(ngModel)]="team.active" alignment="end" justify="space-between" labelPlacement="start">
              <p [innerHTML]="'state_active'|translate" class="ion-text-wrap"></p>
            </ion-checkbox>
          </ion-item>

        </ion-list>
      </ion-card>
    </div>

    <!-- Members-->
    <div [hidden]="!!view.loading || (view.segment != 'members')">

      <pipeline-no-entries-card *ngIf="team.members && !team.members.length"></pipeline-no-entries-card>

      <ion-card *ngIf="!!team.members && !!team.members.length">
        <ion-list>

          <ion-item-sliding *ngFor="let member of team.members" [hidden]="member.visible === false">
            <ion-item button (click)="viewMember(member)">
              <ion-avatar slot="start">
                <ion-img [src]="member.photo && member.photo.length ? member.photo : avatarUrl"
                  (ionError)="member.photo=avatarUrl"></ion-img>
              </ion-avatar>

              <ion-label>
                <h3
                  [innerHTML]="((!!member.firstname || !!member.lastname) ? (member.firstname + ' ' + member.lastname) : member.email)">
                </h3>
                <p
                  [innerHTML]="(member.classifications && member.classifications.type ? member.classifications.type : (member.nickname || member.email))">
                </p>
              </ion-label>

              <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                (click)="removeMemberFromTeam(member)">
                <ion-icon name="trash-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
              </ion-button>
            </ion-item>

            <ion-item-options slot="end" *ngIf="!view.isDesktop">
              <ion-item-option color="danger" (click)="removeMemberFromTeam(member)">
                <ion-icon name="trash-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>

        </ion-list>
      </ion-card>

    </div>

    <!-- Projects -->
    <div [hidden]="!!view.loading || (view.segment != 'projects')">

      <ion-spinner [hidden]="!view.loading"></ion-spinner>

      <pipeline-no-entries-card
        *ngIf="!team.projects || (team.projects && !team.projects.length)"></pipeline-no-entries-card>

      <ion-card *ngIf="!!team.projects && !!team.projects.length">
        <ion-list>

          <div class="cdk-wrapper">
            <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
              [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
              <div *cdkVirtualFor="let project of team.projects" [hidden]="project.visible === false">
                <ion-item-sliding>

                  <ion-item button (click)="viewProject(project)">

                    <ion-thumbnail slot="start">
                      <ion-img [src]="project.photo && project.photo.length ? project.photo : fallbackImg"
                        (ionError)="project.photo=fallbackImg"></ion-img>
                    </ion-thumbnail>

                    <ion-label>
                      <h3 [innerHTML]="project.title"></h3>
                    </ion-label>

                    <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                      (click)="removeProjectFromTeam(project)">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                    </ion-button>
                  </ion-item>

                  <ion-item-options slot="end" *ngIf="!view.isDesktop">
                    <ion-item-option color="danger" (click)="removeProjectFromTeam(project)">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>

        </ion-list>
      </ion-card>

    </div>

    <!-- Billing -->
    <div [hidden]="!!view.loading || (view.segment != 'billing')">

      <ion-grid>

        <ion-row>

          <ion-col [size]="view.isDesktop ? 6 : 12">

            <!-- Wallets -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'team_billing_wallets_title'|translate"></ion-card-title>
              </ion-card-header>

              <ion-card-content *ngIf="view.wallets && !!view.wallets.length">
                <p [innerHTML]="'team_billing_wallets_text'|translate"></p>
              </ion-card-content>

              <ion-card-content *ngIf="view.wallets && !view.wallets.length">
                <p [innerHTML]="'team_billing_wallets_setup_text'|translate"></p>

                <ion-button (click)="setupWallet()" fill="outline" color="primary" class="setup-wallet-btn">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'setup_wallet'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

            </ion-card>

          </ion-col>

          <ion-col [size]="view.isDesktop ? 6 : 12">

            <!-- Refill behaviour -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'team_billing_wallet_auto_refill_title'|translate"></ion-card-title>
              </ion-card-header>

              <ion-card-content>
                <p [innerHTML]="'team_billing_wallet_auto_refill_text'|translate"></p>
              </ion-card-content>

              <ion-radio-group [(ngModel)]="view.refill_behaviour">

                <ion-item>
                  <ion-icon name="sync-outline" slot="start"></ion-icon>
                  <ion-radio value="refill" slot="start"></ion-radio>
                  <ion-label class="ion-text-wrap"
                    [innerHTML]="'team_billing_wallet_auto_refill_option_refill'|translate"></ion-label>
                </ion-item>

                <ion-item lines="none">
                  <ion-icon name="hand-left-outline" slot="start"></ion-icon>
                  <ion-radio value="pause" slot="start"></ion-radio>
                  <ion-label class="ion-text-wrap"
                    [innerHTML]="'team_billing_wallet_auto_refill_option_pause'|translate"></ion-label>
                </ion-item>

              </ion-radio-group>

            </ion-card>

          </ion-col>

          <ion-col [size]="view.isDesktop ? 6 : 12">

            <!-- Payment -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'team_billing_payment_title'|translate"></ion-card-title>
              </ion-card-header>

              <ion-card-content>
                <p [innerHTML]="'team_billing_payment_text'|translate"></p>
              </ion-card-content>

              <pipeline-no-entries-card
                *ngIf="view.payment_methods && !view.payment_methods.length"></pipeline-no-entries-card>

            </ion-card>

          </ion-col>

          <ion-col [size]="view.isDesktop ? 6 : 12">

            <!-- Invoices -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'team_billing_invoices_title'|translate"></ion-card-title>
              </ion-card-header>

              <ion-card-content>
                <p [innerHTML]="'team_billing_invoices_text'|translate"></p>
              </ion-card-content>

              <!-- No entries -->
              <pipeline-no-entries-card *ngIf="view.invoices && !view.invoices.length"></pipeline-no-entries-card>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>