import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { CrudService } from 'src/app/services/core/crud.service';
import { DataService } from 'src/app/services/core/data.service';
import { EventsService } from "src/app/services/core/events.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { IntroService } from 'src/app/services/utils/intro.service';
import { SharingService } from "src/app/services/sharing/sharing.service";
import { LanguageService } from "src/app/services/core/language.service";
import { ModalService } from "src/app/services/core/modal.service";
import { NewslettersService } from "src/app/services/newsletters/newsletters.service";
import { PaginationService } from 'src/app/services/utils/pagination.service';
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { UserService } from 'src/app/services/core/user.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { ViewService } from 'src/app/services/core/view.service';

import { PostsAdminSettingsPage } from '../posts-admin-settings/posts-admin-settings.page';
import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-posts-admin',
  standalone: false,
  templateUrl: './posts-admin.page.html',
  styleUrls: ['./posts-admin.page.scss'],
})
export class PostsAdminPage implements OnDestroy, OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;

  @ViewChild('headerPopover') headerPopover;
  isHeaderPopoverOpen: boolean = false;

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  buttons: button[] = [];

  calendarOptions: calendarOptions = {
    showPagination: true,
    showSelectionToolbar: false,
    showTitle: true,
    showViewModes: true,
    viewModes: ["day", "week", "month"],
  };

  cards: any = {};

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  eventsList: calendarEvent[] = [];

  fallbackImg: string = './assets/img/fallback.webp';

  integrationsByType: any;

  languageOptions: languagesSelectOptions = {
    allowMini: true,
    cached: true,
    mode: 'request',
  };

  mode: string;

  paginationConfig: paginationConfig = {
    itemsKey: 'posts',
    limit: 500,
  };

  search: searchOptions = {
    keys: ['name', 'post_content', 'host', 'url', 'uid'],
    itemsKey: 'posts',
    query: "",
  };

  selectionOptions: selectionOption[] = [
    /*
    {
      color: 'success',
      icon: 'checkmark-done-outline',
      label: 'accept',
      segment: 'review',
      uid: 'accept',
    },
    */
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      color: 'warning',
      icon: 'sync-outline',
      label: 'recreate',
      uid: 'recreate',
    },
    {
      icon: 'image-outline',
      label: 'recreate_images',
      uid: 'recreate_images',
    },
    {
      icon: 'text-outline',
      label: 'recreate_text',
      uid: 'recreate_text',
    },
    {
      icon: 'images-outline',
      label: 'add_media_from_library',
      uid: 'add_media_from_library',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      icon: 'language-outline',
      label: 'translate',
      uid: 'translate',
    },
    /*
    {
      color: 'warning',
      icon: 'hand-left-outline',
      label: 'reject',
      segment: 'review',
      uid: 'reject',
    },
    */
    {
      icon: 'calendar-outline',
      label: 'add_to_campaign',
      segment: 'submit',
      uid: 'add_to_campaign',
    },
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'cloud-download-outline',
      label: 'download',
      uid: 'download',
    },
    {
      icon: 'share-outline',
      label: 'share',
      segment: 'submit',
      uid: 'share',
    },
    {
      icon: 'folder-open-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    {
      icon: 'folder-open-outline',
      label: 'submit_to_review',
      segment: 'draft',
      uid: 'submit_to_review',
    },
  ];

  state: state = {};

  subs = new Subscription();

  tableViewOptions: tableViewOptions = {
  };

  view: any = {
    colSize: {},
    filters: [
      {
        icon: 'arrow-back-outline',
        name: 'start_date',
        type: 'date',
        uid: 'start_date',
      },
      {
        icon: 'arrow-forward-outline',
        name: 'end_date',
        type: 'date',
        uid: 'end_date',
      },
      {
        icon: 'documents-outline',
        multiple: true,
        name: 'type',
        type: 'select',
        uid: 'type',
        value: ['blog', 'social'],
        values: [
          {
            checked: true,
            icon: 'desktop-outline',
            uid: 'blog',
            label: 'ai_planner_type_blog',
            name: 'ai_planner_type_blog',
          },
          {
            checked: true,
            icon: 'share-social-outline',
            uid: 'social',
            label: 'ai_planner_type_social',
            name: 'ai_planner_type_social',
          },
          {
            checked: true,
            icon: 'mail-outline',
            uid: 'newsletter',
            label: 'ai_planner_type_newsletter',
            name: 'ai_planner_type_newsletter',
          },
          /*
          {
            checked: true,
            icon: 'chatbubbles-outline',
            uid: 'messenger',
            label: 'ai_planner_type_messenger',
            name: 'ai_planner_type_messenger',
          },
          */
        ],
      },
      {
        icon: 'bookmark-outline',
        multiple: true,
        name: 'status',
        type: 'select',
        uid: 'post_type',
        value: ['draft', 'external', 'feed_post', 'review', 'submit', 'post'],
        values: [
          {
            label: 'total',
            uid: 'all',
          },
        ]
      },
    ],
    introCard: {
      uid: 'posts_top_card',
      text: 'posts_top_card_text',
      title: 'posts_top_card_title',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    itemSize: 120,
    itemsKey: 'posts',
    mediaCreator: 'all',
    placeholder: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
    showMenuButton: true,
    showLanguagesSelect: true,
    showPagination: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    route: 'posts-admin',
    selectionFilters: [],
    states: [],
    title: 'content_hub',
    viewType: 'grid',
    viewTypes: [
      /*
      { icon: 'pie-chart', label: 'chart', uid: 'chart', expertMode: true },
      */
      { icon: 'calendar', label: 'calendar', uid: 'calendar', expertMode: true },
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ],
  };

  user: user;

  constructor(
    private alertService: AlertService,
    private configService: ConfigService,
    private creators: CreatorsService,
    private crud: CrudService,
    private dataService: DataService,
    private events: EventsService,
    private exportService: ExportService,
    private importService: ImporterService,
    private integrations: IntegrationsService,
    private introService: IntroService,
    private language: LanguageService,
    private modalService: ModalService,
    private newsletters: NewslettersService,
    private pagination: PaginationService,
    public postsAdmin: PostsAdminService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private sharing: SharingService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.integrationsByType = this.integrations.getByType();
    this.user = this.userService.getUser();

    this.view.segmentFromRoute = this.route.snapshot.paramMap.get('segmentName');
  }

  async add(event: any | null = null) {

    // if mode = pick, return selected items
    if (this.view.mode === 'pick') {
      return this.dismiss({
        items: (this.view.selectedItems || []),
      });
    }

    // otherwise, open add modal
    switch (this.view.type) {
      case 'blog':
        return this.addPost(event);
      case 'newsletter':
        return this.addNewsletter(event);
      case 'social':
        return this.addPost(event);
      default:
        return this.addPost(event);
    }

  }

  async addNewsletter(event: any | null = null) {
    this.newsletters.detailItem({} as newsletter);

    this.crud.create('newsletter')
      .then(() => {

        setTimeout(() => {
          this.doRefresh();
        });

      })
      .catch((error: any) => {
        console.warn('add: error', error);
        this.doRefresh();
      });
  }

  async addPost(event: any | null = null) {
    this.postsAdmin.detailItem({} as post);

    this.crud.create('post')
      .then(() => {

        setTimeout(() => {
          this.doRefresh();
        });

      })
      .catch((error: any) => {
        console.warn('add: error', error);
        this.doRefresh();
      });
  }

  accept(item: any | null = null) {
    console.log('accept', item);
  }

  acceptSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const items: any[] = JSON.parse(JSON.stringify(this.view.selectedItems));

    this.view.loading = true;

    this.tools.bulk({
      action: 'accept',
      identifier: 'uid',
      items: items,
      service: this.postsAdmin,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  addSelectedToCampaign() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
    });
  }

  allCategories() {
    this.view.category = null;

    this.view.categories.forEach((_category: category) => {
      _category.checked = false;
    });
  }

  async applyPaginationFromResponse(posts: post[], response: any) {

    if (!!response && !!response.pagination) {
      this.paginationConfig = this.paginationConfig || {};

      if (!!posts && !!posts.length) {
        this.paginationConfig.backup = posts;
      }

      if (!!response.pagination.count) {
        this.paginationConfig.count = response.pagination.count;
      }

      if (!!response.pagination.max) {
        this.paginationConfig.limit = response.pagination.max;
      }
    }

    return response;
  }

  applyViewType(type: string | null = null) {
    type = type || this.view.viewType;

    switch (type) {
      case 'calendar':
        this.view.showPagination = false;

        this.eventsList = (this.view.posts || []).map((post: post) => {
          const iEnd: any = new Date(post.date_gmt); // Math.floor(new Date(post.date_gmt).getTime() / 1000);
          const iStart: any = iEnd;

          const calendarEvent: calendarEvent = {
            title: `${post.name || ''}`,
            start: iStart,
            end: iEnd,
            draggable: true,
            language: (post.language || null),
            photo: ((post.photo || post.thumbnail) || ''),
            platform: 'blog',
            post_content: (post.post_content || ''),
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            uid: (post.uid || null),
          };

          return calendarEvent;
        });

        console.log('posts-admin: eventsList', this.eventsList);

        break;
      default:
        this.view.showPagination = true;
    }
  }

  blogChanged() {
    this.doRefresh();
  }

  async calcIntroCard() {
    this.view.introCard.text = `posts_admin_blog_all_top_card_text`;
    this.view.introCard.title = `posts_admin_blog_all_top_card_title`;
    this.view.introCard.uid = `posts_admin_blog_all_top_card`;
    this.view.introCard.hidden = await this.introService.isIntroCardHidden(this.view.introCard.uid);
  }

  calcStates() {

    if (!this.view.filters[3]) {
      return false;
    }

    let states: any[] = [
      { icon: 'document-text-outline', name: 'draft', uid: 'draft' },
    ];

    if (!!this.userService.isType(['Admin', 'Creator', 'Moderator'])) {
      states.push({
        icon: 'hand-right-outline',
        name: 'review',
        uid: 'review',
      });
    }

    if (!!this.appConfig.useFeeds) {
      states.push({ icon: 'people-outline', name: 'feed_post', uid: 'feed_post' });
    }

    states = states.concat([
      { icon: 'open-outline', name: 'external', uid: 'external' },
      { icon: 'checkmark-outline', name: 'submit', uid: 'submit' }
    ]);

    // add states to selection filters as well
    if (!!this.view.filters && !!this.view.filters[3]) {
      this.view.filters[3].values = [{
        label: 'all',
        name: 'all',
        uid: 'all',
      }]
        .concat(states).map((state: state) => {
          state.label = state.label || `post_type_${state.name}`;
          return state;
        });
    }

  }

  categorySettings(category: category) {

  }

  calcColSizeVars() {
    this.view.colSize = {
      left: (!!this.view.isUltraWide ? 2 : (window.innerWidth > 768 ? 3 : 12)),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };
  }

  calcCurrentSelectionOptions() {
    const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);

    this.view.currentSelectionOptions = this.selectionOptions.filter((option: any) => {
      return (!option.segment ? true : selectedTypes.indexOf(option.segment) !== -1);
    });
  }

  async calculatePagination(posts: post[] | null = null) {
    posts = (posts || this.view.posts_backup) || [];

    this.paginationConfig = this.paginationConfig || {};

    if (!!posts && !!posts.length) {
      this.view.posts_backup = (posts || []);
      this.paginationConfig.backup = JSON.parse(JSON.stringify([...posts as post[]]));
    }

    const calc: any = await this.pagination.calculateConfig(
      Object.assign(this.view, {
        posts: posts,
        posts_backup: posts,
      }),
      this.paginationConfig
    );

    if (!!calc && !!calc.view) {
      this.view = Object.assign(this.view, calc.view);
    }

    return this.view;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.canDelete = this.userService.isType(['Admin', 'Moderator']);
    this.view.canPromote = this.userService.isType(['Admin', 'Creator', 'Redakteur', 'Moderator']);
    this.view.canPublish = this.userService.isType(['Admin', 'Moderator']);
    this.view.canShare = this.userService.isType(['Admin', 'Creator', 'Redakteur', 'Moderator']);
    this.view.canSubmitForReview = this.userService.isType(['Admin', 'Redakteur', 'Moderator']);

    //this.view.language = (this.view.language || await this.language.getDisplayLanguage());
    this.view.mode = (!!this.mode ? this.mode : (this.view.mode || 'view'));

    this.calcColSizeVars();
    this.calcCurrentSelectionOptions();

    this.detectChanges();
  }

  calcVisiblePosts() {
    let key = 'posts', backupKey = `${key}_backup`;

    if (!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
      this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key]));
    }

    const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);
    const postTypes: string[] = ['draft', 'post'];

    if (!!this.view[backupKey]) {

      const filteredPosts: any[] = this.view[backupKey].filter((post: post) => {
        if (postTypes.indexOf(post.type) !== -1) {
          return selectedTypes.indexOf('blog') !== -1;
        }
        return selectedTypes.indexOf(post.type) !== -1;
      });

      this.view[key] = JSON.parse(JSON.stringify([...filteredPosts]));

      if (!!this.paginationConfig) {
        this.paginationConfig.backup = this.view[key];
      }
    }

    this.detectChanges();
  }

  cancelCategoryMode() {
    this.view.createCategoryMode = false;
  }

  cancelFolderMode() {
    this.view.createFolderMode = false;
  }

  createCategory() {
    this.view.createCategoryMode = true;
  }

  createFolder() {
    this.view.createFolderMode = true;
  }

  dateRangeChanged(item: any, event: any | null = null) {
    console.log('dateRangeChanged', item);
  }

  async delete(item: any) {
    switch (this.view.type) {
      case 'blog':
        return this.deletePost(item as post);
      case 'newsletter':
        return this.deleteNewsletter(item as newsletter);
      case 'social':
        return this.deletePost(item as post);
      default:
        return this.deletePost(item as post);
    }
  }

  deleteCategory(category: category) {

  }

  deleteNewsletter(newsletter: newsletter) {
    this.newsletters.delete(newsletter.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deletePost(post: post) {

    if (!this.view.canDelete) {
      this.events.publish('error', 'error_permission_user_group_not_allowed');
      return false;
    }

    this.postsAdmin.delete(post.uid)
      .then(() => {

        this.view.posts = [...this.view.posts.filter((_post: post) => {
          return _post.uid !== post.uid;
        })];

        this.view.posts_backup = [...this.view.posts];

        try {
          if (!!this.searchToolbar) {
            this.searchToolbar.updateItems(this.view.posts);
          }
        } catch (e) {
          console.warn('searching failed', e);
        }

        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const items: any[] = JSON.parse(JSON.stringify(this.view.selectedItems));

    try {
      this.alertService.requestConfirm()
        .then((bl: boolean) => {
          if (!!bl) {
            this.view.loading = true;

            this.tools.bulk({
              action: 'executeDelete',
              items: items,
              service: this.postsAdmin,
            })
              .then(() => {
                this.view.loading = false;
                this.doRefresh();
              })
              .catch((error: any) => {
                this.view.loading = false;

                if (!!error) {
                  this.events.publish('error', error);
                }
              });
          }
        })
        .catch((e: any) => {
          console.warn('deleting selected failed', e);
        });
    } catch (e) {
      console.warn('deleting selected failed', e);
    }
  }

  async detectChanges() {
    try {

      this.view.posts = JSON.parse(JSON.stringify([...this.view.posts || []]));

      if (!!this.searchToolbar) {
        this.searchToolbar.updateItems(this.view.posts);
      }

      this.calculatePagination(this.view.posts);

      this.runSearch();

    } catch (e) {
      console.warn('searching failed', e);
    }
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.calcViewVars();

    this.load(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

        this.detectChanges();
      });
  }

  downloadSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
    });
  }

  async edit(item: any, index: number = 0) {

    if (!item || !item.uid) {
      return false;
    }

    switch (this.view.type) {
      case 'blog':
        return this.editPost(item as post, index);
      case 'newsletter':
        return this.editNewsletter(item as newsletter, index);
      case 'social':
        return this.editPost(item as post);
      default:
        return this.editPost(item as post, index);
    }
  }

  async editNewsletter(newsletter: newsletter, index: number = 0) {
    this.newsletters.detailItem(newsletter);

    this.crud.edit('newsletter', newsletter, {
      index: index,
      posts: this.view.posts,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        console.warn('editing post failed', error);
      });
  }

  async editPost(post: post, index: number = 0) {
    this.postsAdmin.detailItem(post);

    this.crud.edit('post', post, {
      index: index,
      posts: this.view.posts,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        console.warn('editing post failed', error);
      });
  }

  editSelected(event: any | null = null) {
    this.dataService.editUsingUI({
      data: this.view.selectedItems,
      service: this.postsAdmin,
      source: 'pipeline',
      type: 'post',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.posts || []),
      service: this.postsAdmin,
      source: 'postsAdmin',
      type: 'post',
    })
      .then((response: any) => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  importPosts(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.postsAdmin,
      source: 'pipeline',
      type: 'post',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.loadProject();
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.calcColSizeVars();
    });
  }

  ionViewDidEnter() {
    this.calcViewVars();
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser();

    this.view.blogConnected = (
      this.user &&
      this.user.classifications &&
      this.user.classifications.blogSource &&
      this.user.classifications.blogSourceUid
    );

    this.initEvents();

    this.calcStates();
    this.segmentChanged();
    this.calcViewVars();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
    this.calcViewVars();
  }

  load(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadLanguage();
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.connections = [];
        this.view.connections_backup = [];
        this.view.sources = [];
        this.view.sources_backup = [];
        this.view.posts = [];
        this.view.posts_backup = [];

        resolve(this.view);
      } else {
        this.view.loading = true;
        this.view.posts = [...this.view.placeholder];

        this.detectChanges();

        try {
          this.loadFolders(blForceRefresh);
          this.loadSources(blForceRefresh);
        } catch (e) {
          console.warn('loading metadata failed', e);
        }

        const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);
        let items: any[] = [];

        // load posts once
        if (selectedTypes.indexOf('blog') !== -1 || selectedTypes.indexOf('post') !== -1 || selectedTypes.indexOf('social') !== -1) {
          try {
            let posts: any = await this.loadPosts(blForceRefresh);

            if (!!posts && !!posts.length) {
              items = posts;
            }
          } catch (e) {
            console.warn('loading posts failed', e);
          }
        }

        selectedTypes.forEach(async (type: string) => {
          let itemsByType: any = [];

          switch (type) {
            case 'newsletter':
              itemsByType = await this.loadNewsletter(blForceRefresh).then(resolve).catch(reject);
              break;
          }

          if (!!itemsByType && !!itemsByType.length) {
            items = items.concat(itemsByType);
          }
        });

        this.view.loading = false;
        this.view.posts = JSON.parse(JSON.stringify([...(items || [])]));
        this.view.posts_backup = [...this.view.posts];

        try {
          this.calculatePagination(this.view.posts);
        } catch (e) {
          console.warn('caculating pagination after server-side update failed', e);
        }

        this.segmentChanged();
        this.applyViewType();
      }
    });
  }

  loadBlogs(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.creators.getBlogs(blForceRefresh)
        .then((blogs: source[]) => {

          if (!!blogs && !!blogs.length) {
            blogs = blogs.map((blog: source) => {
              blog.system = `system_${`${blog.system}`.replace('system_', '')}`;
              return blog;
            });
          }

          this.view.blogs = blogs;
          this.view.sources = blogs;

          resolve(blogs);
        })
        .catch(reject);
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadConnections(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loadingConnections = true;

      this.sharing.getConnections(blForceRefresh)
        .then((connections: any[]) => {

          // parse connections
          if (!!connections && !!connections.length) {
            connections = connections.map((connection: integrationConnection) => {
              connection.checked = (connection.hasOwnProperty('checked') ? !!connection.checked : true);
              connection.title = `${connection.name}`;

              return connection;
            });
          }

          const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);

          // filter connections by integrations type
          connections = connections.filter((connection: integrationConnection) => {
            let bl: boolean = false;

            selectedTypes.forEach((type: string) => {
              if (!!this.integrationsByType[type]) {
                bl = bl || (this.integrationsByType[type].indexOf(connection.platform || connection.system) !== -1);
              }
            });

            return bl;
          });

          this.view.loadingConnections = false;
          resolve(connections);
        })
        .catch((error: any) => {
          this.view.loadingConnections = false;
          reject(error);
        });
    });
  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        this.postsAdmin.getFolders(this.view.options, blForceRefresh, { limit: 1000 })
          .then((folders: folder[]) => {
            this.view.loadingFolders = false;

            this.view.folders = (folders || [])
              .map((folder: folder) => {
                folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                return folder;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;

                return 0;
              });

            resolve(folders);
          })
          .catch((error: any) => {
            this.view.loadingFolders = false;
            reject(error);
          });
      }

    });
  }

  async loadLanguage() {
    const requestLanguage: string | null = await this.language.getRequestLanguage();

    this.view.filter = this.view.filter || {};
    this.view.filter.language = requestLanguage;
    this.view.language = this.view.language || requestLanguage;

    this.languageOptions.default = requestLanguage;
  }

  loadNewsletter(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.newsletters.get(blForceRefresh)
        .then((newsletters: newsletter[]) => {

          if (!!newsletters && !!newsletters.length) {
            newsletters = newsletters.map((newsletter: any) => {
              newsletter.name = `${newsletter.title}`;
              return newsletter;
            });
          }

          this.view.newsletters = newsletters;
          resolve(newsletters);
        })
        .catch(reject);
    });
  }

  loadPosts(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {

      let options: postRequestOptions = {
        filter: (this.view.filter || {}),
      };

      if (this.view.blog && this.view.blog.uid) {
        options.blogSourceUid = this.view.blog.uid;
      }

      if (!this.view.project || !this.view.project.uid) {
        this.view.loading = false;
        this.view.posts = [];
        resolve(this.view);
      } else {

        this.postsAdmin.getAll(options, blForceRefresh)
          .then(async (response: any) => {
            const posts: post[] = (!!response && !!response.data ? response.data : (!!response && !!response.length ? response : []));

            // apply pagination (count) from response if provided
            this.applyPaginationFromResponse(posts, response);

            resolve(posts);
          }).catch(reject);
      }
    });
  }

  loadPostsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadSources(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let sources: integrationConnection[] = [];

      const connections: integrationConnection[] = await this.loadConnections(blForceRefresh) as integrationConnection[];

      if (!!connections && !!connections.length) {
        sources = connections;
      }

      const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);

      selectedTypes.forEach(async (type: string) => {
        let sourcesByType: any = [];

        switch (type) {
          case 'blog':
            sourcesByType = await this.loadBlogs(blForceRefresh);
            break;
        }

        if (!!sourcesByType && !!sourcesByType.length) {
          sources = sources.concat(sourcesByType);
        }
      });

      // filter connections by language, if language selected
      if (!!this.view.language) {
        sources = sources.filter((connection: integrationConnection) => {
          return connection.language === this.view.language;
        });
      }

      this.view.sources = JSON.parse(JSON.stringify((sources || [])));
      this.view.sources_backup = this.view.sources;
      this.view.connections = this.view.sources;
      this.view.connections_backup = this.view.sources;

      resolve(this.view);
    });
  }

  moveFolder(post: post) {
    this.postsAdmin.moveFolder(post)
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.postsAdmin.moveFolder(this.view.selectedItems)
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnChanges() {
  }

  ngOnDestroy() {
    try {
      this.subs.unsubscribe();
    } catch (e) {
      console.warn('unsubscribe failed', e);
    }
  }

  ngOnInit() {
    this.loadCards();
    this.calcStates();

    this.view.viewType = 'grid';

    this.calcViewVars();

    // apply segment if provided in url
    if (!!this.view.segmentFromRoute) {
      this.segmentChanged();
    }

    this.load()
      .catch((error: any) => {
        console.warn('> load posts failed', error);
        this.events.publish('error', error);
      });

    this.calcIntroCard();
    this.detectChanges();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};

    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    this.doRefresh();
  }

  onFieldsChanged(fields: any[] = []) {
  }

  onFiltersChanged(event: any | null = null) {
    const selectedStates: string[] = (!!this.view.filters && !!this.view.filters[3] && !!this.view.filters[3].value ? this.view.filters[3].value : []);
    const selectedTypes: string[] = (!!this.view.filters && !!this.view.filters[2] && !!this.view.filters[2].value ? this.view.filters[2].value : []);

    if (selectedTypes !== this.view.prevSelectedTypes) {
      // if type filter changed, refresh data

      this.view.prevSelectedTypes = JSON.parse(JSON.stringify(selectedTypes));
      this.typeChanged();
    } else
      if (selectedStates !== this.view.prevSelectedStates) {
        // else, if segment changed

        this.view.prevSelectedStates = JSON.parse(JSON.stringify(selectedStates));
        this.segmentChanged();
      }
  }

  onFolderLoaded(items: any[] | null = null) {
    this.view.posts = [...(items || [])];

    this.paginationConfig = this.paginationConfig || {};
    this.paginationConfig.backup = [...this.view.posts];

    setTimeout(() => {
      this.runSearch();
    }, 100);
  }

  onItemsChanged(items: any[] = []) {

  }

  onItemsUpdated(items: any[] = []) {

  }

  onLanguageChanged(event: string | null = null) {
    if (!this.view.filter || !this.view.filter.language || (event !== this.view.filter.language)) {
      this.view.language = event;

      this.view.filter = this.view.filter || {};
      this.view.filter.language = this.view.language;

      this.doRefresh();
    }
  }

  onMouseMove(e: any) {
    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);
  }

  onPostItemChecked(post: post) {

    this.view.selectedItems = this.view.posts.filter((_post: post) => {
      return _post.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(event: any | null = null) {

    if (!!event && !!event.search) {
      this.search = event.search;
    }

    this.runGlobalSearch();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'accept':
        this.acceptSelected();
        break;
      case 'add_media_from_library':
        break;
      case 'add_to_campaign':
        this.addSelectedToCampaign();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'download':
        this.downloadSelected();
        break;
      case 'edit':
        this.editSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
      case 'optimize':
        this.optimizeSelected();
        break;
      case 'recreate':
        this.recreateSelected({
          recreate_images: true,
          recreate_texts: false,
        });
        break;
      case 'recreate_images':
        this.recreateSelected({
          recreate_images: true,
          recreate_texts: false,
        });
        break;
      case 'recreate_texts':
        this.recreateSelected({
          recreate_images: false,
          recreate_texts: true,
        });
        break;
      case 'reject':
        this.rejectSelected();
        break;
      case 'share':
        this.shareSelected();
        break;
      case 'submit_to_review':
        this.submitSelectedToReview();
        break;
      case 'translate':
        this.translateSelected();
        break;
    }

    this.view.selectedItems = [];
    this.view.hasSelectedItems = false;

    return true;

  }

  onViewTypeChanged(event: any = null) {
    this.calcViewVars();
    this.applyViewType(event);
  }

  openCategory(category: category) {
    this.view.category = category;
    this.runSearch();
  }

  optimizeSelected(options: any = {}) {
    console.log('optimizeSelected', options);

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const items: any[] = JSON.parse(JSON.stringify(this.view.selectedItems));

    this.view.loading = true;

    this.tools.bulk({
      action: 'optimize',
      identifier: 'uid',
      items: items,
      params: options,
      service: this.postsAdmin,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  recreate(item: any | null = null) {
    console.log('recreate', item);
  }

  recreateSelected(options: any = {}) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const items: any[] = JSON.parse(JSON.stringify(this.view.selectedItems));

    const itemUids: number[] = (items || []).map((post: post) => {
      return post.uid;
    });

    console.log('recreateSelected: items', items);
    console.log('recreateSelected: itemUids', itemUids);
    console.log('recreateSelected: options', options);

    if (!!this.view.posts && !!this.view.posts.length) {
      this.view.posts.forEach((post: post, index: number) => {

        if (!post || !post.uid || (itemUids.indexOf(post.uid) === -1)) {
          return;
        }

        this.view.posts[index].loading = true;
      });
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'recreate',
      identifier: 'uid',
      items: items,
      params: options,
      service: this.postsAdmin,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  reject(item: any | null = null) {
    console.log('reject', item);
  }

  rejectSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const items: any[] = JSON.parse(JSON.stringify(this.view.selectedItems));

    this.view.loading = true;

    this.tools.bulk({
      action: 'reject',
      identifier: 'uid',
      items: items,
      service: this.postsAdmin,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  runGlobalSearch(blForceRefresh: boolean = false, blBackground: boolean = false, appendChildren: any[] = []) {

    if (!this.search || !this.search.query || !this.search.query.length || (this.search.query.length < 3)) {
      return false;
    }

    this.view.loading = true;

    this.postsAdmin.search(this.search.query)
      .then((response: any) => {
        this.view.loading = false;

        const posts: post[] = (!!response && !!response.data ? response.data : (!!response && !!response.length ? response : []));

        // apply pagination (count) from response if provided
        this.applyPaginationFromResponse(posts, response);

        this.calculatePagination(posts);
      })
      .catch((error: any) => {
        this.view.loading = false;

        if (!!error && error.toLowerCase().indexOf('too_short') === -1 && error.toLowerCase() !== 'error_missing_search_input') {
          this.events.publish('error', error);
        }
      });
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  saveCategory() {

  }

  segmentChanged() {
    this.calcVisiblePosts();
    this.calcCurrentSelectionOptions();

    this.detectChanges();
  }

  selectAll() {
    console.log('selectAll: view', this.view);

    this.view.posts.forEach((post: post) => {
      post.checked = !post.hidden && !this.view.allSelected;
    });

    console.log('selectAll: view.posts', this.view.posts);

    this.view.allSelected = !this.view.allSelected;
    console.log('selectAll: view.allSelected', this.view.allSelected);

    this.view.selectedItems = this.view.posts.filter((post: post) => {
      return !!post.checked;
    });

    console.log('selectAll: view.selectedItems', this.view.selectedItems);

    this.view.hasSelectedItems = !!this.view.selectedItems && !!this.view.selectedItems.length;

    this.events.publish('posts:admin:selection:changed', {
      items: this.view.selectedItems,
      last: (!!this.view.selectedItems ? this.view.selectedItems[0] : null),
    });

    this.detectChanges();
  }

  async settings() {

    const modal: any = await this.modalService.create({
      component: PostsAdminSettingsPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'postsAdminSettingsModal'
    });

    modal.onWillDismiss().then(() => {
      this.calcViewVars();
    });

    this.modalService.present(modal);
  }

  setView(viewType: string) {
    this.view.viewType = viewType;
  }

  shareSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
    });
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  sourceSettings(source: source) {

  }

  submitSelectedToReview() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
    });
  }

  thumbnailLoadingFailed(post: post) {
    post.photo = this.fallbackImg;
    post.thumbnail = this.fallbackImg;
    this.detectChanges();
  }

  toggleCategoriesList() {

    if (!!this.view.isDesktop) {
      return false;
    }

    this.view.categoriesListOpen = !this.view.categoriesListOpen;
  }

  toggleFoldersList() {

    if (!!this.view.isDesktop) {
      return false;
    }

    this.view.foldersListOpen = !this.view.foldersListOpen;
  }

  toggleSearch() {
    this.search.visible = !this.search.visible;

    if (this.searchInput) {
      this.searchInput.setFocus();
    }

    this.detectChanges();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  translateSelected() {
    console.log('translateSelected', this.view.selectedItems);

    if(!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }
    
  }

  typeChanged() {
    this.load()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}