import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { IconPickerService } from "src/app/services/utils/icon-picker.service";

@Component({
  selector: 'pipeline-icon-picker',
  standalone: false,
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent implements OnInit {
  @Output() valueEvent = new EventEmitter();
  
  view: any = {
    icons: {},
  };

  constructor(
    private events: EventsService,
    private iconPicker: IconPickerService,
  ) {

  }
  
  

  ngOnInit() {
  }

  async pickIcon(ev: any|null = null) {
    this.iconPicker.pick()
    .then((response: any) => {
      if(response && !!response.icon) {
        this.view.icon = response.icon;
        this.valueEvent.emit(this.view.icon);
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
