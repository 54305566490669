import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ExchangeService } from '../../../../services/integrations/microsoft_exchange.service';
import { MailCrawlerService } from "src/app/services/integrations/mail-crawler.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-mail-crawler',
  standalone: false,
  templateUrl: './mail-crawler.page.html',
  styleUrls: ['./mail-crawler.page.scss'],
})
export class MailCrawlerPage implements OnInit {

  crawler: mailCrawler;

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  view: any = {
    crawlerTypeIcons: {
      exchange: 'logo-microsoft',
      icloud: 'logo-apple',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'mail_crawler_top_card',
      text: 'mail_crawler_top_card_text',
      title: 'mail_crawler_top_card_title',
    },
    options: {
    },
    showMenuButton: true,
    title: 'mail_crawler',
  };

  constructor(
    private events: EventsService,
    private exchange: ExchangeService,
    public mail: MailCrawlerService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private viewService: ViewService,
  ) {
  }

  authExchange() {
    try {
      const auth: any = this.exchange.connect();
      console.log('authExchange: auth', auth);

      return auth;
    } catch(e) {
      this.events.publish('error', e);
      return false;
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.crawler = this.crawler || {};
  }

  create() {
    this.mail.createCrawler(this.crawler)
      .then((response: any) => {
        console.log('create response', response);
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();

    console.log('crawler:', this.crawler);

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  async update() {

    switch(this.crawler.type) {
      case 'exchange':
        //const auth: any = await this.authExchange();
        //console.log('exchange auth:', auth);
        break;
    }

    this.mail.updateCrawler(this.crawler)
      .then((response: any) => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}