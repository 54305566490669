<ion-card>

  <ion-card-header>

    <ion-card-title [innerHTML]="'ai_text_to_speech'|translate"></ion-card-title>

  </ion-card-header>

  <ion-spinner [hidden]="!view.loadingTextToSpeech"></ion-spinner>

  <div [hidden]="!!view.loadingTextToSpeech" class="ion-padding">

    <ion-list>

      <ion-item lines="none">
        <ion-icon name="information-outline" slot="start"></ion-icon>

        <ion-textarea rows="8" [label]="'input'|translate" labelPlacement="stacked" [(ngModel)]="input"
          [placeholder]="'ai_text_to_speech_helper_input_placeholder'|translate"></ion-textarea>

        <ion-button slot="end" color="primary" shape="round" icon-only (click)="generate()">
          <ion-icon name="send-outline"></ion-icon>
        </ion-button>

      </ion-item>

    </ion-list>

    <audio *ngIf="!!input" controls autoplay loop [src]="input"></audio>

  </div>

  <ion-item lines="none">

    <!-- Model -->
    <pipeline-ai-settings-picker slot="end" [config]="aiSettings" [hidden]="view.expertMode === false"
      [mini]="!view.isDesktop" [view]="view" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

  </ion-item>

</ion-card>