import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { EventsService } from "src/app/services/core/events.service";
import { ShopService } from 'src/app/services/ecommerce/shop.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

import { ShopCategoryPage } from '../shop-category/shop-category.page';

@Component({
  selector: 'app-shop-categories',
  standalone: false,
  templateUrl: './shop-categories.page.html',
  styleUrls: ['./shop-categories.page.scss'],
})
export class ShopCategoriesPage implements OnInit {

  cta: any = {
    icon: 'link-outline',
    label: 'connect',
    url: '/sources/shops',
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  paginationConfig: paginationConfig = {

  };

  search: searchOptions = {
    itemsKey: 'products',
    keys: ['title', 'description', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    canDelete: true,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    limit: 1000,
    offset: 0,
    placeholders: {
      categories: [{}, {}, {}, {}, {}, {}],
      products: [{}, {}, {}, {}, {}, {}],
    },
    shop: {},
    showMenuButton: true,
    showLanguagesSelect: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    source: {},
    title: 'shop_categories',
    viewType: 'grid',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private sources: SourcesService,
    private shop: ShopService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.view.source.uid = parseInt(this.route.snapshot.paramMap.get('hostId'));
  }

  async add() {

    const modal: any = await this.modalService.create({
      component: ShopCategoryPage,
      componentProps: {
        product: {},
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (window.innerWidth >= 1024 ? 3 : (window.innerWidth >= 768 ? 4 : 12));
  }

  delete(category: any) {
    this.shop.deleteCategory(category.uid)
      .then(() => {

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteCategory',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this.shop,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh(event: any | null = null) {
    this.loadShop(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  initResize() {
    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  loadNext(event: any) {
    this.view.iOffset++;

    let iCountBefore = this.view.posts && this.view.posts.all ? this.view.posts.all.length || 0 : 0;

    this.loadShop(true)
      .then(() => {
        let iCountAfter = this.view.posts && this.view.posts.all ? this.view.posts.all.length || 0 : 0;
        let message = iCountBefore === iCountAfter ? 'Keine weiteren Einträge' : (iCountAfter - iCountBefore) + ' weitere geladen';

        this.events.publish('toast', {
          message: message,
          color: 'primary',
        });

        event.target.complete();

      })
      .catch((error: any) => {
        event.target.complete();

        if (error && error.hasOwnProperty('message')) {
          this.events.publish('error', error.message);
        }
      });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShop(blForceReload: boolean = false) {
    return new Promise(async (resolve, reject) => {

      this.calcViewVars();
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        reject('error_missing_project_uid');
      } else
        if (!this.view.source || !this.view.source.uid) {
          this.shop.getCategories(blForceReload, {
            project_uid: this.view.project.uid,
          })
            .then((categories: category[]) => {
              this.view.shop.categories = this.shop.parseCategories(categories);
              this.calcViewVars();

              resolve(this.view);
            })
            .catch(reject);
        } else {

          this.view.shop = {
            categories: this.view.placeholders.categories,
          };

          this.calcViewVars();

          this.sources.getSourceByUid(this.view.source.uid, blForceReload)
            .then((source: source) => {
              this.view.source = source;
              this.view.title = source.title;

              this.shop.getCategoriesBySource(this.view.source.uid)
                .then((categories: category[]) => {
                  this.view.shop.categories = this.shop.parseCategories(categories);
                  this.calcViewVars();

                  resolve(this.view);
                })
                .catch(reject);
            })
            .catch(reject);
        }
    });
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadShop()
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  onSwipeLeft(event: any | null = null) {
    console.log('onSwipeLeft', event);
  }

  onSwipeRight(event: any | null = null) {
    console.log('onSwipeRight', event);
  }

  runSearch(event: any | null = null) {
  }

  thumbnailLoadingFailed(item: any) {
    item.image = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}