import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AiKnowledgebaseService } from 'src/app/services/ai/ai-knowledgebase.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

import { GetgeniusDaniComponent } from 'src/app/components/getgenius/getgenius-dani/getgenius-dani.component';

@Component({
  selector: 'app-dani-alert-learn',
  standalone: false,
  templateUrl: './dani-alert-learn.page.html',
  styleUrls: ['./dani-alert-learn.page.scss'],
})
export class DaniAlertLearnPage implements OnInit {
  @ViewChild(GetgeniusDaniComponent) daniComponent: any;

  appConfig: pipelineAppConfig;

  daniConfig: daniConfig = {
    chat: {
      messages: [
        {
          mode: 'message',
          input: `getgenius_dani_alert_learn_chat_intro_message`,
          role: 'assistant',
        },
      ] as chatHistoryItem[],
    },
    hasChat: true,
    mini: true,
    uiMode: 'widget',
    userCanWrite: true, // false
    zoom: (window.outerWidth > 768 ? 0.55 : 0.25),
  };

  entry: any = {
    input: '',
    output: '',
  };

  state: state = {};

  view: any = {
    dani: {
      state: {
        inConversation: true,
        speaking: false,
      },
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'getgenius_dani_alert_learn_top_card',
      text: 'getgenius_dani_alert_learn_top_card_text',
      title: 'getgenius_dani_alert_learn_top_card_title',
    },
    route: 'getgenius/dani',
    showMenuButton: true,
    title: 'getgenius_dani_alert_learn_chat',
    verified: false,
  };

  user: user;

  constructor(
    private aiBridge: AiBridgeService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private knowledgeBase: AiKnowledgebaseService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcSecondChatMessage() {
    this.scrollDown();

    const data = JSON.parse(JSON.stringify(this.entry));
    const history: any[] = (!!data.history ? data.history : []);
    delete data.history;

    if (!!history) {
      this.daniConfig.chat = this.daniConfig.chat || {};
      this.daniConfig.chat.messages = history;
    }

    const command: string = `Your previous decision was wrong. The user corrected your JSON response.
    Do not talk about or return the corrected JSON response, do not use the word "JSON", but always talk about the difference that was made
    (name the old and the new value, (uid + name, match / prefer supplier / manufacturer / country / object name instead of uid, information is always provided in the history of the chat).
    
    Create a nice, human-like chat message (without greeting) that shows that you understood the requested change.
    Request / ask the user to save this to your database to learn this for the future.

    ${JSON.stringify(data)}`;

    this.aiBridge.execute({
      history: history,
      post_content: command,
    }, true)
      .then((response: any) => {
        this.scrollDown();

        if (!!response && !!response.output) {
          this.daniConfig.chat.messages = this.daniConfig.chat.messages || [];

          this.daniConfig.chat.messages.push({
            mode: 'message',
            input: response.output,
            role: 'assistant',
          });

          this.setChat(this.daniConfig.chat);
        }
      })
      .catch((error: any) => {
        console.warn('calc second message failed', error);
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.dani = this.view.dani || {};
    this.daniConfig = this.daniConfig || {};
    this.daniConfig.zoom = (!!this.view.isDesktop ? 0.55 : 0.25);
  }

  dismiss(event: any | null = null) {
    this.modalService.dismiss();
  }

  ionViewWillLeave() {
    this.events.publish('menu:dani:show');
  }

  ngAfterContentInit() {
    this.events.publish('menu:dani:hide');
  }

  ngOnInit() {
    this.calcViewVars();

    setTimeout(() => {
      this.calcSecondChatMessage();
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  save() {

    const entry: aiKnowledgebaseEntry = {
      contexts: ['text_classification'],
      input: this.entry.input,
      output: this.entry.output,
    };

    this.knowledgeBase.save(entry)
      .then(() => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  scrollDown() {
    try {
      this.daniComponent.scrollDown();
    } catch (e) {
      console.warn('dani component scroll down failed', e);
    }
  }

  setChat(chat: chat) {
    this.zone.run(() => {
      this.daniConfig.chat = (chat || {} as chat);

      try {
        this.daniComponent.setChat(this.daniConfig.chat);
      } catch (e) {
        console.warn('dani component scroll down failed', e);
      }

      this.scrollDown();
    });
  }

  viewModeChanged(event: any | null = null) {
    //this.calcViewVars();
  }

}