<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="downloadSelected()" [disabled]="!view.selectedItems || !view.selectedItems.length"
        fill="solid" shape="round" color="primary">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-grid>
      <ion-row class="settings-row">

        <ion-col>
          <ion-item lines="none">
            <ion-icon name="images-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'type'|translate" position="stacked"></ion-label>
            <ion-select interface="popover" [(ngModel)]="view.filters.types" multiple [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" (ionChange)="runSearch()">
              <ion-select-option *ngFor="let type of view.types" [innerHTML]="type.name|translate"
                [value]="type.uid"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

        <ion-col [hidden]="!view.expertMode">
          <ion-item lines="none">
            <ion-icon name="color-palette-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'color'|translate" position="stacked"></ion-label>
            <ion-select interface="popover" [(ngModel)]="view.filters.colors" multiple [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" (ionChange)="runSearch()">
              <ion-select-option *ngFor="let color of view.colors" [innerHTML]="color.name|translate"
                [value]="color.uid"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

        <ion-col [hidden]="!view.expertMode">
          <ion-item lines="none">
            <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'format'|translate" position="stacked"></ion-label>
            <ion-select interface="popover" [(ngModel)]="view.filters.custom" multiple [okText]="'okay'|translate"
              [cancelText]="'cancel'|translate" (ionChange)="runSearch()">
              <ion-select-option *ngFor="let customSetting of view.custom" [innerHTML]="customSetting.name|translate"
                [value]="customSetting.uid"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

        <ion-col>
          <ion-item lines="none">
            <ion-icon name="resize-outline" slot="start"></ion-icon>
            <ion-select interface="popover" [(ngModel)]="view.filters.aspect_ratios" [label]="'aspect_ratio'|translate"
              labelPlacement="stacked" multiple [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
              (ionChange)="runSearch()">
              <ion-select-option *ngFor="let aspect_ratio of view.aspect_ratios"
                [innerHTML]="aspect_ratio.name|translate" [value]="aspect_ratio.uid"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <pipeline-selection-toolbar [(items)]="view.templates" [(view)]="view" [options]="selectionOptions"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-grid *ngIf="!!view.templates && !!view.templates.length">
      <ion-row>

        <ion-col *ngFor="let template of view.templates; let i = index; trackBy:trackItems" [size]="view.isDesktop ? 3 : 12">
          <ion-card>

            <ion-item lines="none">

              <ion-checkbox justify="start" labelPlacement="end" class="ion-text-wrap" [(ngModel)]="template.checked"
                (ionChange)="onItemCheckboxClicked(template)" [disabled]="template.disabled">
                <ion-label [hidden]="!template.uid" class="excerpt-label ion-text-wrap" (click)="use(template)">
                  <h3 [innerHTML]="template.name" class="ion-text-wrap"></h3>
                </ion-label>
              </ion-checkbox>

            </ion-item>

            <ion-spinner [hidden]="!!template.uid"></ion-spinner>

            <pipeline-media-template-preview *ngIf="!!template.custom && !!template.uid"
              [(template)]="view.templates[i]" [(view)]="template.view"></pipeline-media-template-preview>

            <pipeline-video-template-preview [options]="view.videoTemplateOptions"
              *ngIf="!template.custom && !!template.uid"
              [(template)]="view.templates[i]"></pipeline-video-template-preview>

            <ion-item lines="none">

              <ion-skeleton-text [hidden]="!!template.uid"
                style="float:left;width:60%;margin-right: 10px;"></ion-skeleton-text>
              <ion-skeleton-text [hidden]="!!template.uid" style="float:left;width:30%;"></ion-skeleton-text>

              <ion-label [hidden]="!template.uid" class="excerpt-label ion-text-wrap" (click)="use(template)">
                <p [innerHTML]="(!!template.coins ? (template.coins + ' ' + ('token'|translate)) : ('free'|translate))">
                </p>
              </ion-label>

              <ion-button [disabled]="!template.uid" [hidden]="!!template.coins" slot="end" shape="round" expand="block"
                color="primary" (click)="use(template)">
                <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'load'|translate" class="ion-text-wrap"></ion-label>
              </ion-button>

              <ion-button [disabled]="!template.uid" [hidden]="!template.coins" slot="end" shape="round" expand="block"
                color="primary" (click)="buy(template)">
                <ion-icon name="card-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'buy'|translate" class="ion-text-wrap"></ion-label>
              </ion-button>

            </ion-item>

          </ion-card>
        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>
    
  </ion-toolbar>
</ion-footer>