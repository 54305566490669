<ion-header class="ion-no-border" [translucent]="appConfig.translucentHeaders">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.isAdmin">

      <!-- Create -->
      <ion-button (click)="createCustomer()" shape="round" color="primary" fill="solid">
        <ion-icon name="add" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="ion-no-border container" *ngIf="!!view.types && !!view.types.length">
    <ion-segment scrollable [(ngModel)]="view.type" (ionChange)="segmentChanged()">
      <ion-segment-button value="" icon-only>
        <ion-label [innerHTML]="'all'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button *ngFor="let type of view.types" [value]="type" icon-only>
        <ion-label [innerHTML]="type|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.customers" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- No entries card -->
    <pipeline-no-entries-card *ngIf="view.customers && !view.customers.length"></pipeline-no-entries-card>

    <!-- Customers list -->
    <div [hidden]="!!view.loading" *ngIf="!!view.customers && !!view.customers.length">

      <ion-card *ngFor="let customer of view.customers; let i = index;">
        <ion-item-sliding>

          <ion-item>
            <ion-checkbox [name]="'customerChecked' + customer.uid" [(ngModel)]="customer.checked" justify="start"
              labelPlacement="end" (ionChange)="onItemChecked(customer)"></ion-checkbox>

            <ion-button [hidden]="!view.isDesktop" slot="end" fill="clear" color="dark"
              (click)="viewCustomer(customer)">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'edit'|translate"></ion-label>
            </ion-button>

            <ion-button [hidden]="!view.isDesktop" slot="end" fill="clear" color="danger" (click)="delete(customer)">
              <ion-icon name="trash-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'delete'|translate"></ion-label>
            </ion-button>

          </ion-item>

          <ion-item lines="none">

            <ion-avatar slot="start" (click)="viewCustomer(customer)">
              <ion-img [src]="customer.photo && customer.photo.length ? customer.photo : avatarUrl"
                (ionError)="customer.photo=avatarUrl"></ion-img>
            </ion-avatar>

            <ion-label class="ion-text-wrap">
              <p *ngIf="!!customer.uid"
                [innerHTML]="((!!customer.firstname || !!customer.lastname) ? (customer.firstname + ' ' + customer.lastname) : customer.email)">
              </p>
              <small *ngIf="!!customer.uid"
                [innerHTML]="(customer.classifications && customer.classifications.type ? customer.classifications.type : (customer.nickname || customer.email))"></small>

              <ion-skeleton-text *ngIf="!customer.uid" style="float:left;width:25%;margin-right:10px;"
                animated></ion-skeleton-text>
              <ion-skeleton-text *ngIf="!customer.uid" style="float:left;width:35%;margin-right:25%;"
                animated></ion-skeleton-text>
              <ion-skeleton-text *ngIf="!customer.uid" style="float:left;width:15%;margin-right:10px;"
                animated></ion-skeleton-text>
              <ion-skeleton-text *ngIf="!customer.uid" style="float:left;width:25%;margin-right:25%;"
                animated></ion-skeleton-text>

            </ion-label>

          </ion-item>

          <ion-item-options slot="end" *ngIf="!view.isDesktop">

            <ion-item-option color="dark" (click)="viewCustomer(customer)">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'edit'|translate"></ion-label>
            </ion-item-option>

            <ion-item-option color="danger" (click)="delete(customer)">
              <ion-icon name="trash-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'delete'|translate"></ion-label>
            </ion-item-option>

          </ion-item-options>
        </ion-item-sliding>
      </ion-card>

    </div>

  </div>

</ion-content>