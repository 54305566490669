import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { EventsService } from "src/app/services/core/events.service";
import { ShopService } from 'src/app/services/ecommerce/shop.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

import { EditPostTranslationsModalPage } from 'src/app/pages/core/post/edit-post-translations-modal/edit-post-translations-modal.page';

@Component({
  selector: 'app-shop-product',
  standalone: false,
  templateUrl: './shop-product.page.html',
  styleUrls: ['./shop-product.page.scss'],
})
export class ShopProductPage implements OnInit {

  cards: any = {
    filters: { open: true },
    item_attributes: { open: true },
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  forbiddenKeys: string[] = ['checked', 'description', 'field_optimizations', 'hidden', 'image', 'name', 'open', 'original_uid', 'uid', 'url', 'variations'];

  index: number = 0;

  product: product;
  products: product[];

  state: state = {};

  view: any = {
    descriptionTab: 'original',
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    icons: {
      //active: 'eye',
      category_uid: 'grid-outline',
      description: 'text-outline',
      host_uid: 'radio-outline',
      id: 'finger-print-outline',
      name: 'eye-outline',
      original_uid: 'git-branch-outline',
      price: 'pricetag-outline',
      public: 'eye-outline',
      sku: 'bookmark-outline',
      source_uid: 'radio-outline',
      timestamp: 'calendar-outline',
      uid: 'finger-print-outline',
      url: 'link-outline',
    },
    keys: [
      "uid",
      "active",
      "original_uid",
      "name",
      "description",
      "image",
      "timestamp",
      "host_uid",
      "url",
      "sku",
      "price",
      "category_uid",
      "variations"
    ],
    showMenuButton: true,
    showLanguagesSelect: false,
    showProjectsSelect: false,
    showViewModeSelect: false,
    title: 'product',
  };

  constructor(
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private modalService: ModalService,
    private shop: ShopService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.view.canPublish = true;
    this.view.canUpdate = false;
  }

  add() {

  }

  bindKeyEvents() {
    window.addEventListener('keydown', (event) => {
      event.stopImmediatePropagation();

      this.onKeyDown(event);
    }, false);
  }

  calcDescriptionPreview(value: string | null = null) {
    value = value || this.product.description;
    console.log('calcDescriptionPreview', value);

    this.view.description_formatted = this.domSanitizer.bypassSecurityTrustHtml(`${value || ''}`);
    console.log('this.view.description_formatted', this.view.description_formatted);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.canNavigateBack = (!!this.products && (this.index !== 0));
    this.view.canNavigateNext = (!!this.products && (this.index !== this.products.length));

    this.calcDescriptionPreview();
  }

  create() {

  }

  descriptionChanged(event: any|null = null) {
    this.calcDescriptionPreview(`${event.detail.value || ''}`);
  }

  descriptionTabChanged(event: any|null = null) {
    this.view.rebuild = true;
    
    setTimeout(() => {
      this.view.rebuild = false;
    }, 250);
  }

  dismiss(data: any|null = null) {
    this.modalService.dismiss(data);
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadUI() {
    this.view.loading = true;
    this.view.canPublish = true;
    this.view.canUpdate = false;

    this.view.input_prompt = this.shop.getDefaultProductOptimizationPrompt();

    if (!!this.product && !!this.product.uid) {

      this.view.keys = Object.keys(this.product).filter((key: any) => {
        return this.forbiddenKeys.indexOf(key) === -1;
      });

      console.log('this.view.keys', this.view.keys);

      this.view.canPublish = false;
      this.view.canUpdate = true;
    } else {
      this.product = {};
    }

    setTimeout(() => {
      this.view.loading = false;
    });
  }

  navBack() {
    this.index--;
    this.onNavIndexChanged();
  }

  navNext() {
    this.index++;
    this.onNavIndexChanged();
  }

  ngOnDestroy() {
    this.unbindKeyEvents();
  }

  ngOnInit() {
    this.loadCards();
    this.calcViewVars();
    this.bindKeyEvents();

    this.loadUI();
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "ArrowLeft":
        if (!this.view.canNavigateBack) { return false; }
        this.navBack();
        break;
      case "ArrowRight":
        if (!this.view.canNavigateNext) { return false; }
        this.navNext();
        break;
    }
  }

  onNavIndexChanged() {
    this.product = this.products[this.index];
    this.view.history = [this.product];

    this.calcViewVars();
    this.loadUI();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  optimize(key: string) {

    if (!this.product.uid) {
      return false;
    }

    if (this.view.mode !== 'optimize') {
      this.view.mode = 'optimize';
      return false;
    }

    this.view.optimized = false;

    const input_prompt: string = this.tools.nl2br(`${this.view.input_prompt || ''}`);

    this.view.loading = true;
    this.view.loadingKey = key;

    this.shop.optimizeProductDescription(this.product.uid, key, {
      force_rebuild_images: false,
      force_rebuild_texts: true,
      input_prompt: input_prompt,
    })
      .then((response: any) => {
        this.view.loading = false;

        if (!!response && !!response.output) {
          this.product[key] = this.tools.nl2br(`${response.output || ''}`);
          this.view.optimized = true;
          this.calcDescriptionPreview();
        }
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(key: string) {
    this.product[key] = this.fallbackImg;
  }

  async toggleLanguage() {

    const modal: any = await this.modalService.create({
      component: EditPostTranslationsModalPage,
      componentProps: {
        post: {},
        product: this.product,
        type: 'product',
      },
      animated: true,
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then((response: any) => {
      const language = response && response.data && response.data.language ? response.data.language : null;
      console.log('language', language);
    });

    this.modalService.present(modal);
  }

  toolsMenu() {

  }

  unbindKeyEvents() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }

  update() {

  }

  viewModeChanged(event: any|null = null) {
    this.calcViewVars();
  }

}