import { Injectable } from '@angular/core';

import { AppcmsService } from "src/app/services/core/appcms.service";
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private userService: UserService,
  ) {

  }

  createCalendar(calendar: calendar, options: any = {}) {
    return this.AppCMS.loadPluginData('calendar', Object.assign(options, {
      calendar: calendar,
    }), ['create']);
  }

  createEvent(_event: calendarEvent, options: any = {}) {
    let event: any = Object.assign({}, _event);

    if (!!_event.end) {
      event.end_date = new Date(_event.end * 1000 as any).toISOString();
    }

    if (!!_event.start) {
      event.start_date = new Date(_event.start * 1000 as any).toISOString();
    }

    event.date = event.date || event.start_date;

    delete event.end;
    delete event.start;

    return this.AppCMS.loadPluginData('calendar', Object.assign(options, {
      calendar: event,
    }), ['newEntry']);
  }

  deleteCalendar(calendarId: number) {
    return this.AppCMS.loadPluginData('calendar', { uid: calendarId }, ['calendars', calendarId, 'delete']);
  }

  deleteEvent(eventId: number) {
    return this.AppCMS.loadPluginData('calendar', { uid: eventId }, ['events', eventId, 'delete']);
  }

  getCalendarByUid(eventId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('calendar', {
      from: 'past',
      to: 'future',
    }, ['calendars', eventId], null, blForceRefresh);
  }

  getCalendars(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('calendar', options, ['calendars'], null, blForceRefresh)
        .then((response: any) => {
          if (response && response.result && response.result.length) {
            response.result.forEach((event: calendarEvent) => {
              event = this.getFullEvent(event);
            });
          }
          resolve(response);
        })
        .catch(reject);
    });
  }

  getEventByUid(eventId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('calendar', {}, ['events', eventId], null, blForceRefresh);
  }

  getEvents(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('calendar', options, ['events'], null, blForceRefresh)
        .then((response: any) => {
          if (response && response.result && response.result.length) {
            response.result.forEach((event: calendarEvent) => {
              event = this.getFullEvent(event);
            });
          }
          resolve(response);
        })
        .catch(reject);
    });
  }

  getFullEvent(event: calendarEvent) {
    return event;
  }

  parseDatabaseEvents(events: any[]) {
    return (events || []).map((item: any) => {
      item.end = new Date(item.end || item.date_gmt);
      item.start = new Date(item.start || item.date_gmt);

      return item;
    });
  }

  pick(options: any | null = null, event: any | null = null) {
    return new Promise(async (resolve, reject) => {

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.getCalendars(),
        labelKey: 'title',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
        .then((chooseResponse: chooseResponse) => {
          resolve(chooseResponse);
        })
        .catch(reject);
    });
  }

  updateCalendar(calendar: calendar, options: any = {}) {
    return this.AppCMS.loadPluginData('calendar', Object.assign(options, {
      calendar: calendar,
    }), ['update']);
  }

  updateEvent(_event: calendarEvent, options: any = {}) {
    return new Promise((resolve, reject) => {
      if (!_event.end || !_event.start) {
        console.warn('calendar: missing event time information', _event);
        reject(false);
      } else {

        let event: any = {
          description: _event.description,
          end_date: new Date(_event.end * 1000 as any).toISOString(),
          link: _event.url,
          photo: _event.photo,
          start_date: new Date(_event.start * 1000 as any).toISOString(),
          title: _event.title,
          uid: _event.id,
        };

        event.date = event.date || event.start_date;
        event.user = this.userService.getUid();

        console.log('calendar: updateEvent: event', event);

        this.AppCMS.loadPluginData('calendar', Object.assign(options, {
          event: event
        }), ['updateEvent']).then(resolve).catch(reject);
      }
    });
  }

}