import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-user-group',
  standalone: false,
  templateUrl: './user-group.page.html',
  styleUrls: ['./user-group.page.scss'],
})
export class UserGroupPage implements OnInit {

  item: userGroup = {
    config: {},
  };

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    editMode: false,
    segment: 'general',
    route: 'user-group',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    let userGroup = this.navParams.get('user_group');

    this.item = (userGroup || this.item) || {};
    this.item.config = this.item.config || {};

    this.view.editMode = !!userGroup;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.item && this.item.uid ? 'edit_user_group' : 'create_user_group');
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadUserGroup(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  loadUserGroup(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      resolve(this.view);
    });
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  save() {
    this.userService.createUserGroup(this.item)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  update() {
    this.userService.updateUserGroup(this.item)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}
