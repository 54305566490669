import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { EventsService } from "src/app/services/core/events.service";
import { ReactionsService } from "src/app/services/social/reactions.service";
import { UserService } from "src/app/services/core/user.service";

@Component({
  selector: 'pipeline-chat-message',
  standalone: false,
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  @Input('index') index: number;

  @Input('message') message: inboxChatMessage;
  @Input('messenger') messenger: inboxChat;

  @Output() onResend = new EventEmitter();

  user: user;

  view: any = {};

  constructor(
    private clipboard: ClipboardService,
    private events: EventsService,
    private reactions: ReactionsService,
    private userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
    this.view.reactionEmojiList = this.reactions.getEmojis();
  }

  copyText(text: string) {
    this.clipboard.copyText(text);
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
  }

  onMessageClick(message: inboxChatMessage) {
    switch (message.action) {
      case 'viewPost':
        this.events.publish('view:post', message.item);
        break;
      case 'viewProfile':
        this.events.publish('view:profile', message.user);
        break;
    }
  }

  public resend(message: any) {
    this.onResend.emit(message);
  }

  thumbnailLoadingFailed() {
    this.message.input = this.fallbackImg;
  }

}