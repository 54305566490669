import { Injectable } from '@angular/core';

import { ModalService } from 'src/app/services/core/modal.service';

import { ChooseActionSheetPage } from 'src/app/pages/core/chooser/choose-action-sheet/choose-action-sheet.page';

@Injectable({
  providedIn: 'root'
})
export class ChooserService {

  constructor(
    private modalService: ModalService,
  ) {
  }

  choose(chooseConfig: chooseConfig, event: any|null = null) {
    return new Promise(async (resolve, reject) => {

      const chooseModal: any = await this.modalService.create({
        component: chooseConfig.component || ChooseActionSheetPage,
        componentProps: chooseConfig,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });
      
      chooseModal.onWillDismiss().then((response: any) => {
        console.log('choose: response', response);
        resolve(response);
      });

      this.modalService.present(chooseModal);
    });
  }

}
