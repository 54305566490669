<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Main export button -->
      <ion-button *ngIf="!!view.startManually && !!view.project && !!view.project.uid"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="export()" [disabled]="!!view.loading || !data || !data.length" shape="round" color="primary"
        fill="solid">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'export'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig" [hidden]="!!view.startManually"
    (onIntegrationChanged)="onIntegrationChanged($event)" (onSkip)="startManually()" [(view)]="view">
  </pipeline-intro-overlay>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container slider-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

            <!-- Source -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('source')">
                    <span [innerHTML]="'source'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('source')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.source && !!cards.source.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.source && !cards.source.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_exporter_source_text'|translate"></p>
                  </ion-card-content>

                  <ion-item lines="none" *ngIf="!!view.integrations && !!view.integrations.length">

                    <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                    <ion-select interface="popover" [label]="'integration'|translate" [(ngModel)]="view.integration"
                      (ionChange)="onIntegrationChanged($event)">
                      <ion-select-option *ngFor="let integration of view.integrations; trackBy:trackItems" [value]="integration"
                        [innerHTML]="integration.name|translate"></ion-select-option>
                    </ion-select>
                  </ion-item>

                </div>
              </ion-card>
            </div>

            <!-- Filters -->
            <div class="col-inner" [hidden]="!view.expertMode">
              <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
                (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>
            </div>

            <!-- Output settings -->
            <div class="col-inner">
              <ion-card>

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('output')">
                    <span [innerHTML]="'output'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('output')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.output && !!cards.output.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.output && !cards.output.open">

                  <ion-card-content>
                    <p [innerHTML]="'data_exporter_output_text'|translate"></p>
                  </ion-card-content>

                  <!-- Output type -->
                  <ion-radio-group [(ngModel)]="view.outputType">

                    <!-- Static -->
                    <ion-item>

                      <ion-icon name="list-outline" slot="start"></ion-icon>

                      <ion-radio labelPlacement="start" value="static">
                        <ion-label class="ion-text-wrap">
                          <h3 [innerHTML]="'static'|translate"></h3>
                          <p [innerHTML]="'data_export_type_static_description'|translate"></p>
                        </ion-label>
                      </ion-radio>

                    </ion-item>

                    <!-- Dynamic -->
                    <ion-item lines="none">

                      <ion-icon name="funnel-outline" slot="start"></ion-icon>

                      <ion-radio labelPlacement="start" value="dynamic">
                        <ion-label class="ion-text-wrap">
                          <h3 [innerHTML]="'dynamic'|translate"></h3>
                          <p [innerHTML]="'data_export_type_dynamic_description'|translate"></p>
                        </ion-label>
                      </ion-radio>

                    </ion-item>

                  </ion-radio-group>

                  <ion-list>

                    <!-- File name -->
                    <ion-item>
                      <ion-icon name="text-outline" slot="start"></ion-icon>
                      <ion-input [(ngModel)]="settings.file_name" labelPlacement="stacked" name="fileName"
                        [label]="'file_name'|translate"></ion-input>
                    </ion-item>

                    <!-- Format -->
                    <ion-item>

                      <ion-icon name="document-attach-outline" slot="start"></ion-icon>

                      <ion-select interface="popover" labelPlacement="stacked" [(ngModel)]="settings.format"
                        name="format" [label]="'format'|translate" (ionChange)="onFormatChanged()">
                        <ion-select-option value="csv">CSV</ion-select-option>
                        <ion-select-option value="excel">Excel</ion-select-option>
                        <ion-select-option value="html">HTML</ion-select-option>
                        <ion-select-option value="json">JSON</ion-select-option>
                        <ion-select-option value="tsv">TSV</ion-select-option>
                        <ion-select-option value="raw" [innerHTML]="'raw_data'|translate"></ion-select-option>
                        <ion-select-option value="xml">XML</ion-select-option>
                      </ion-select>

                    </ion-item>

                    <!-- Seperator -->
                    <ion-item [hidden]="settings.format !== 'csv'" *ngIf="!!view.seperators">
                      <ion-icon name="return-down-back-outline" slot="start"></ion-icon>
                      <ion-select [(ngModel)]="settings.seperator" name="seperator" labelPlacement="stacked"
                        interface="popover" [label]="'seperator'|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate">
                        <ion-select-option *ngFor="let seperator of view.seperators"
                          [innerHTML]="seperator.label|translate" [value]="seperator.indent"></ion-select-option>
                      </ion-select>
                    </ion-item>

                    <!-- ZIP -->
                    <!--
                    <ion-item>
                      <ion-icon name="cube-outline" slot="start"></ion-icon>
                      <ion-checkbox [(ngModel)]="settings.compress_to_zip" name="compressToZip">
                        <ion-label [innerHTML]="'compress_to_zip'|translate"></ion-label>
                      </ion-checkbox>
                    </ion-item>
                    -->

                    <!-- Smart URL -->
                    <pipeline-shortlink-generator *ngIf="!!view.smart_link" [config]="shortlinkConfig"
                      [url]="view.exportUrl" [link]="view.smart_link"></pipeline-shortlink-generator>

                  </ion-list>

                </div>

              </ion-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop ? 9 : 12">

            <!-- Intro card -->
            <pipeline-intro-card [hidden]="view.introCard.hidden" class="introCard"
              [(view)]="view.introCard"></pipeline-intro-card>

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <!-- Preview -->
            <ion-card [hidden]="!!view.loading">

              <ion-card-header>
                <ion-card-title [innerHTML]="'data_exporter_preview'|translate"></ion-card-title>
                <ion-card-subtitle *ngIf="!!data.length"
                  [innerHTML]="data.length + ' ' + ('entries'|translate)"></ion-card-subtitle>
              </ion-card-header>

              <!-- Dynamic data preview -->
              <pipeline-table-view [hidden]="view.viewType !== 'table'" *ngIf="!!view.preview_data"
                (itemsChanged)="onItemsChanged($event)" [(items)]="view.preview_data" [buttons]="buttons"
                [fields]="fields" [(view)]="view" [method]="view.method" mode="view" [options]="tableViewOptions"
                [service]="service"></pipeline-table-view>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>