<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <!-- Options button -->
      <ion-button *ngIf="!!view.coder_projects && !!view.coder_projects.length" (click)="showHeaderPopover($event)"
        fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add button -->
      <ion-button (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Mobile selection toolbar -->
  <pipeline-selection-toolbar
    *ngIf="!view.isDesktop && (!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid))"
    [(items)]="view.coder_projects" [options]="selectionOptions" [(paginationConfig)]="paginationConfig" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Export -->
        <ion-item lines="none" button (click)="export($event)" [disabled]="!!view.loading"
          *ngIf="!!view.coder_projects && !!view.coder_projects.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)"
    [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" *ngIf="!view.dragging">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <pipeline-intro-card [hidden]="!!view.isDesktop || view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <ion-grid class="ai-codes-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="aiTools" location="projects" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadCoderProjectsByFolder($event)"></pipeline-folders-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Desktop selection toolbar -->
          <pipeline-selection-toolbar
            *ngIf="!!view.isDesktop && (!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid))"
            [(items)]="view.coder_projects" [options]="selectionOptions" [(view)]="view"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <pipeline-intro-card [hidden]="!view.isDesktop || view.introCard.hidden" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- No entries card -->
          <pipeline-no-entries-card [cta]="cta" [text]="'ai_codes_cta_text'|translate"
            *ngIf="view.coder_projects && !view.coder_projects.length && !view.loading"></pipeline-no-entries-card>

          <!-- Results list -->
          <ion-grid *ngIf="!!view.coder_projects && !!view.coder_projects.length">
            <ion-row>

              <ion-col *ngFor="let project of view.coder_projects; let i = index; trackBy:trackItems"
                [size]="view.viewType === 'list' ? 12 : view.colSize.item">

                <ion-card>

                  <ion-item [lines]="!!view.isDesktop ? true : 'none'">

                    <ion-checkbox [(ngModel)]="project.checked" justify="start" labelPlacement="end"
                      (ionChange)="onItemChecked(project)">
                      <p [innerHTML]="project.name" class="ion-text-wrap"></p>
                    </ion-checkbox>

                    <ion-button slot="end" (click)="presentPopover($event, project)" fill="clear" icon-only color="dark"
                      *ngIf="(view.mode !== 'pick') && !!project.uid">
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <!-- Simple mode: Preview -->
                  <iframe *ngIf="!view.expertMode" class="code-preview" [srcdoc]="project.code_preview"></iframe>

                  <!-- Expert mode: Files -->
                  <ion-list *ngIf="!!view.expertMode && !!project.files && (view.viewType !== 'list')"
                    [hidden]="!view.isDesktop">
                    <ion-item *ngFor="let file of project.files; let iSub = index; trackBy:trackItems">

                      <ion-icon *ngIf="!!file.icon" [name]="file.icon" slot="start"></ion-icon>

                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="file.label || ('no_name'|translate)"></h3>
                      </ion-label>

                    </ion-item>
                  </ion-list>

                  <ion-item lines="none">

                    <!-- Reload -->
                    <ion-button [hidden]="!!view.expertMode" slot="end" fill="clear" color="dark" icon-only
                      (click)="refreshView(project)">
                      <ion-icon name="sync-outline"></ion-icon>
                    </ion-button>

                    <!-- Edit -->
                    <ion-button slot="end" fill="clear" icon-only (click)="edit(project)" color="dark">
                      <ion-icon name="create-outline"></ion-icon>
                    </ion-button>

                    <!-- Delete -->
                    <ion-button slot="end" fill="clear" icon-only (click)="delete(project)" color="danger">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </ion-col>

            </ion-row>
          </ion-grid>


        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>