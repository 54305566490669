<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
      
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Small generate button (if buttonAction = use)-->
      <ion-button icon-only
        *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="!!view.showSplineView" (click)="aiCreate()" color="dark" fill="clear"
        [disabled]="!!view.loading || (view.phase === 'loading')">
        <ion-icon name="sync-outline"></ion-icon>
      </ion-button>

      <!-- Download button -->
      <!--
      <ion-button *ngIf="!!view.finalResultUrl" [href]="view.finalResultUrl" download
        [color]="view.downloaded ? 'success' : 'primary'" fill="clear">
        <ion-icon [name]="view.downloaded ? 'download' : 'download-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="(view.downloaded ? 'downloaded' : 'download')|translate"></ion-label>
      </ion-button>
      -->

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create text to speech intro mode -->
  <div class="tool-intro-wrapper">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'ai_tts_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <ion-textarea rows="1" [(ngModel)]="view.aiCreateInput" [disabled]="view.loading"
          [placeholder]="'ai_tts_helper_input_placeholder'|translate"></ion-textarea>

        <audio *ngIf="!!view.outputSrc" controls autoplay loop [src]="view.outputSrc"></audio>

        <ion-spinner [hidden]="!view.loading"></ion-spinner>

        <ion-grid>
          <ion-row>

            <!-- AI create -->
            <ion-col [size]="view.isDesktop ? 6 : 12">

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
                [disabled]="!view.aiCreateInput || !view.aiCreateInput.length">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>

            </ion-col>

            <!-- AI settings -->
            <ion-col [size]="view.isDesktop ? 6 : 12">

              <pipeline-ai-settings-picker [config]="aiSettings" context="text-generation" size="block" fill="clear"
                [mini]="false" [view]="view" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-card>

    </div>
  </div>

  <div class="container" [hidden]="view.introCard.hidden">
    <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
  </div>

  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>