<ion-card class="sidebar-accordion-card">

    <!-- Header -->
    <ion-card-header>

        <ion-card-title>
            <span [innerHTML]="'connections'|translate"></span>
        </ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.connections" class="card-toggle" (click)="toggleCard()" icon-only
            color="dark" fill="clear">
            <ion-icon
                [name]="!!cards && !!cards.connections && cards.connections.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Loading spinner -->
    <ion-spinner
        [hidden]="!cards || !cards.connections || !cards.connections.open || !view.loadingConnections"></ion-spinner>

    <!-- No entries -->
    <pipeline-no-entries-card [cta]="cta" [hidden]="!cards || !cards.connections || !cards.connections.open"
        [text]="'connections_cta_text'|translate"
        *ngIf="(!view.connections || (view.connections && !view.connections.length)) && !view.loadingConnections"></pipeline-no-entries-card>

    <ion-list [hidden]="!cards || !cards.connections || !cards.connections.open || !!view.loadingConnections"
        *ngIf="!!view.connections && !!view.connections.length">

        <ion-item *ngFor="let connection of view.connections; trackBy:trackItems" class="connection-btn"
            [class.active]="connection.checked" [drop]="!!view.isDesktop" [dropItem]="connection"
            dropItemType="connection" (itemDropped)="handleItemDropped($event)">

            <ion-thumbnail (click)="openConnection(connection)" slot="start" class="connection-preview">
                <ion-img [src]="connection.photo || fallbackImg"
                    (ionError)="thumbnailLoadingFailed(connection)"></ion-img>
            </ion-thumbnail>

            <ion-checkbox [(ngModel)]="connection.checked" class="ion-text-wrap" justify="start" labelPlacement="end"
                [disabled]="!connection.uid" (ionChange)="onConnectionItemChecked(connection)">
                <ion-label class="ion-text-wrap" (click)="openConnection(connection)">
                    <h3 [innerHTML]="connection.name|translate"></h3>
                    <p *ngIf="!!connection.email || !!connection.platform"
                        [innerHTML]="connection.email || (connection.platform|translate)"></p>
                </ion-label>
            </ion-checkbox>

            <ion-spinner slot="end" [hidden]="!!connection.uid"></ion-spinner>

        </ion-item>

    </ion-list>

</ion-card>