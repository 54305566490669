<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Export -->
      <ion-button (click)="export()" color="dark" fill="clear" [hidden]="view.mode === 'pick'"
        [disabled]="!!view.loading">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'export'|translate"></ion-label>
      </ion-button>

      <!-- Add -->
      <ion-button (click)="create()" color="primary" shape="round" fill="solid">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner name="circular" *ngIf="!view.target_groups"></ion-spinner>

    <ion-card *ngIf="view.target_groups && !view.target_groups.length">
      <ion-card-header>
        <ion-card-title [innerHTML]="'no_target_groups'|translate"></ion-card-title>
        <ion-card-subtitle [innerHTML]="'no_target_groups_subheadline'|translate"></ion-card-subtitle>
      </ion-card-header>
    </ion-card>

    <div *ngIf="view.target_groups">

      <div class="cdk-wrapper">
        <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
          [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">

          <div *cdkVirtualFor="let group of view.target_groups" [hidden]="group.hidden">

            <ion-card>
              <ion-list lines="none">
                <ion-item-sliding>
                  <ion-item class="ion-text-wrap" lines="none">
                    <ion-icon (click)="toggleActive(group)"
                      [name]="group.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                      [color]="group.active ? 'success' : 'danger'" slot="start"></ion-icon>
                    <ion-label class="ion-text-wrap" (click)="viewGroup(group)">
                      <h2 class="ion-text-wrap" [innerHTML]="group.label || ('no_name'|translate)"></h2>

                      <span class="badge" *ngIf="group.min_age && group.max_age">
                        <span [innerHTML]="'age'|translate"></span>:
                        <span [innerHTML]="group.min_age + ' - ' + group.max_age"></span>
                      </span>

                      <span class="badge" *ngIf="group.languages">
                        <span [innerHTML]="'languages'|translate"></span>:
                        <span *ngFor="let language of group.languages" [innerHTML]="language"></span>
                      </span>

                    </ion-label>

                    <ion-button slot="end" fill="clear" [hidden]="!view.isDesktop" color="danger"
                      (click)="delete(group)">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate"></ion-label>
                    </ion-button>

                  </ion-item>
                  <ion-item-options slot="end" *ngIf="!view.isDesktop">
                    <ion-item-option color="danger" (click)="delete(group)">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate"></ion-label>
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </ion-list>
            </ion-card>

          </div>

        </cdk-virtual-scroll-viewport>
      </div>

    </div>

  </div>

</ion-content>