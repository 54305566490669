import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DragService {

    draggedItem: any;

    groups: any = {};

    constructor(
    ) {

    }

    makeDraggable(element: HTMLElement, handler: any | null = null) {
        let animationFrameId: number;

        if (!handler) {
            handler = element.querySelector('ion-header');
        }

        handler = handler || element;

        let offset = { x: 0, y: 0 };
        let isDown = false;
        let currentTransform = { x: 0, y: 0 };

        element.style.willChange = 'transform';
        element.style.transform = 'translate3d(0, 0, 0)';
        element.style.transition = 'transform 0.1s ease-out';

        const handleStart = (e: MouseEvent | TouchEvent) => {
            isDown = true;
            element.style.transition = 'none';

            const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
            const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

            const rect = element.getBoundingClientRect();
            offset = {
                x: clientX - rect.left,
                y: clientY - rect.top
            };

            const style = window.getComputedStyle(element);
            const matrix = new WebKitCSSMatrix(style.transform);

            currentTransform = {
                x: matrix.m41,
                y: matrix.m42
            };
        };

        const handleEnd = () => {
            if (!isDown) return;
            isDown = false;
            element.style.transition = 'transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)';
            element.style.willChange = 'auto';

            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (!isDown) return;
            e.preventDefault();

            animationFrameId = requestAnimationFrame(() => {
                const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
                const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

                const x = clientX - offset.x;
                const y = clientY - offset.y;

                element.style.transform = `translate3d(${x}px, ${y}px, 0)`;
            });
        };

        handler.addEventListener('mousedown', handleStart, { passive: true });
        handler.addEventListener('touchstart', handleStart, { passive: true });

        document.addEventListener('mouseup', handleEnd, { passive: true });
        document.addEventListener('touchend', handleEnd, { passive: true });

        document.addEventListener('mousemove', handleMove, { passive: false });
        document.addEventListener('touchmove', handleMove, { passive: false });

        return () => {

            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }

            handler.removeEventListener('mousedown', handleStart);
            handler.removeEventListener('touchstart', handleStart);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);

            element.style.willChange = 'auto';
            element.style.transform = '';
            element.style.transition = '';
        };
    }

}