<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>
  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <ion-card>
    <ion-card-content>
      <p [innerHTML]="'share_fallback_text'|translate"></p>
    </ion-card-content>
    <ion-list lines="none">
      <ion-item *ngIf="shareOptions.title && shareOptions.title.length">
        <ion-label position="stacked" [innerHTML]="'subject'|translate"></ion-label>
        <ion-input [(ngModel)]="shareOptions.title" [placeholder]="'subject'|translate"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked" [innerHTML]="'message'|translate"></ion-label>
        <ion-textarea rows="5" [(ngModel)]="shareOptions.message" [placeholder]="'message'|translate"></ion-textarea>
      </ion-item>
      <ion-item *ngIf="shareOptions.url && shareOptions.url.length">
        <ion-label position="stacked" [innerHTML]="'url'|translate"></ion-label>
        <ion-input [(ngModel)]="shareOptions.url" disabled [placeholder]="'url'|translate"></ion-input>
      </ion-item>
    </ion-list>
    <ion-card-content>
      <ion-button expand="block" (click)="copyToClipboard()">
        <ion-label [innerHTML]="'copy_to_clipboard'|translate"></ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>

</ion-content>