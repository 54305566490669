import { Component, Input, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { WhitelabelService } from 'src/app/services/pipeline/whitelabel.service';

@Component({
  selector: 'pipeline-whitelabel-configuration-slider',
  standalone: false,
  templateUrl: './whitelabel-configuration-slider.component.html',
  styleUrls: ['./whitelabel-configuration-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhitelabelConfigurationSliderComponent implements OnDestroy, OnInit {
  @Input() app: whitelabelApp;

  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild('whitelabelConfiguratorSlider') slider: ElementRef | undefined;

  sliderOptions: any = {
    allowTouchMove: false,
    //autoHeight: true,
    //parallax: false,
    //navigation: false,
    //speed: 1000,
    //slidesPerView: 1,
    //slidesPerGroup: 1,
    //virtual: false,
  };

  view: any = {
    canSlideBack: false,
    canSlideNext: false,
    colors: [
      'primary',
      'secondary',
      'tertiary',
      'success',
      'warning',
      'danger',
      'black',
      'dark',
      'medium',
      'light',
      'white',
    ],
    colorsHex: [
      '#F48B94',
      '#E2BB78',
      '#507E65',
      '#6C5B7B',
      '#355C7D',
      '#A1EAF2',
      '#D6EBC2'
    ],
    colorVariations: [
      'rgb',
      'contrast',
      'contrast-rgb',
      'shade',
      'tint'
    ],
    colSize: (window.innerWidth > 768 ? 6 : 12),
    hasUI: false,
    introCard: {
      create: {
        uid: 'create_whitelabel_app_top_card',
        subtitle: 'create_whitelabel_app_top_card_subtitle',
        text: 'create_whitelabel_app_top_card_text',
        title: 'create_whitelabel_app_top_card_title',
      },
      edit: {
        uid: 'edit_whitelabel_app_top_card',
        subtitle: 'edit_whitelabel_app_top_card_subtitle',
        text: 'edit_whitelabel_app_top_card_text',
        title: 'edit_whitelabel_app_top_card_title',
      }
    },
    plans: [],
    platforms: [
      {
        checked: true,
        icon: 'code-outline',
        name: 'api',
        uid: 'api',
      },
      {
        icon: 'logo-android',
        name: 'android',
        uid: 'android',
      },
      {
        icon: 'logo-apple',
        name: 'ios',
        uid: 'ios',
      },
      {
        icon: 'globe-outline',
        name: 'web',
        uid: 'web',
      },
    ],
    sourceTypes: ['facebook', 'instagram', 'spotify', 'url'],
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private whitelabelService: WhitelabelService,
  ) {
    this.app = this.app || {};
    this.app.config = this.app.config || {};
    this.app.sources = this.app.sources || [];
    this.app.testers = this.app.testers || [];
  }

  addSource() {
    this.app.sources.push({
      type: 'url',
    });
  }

  addTester() {
    this.app.testers.push({
      email: '',
      firstname: '',
      lastname: '',
    });
  }

  calcAppId() {
    this.app.config.appName = this.app.config.appName || this.app.name;
    this.app.config.appId = this.whitelabelService.calculateAppId(this.app.config.appName);
  }

  calcAppPackageName() {
    this.app.config.appName = this.app.config.appName || this.app.name;
    this.app.config.appPackageName = this.whitelabelService.calculateAppPackageName(this.app.config.appName);
  }

  calcColSizes() {
    this.view.colSize = (window.innerWidth > 768 ? 6 : 12);
  }

  calcHideUI() {
    let bl: boolean = false, uiPlatforms: string[] = ['android', 'ios', 'web'];

    this.view.platforms.forEach((platform: any) => {
      if (!!platform.checked && uiPlatforms.indexOf(platform.uid) !== -1) {
        bl = true;
      }
    });

    this.view.hasUI = !!bl;
  }

  async calcViewStates() {
    this.calcAppId();
    this.calcAppPackageName();

    this.view.canBack = false;
    this.view.canSave = true;
    this.view.canSlideNext = true;
    this.view.canUpdate = true;

    try {
      let activeIndex: number = await this.slider.nativeElement.swiper.activeIndex;;
      console.log('activeIndex', activeIndex);

      if (!!activeIndex) {
        this.view.canBack = true;
      }

      switch (activeIndex) {

      }

    } catch (e) {
      console.warn('calc slide state failed', e);
    }

    this.events.publish('whitelabel:configurator:view:updated', this.view);
  }

  deleteSource(source: any, index: number) {
    this.app.sources = this.app.sources.filter((_source: any, _index: number) => {
      return _index !== index;
    });
  }

  deleteTester(tester: any, index: number) {
    this.app.testers = this.app.testers.filter((_tester: any, _index: number) => {
      return _index !== index;
    });
  }

  initEvents() {

    this.events.subscribe('whitelabel:configurator:slide:back', () => {
      this.slideBack();
    });

    this.events.subscribe('whitelabel:configurator:slide:next', () => {
      this.slideNext();
    });

  }

  async loadPlans() {
    try {
      let plans: whitelabelAppPlan[] = (await this.whitelabelService.getPlans() as whitelabelAppPlan[]);

      if (!!plans) {
        this.view.plans = plans.filter((plan: whitelabelAppPlan) => {
          return plan.indent !== 'basic';
        });
      }

    } catch (e) {
      console.warn('load plans error', e);
    }
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
    this.loadPlans();

    setTimeout(() => {
      this.calcViewStates();
    });
  }

  onInputChanged(event: any | null = null) {
    this.calcViewStates();
  }

  @HostListener('window:resize')
  onResize() {
    this.calcColSizes();
  }

  slideBack() {
    this.slider.nativeElement.swiper.slidePrev();
  }

  slideNext() {
    this.update()
      .then(() => {
        try {
          this.slider.nativeElement.swiper.slideNext();
        } catch (e) {
          console.warn('> slide next failed', e);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(key: string | null = null) {
    this.app.config = this.app.config || {};
    this.app.config.appIconUrl = (this.app.icon || this.fallbackImg);
  }

  update() {
    return new Promise((resolve, reject) => {

      if (!this.app || !this.app.uid) {
        resolve(true);
        return false;
      }

      this.whitelabelService.updateWhitelabelApp(this.app)
        .then((response: any) => {

          if (response && !!response.app) {
            this.app = response.app;
            this.calcViewStates();
          }

          resolve(this.app);
        })
        .catch(reject);
    });
  }

  uploadLogo(logoType: string) {
    this.media.applyFromWeb(null, {
      services: ["upload", "media_library"],
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.app.config[logoType] = imageUrl;

        if ((logoType === 'app_icon')) {
          this.app.icon = imageUrl;
        }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useColorHex(hex: string) {
    this.view.colorHex = hex;
  }

  usePlan(plan: whitelabelAppPlan) {
    plan.checked = !plan.checked;
  }

  usePlatform(platform: any) {
    platform.checked = !platform.checked;

    this.calcHideUI();
  }

}
