import { AfterContentInit, Component, OnInit } from '@angular/core';

import { BrowserService } from "src/app/services/utils/browser.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'app-data-duplicator',
  standalone: false,
  templateUrl: './data-duplicator.page.html',
  styleUrls: ['./data-duplicator.page.scss'],
})
export class DataDuplicatorPage implements AfterContentInit, OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  buttons: [

  ];

  cards: any = {
    filters: { open: true },
    item_attributes: { open: true },
    output: { open: true },
    source: { open: true },
  };

  data: any; // provided by modalCtrl

  exportService: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'data_duplicator_helper_text',
    showAiCreate: false,
    showAiSettings: false,
    showInput: false,
    showIntegrations: true,
  };

  items: any[];

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  service: any; // provided by modalCtrl

  settings: any = {
    format: 'csv',
    seperator: ',',
  };

  source: any; // provided by modalCtrl

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  type: any; // provided by modalCtrl

  view: any = {
    buttonAction: 'export',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    introCard: {
      uid: 'data_duplicator_top_card',
      text: 'data_duplicator_top_card_text',
      title: 'data_duplicator_top_card_title',
    },
    multiple: true,
    output: '',
    phase: 'edit',
    progress: 0,
    route: 'ai/items',
    sections: [],
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    tab: 'item',
    title: 'data_duplicator',
    viewType: 'grid',
  };

  constructor(
    private browser: BrowserService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcFilters() {
    const blockKeys: string[] = ['checked', 'field_optimizations', 'image', 'photo', 'timestamp', 'uid', 'variations'];
    let filters: any[] = [], valuesByKey: any = {};

    if (!this.data || !this.data[0]) {
      return false;
    }

    const keys: string[] = Object.keys(this.data[0]).filter((key: string) => {
      return blockKeys.indexOf(key) === -1;
    });

    this.data.forEach((item: any) => {
      keys.forEach((key: string) => {
        valuesByKey[key] = valuesByKey[key] || [];
        if (!!item[key] && (valuesByKey[key].indexOf(item[key]) === -1)) {
          valuesByKey[key].push(item[key]);
        }
      });
    });

    keys.forEach((key: string) => {

      const values: any[] = (valuesByKey[key] || []).map((value: string) => {
        return { label: value, value: value };
      });

      if (!!values && !!values.length) {
        filters.push({
          label: key,
          name: key,
          type: 'select',
          values: values,
          uid: key,
        });
      }
    });

    this.view.filters = (filters || []);
  }

  calcParams() {
    return {
      data: this.data,
      settings: this.settings,
      //service: this.service,
      source: this.source,
      type: this.type,
    };
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    // skip intro view if data is provided
    if (!this.view.startManually && (!!this.service && !!this.items && !!this.items.length)) {
      this.view.startManually = true;
    }
  }

  create() {

  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load() {
    await this.loadProject();

    if (!!this.view.project) {
      await this.loadIntegrations();
    }

    console.log('data-duplicator: data', this.data);
    console.log('data-duplicator: service', this.service);
    console.log('data-duplicator: source', this.source);
    console.log('data-duplicator: type', this.type);

    // if all params are provided, skip intro config view
    if (!!this.data && !!this.service && !!this.source && !!this.type) {

      this.data = this.data.map((item: any) => {
        item.checked = true;
        return item;
      });

      this.calcFilters();
      this.startManually();
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.integrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.loadCards();

    this.calcViewVars();

    this.load();
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
  }

  onFormatChanged() {
    console.log('onFormatChanged', this.settings.format);

    switch (this.settings.format) {
      case 'csv':
        this.view.seperator = 'comma';
        break;
      case 'tsv':
        this.view.seperator = 'tab';
    }
  }

  async onIntegrationChanged(event: any | null = null) {
    console.log('onIntegrationChanged', event);

    this.view.integrationName = (!!this.view.integration ? `${this.view.integration.name}`.replace('integration_', '') : null);
    this.view.startManually = true;

    //await this.loadSettings();
    //await this.loadRoutes();
  }

  onLanguageChange(event: string | null = null) {
    this.view.language = event;
    this.saveFilters();
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      //this.media.saveFilters(this.view.filters);
    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}