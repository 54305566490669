import { Component, OnDestroy, OnInit, Input } from '@angular/core';

import { SharingService } from 'src/app/services/sharing/sharing.service';

@Component({
  selector: 'pipeline-url-preview',
  standalone: false,
  templateUrl: './url-preview.component.html',
  styleUrls: ['./url-preview.component.scss'],
})
export class UrlPreviewComponent implements OnDestroy, OnInit {
  @Input() data: any;

  fallbackImg: string = './assets/img/fallback.webp';
  
  constructor(
    private sharing: SharingService,
  ) {

  }

  avatarLoadingFailed() {
    this.data.avatar = this.fallbackImg;
  }

  async loadData() {
    this.data = this.data || {};
    this.data.metaData = (this.data.hasOwnProperty('metaData') ? this.data.metaData : (!!this.data.url ? await this.sharing.getURLMetaData(this.data.url) : {}));

    this.data.avatar = this.data.avatar || this.data.metaData['msapplication-TileImage'];
    this.data.description = this.data.description || (this.data.metaData['og:description'] || this.data.metaData.description);
    this.data.name = this.data.name || this.data.metaData.author;
    this.data.thumbnail = this.data.thumbnail || this.data.metaData['og:image'];
  }

  ngOnDestroy() {
    
  }

  ngOnInit() {
    this.loadData();
  }

  thumbnailLoadingFailed() {
    this.data.thumbnail = this.fallbackImg;
  }

}
