import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { ConfigService } from "src/app/services/core/config.service";
import { CrawlerService } from "src/app/services/utils/crawler.service";
import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ModalService } from "src/app/services/core/modal.service";
import { TemplateEditorService } from 'src/app/services/media/template-editor.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ProjectsService } from 'src/app/services/core/projects.service';

@Component({
  selector: 'app-ai-url-lookup',
  standalone: false,
  templateUrl: './ai-url-lookup.page.html',
  styleUrls: ['./ai-url-lookup.page.scss'],
})
export class AiUrlLookupPage implements OnDestroy, OnInit {
  @ViewChild('urlLookupFrame') urlLookupFrame: ElementRef | undefined;

  aiSettings: aiSettings = {

  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'ai_url_lookup_ai_helper_text',
    input_placeholder: 'ai_url_lookup_ai_helper_input_placeholder',
    showAiCreate: true,
    showAiSettings: false,
    showInput: true,
    showIntegrations: false,
    submitLabel: 'analyse',
  };

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'cloud-download-outline',
      label: 'import',
      uid: 'import',
    }
  ];

  splineOptions: splineOptions = {
  };

  shortlinkConfig: shortlinkConfig = {
    canInput: false,
    hasInput: false,
  };

  state: state = {};

  view: any = {
    aspect_ratios: [
      {
        uid: '16x9',
        name: '16 x 9',
      },
      {
        uid: '1x1',
        name: '1 x 1',
      },
      {
        uid: '9x16',
        name: '9 x 16',
      },
    ],
    buttonAction: 'search',
    colors: [
      {
        name: 'color_scheme_light',
        uid: 'light',
      },
      {
        name: 'color_scheme_dark',
        uid: 'dark',
      }
    ],
    filters: {
      aspect_ratio: '1x1',
      colors: ['light', 'dark'],
      platforms: ['facebook', 'instagram', 'tiktok', 'youtube'],
      types: ['image', 'video'],
    },
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_url_lookup_top_card',
      lottieSrc: './assets/lottie/ai_url_lookup.json',
      text: 'ai_url_lookup_top_card_text',
      title: 'ai_url_lookup_top_card_title',
    },
    media: {},
    mediaList: [],
    route: 'ai_url_lookup',
    segment: 'ideas',
    showMenuButton: true,
    showProjectsSelect: true,
    source: {

    },
    title: 'ai_url_lookup',
    templateView: {
      editable: false,
      infinite: true,
      running: false,
      maxHeight: 300,
      maxWidth: 300,
    },
  };

  constructor(
    private ai: AiToolsService,
    private configService: ConfigService,
    private crawler: CrawlerService,
    private domSanitizer: DomSanitizer,
    private editor: TemplateEditorService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  aiCreate() {
    this.runSearch();
  }

  applyCIToTemplate(ci: any, template: mediaTemplate) {
    template.config = template.config || {};
    template.config.Comp = (template.config.Comp || {} as any);
    template.config.Comp.accent = (template.config.Comp.accent || {} as any);
    template.config.Comp.call2action = (template.config.Comp.call2action || {} as any);
    template.config.Comp.call2action_link = (template.config.Comp.call2action_link || {} as any);
    template.config.Comp.source = (template.config.Comp.source || {} as any);
    template.config.Comp.subtext = (template.config.Comp.subtext || {} as any);
    template.config.Comp.title = (template.config.Comp.title || {} as any);

    if (!!ci && !!ci.icon) {
      template.config._meta = template.config._meta || {};
      template.config._meta.logo_src = ci.icon;
      template.config.Comp.logo_bg_color = '#ffffff';
    }

    if (!!ci && !!ci.primary_color) {
      template.config.Comp.accent.color = ci.primary_color;
      template.config.Comp.accent.color_dark = ci.primary_color;

      template.config.Comp.call2action.bg_color = ci.primary_color;
      template.config.Comp.call2action.bg_color_dark = ci.primary_color;
      template.config.Comp.call2action_link.bg_color = ci.primary_color;
      template.config.Comp.call2action_link.bg_color_dark = ci.primary_color;

      template.config.Comp.subtext.bg_color = ci.primary_color;
      template.config.Comp.subtext.bg_color_dark = ci.primary_color;

      template.config.Comp.title.bg_color = ci.primary_color;
      template.config.Comp.title.bg_color_dark = ci.primary_color;

      if (ci.primary_color.indexOf('#fff') === -1) {
        template.config.Comp.call2action.color = '#ffffff';
        template.config.Comp.call2action.color_dark = '#ffffff';
        template.config.Comp.call2action_link.color = '#ffffff';
        template.config.Comp.call2action_link.color_dark = '#ffffff';

        template.config.Comp.title.color = '#ffffff';
        template.config.Comp.title.color_dark = '#ffffff';

        template.config.Comp.subtext.color = '#ffffff';
        template.config.Comp.subtext.color_dark = '#ffffff';
      }

    }

    this.templates.setRootVars(template);

    return template;
  }

  calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (window.innerWidth > 1280 ? 3 : (!!this.view.isDesktop ? 4 : 12));
  }

  createMediaTemplates(response: any) {
    this.zone.run(() => {
      this.templates.bulkGenerateTemplates(response.ideas, this.view)
        .then((items: mediaTemplate[]) => {
          this.zone.run(() => {

            let ideas: any[] = this.tools.shuffle(items.map((item: any) => {
              item.config = JSON.parse(item.value);
              item.custom = true;
              item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));

              item.uid = item.uid || item.template_uid;
              return item;
            })).slice(0, 100);

            this.view.ideas = ideas;

            this.segmentChanged();
          });
        })
        .catch((error: any) => {
          this.view.loading = false;
          this.view.success = false;

          this.events.publish('error', error);
        });
    });
  }

  deleteSelectedItems() {
    if (this.view.ideas[this.view.segment] && !!this.view.ideas[this.view.segment].length) {
      this.view.ideas[this.view.segment] = this.view.ideas[this.view.segment].filter((_item: any) => {
        return !_item.checked;
      });
    }
  }

  dismiss() {
    this.modalService.dismiss();
  }

  async editCustomTemplate(template: any) {

    if (!template.custom) {
      return false;
    }

    this.editor.editCustomTemplate(template);
  }

  extractMediaListFromIdeas() {

    if (!this.view.ideas || !this.view.ideas.length) {
      return false;
    }

    let mediaList: any[] = [], mediaListUrls: string[] = [];

    this.view.ideas.forEach((idea: any) => {
      if (!!idea.photo && !!idea.photo.length && (mediaListUrls.indexOf(idea.photo) === -1)) {
        mediaList.push(idea);
        mediaListUrls.push(idea.photo);
      }
    });

    // apply media list from ideas
    this.view.mediaList = mediaList;
  }

  extractPostsFromIdeas() {

    if (!this.view.ideas || !this.view.ideas.length) {
      return false;
    }

    let posts: any[] = [];

    this.view.ideas.forEach((idea: any) => {
      console.log('> idea to post', idea);
    });

    this.view.posts = posts;
  }

  iFrameLoaded(event: any | null = null) {

    if (!this.urlLookupFrame || !this.urlLookupFrame.nativeElement) {
      return false;
    }

    const iframeDocument = this.urlLookupFrame.nativeElement.contentDocument || this.urlLookupFrame.nativeElement.contentWindow.document;
  }

  importSelectedItems() {

    let checkedItems: any = this.view.ideas.filter((idea: any) => {
      return !!idea && !!idea.checked;
    });

    if (!checkedItems || !checkedItems.length) {
      return false;
    }

    if (this.view.segment === 'media') {
      this.view.phase = 'queue';
    }

    checkedItems.forEach((_item: any) => {
      switch (this.view.segment) {
        case 'media':
          this.importSingleMedia(_item);
          break;
        case 'posts':
          this.importSinglePost(_item);
          break;
      }
    });
  }

  importSingleMedia(idea: aiIdea) {

    if (!!idea.headline) {
      idea.config.Comp.title.text = idea.headline;
    }

    if (!!idea.text) {
      idea.config.Comp.subtext.text = idea.text;
    }

    if (!!idea.photo) {
      idea.config._meta = idea.config._meta || {};
      idea.config._meta.bg_src = idea.photo;

      delete idea.config._meta.media;
    }

    let media: mediaQueueItem = {
      active: true,
      name: idea.headline,
      state: 'waiting',
      template_uid: idea.template_uid,
      type: idea.type,
      value: JSON.stringify(idea.config),
    };

    this.media.create(media)
      .then(() => {
        idea.disabled = true;
      })
      .catch((error: any) => {
        console.warn('> media creation failed', error);
      });
  }

  importSinglePost(idea: aiIdea) {
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  ionViewWillEnter() {
    this.initEvents();
    this.loadTemplates();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadNewContentIdeas() {
    this.ai.createContentIdeas({
      history: this.view.ideas.media,
      url: this.search.query,
    })
      .then((ideas: aiIdea[]) => {

        if (!!ideas && !!ideas.length) {
          this.view.ideas = this.view.ideas.concat(ideas);
          this.segmentChanged();
        }

      })
      .catch((error: any) => {
        console.warn('> creating content ideas failed', error);
      });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  async loadTemplates(blForceRefresh: boolean = false) {
    try {
      this.view.templates = await this.templates.get(blForceRefresh, {
        filter: {
          public: true,
        },
        project_uid: 0, //(!!this.view.project && !!this.view.project.uid ? this.view.project.uid : null)
      });
    } catch (e) {
      console.warn('loading public templates failed', e);
    }
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();
  }

  onItemCheckboxClicked(idea: aiIdea) {

    if (this.view.ideas[this.view.segment] && this.view.ideas[this.view.segment].length) {
      this.view.selectedItems = this.view.ideas[this.view.segment].filter((_idea: aiIdea) => {
        return !!_idea.checked;
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onOverlayInputChanged(event: any = null) {
    console.log('onOverlayInputChanged', event);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    switch (event) {
      case 'delete':
        this.deleteSelectedItems();
        break;
      case 'import':
        this.importSelectedItems();
        break;
    }

    setTimeout(() => {
      this.view.selectOptionValue = null;
    });

  }

  onUrlChanged() {
    const validate: any = this.tools.validateUrl(this.search.query);

    this.view.isValidWebsiteUrl = validate.success;

    if (!this.view.isValidWebsiteUrl) {
      //this.events.publish('error', 'error_url_invalid');
      return false;
    }

    if (this.view.hasOwnProperty('success')) {
      delete this.view.success;
    }

    this.view.loading = false;

    setTimeout(() => {
      this.startManually();
    }, 5000);

    if (
      this.search.query &&
      (this.search.query.length > 4) &&
      (this.search.query.indexOf('.') !== -1)
    ) {
      this.view.loading = true;
      this.view.ideas = [];

      this.ai.inspectURL(this.search.query, true, {
        index: false,
      })
        .then((response: any) => {
          this.view.executed = true;
          this.view.loading = false;
          this.view.errors = (!!response && !!response.errors ? response.errors : {});
          this.view.smart_link = (!!response && !!response.smart_link ? response.smart_link : {});
          this.view.errorKeys = Object.keys(this.view.errors || {});
          this.view.success = (!!response && !!response.success);

          this.startManually();

          if (!!response) {

            if (!!response.ci) {
              this.view.ci = response.ci;
              this.view.infoKeys = Object.keys(response.ci);

              if (!!response.ci.primary_color) {
                this.view.logo_bg_color = response.ci.primary_color;
              }

            }

            if (!!response.data && !!response.data.cms) {
              this.view.source.system = `${response.data.cms}`;
            }

            if (!!response.data && !!response.data.url) {
              this.view.source.url = `${response.data.url}`;
            }

            if (!!response.ideas && !!response.ideas.length) {

              this.view.items = response.ideas.map((idea: any) => {
                idea.label = idea.label || idea.text;
                idea.photo = idea.photo || this.fallbackImg;
                idea.name = idea.name || idea.text;
                idea.title = idea.title || idea.text;
                idea.thumbnail = idea.thumbnail || ((idea.url || idea.photo) || null);
                idea.type = idea.type || 'image';
                idea.url = (idea.url || idea.photo) || null;

                return idea;
              });

              this.view.ideas = this.view.items;
              this.segmentChanged();

              this.extractMediaListFromIdeas();
              this.extractPostsFromIdeas();
            }

          }

          this.loadNewContentIdeas();
          //this.createMediaTemplates(response);
        })
        .catch((errorResponse: any) => {
          this.view.errors = (!!errorResponse && !!errorResponse.errors ? errorResponse.errors : {});
          this.view.errorKeys = Object.keys(this.view.errors || {});

          this.view.loading = false;
          this.view.success = false;

          this.view.source.system = 'custom';
          this.view.source.url = this.search.query;
        });
    }
  }

  runLocalLookup() {
    try {

      // reject if no input
      if (!this.search.query || !this.search.query.length) {
        return false;
      }

      // add http if missing
      if (this.search.query.indexOf('http') === -1) {
        this.search.query = `http://${this.search.query}`;
      }

      // method 1: load content to iframe
      this.view.frameSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.search.query);

      // method 2: try fetching the resource if method 1 fails
      this.crawler.fetch(this.search.query, {})
        .then((response: any) => {
          console.log('local fetch response (from crawler)', response);
        })
        .catch((e: any) => {
          console.warn('local lookup failed (2)', e);
        });

    } catch (e) {
      console.warn('local lookup failed (1)', e);
    }
  }

  runSearch(event: any | null = null) {

    //this.runLocalLookup();

    this.onUrlChanged();
  }

  segmentChanged() {

    switch (this.view.segment) {
      case 'ideas':
        this.view.ideas = this.view.items;
        break;
      case 'media':
        this.view.ideas = this.view.items.filter((item: any) => {
          return !!item && !!item.photo;
        });
        break;
      case 'posts':
        this.view.ideas = this.view.items.filter((item: any) => {
          return !!item && (!!item.headline || !!item.text);
        });
        break;
      default:
        console.warn(this.view.segment);
    }
  }

  startManually() {
    this.view.startManually = true;
  }

  submit() {
    this.onUrlChanged();
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  use(idea: aiIdea, event: any | null = null) {

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}