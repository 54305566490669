import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AiBridgeService } from '../../../services/ai/ai-bridge.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-ai-text-to-image-card',
  standalone: false,
  templateUrl: './ai-text-to-image-card.component.html',
  styleUrls: ['./ai-text-to-image-card.component.scss']
})
export class AiTextToImageCardComponent implements AfterViewInit, OnDestroy, OnInit {

  aiSettings: aiSettings = {};
  
  @Input() autostart: boolean = false;
  @Input() colSize: number|null = null;
  @Input() input: string = '';
  @Input() options: aiTextToImageOptions = {};

  fallbackImg: string = './assets/img/fallback.webp';
  
  @Input() selectionOptions: selectionOption[];
  
  @Input() size: number = 12;
  
  view: any = {
  };

  constructor(
    public ai: AiToolsService,
    private aiBridge: AiBridgeService,
    
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  aiSettingsChanged(event: any|null = null) {
    console.log('aiSettingsChanged', event);
  }
  
  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = this.colSize || this.view.colSize;
  }

  async generate() {

    if (!this.input) {
      return false;
    }


    const iMax: number = 6;
    let iDone: number = 0, images: any = [];

    this.view.ideas = [
      { state: 'loading' },
      { state: 'loading' },
      { state: 'loading' },
      { state: 'loading' },
      { state: 'loading' },
      { state: 'loading' },
    ];

    this.view.loadingTextToImage = true;

    while (iMax > iDone) {
      try {
        const response: any = await this.aiBridge.textToImage(this.input, {}, true);
        this.view.loadingTextToImage = (iDone < iMax);

        if (!!response && !!response.error_message) {
          this.events.publish('error', response.error_message);
        } else
        if(!!response && !!response.images) {
          
          response.images.forEach((image: string) => {
            this.view.ideas[iDone] = {
              disabled: false,
              guid: image,
              photo: image,
              state: 'done',
              thumbnail: image,
              type: 'image',
              url: image,
            };
          });

          iDone++;
        }

      } catch (e) {
        iDone++;

        this.view.loadingTextToImage = (iDone < iMax);
        this.events.publish('error', e);
      }
    }

  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if(!!this.autostart && !!this.input) {
      this.generate();
    }
  }

  selectedMediaItemsChanged(event: any|null = null) {
    console.log('selectedMediaItemsChanged', event);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

}
