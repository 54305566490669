<ion-toolbar id="adPreviewToolbar" *ngIf="ad.location.indexOf('ad.location.web') === 0">
    <img src="./assets/img/pipeline_clean.png" class="pipeline-logo" />
</ion-toolbar>

<div class="navigation-background" *ngIf="(ad.location.indexOf('ad.location.web') === 0) || (ad.location === 'ad.location.stories.between')"></div>

<div class="ion-padding" *ngIf="ad.location === 'ad.location.posts.related'">
    
    <ion-skeleton-text style="float:left;width:60%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:30%;"></ion-skeleton-text>

    <ion-skeleton-text style="float:left;width:25%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:55%;"></ion-skeleton-text>

    <ion-skeleton-text style="float:left;width:40%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:50%;"></ion-skeleton-text>

    <ion-skeleton-text style="float:left;width:20%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:15%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:35%;"></ion-skeleton-text>

    <ion-skeleton-text style="float:left;width:60%;margin-right: 10px;"></ion-skeleton-text>
    <ion-skeleton-text style="float:left;width:30%;"></ion-skeleton-text>

</div>

<ion-card id="adPreviewCard" class="ad-preview-location-{{(ad.location || '').replace('ad.location.', '').replace('.', '-')}}">
    
    <ion-card-header *ngIf="ad.location === 'ad.location.posts.related'">
        <ion-card-title [innerHTML]="'related_articles'|translate"></ion-card-title>
    </ion-card-header>
    
    <pipeline-post-grid [(view)]="view" [posts]="view.posts"></pipeline-post-grid>

    <ion-button *ngIf="ad.location === 'ad.location.stories.between'" expand="block" shape="round" fill="outline" color="primary" (click)="read()">
        <ion-label [innerHTML]="'read_now'|translate"></ion-label>
    </ion-button>

</ion-card>