<ion-header *ngIf="view.isMultiMode" class="ion-no-border">
    <ion-toolbar class="ion-no-border container">
            
        <!-- Window buttons -->
        <pipeline-header-window-buttons></pipeline-header-window-buttons>
        
    </ion-toolbar>
</ion-header>

<ion-content class="scrollable ion-padding" [class.multiMode]="!!view.isMultiMode">

    <div class="login-style-helper a"></div>
    <div class="login-style-helper b"></div>
    <div class="login-style-helper c"></div>
    <div class="login-style-helper d"></div>
    <div class="login-style-helper e"></div>

    <img *ngIf="config.showLoginTopLogo && !view.isMultiMode" [src]="config.loginTopLogoUrl || './assets/img/logo.webp'"
        class="login-logo pipeline-logo light size-{{config.loginTopLogoSize || 'medium'}}" />

    <img *ngIf="config.showLoginTopLogo && !view.isMultiMode"
        [src]="config.loginTopLogoUrl || './assets/img/logo-dark.webp'"
        class="login-logo pipeline-logo dark size-{{config.loginTopLogoSize || 'medium'}}" />

    <ion-spinner name="circular" [hidden]="!view.loading || view.loggedIn || view.verify"></ion-spinner>

    <!--<pipeline-lottie-loading-stage *ngIf="!!config.showLoginPageLottie && (!!view.isMultiMode || (!view.loading && (!user || !user.uid)))" [class]="view.animationClass" [options]="splineOptions"></pipeline-lottie-loading-stage>-->
    <pipeline-spline-viewer
        *ngIf="!!config.showLoginPageSpline && (!!view.isMultiMode || (!view.loading && (!user || !user.uid)))"
        [class]="view.animationClass" [options]="splineOptions"></pipeline-spline-viewer>

    <div class="form" [hidden]="view.loading" autocomplete="off" (ngSubmit)="login()"
        [class.useCase]="!!config.showLoginPageLeftImgCase" [class.useImg]="!!config.showLoginPageLeftImg">

        <h1 class="headline" *ngIf="!view.isMultiMode"
            [innerHTML]="(view.headline || (config.loginHeadlineText || 'creator_studio_login_headline'))|translate|fillVars">
        </h1>

        <form (ngSubmit)="login()">

            <ion-card>

                <ion-list lines="none">

                    <ion-item *ngIf="error" class="ion-text-wrap error-msg" color="danger">
                        <ion-icon name="warning-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="error|translate" class="ion-text-wrap"></ion-label>
                    </ion-item>

                    <ion-item *ngIf="!!view.isApiURLConfigurable">
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-input #loginApiUrlInput [label]="'api_url'|translate" labelPlacement="stacked"
                            autocorrect="on" name="apiUrl" type="url" #apiUrl id="apiUrl" autocomplete="off"
                            [(ngModel)]="view.api_url" class="color-text-primary"
                            [placeholder]="'api_url_placeholder'|translate"></ion-input>
                    </ion-item>

                    <ion-item>
                        <ion-icon name="mail-outline" slot="start"></ion-icon>
                        <ion-input #loginEmailInput [label]="'email'|translate" labelPlacement="stacked"
                            autocorrect="on" name="email" type="email" #loginEmail id="loginEmail" autocomplete="off"
                            [(ngModel)]="user.email" class="color-text-primary"
                            [placeholder]="'email_placeholder'|translate"></ion-input>
                    </ion-item>

                    <ion-item>
                        <ion-icon name="key-outline" slot="start"></ion-icon>
                        <ion-input #loginPasswordInput [label]="'password'|translate" labelPlacement="stacked"
                            autocorrect="on" name="password" type="password" autocomplete="off"
                            [(ngModel)]="user.password" class="color-text-primary"
                            [placeholder]="'password_placeholder'|translate"></ion-input>
                    </ion-item>

                </ion-list>

                <ion-card-header>

                    <ion-grid>
                        <ion-row>

                            <ion-col size="12">
                                <ion-button type="submit" fill="solid" size="block" color="primary">
                                    <ion-label [innerHTML]="'log_in'|translate"></ion-label>
                                </ion-button>
                            </ion-col>

                            <ion-col size="12" *ngIf="!!view.canRegiser">
                                <ion-button (click)="register()" size="block" fill="outline" color="primary">
                                    <ion-label [innerHTML]="'create_account'|translate"></ion-label>
                                </ion-button>
                            </ion-col>

                            <ion-col size="12">
                                <ion-button type="button" size="block" (click)="forgotPassword()" fill="clear"
                                    type="button">
                                    <ion-label [innerHTML]="'forgot_password'|translate"></ion-label>
                                </ion-button>
                            </ion-col>

                        </ion-row>
                    </ion-grid>

                </ion-card-header>

            </ion-card>

        </form>

        <small class="legal-info-text" [innerHTML]="view.loginLegalInfo"></small>

        <svg class="curve curve-pink curve-vertical curve-middle curve-inverted" xmlns="http://www.w3.org/2000/svg"
            width="64.128" height="931.265" viewBox="0 0 64.128 931.265">
            <path data-name="curve"
                d="M465.611,0c257.155,0,466.568,64.415,465.62,64.105S2.48,62.7-.009,64.105,208.456,0,465.611,0Z"
                transform="translate(64.128 0.031) rotate(90)" fill="#e2d8ff" />
        </svg>

    </div>

</ion-content>