import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AiSettingsPickerComponent } from './ai-settings-picker.component';

import { AiSettingsCardComponentModule } from 'src/app/components/ai/ai-settings-card/ai-settings-card.component.module';

@NgModule({
  declarations: [
    AiSettingsPickerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    AiSettingsCardComponentModule,
  ],
  exports: [
    AiSettingsPickerComponent,
  ]
})
export class AiSettingsPickerComponentModule { }