<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-title *ngIf="app.uid" [hidden]="!view.isDesktop" [innerHTML]="'edit_whitelabel_app'|translate"></ion-title>
    <ion-title *ngIf="!app.uid" [hidden]="!view.isDesktop" [innerHTML]="'create_whitelabel_app'|translate"></ion-title>

    <ion-buttons slot="end">

      <ion-button (click)="toggleDarkMode()" icon-only [hidden]="(!!app.uid && !view.setupMode) || !view.hasUI">
        <ion-icon [name]="view.darkMode ? 'moon' : 'moon-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'dark_mode'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <ion-button (click)="toggleDevice($event)" icon-only [hidden]="(!!app.uid && !view.setupMode) || !view.hasUI">
        <ion-icon [name]="view.device.icon || 'phone-portrait-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'device'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>
      
      <!-- update an app -->
      <ion-button (click)="update()" *ngIf="!!app.uid" fill="solid" color="primary" shape="round" [disabled]="!view.canUpdate">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <!-- create the new app -->
      <ion-button (click)="save()" *ngIf="!app.uid" fill="solid" color="primary" shape="round" [disabled]="!view.canSave">
        <ion-icon name="checkmark" slot="start" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- switch to setup mode -->
      <ion-button (click)="setup()" *ngIf="!!app.uid" [hidden]="!!view.setupMode" fill="solid" color="primary" shape="round">
        <ion-icon name="settings-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'setup'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>
    
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    
    <!-- app configuration wizard -->
    <pipeline-whitelabel-configuration-slider [app]="app" [hidden]="!!app.uid && !view.setupMode"></pipeline-whitelabel-configuration-slider>

    <ion-grid *ngIf="!!app.uid" [hidden]="!!view.setupMode">
      <ion-row>
        
        <ion-col class="sidebar" [size]="view.sidebarSize">

          <ion-card *ngFor="let item of view.sidebar" [class.active]="view.tab === item.uid" [class.checked]="!!item.checked">
            <ion-button fill="clear" [color]="(view.tab === item.uid) ? 'success' : 'dark'" (click)="sideMenuItemcClicked(item)">
              <ion-icon color="primary" [name]="item.icon" slot="start" color="primary"></ion-icon>
              <ion-label class="ion-text-wrap">
                <h2 [innerHTML]="item.name|translate"></h2>
                <p [innerHTML]="item.description|translate"></p>
              </ion-label>
            </ion-button>
          </ion-card>

        </ion-col>

        <ion-col [size]="(12 - view.sidebarSize) || 12">

          <ion-card>

            <ion-card-header [hidden]="!view.isDesktop">
              <ion-card-title [innerHTML]="view.tab|translate"></ion-card-title>
            </ion-card-header>

            <div [hidden]="view.tab !== 'api'">
              <ion-list>

                <ion-item>
                  <ion-icon name="key-outline" slot="start"></ion-icon>

                  <ion-input [label]="'api_key'|translate" [value]="!!app.client_key ? ('ck-' + app.client_key) : ''" disabled></ion-input>

                  <ion-button [disabled]="!app.client_key" (click)="copyToClipboard('ck-' + app.client_key)" icon-only fill="clear" color="primary" slot="end">
                    <ion-icon name="copy-outline"></ion-icon>
                  </ion-button>

                </ion-item>

              </ion-list>
            </div>

            <div [hidden]="view.tab !== 'sources'">

              <ion-button (click)="addSource()">
                <ion-icon name="add-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'add_source'|translate"></ion-label>
              </ion-button>

              <ion-searchbar [(ngModel)]="search.query" (ionInput)="runSearch()"
                [placeholder]="'search_source'|translate"></ion-searchbar>

              <ion-list *ngIf="app.sources && app.sources.length">
                <div class="cdk-wrapper">
                  <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 15"
                    [maxBufferPx]="view.itemSize * 15" class="ion-content-scroll-host">
                    <div *cdkVirtualFor="let source of app.sources" [hidden]="source.hidden">
                      <ion-item-sliding>
                        <ion-item>

                          <ion-thumbnail slot="start">
                            <ion-img *ngIf="!!source.photo" [src]="source.photo"></ion-img>
                            <ion-skeleton-text class="image" [animated]="!source.uid" [hidden]="!!source.photo"></ion-skeleton-text>
                          </ion-thumbnail>
                          
                          <ion-icon (click)="toggleSourceActive(source)"
                            [name]="source.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                            [color]="source.active ? 'success' : 'danger'" slot="start"></ion-icon>

                          <ion-label>
                            <h2 [innerHTML]="source.title"></h2>
                            <p [innerHTML]="source.url"></p>
                          </ion-label>

                          <ion-button slot="end" icon-only fill="clear" color="dark"
                            (click)="editSourceSettings(source)">
                            <ion-icon name="settings-outline"></ion-icon>
                          </ion-button>

                          <ion-button slot="end" icon-only fill="clear" color="danger" (click)="deleteSource(source)">
                            <ion-icon name="trash-outline"></ion-icon>
                          </ion-button>
                        </ion-item>
                        <ion-item-options slot="end">
                          <ion-item-option slot="end" icon-only fill="clear" color="dark"
                            (click)="editSourceSettings(source)">
                            <ion-icon name="settings-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'edit'|translate"></ion-label>
                          </ion-item-option>
                          <ion-item-option slot="end" icon-only fill="clear" color="danger"
                            (click)="deleteSource(source)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'delete'|translate"></ion-label>
                          </ion-item-option>
                        </ion-item-options>
                      </ion-item-sliding>
                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>
              </ion-list>

            </div>

          </ion-card>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <pipeline-whitelabel-configuration-slider-navigation></pipeline-whitelabel-configuration-slider-navigation>

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" [hidden]="(!!app.uid && !view.setupMode) || !view.hasUI"></pipeline-view-mode-picker>
    </ion-buttons>
    
  </ion-toolbar>
</ion-footer>