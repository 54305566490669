<ion-header class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>
    </ion-buttons>
  </ion-toolbar>
  
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">

    <ion-card *ngIf="!basketStorage || !basketStorage.length">
      <ion-card-header>
        <ion-card-title [innerHTML]="'empty_basket'|translate"></ion-card-title>
        <p [innerHTML]="'empty_basket_text'|translate"></p>
      </ion-card-header>
      <ion-card-content>
        <ion-button (click)="shop()" expand="block" fill="outline" color="dark">
          <ion-icon name="chevron-back-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'go_to_home'|translate"></ion-label>
        </ion-button>
      </ion-card-content>
    </ion-card>
  
    <div *ngIf="basketStorage && basketStorage.length">
      <ion-card *ngFor="let item of basketStorage; let i = index; trackBy:trackItems">
        <ion-list lines="none">
          <ion-item-sliding>
            <ion-item>
              <ion-thumbnail slot="start" *ngIf="item.image">
                <ion-img [src]="item.image.src || item.image"></ion-img>
              </ion-thumbnail>
              <ion-thumbnail
                slot="start"
                *ngIf="!item.image && item.images && item.images[0]"
              >
                <ion-img [src]="item.images[0].src"></ion-img>
              </ion-thumbnail>
              <ion-label text-wrap (click)="viewItem(item, i)">
                {{(item.amount || 1)}}x <span [innerHTML]="item.name"></span
                ><br />
                <b *ngIf="item.price"
                  >{{((item.amount || 1) * item.price).toFixed(2)}}€</b
                >
              </ion-label>
              <ion-button color="danger" shape="round" (click)="remove(item,i)">
                <ion-icon name="remove"></ion-icon>
              </ion-button>
              <ion-button
                slot="end"
                shape="round"
                *ngIf="item.price && item.price > 0 && item.price != '0'"
                (click)="add(item, i)"
                color="primary"
              >
                <ion-icon name="add"></ion-icon>
              </ion-button>
            </ion-item>
            <ion-item-options>
              <ion-item-option color="danger" (click)="remove(item,i)">
                <ion-icon name="remove"></ion-icon>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-card>
    </div>

  </div>
  
</ion-content>

<ion-footer class="ion-no-border" *ngIf="basketStorage && basketStorage.length">
  <ion-toolbar class="ion-no-border container">
    <ion-list lines="none" color="transparent">
      <ion-item
        color="transparent"
        *ngIf="basketInfo.requestMinimumValue && !basketInfo.hasRequestMinimumValue"
      >
        <ion-label [innerHTML]="'min_order_amount'|translate"></ion-label>
        <ion-note slot="end">{{basketInfo.requestMinimumValue || 0}}€</ion-note>
      </ion-item>
      <ion-item color="transparent" *ngIf="basketInfo.difference">
        <ion-label [innerHTML]="'surcharge'|translate"></ion-label>
        <ion-note slot="end">{{basketInfo.difference || 0}}€</ion-note>
      </ion-item>
      <ion-item color="transparent">
        <ion-label [innerHTML]="'total_price'|translate"></ion-label>
        <ion-note slot="end">{{basketInfo.price_a || 0}}€</ion-note>
      </ion-item>
    </ion-list>
    <ion-grid>
      <ion-row>
        
        <ion-col size="6">
          <ion-button (click)="shop()" expand="block" color="dark" fill="clear">
            <ion-label [innerHTML]="'continue_shopping'|translate"></ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button (click)="checkout()" expand="block" color="primary">
            <ion-label [innerHTML]="'order_now'|translate"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
