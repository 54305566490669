import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from "src/app/services/core/view.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-choose-action-sheet',
  standalone: false,
  templateUrl: './choose-action-sheet.page.html',
  styleUrls: ['./choose-action-sheet.page.scss'],
})
export class ChooseActionSheetPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  fallbackImg: string = './assets/img/fallback.webp';

  options: any = {};

  search: searchOptions = {
    itemsKey: 'data',
    keys: ['title', 'email', 'nickname', 'firstname', 'lastname', 'excerpt', 'url', 'post_date', 'uid', 'description', 'name', 'indent'],
    query: '',
  };

  selectionOptions: selectionOption[];

  service: any;

  state: state = {};

  view: any = {
    data: [],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 48,
    labelPrefix: '',
    multiple: false,
    templateView: {
      aspectRatio: '1x1',
      editable: false,
      infinite: true,
      running: true,
      maxWidth: 150,
    },
    title: 'choose',
    type: 'custom',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    const data: any = this.navParams.get('data');
    const labelKey: any = this.navParams.get('labelKey');
    const labelPrefix: any = this.navParams.get('labelPrefix');
    const multiple: any = this.navParams.get('multiple');
    const title: any = this.navParams.get('title');
    const type: any = this.navParams.get('type');
    const service: any = this.navParams.get('service');
    const subLabelKey: any = this.navParams.get('subLabelKey');
    const subLabelPrefix: any = this.navParams.get('subLabelPrefix');
    const valueKey: any = this.navParams.get('valueKey');

    this.options.labelKey = labelKey || this.options.labelKey;
    this.options.labelPrefix = labelPrefix || this.options.labelPrefix;
    this.options.multiple = !!multiple;
    this.options.subLabelKey = subLabelKey || this.options.subLabelKey;
    this.options.subLabelPrefix = subLabelPrefix || this.options.subLabelPrefix;
    this.options.type = type || this.options.type;
    this.options.valueKey = valueKey || this.options.valueKey;

    this.service = service || this.service;

    this.view.data = this.prepare(data || this.view.data);
    this.view.title = title || this.view.title;

    // calc selection bar states
    this.view.hideSelectAll = !this.options.multiple;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
    */
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  ionViewWillEnter() {
    this.detectChanges();
  }

  ngOnInit() {
    this.calcViewVars();
  }

  onSearchChanged(searchOptions: any | null = null) {
    if (!this.view.data || !this.view.data.length && !!this.service) {
      try {
        this.service.search(this.search.query)
          .then((response: any) => {
            this.view.data = (response || []);
          })
          .catch((e: any) => {
            console.warn('performing global search in action sheet failed (b)', e);
            this.view.data = [];
          });
      } catch (e) {
        console.warn('performing global search in action sheet failed (a)', e);
        this.view.data = [];
      }
    }
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  prepare(data: any[]) {

    if (!!this.view.type && this.view.type === 'template') {
      data = data.map((item: any) => {
        item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));

        return item;
      });
    }

    return data;
  }

  runGlobalSearch() {
    try {

      if (!this.view || !this.view.hasOwnProperty('service')) {
        return false;
      }

      this.service.search(this.search.query)
        .then((searchResponse: any) => {
          if (!!searchResponse && searchResponse.length) {
            this.view.data = searchResponse;
            delete this.view.data_backup;

            this.detectChanges();

            if (!!this.view.data && !!this.view.data.length) {
              this.runSearch();
            }
          }
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    } catch (error) {
      console.warn('> search failed', error);
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  selectedItemsChanged(event: any = null) {
    if (!this.options.multiple) {
      this.dismiss({
        item: event.item,
        items: event.items || [],
      }, 'done');
    }
  }

  submitMultiple() {

    const checkedItems: any[] = (this.view.data || []).filter((item: any) => {
      return !!item.checked;
    });

    let params: any = {
      items: checkedItems,
    };

    if (checkedItems.length === 1) {
      params.item = checkedItems[0];
    }

    this.dismiss(params, 'done');
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    this.detectChanges();
  }

}