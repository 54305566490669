<!-- Info popover -->
<ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
  <ng-template>
    <ion-content>
      <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate"></p>
    </ion-content>
  </ng-template>
</ion-popover>

<!-- Chooser popover -->
<ion-popover #chooserPopover [isOpen]="isChooserPopoverOpen" (didDismiss)="isChooserPopoverOpen = false">
  <ng-template>

    <pipeline-chooser *ngIf="!!view.data" (selectedItemsChanged)="selectedItemsChanged($event)" [(data)]="view.data"
      [(item)]="view.item" [options]="chooseConfig" [service]="aiTools">
    </pipeline-chooser>

  </ng-template>
</ion-popover>

<!-- Settings card -->
<ion-card class="sidebar-accordion-card" [class.in-popover]="!!options && !!options.inPopover">

  <!-- Header -->
  <ion-card-header [hidden]="!cards || !cards.ai">

    <ion-card-title (click)="toggleCard('ai')">
      <span [innerHTML]="'ai_settings'|translate"></span>
    </ion-card-title>

    <ion-button class="card-toggle" (click)="toggleCard('ai')" icon-only color="dark" fill="clear">
      <ion-icon [name]="!!cards && !!cards.ai && !!cards.ai.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
    </ion-button>

  </ion-card-header>

  <!-- Settings list -->
  <ion-list [hidden]="!!cards && !!cards.ai && !cards.ai.open">

    <!-- Context -->
    <ion-item button lines="none"
      *ngIf="!!options && !!options.operations && !!options.operations.length && (options.operations.length > 1)">
      <ion-icon name="funnel-outline" slot="start"></ion-icon>

      <!-- Select context from list -->
      <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
        [placeholder]="'select'|translate" [label]="'context'|translate" [(ngModel)]="config.context"
        (ionChange)="onChanged($event, 'context')">
        <ion-select-option *ngFor="let operation of options.operations" [value]="operation"
          [innerHTML]="operation|translate"></ion-select-option>
      </ion-select>

      <!-- Information: About contexts -->
      <ion-button (click)="presentInfoPopover($event, 'ai_settings_context_infotext')" icon-only fill="clear"
        color="dark" slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- Provider -->
    <ion-item button lines="none" *ngIf="!!providers && !!providers.length">

      <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>

      <ion-label [innerHTML]="'provider'|translate" (click)="chooseProvider($event)"></ion-label>
      <ion-note [innerHTML]="(config.provider || 'select')|translate" slot="end"
        (click)="chooseProvider($event)"></ion-note>

      <!-- Select provider from list -->
      <!--
      <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
        [placeholder]="'select'|translate" [label]="'provider'|translate" [(ngModel)]="config.provider"
        (ionChange)="onChanged($event, 'provider')">
        <ion-select-option *ngFor="let provider of providers" [value]="provider.uid"
          [innerHTML]="provider.name|translate"></ion-select-option>
      </ion-select>
      -->

      <!-- Information: About providers -->
      <ion-button (click)="presentInfoPopover($event, 'ai_settings_provider_infotext')" icon-only fill="clear"
        color="dark" slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- Model(s)-->
    <ion-item lines="none" button>

      <ion-icon name="cube-outline" slot="start" (click)="chooseModel('models', true, null, $event)"></ion-icon>

      <ion-label [innerHTML]="'model'|translate" (click)="chooseModel('models', true, null, $event)"></ion-label>

      <ion-button fill="outline" color="primary" (click)="chooseModel('models', true, null, $event)"
        [innerHTML]="(!!config.models && config.models.length > 1) ? ('multiple'|translate) : (!!config.models && !!config.models[0] ? config.models[0].name : ('select'|translate))"
        slot="end"></ion-button>

      <!-- Select model from list -->
      <!--
      <ion-select *ngIf="!!models && !!models.length" multiple interface="popover" [okText]="'okay'|translate"
        [cancelText]="'cancel'|translate" [placeholder]="'select'|translate" [label]="'model'|translate"
        [(ngModel)]="config.model" (ionChange)="onChanged($event)">
        <ion-select-option *ngFor="let _model of models" [value]="_model.uid"
          [hidden]="!!_model.provider && !!config.provider && (_model.provider !== config.provider)"
          [innerHTML]="_model.name|translate"></ion-select-option>
      </ion-select>
      -->

      <!-- Add new model -->
      <ion-button slot="end" fill="clear" color="dark" (click)="addModel('models', true)" icon-only>
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>

      <!-- Information: About models -->
      <ion-button (click)="presentInfoPopover($event, 'ai_sd_models_infotext')" icon-only fill="clear" color="dark"
        slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- LoRa (Only if a model is selected manually) -->
    <ion-item lines="none" button
      [hidden]="!view.expertMode || (config.provider !== 'getgenius' && config.provider !== 'huggingface')"
      *ngIf="!!config.models && !!config.models.length">

      <ion-icon name="diamond-outline" slot="start" (click)="chooseModel('lora', false, null, $event)"></ion-icon>

      <ion-label [innerHTML]="'lora'|translate" (click)="chooseModel('lora', false, null, $event)"></ion-label>

      <ion-button fill="outline" color="primary" (click)="chooseModel('lora', false, null, $event)"
        [innerHTML]="!!config.lora && !!config.lora.name ? config.lora.name : ('select'|translate)"
        slot="end"></ion-button>

      <!-- Select lora from list -->
      <!--
      <ion-select *ngIf="!!models && !!models.length" interface="popover" [okText]="'okay'|translate"
        [cancelText]="'cancel'|translate" [placeholder]="'select'|translate" [label]="'lora'|translate"
        [(ngModel)]="config.lora" (ionChange)="onChanged($event, 'lora')">
        <ion-select-option *ngFor="let _model of models" [value]="_model.uid"
          [hidden]="!!_model.category && (_model.category !== 'lora')"
          [innerHTML]="_model.name|translate"></ion-select-option>
      </ion-select>
      -->

      <!-- Add new lora -->
      <ion-button slot="end" fill="clear" color="dark" (click)="addModel('lora', false)" icon-only>
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>

      <!-- Information: About LoRa -->
      <ion-button (click)="presentInfoPopover($event, 'ai_sd_lora_infotext')" icon-only fill="clear" color="dark"
        slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- Text generation options -->
    <div *ngIf="shouldShow.text_generation">

      <!-- Temperature -->
      <ion-item lines="none" *ngIf="!!view.expertMode && !!config && !!config.provider">
        <ion-icon name="thermometer-outline" slot="start"></ion-icon>

        <ion-input [label]="'temperature'|translate" labelPlacement="floating" type="number" [step]="0.1" [min]="0"
          [max]="2" [(ngModel)]="config.temperature" (ionInput)="onChanged($event)"></ion-input>

        <!-- Information: About temperature -->
        <ion-button (click)="presentInfoPopover($event, 'ai_settings_temperature_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Max tokens -->
      <ion-item lines="none" *ngIf="!!view.expertMode && !!config && !!config.provider">
        <ion-icon name="cash-outline" slot="start"></ion-icon>

        <ion-input [label]="'max_tokens'|translate" labelPlacement="floating" type="number" [step]="1" [min]="0"
          [max]="131072" [(ngModel)]="config.max_tokens" (ionInput)="onChanged($event)"></ion-input>

        <!-- Information: About max_tokens -->
        <ion-button (click)="presentInfoPopover($event, 'ai_settings_max_tokens_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

    </div>

    <!-- Image & video options -->
    <div *ngIf="shouldShow.image_to_video || shouldShow.text_to_image">

      <!-- Sampler -->
      <ion-item lines="none" [hidden]="!view.expertMode" *ngIf="!!samplers && !!samplers.length">
        <ion-icon name="layers-outline" slot="start"></ion-icon>

        <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
          [label]="'sampler_name'|translate" [(ngModel)]="config.sampler_name">
          <ion-select-option *ngFor="let sampler of samplers; trackBy:trackItems" [value]="sampler.uid"
            [innerHTML]="sampler.name|translate"></ion-select-option>
        </ion-select>

        <!-- Information: About samplers -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_sampler_infotext')" icon-only fill="clear" color="dark"
          slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Sampling steps -->
      <ion-item lines="none" [hidden]="!view.expertMode">
        <ion-icon name="footsteps-outline" slot="start"></ion-icon>

        <ion-input type="number" [label]="'sampling_steps'|translate" labelPlacement="floating" [min]="1" [max]="150"
          [step]="1" [(ngModel)]="config.steps" (ionInput)="onChanged($event)"></ion-input>

        <!-- Information: About sampling steps -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_steps_infotext')" icon-only fill="clear" color="dark"
          slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Batch count -->
      <ion-item lines="none" [hidden]="!view.expertMode" *ngIf="shouldShow.text_to_image">

        <ion-icon name="albums-outline" slot="start"></ion-icon>

        <ion-range [ticks]="true" [snaps]="true" [label]="'batch_count'|translate" labelPlacement="stacked" [min]="1"
          [max]="4" [step]="1" [(ngModel)]="config.batch_count" (ionInput)="onChanged($event)"></ion-range>

        <!-- Information: About sampling steps -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_batch_count_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Batch size -->
      <ion-item lines="none" [hidden]="!view.expertMode" *ngIf="shouldShow.text_to_image">
        <ion-icon name="repeat-outline" slot="start"></ion-icon>

        <ion-range [ticks]="true" [snaps]="true" [label]="'batch_size'|translate" labelPlacement="stacked" [min]="1"
          [max]="4" [step]="1" [step]="1" [(ngModel)]="config.batch_size" (ionInput)="onChanged($event)"></ion-range>

        <!-- Information: About sampling steps -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_batch_size_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- CFG Scale -->
      <ion-item lines="none" [hidden]="!view.expertMode">
        <ion-icon name="thermometer-outline" slot="start"></ion-icon>

        <ion-input [label]="'cfg_scale'|translate" labelPlacement="floating" type="number" [step]="1" [min]="1"
          [max]="30" [(ngModel)]="config.cfg_scale" (ionInput)="onChanged($event)"></ion-input>

        <!-- Information: About CFG scale -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_cfg_scale_infotext')" icon-only fill="clear" color="dark"
          slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Seed -->
      <ion-item lines="none" [hidden]="!view.expertMode">
        <ion-icon name="finger-print-outline" slot="start"></ion-icon>

        <ion-input [label]="'seed'|translate" labelPlacement="floating" type="number" [step]="1" [min]="1"
          [max]="99999999" [(ngModel)]="config.seed" (ionInput)="onChanged($event)"></ion-input>

        <!-- Information: About seed -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_seed_infotext')" icon-only fill="clear" color="dark"
          slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Auto upscale (using upscaler) -->
      <ion-item lines="none" [hidden]="!!view.expertMode" class="ion-text-wrap">
        <ion-icon name="expand-outline" slot="start"></ion-icon>

        <ion-checkbox [(ngModel)]="config.auto_upscale" justify="start" labelPlacement="end"
          [innerHTML]="'auto_upscale'|translate" class="ion-text-wrap" (ionChange)="onChanged($event)"></ion-checkbox>

        <!-- Information: About auto upscaling -->
        <ion-button (click)="presentInfoPopover($event, 'ai_sd_auto_upscale_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Upscale first frame -->
      <ion-item lines="none" [hidden]="!shouldShow.image_to_video || !view.expertMode || !!config.auto_upscale"
        class="ion-text-wrap">
        <ion-icon name="expand-outline" slot="start"></ion-icon>

        <ion-checkbox [(ngModel)]="config.upscale_first_frame" justify="start" labelPlacement="end"
          [innerHTML]="'upscale_first_frame'|translate" class="ion-text-wrap"
          (ionChange)="onChanged($event)"></ion-checkbox>

        <ion-button (click)="presentInfoPopover($event, 'ai_sd_upscale_first_frame_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Upscale end result -->
      <ion-item lines="none" [hidden]="!shouldShow.image_to_video || !view.expertMode || !!config.auto_upscale"
        class="ion-text-wrap">
        <ion-icon name="expand-outline" slot="start"></ion-icon>

        <ion-checkbox [(ngModel)]="config.upscale_end_result" justify="start" labelPlacement="end"
          [innerHTML]="'upscale_end_result'|translate" class="ion-text-wrap"
          (ionChange)="onChanged($event)"></ion-checkbox>

        <ion-button (click)="presentInfoPopover($event, 'ai_sd_upscale_end_result_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <!-- Use frame interpolation -->
      <ion-item lines="none" [hidden]="!shouldShow.image_to_video || !view.expertMode" class="ion-text-wrap">
        <ion-icon name="layers-outline" slot="start"></ion-icon>

        <ion-checkbox [(ngModel)]="config.use_frame_interpolation" justify="start" labelPlacement="end"
          [innerHTML]="'use_frame_interpolation'|translate" class="ion-text-wrap"
          (ionChange)="onChanged($event)"></ion-checkbox>

        <ion-button (click)="presentInfoPopover($event, 'ai_sd_frame_interpolation_infotext')" icon-only fill="clear"
          color="dark" slot="end">
          <ion-icon name="information-circle-outline"></ion-icon>
        </ion-button>

      </ion-item>

    </div>

    <!-- Precision (if model or lora selected) -->
    <ion-item lines="none" [hidden]="!view.expertMode"
      *ngIf="!!config.models && !!config.models.length && !!precisions && !!precisions.length">
      <ion-icon name="calculator-outline" slot="start"></ion-icon>

      <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
        [label]="'precision'|translate" [(ngModel)]="config.precision" (ionChange)="onChanged($event)">
        <ion-select-option *ngFor="let precision of precisions" [value]="precision"
          [innerHTML]="precision|translate"></ion-select-option>
      </ion-select>

      <ion-button (click)="presentInfoPopover($event, 'ai_sd_precision_infotext')" icon-only fill="clear" color="dark"
        slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

    <!-- Safety checker -->
    <ion-item lines="none" class="ion-text-wrap">
      <ion-icon name="eye-outline" slot="start"></ion-icon>

      <ion-checkbox [(ngModel)]="config.use_safety_checker" justify="start" labelPlacement="end"
        [innerHTML]="'use_safety_checker'|translate" class="ion-text-wrap"
        (ionChange)="onChanged($event)"></ion-checkbox>

      <ion-button (click)="presentInfoPopover($event, 'ai_sd_safety_checker_infotext')" icon-only fill="clear"
        color="dark" slot="end">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-button>

    </ion-item>

  </ion-list>

</ion-card>