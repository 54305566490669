import { Component, Input, OnInit } from '@angular/core';

import { ConfigService } from 'src/app/services/core/config.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-header-window-buttons',
  standalone: false,
  templateUrl: './header-window-buttons.component.html',
  styleUrls: ['./header-window-buttons.component.scss']
})
export class HeaderWindowButtonsComponent implements OnInit {
  @Input() options: any = {};
  @Input() view: any = {};

  appConfig: pipelineAppConfig;

  constructor(
    private configService: ConfigService,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  async minimize(data: any | null = null, role: string | null = 'dismiss') {
    const modal: any = await this.modalService.getTop();
    console.log('minimize: view', this.view);

    // store window in background:
    this.modalService.store(modal, {
      data: data,
      view: this.view,
    });

    // then dismiss:
    this.modalService.dismiss(data, role);
  }

  async maximize() {
    const modal: any = await this.modalService.getTop();

    try {
      if (modal.classList.contains('defaultModal')) {
        modal.classList.remove('defaultModal');
        modal.classList.add('fullscreenModal');
        modal.style.height = `100%`;
        modal.style.width = `100%`;
        modal.style.transform = `translate3d(0px, 0px, 0)`;

        const page: any = modal.querySelector('.ion-page');
        page.style.height = `100%`;
        page.style.width = `100%`;
        page.style.transform = `translate3d(0px, 0px, 0)`;
      } else {
        modal.classList.add('defaultModal');
        modal.classList.remove('fullscreenModal');
      }
    } catch (e) {
      console.warn('mazimize failed:', e);
    }
  }

  ngOnInit() {
    this.calcViewVars();
  }

}