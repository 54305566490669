import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-header-categories-toolbar',
  standalone: false,
  templateUrl: './header-categories-toolbar.component.html',
  styleUrls: ['./header-categories-toolbar.component.scss'],
})
export class HeaderCategoriesToolbarComponent implements OnInit {
  @Input() state: state;
  @Input() view: any;

  constructor() { }

  ngOnInit() { }

  setSelectedCategory(category: category) {
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}
