import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AiTextToImageCardComponent } from './ai-text-to-image-card.component';

import { MediaItemsComponentModule } from 'src/app/components/media/media-items/media-items.component.module';

import { AiSettingsPickerComponentModule } from 'src/app/components/ai/ai-settings-picker/ai-settings-picker.component.module';

@NgModule({
  declarations: [
    AiTextToImageCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    MediaItemsComponentModule,
    AiSettingsPickerComponentModule,
  ],
  exports: [
    AiTextToImageCardComponent,
  ]
})
export class AiTextToImageCardComponentModule { }