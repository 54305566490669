import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { CacheService } from 'src/app/services/core/cache.service';

//import { createAction, props } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    //tokens: any = {};

    constructor(
        private cache: CacheService,
    ) {

    }

    clear(tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        return this.cache.remove(cacheKey);
    }

    async get(tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        const fromCache: cacheItem = await this.cache.get(cacheKey, -1);
        return (fromCache && !!fromCache.data ? fromCache.data : null);
    }

    getCacheKey(tokenName: string = 'auth_token') {
        return `pipeline_token_${tokenName}`;
    }

    set(token: string, tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        //this.tokens[tokenName] = token;
        return from(this.cache.set(cacheKey, token));
    }

}